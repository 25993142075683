//@ts-nocheck
import { useEffect, useState } from 'react'
import { AddIcon, CloseIcon } from '@chakra-ui/icons'
import {
	Button,
	FormControl,
	FormLabel,
	HStack,
	IconButton,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Textarea,
	useColorMode,
	useToast,
	VStack,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { editApplication } from '../../../redux/applications/actions'
import { useAppDispatch } from '../../../redux/hooks'
import { getSignedUploadUrlFromS3ForApplicationImage } from '../../../utils/utils'

export const EditApplication = ({ isOpen, onClose, application, allHTTPproviders }) => {
	const [addNewProvider, setAddNewProvider] = useState(false)
	const [applicationImage, setApplicationImage] = useState<File | null>(null)
	const [formData, setFormData] = useState({
		name: application.name,
		publicKey: application.publicKey,
		description: application.description,
		providers: application.httpProvider || [],
		projectName: application.projectName || '',
	})
	const [selectedProviders, setSelectedProviders] = useState(formData.providers)
	const toast = useToast()
	const { colorMode } = useColorMode()
	//const [allHTTPproviders, setAllHTTPProviders] = useState([])
	const dispatch = useAppDispatch()
	useEffect(() => {
		setSelectedProviders(formData.providers)
	}, [formData.providers])

	const handleChange = (e) => {
		const { name, value } = e.target
		setFormData((prevData) => ({ ...prevData, [name]: value }))
	}

	const handleRemoveProvider = (id) => {
		const updatedProviders = formData.providers.filter((provider) => provider.id !== id)
		setFormData((prevData) => ({ ...prevData, providers: updatedProviders }))
	}

	const handleImageChange = (e: any) => {
		const file = e.target.files[0]
		if (file) {
			//   const reader = new FileReader()
			//   reader.onloadend = () => {
			// 		setApplicationImage(reader.result as string)
			//   }

			//   reader.readAsDataURL(file)
			setApplicationImage(file)
		}
	}

	const handleSelectProvider = (selectedOptions) => {
		const selectedIds = selectedOptions.map((option) => option.value)
		const selectedProviders = allHTTPproviders.filter((provider) => selectedIds.includes(provider.id))
		setFormData((prevData) => ({
			...prevData,
			providers: selectedProviders,
		}))
	}

	const editApplicationHandler = async () => {
		try {

			if (formData.providers.length <= 0) {
				toast({
					title: 'Application Edit failed.',
					description: 'Please select at least one provider. This Field cannot be empty.',
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				return
			}

			let uploadedImageUrl = ''

			if (applicationImage) {

				const { error, url } = await getSignedUploadUrlFromS3ForApplicationImage(application.appId, applicationImage)

				if (error) {
					return toast({
						title: 'Application creation failed.',
						description: 'Unable to upload image',
						status: 'error',
						duration: 3000,
						isClosable: true,
						position: 'top-right',
					})
				}

				uploadedImageUrl = url
			}

			const response = await dispatch(editApplication({
				name: formData.name,
				description: formData.description,
				callbackUrl: '',
				appId: application.appId,
				httpProviderId: formData.providers.map((provider) => provider.id),
				whitelistedDomains: [],
				projectName: formData.projectName,
				image: uploadedImageUrl,
			}))

			if (response.meta.requestStatus === 'fulfilled') {
				toast({
					title: 'Application Edited.',
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				onClose()
			} else {
				throw new Error('Edit failed')
			}
		} catch (error) {

			toast({
				title: 'Application Edit failed.',
				description: error.toString(),
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}
	}


	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size='md'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					Edit Application
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>

					<FormControl mt={0}>
						<FormLabel>
							App ID
						</FormLabel>
						<Input
							type='text'
							name='publicKey'
							value={formData.publicKey}
							isDisabled={true}
							onChange={handleChange} />
					</FormControl>

					<FormControl mt={4}>
						<FormLabel>
							Application Name
						</FormLabel>
						<Input
							type='text'
							name='name'
							value={formData.name}
							onChange={handleChange} />
					</FormControl>

					<FormControl mt={4}>
						<FormLabel>
							Project/Company Name
						</FormLabel>
						<Input
							type='text'
							name='projectName'
							placeholder={formData.projectName || 'N/A'}
							value={formData.projectName}
							onChange={handleChange} />
					</FormControl>

					{/* Image Upload */}
					<FormControl mt={4}>
						<FormLabel>
							Application Logo (Optional)
						</FormLabel>
						<Input
							type='file'
							accept='image/*'
							onChange={handleImageChange} />
					</FormControl>


					<FormControl mt={4}>
						<FormLabel>
							Application Description
						</FormLabel>
						<Textarea
							name='description'
							value={formData.description}
							onChange={handleChange} />
					</FormControl>

					<FormControl mt={4}>
						<FormLabel>
							Providers
						</FormLabel>
						<VStack>
							{
								formData.providers.map((provider, index) => (
									<HStack
										key={provider.id || index}
										justifyContent='flex-end'
									>
										<Input
											width='100%'
											value={provider.name}
											isReadOnly />
										<IconButton
											aria-label='Remove provider'
											icon={<CloseIcon />}
											onClick={() => handleRemoveProvider(provider.id)} />
									</HStack>
								))
							}
							{
								!addNewProvider && (
									<Button
										leftIcon={<AddIcon />}
										onClick={() => setAddNewProvider(true)}
										size='sm'>
										Add Provider
									</Button>
								)
							}
							{
								addNewProvider && (
									<FormControl>
										<FormLabel>
											Add New Provider
										</FormLabel>
										<Select
											colorScheme='purple'
											isMulti
											options={allHTTPproviders.map(provider => ({ label: provider.name, value: provider.id }))}
											onChange={handleSelectProvider}
											value={selectedProviders.map(provider => ({ label: provider.name, value: provider.id }))}
										/>
										{
											formData?.providers?.length <= 0 && (
												<Text
													mt={4}
													color='red'>
													Please select at least one provider. This Field cannot be empty.
												</Text>
											)
										}
									</FormControl>
								)
							}
						</VStack>
					</FormControl>
					<HStack w='100%'>
						<Button
							mt={4}
							colorScheme='teal'
							color={colorMode === 'dark' ? 'white' : '#fff'}
							type='submit'
							onClick={editApplicationHandler}
							isDisabled={formData.providers.length <= 0}
							width='100%'>
							Save
						</Button>
						<Button
							mt={4}
							width='100%'
							variant='outline'
							onClick={onClose}>
							Cancel
						</Button>
					</HStack>
				</ModalBody>
				<ModalFooter />
			</ModalContent>
		</Modal>
	)
}

