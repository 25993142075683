import { useEffect, useState } from 'react'
import { getAuth } from 'firebase/auth'
import { v4 as uuid } from 'uuid'
import { app } from '../../configs/firebaseApp'

const logBackendUrl = process.env.REACT_APP_LOGS_URL + '/api/business-logs/devtool'
const searchLogBackendUrl = process.env.REACT_APP_LOGS_URL + '/api/business-logs/search'
const dataSelectionAlertLogsUrl = process.env.REACT_APP_LOGS_URL + '/api/devtool-logs/data-selection'


function isSessionExpired() {
	const lastSessionExpiry = localStorage.getItem('sessionExpiry') || ''
	if (lastSessionExpiry && new Date() > new Date(lastSessionExpiry)) {
		return true
	}

	return false
}

function getSessionId() {
	try {
		let storedSessionId = localStorage.getItem('sessionId') || ''
		if (!storedSessionId || isSessionExpired()) {
			storedSessionId = uuid()
			const expiryTime = new Date()
			expiryTime.setMinutes(expiryTime.getMinutes() + 30)
			localStorage.setItem('sessionId', storedSessionId)
			localStorage.setItem('sessionExpiry', expiryTime.toString())
		}

		return storedSessionId
	} catch (error) {
		console.error('Error getting session id:', error)
	}
}

function skipSendingLogs(logType: string) {
	if (logType === 'PROVIDER_INITD') {
		const providerCreated = localStorage.getItem('providerCreated') || ''
		if (providerCreated === 'false') {
			return true
		}
	} else if (logType === 'WEBSITE_OPENED') {
		const storedSessionId = localStorage.getItem('sessionId') || ''
		if (storedSessionId && !isSessionExpired()) {
			return true
		}
	}

	return false
}

function setLocalStorageOptions(logType: string) {
	if (logType === 'PROVIDER_PUBLISHED') {
		localStorage.setItem('providerCreated', 'true')
	} else if (logType === 'PROVIDER_INITD') {
		const providerCreated = localStorage.getItem('providerCreated') || ''
		if (providerCreated === 'true' || providerCreated === '') {
			localStorage.setItem('providerCreated', 'false')
		}
	}
}

const sendDevtoolLogs = async(logType: string, providerId?: string) => {
	if (process.env.REACT_APP_ENV !== 'production') {
		return
	  }

	try {
		if(skipSendingLogs(logType)) {
			return
		}

		setLocalStorageOptions(logType)

		const sessionId = getSessionId()
		const auth = getAuth(app)
		// check if user is logged in
		const userId = auth.currentUser?.uid

		const response = await fetch(logBackendUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				sessionId,
				userId: userId || '',
				date: new Date().toISOString(),
				logType,
				providerId,
			}),
		})
		return response.json()
	} catch (error) {
		console.error('Error sending logs:', error)
	}
}


// Hook for debouncing a value
function useDebounce(value: string, delay: number) {
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(() => {
		// Set debouncedValue to value (passed in) after the specified delay
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)

		// Return a cleanup function that will be called every time useEffect is re-called.
		// This effectively cancels the previous debounce timer upon value or delay change.
		return () => {
			clearTimeout(handler)
		}
	}, [value, delay]) // Only re-call effect if value or delay changes

	return debouncedValue
}

const sendSearchLogs = async(searchValue: string, isSuccessful: boolean, providerId?: string, providerName?: string) => {
	if (process.env.REACT_APP_ENV !== 'production') {
		return
	  }

	try {
		const auth = getAuth(app)
		const sessionId = getSessionId()
		const userId = auth.currentUser?.uid || ''
		const response = await fetch(searchLogBackendUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				sessionId,
				userId,
				date: new Date().toISOString(),
				searchValue,
				isSuccessful,
				providerId: providerId || '',
				providerName: providerName || '',
			}),
		})
		return response.json()
	} catch (error) {
		console.error('Error sending logs:', error)
	}
}

interface dataSelectionAlertLogs {
	userId: string
	url: string
	contentType: string
	requestType: string
	searchString: string
	requestBody: string
}

const sendDataSelectionErrorLogs = async(messageBody: dataSelectionAlertLogs) => {
	if (process.env.REACT_APP_ENV !== 'production') {
		return
	  }

	try {
		const sessionId = getSessionId()
		const { userId, url, contentType, requestType, searchString, requestBody } = messageBody
		const response = await fetch(dataSelectionAlertLogsUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				sessionId,
				userId,
				date: new Date().toISOString(),
				url,
				contentType,
				requestType,
				searchString,
				requestBody,
			}),
		})
		return response.json()
	} catch (error) {
		console.error('Error sending logs:', error)
	}
}

export { sendDevtoolLogs, sendSearchLogs, useDebounce, sendDataSelectionErrorLogs }