/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react'
import { Box, Card, CardBody, CardHeader, Flex, Heading, Step, StepIcon, StepIndicator, StepNumber, Stepper, StepSeparator, StepStatus, StepTitle, useSteps } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateBackAction } from '../../../../redux/providerBuilder/stepperDetails'
import { getActiveStep, getBackAction, getStepperTitles } from '../../../../redux/providerBuilder/stepperDetails/selectors'
import { getSessionId } from '../../../../redux/session/selectors'
import { sendDevtoolLogs } from '../../../../utils/logs/send-logs'
import { EnterUrl } from './enter-url.component'
import { InstallApp } from './install-app.component'
import { NavigateUrl } from './navigate-url.component'
import { ScanQR } from './scan-qr.component'

interface InitialiseSessionProps {
	accessToken: string
	userUid: string
}

export const InitialiseSession = (props: InitialiseSessionProps) => {
	const dispatch = useAppDispatch()
	const sessionId = useAppSelector(getSessionId)
	const { accessToken, userUid } = props
	const activeStep = useAppSelector(getActiveStep)
	const stepperTitles = useAppSelector(getStepperTitles)
	const backActionUsed = useAppSelector(getBackAction)

	const steps = ['Install Application', 'Scan QR with App', 'Enter Website URL', 'Navigate to Proof Data']

	const { activeStep: activeStepInnerForm, setActiveStep: setActiveStepInnerForm } = useSteps({
		index: 0,
		count: steps.length,
	})

	useEffect(() => {
		sendDevtoolLogs('PROVIDER_INITD')
	}, [])

	useEffect(() => {
		if(backActionUsed) {
			setActiveStepInnerForm(2)
			dispatch(updateBackAction(false))
		}
	}, [backActionUsed, dispatch])

	return (
		<>
			<Box
				marginTop='2.5rem'
				marginBottom='5rem'>
				<Card>
					<CardHeader>
						<Heading fontSize='larger'>
							{stepperTitles[activeStep]}
						</Heading>
					</CardHeader>
					<CardBody>
						<Flex>
							<Stepper
								index={activeStepInnerForm}
								orientation='vertical'
								height='400px'
								gap='0'>
								{
									steps.map((step, index) => (
										<Step key={index}>
											<Flex align='baseline'>
												<Box
													cursor={activeStepInnerForm >= index ? 'pointer' : 'not-allowed'}
													onClick={() => activeStepInnerForm >= index ? setActiveStepInnerForm(index) : null}
													width='145px'
												>
													<StepTitle>
														{step}
													</StepTitle>
												</Box>
												<StepIndicator>
													<StepStatus
														complete={
															<StepIcon
																onClick={() => activeStepInnerForm >= index ? setActiveStepInnerForm(index) : null}
																cursor='pointer'
															/>
														}
														incomplete={<StepNumber />}
														active={<StepNumber />}
													/>
												</StepIndicator>
											</Flex>
											<StepSeparator style={{ marginLeft: '82%' }} />
										</Step>
									))
								}
							</Stepper>
							<Box
								mt='-3%'
								ml='5%'
								padding='3%'
								width='100%'
								alignItems='center'>
								{
									activeStepInnerForm === 0 && (
										<InstallApp
											setActiveStepInnerForm={setActiveStepInnerForm} />
									)
								}
								{
									activeStepInnerForm === 1 && (
										<ScanQR
											setActiveStepInnerForm={setActiveStepInnerForm}
											sessionId={sessionId}
											accessToken={accessToken}
											userUid={userUid} />
									)
								}
								{
									activeStepInnerForm === 2 && (
										<EnterUrl
											accessToken={accessToken}
											sessionId={sessionId}
											setActiveStepInnerForm={setActiveStepInnerForm} />
									)
								}
								{
									activeStepInnerForm === 3 && (
										<NavigateUrl
											setActiveStepInnerForm={setActiveStepInnerForm} />
									)
								}

							</Box>
						</Flex>
					</CardBody>
				</Card>
			</Box>
		</>
	)
}