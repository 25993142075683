import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SearchIcon } from '@chakra-ui/icons'
import {
	Box,
	Flex,
	Grid,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Skeleton,
	useColorMode,
} from '@chakra-ui/react'
import { EmptyState } from '../../../../components'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { HttpsProvider } from '../../../../redux/httpsProviders/types'
import { getAllActiveHttpsProvidersWithManualVerification } from '../../../../redux/manualVerification/providerDetails/actions'
import { getLoading, getManualVerificationHttpsProviders } from '../../../../redux/manualVerification/providerDetails/selectors'
import { pxToRem } from '../../../../utils'
import { ProviderCard } from './provider-card.component'


export const ManualVerificationProvidersScreen = () => {

	const allManualVerificationHttpsProviders = useAppSelector(getManualVerificationHttpsProviders)
	const loading = useAppSelector(getLoading)
	const dispatch = useAppDispatch()

	const { sessionId } = useParams()

	const [searchQuery, setSearchQuery] = useState('')
	const [flteredResponse, setFilteredResponse] = useState<
		HttpsProvider[]
	>([])

	const { colorMode } = useColorMode()


	useEffect(() => {
		searchFilter()
	}, [searchQuery, allManualVerificationHttpsProviders])

	useEffect(() => {
		dispatch(getAllActiveHttpsProvidersWithManualVerification())
	}, [sessionId])

	const searchFilter = () => {
		if (allManualVerificationHttpsProviders) {
			const response = allManualVerificationHttpsProviders.filter(
				(p) => p.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
					p.name.toUpperCase().includes(searchQuery.toUpperCase())
			)
			setFilteredResponse(response)
		}
	}


	return (
		<>
			<Box p='16px'>
				<Box mt='30px'>
					<Heading
						as='h1'
						fontWeight={700}
						size='lg'
						fontSize='24px'>
						Explore Data Providers With Manual Verification
					</Heading>
				</Box>
				<Box mt='20px'>
					<Flex
						gap={pxToRem(10)}
						alignItems='center'
						justify='space-between'>
						<InputGroup>
							<InputRightElement pointerEvents='none'>
								<SearchIcon color='gray.300' />
							</InputRightElement>
							<Input
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
								type='search'
								placeholder='Search Providers'
								bg={colorMode === 'light' ? 'white' : '#131417'}

							/>
						</InputGroup>
					</Flex>
				</Box>
				<Box mt='19px'>
					<Box>
						<Grid
							mt='20px'
							gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
							gap='22px'
						>
							{
								flteredResponse?.length > 0 && (
									<>
										{
											flteredResponse
												?.sort((a, b) => {
													// Sort by isVerified (verified providers first)
													if (a.isVerified && !b.isVerified) {
														return -1
													} else if (!a.isVerified && b.isVerified) {
														return 1
													}

													// If both providers have the same isVerified status, sort alphabetically by name
													return a.name.localeCompare(b.name)
												})
												.map((providerDetails: HttpsProvider) => (
													<ProviderCard
														providerData={providerDetails}
														key={providerDetails?.id}
													/>
												))
										}
									</>
								)
							}
						</Grid>

						{
							loading && (
								<Grid
									mt='20px'
									gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
									gap='22px'
								>
									{
										new Array(10).fill(0).map((_, i) => (
											<Skeleton
												key={i}
												height={pxToRem(117)}
												borderRadius='lg'
												width={pxToRem(371)}
											/>
										))
									}
								</Grid>
							)
						}
						{
							!loading && flteredResponse?.length === 0 && (
								<>
									<EmptyState
										message='No Data Provider Found.'
										navigateTo=''
									/>
								</>
							)
						}
					</Box>
				</Box>
			</Box>
		</>
	)
}

