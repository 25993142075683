import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { getAuth, signOut } from 'firebase/auth'
import 'firebase/auth'
import { app } from '../configs/firebaseApp'
import { useAppDispatch } from '../redux/hooks'
import { logoutClient } from '../redux/user/actions'
import { isTokenExpired } from '../utils'


export const useTokenExpiration = () => {

	const [isExpired, setIsExpired] = useState(false)
	const navigate = useNavigate()
	const auth = getAuth(app)
	const dispatch = useAppDispatch()
	const toast = useToast()

	useEffect(() => {
		const checkTokenExpiration = async () => {
			const tokenExpired = await isTokenExpired(app)
			setIsExpired(tokenExpired)

			if (tokenExpired) {
				toast({
					title: 'Session expired',
					description: 'Please login again.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				})
				signOut(auth)
				dispatch(logoutClient())
				navigate('/')
			}
		}

		const interval = setInterval(checkTokenExpiration, 300000) // Check every 5 minutes

		return () => clearInterval(interval)

	}, [history])


	return isExpired
}

