import { useEffect, useState } from 'react'
import {
	Box,
	Button,
	Card,
	CardBody,
	Code,
	Flex,
	Heading,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stat,
	StatArrow,
	StatHelpText,
	StatLabel,
	StatNumber,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	VStack,
} from '@chakra-ui/react'
import { VideoModal } from '../../../../components'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { getCurrentUrlInput } from '../../../../redux/providerBuilder/mobileClient/selectors'
import { getAllNetworkRequest, getPagesVisted } from '../../../../redux/providerBuilder/networkRequestStore/selectors'
import { resetProviderDetails } from '../../../../redux/providerBuilder/providerDetails'
import { resetSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances'
import { setSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest'
import { getSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest/selectors'
import { updateActiveStep } from '../../../../redux/providerBuilder/stepperDetails'
import { getActiveStep } from '../../../../redux/providerBuilder/stepperDetails/selectors'
import { getUniqueItems } from '../../../../utils/provider-builder/instance-selection-utils'
import { RequestDetails } from './request-details.component'

interface SelectRequestProps {
	setActiveStepInnerForm: (value: any) => void
	setSearchQueryInput: (value: string) => void
	searchQueryInput: string
}

export const SelectRequest = (props: SelectRequestProps) => {
	const dispatch = useAppDispatch()

	const pagesVisited = useAppSelector(getPagesVisted)
	const networkRequests = useAppSelector(getAllNetworkRequest)
	const selectedNetworkRequest = useAppSelector(getSelectedNetworkRequest)
	const activeStep = useAppSelector(getActiveStep)
	const currentUrlInput = useAppSelector(getCurrentUrlInput)

	const { setActiveStepInnerForm, setSearchQueryInput, searchQueryInput } =
		props

	const { isOpen, onOpen, onClose } = useDisclosure()
	const [buttonLoading, setButtonLoading] = useState(false)
	const [filteredNetworkRequestStore, setFilteredNetworkRequestStore] =
		useState<any>([...networkRequests])

	const [videoOpen, setVideoOpen] = useState(false)
	const [tempSelectedNetworkRequest, setTempSelectedNetworkRequest] = useState<any>(selectedNetworkRequest)

	useEffect(() => {
		handleSearch(searchQueryInput)
	}, [networkRequests])

	function handleSearch(searchString: string): void {
		setSearchQueryInput(searchString)
		const filteredRequests = networkRequests.filter((networkReq: any) => {
			return (
				networkReq.url.includes(searchString) ||
				(networkReq.response.body
					? networkReq.response?.body.includes(searchString)
					: false)
			)
		})
		setFilteredNetworkRequestStore([...filteredRequests])
	}

	function handleRequestClick(networkRequest: any) {
		setTempSelectedNetworkRequest(networkRequest)
	}

	function handleNext() {

		if (selectedNetworkRequest?.id !== tempSelectedNetworkRequest?.id) {
			dispatch(resetSelectedInstances())
			dispatch(resetProviderDetails())
			dispatch(setSelectedNetworkRequest(tempSelectedNetworkRequest))
		}

		setButtonLoading(true)
		setActiveStepInnerForm(1)
	}


	return (
		<>
			<Box
				width='100%'
				height='100%'
			>
				<VStack>
					<Box
						borderBottom='2px solid #F2F4F7'
						w='full'
						pb={10}
						mb={10}
					>
						<Flex>
							<Box flex={0.8}>
								<Heading
									fontSize='large'
									mb={2}
								>
									Requests Captured
								</Heading>
								<Text
									fontSize='medium'
									lineHeight='24px'
									color='#98A2B3'
								>
									This data represents the amount of events captured by
									<br />
									our dev tool from the Reclaim App
								</Text>
							</Box>
							<Box flex={1}>
								<Flex gap={5}>
									<Card
										variant='outline'
										flex={1}
										borderRadius={10}
									>
										<CardBody>
											<Stat>
												<StatLabel mb={3}>
													Pages visited
												</StatLabel>
												<StatNumber mb={2}>
													<Flex
														alignItems='center'
														gap={2}>
														{getUniqueItems(pagesVisited).length}
														<StatArrow type='increase' />
													</Flex>
												</StatNumber>
												<StatHelpText>

													Number of unique pages visited
												</StatHelpText>
											</Stat>
										</CardBody>
									</Card>
									<Card
										variant='outline'
										flex={1}
										borderRadius={10}
									>
										<CardBody>
											<Stat>
												<StatLabel mb={3}>
													Requests captured
												</StatLabel>
												<StatNumber mb={2}>
													<Flex
														alignItems='center'
														gap={2}>
														{networkRequests.length}
														<StatArrow type='increase' />
													</Flex>
												</StatNumber>
												<StatHelpText>
													Number of Requests Captured by the dev tool
												</StatHelpText>
											</Stat>
										</CardBody>
									</Card>
								</Flex>
							</Box>
						</Flex>
					</Box>
					<Box
						w='full'
						mb={2}>
						<Flex alignItems='flex-start'>
							<Box flex={1}>
								<Heading
									fontSize='large'
									mb={2}
								>
									Search data instances
								</Heading>
								<Text
									fontSize='medium'
									lineHeight='24px'
									color='#98A2B3'>
									Search the data you want to verify
								</Text>
							</Box>
							<Box flex={1} >
								<Flex
									alignItems='center'
									justifyContent='flex-end'
									gap={3}>
									<Text
										style={{ textWrap: 'nowrap' }}
										fontWeight={600}
										fontSize='14px'
										color='#475467'
										whiteSpace='nowrap'
										textOverflow='ellipsis'>
										Current URL
									</Text>
									<Text
										border='1px solid #D0D5DD'
										px='12px'
										py='8px'
										borderRadius='8px'
										fontSize='small'
										color='#667085'
										whiteSpace='nowrap'
										textOverflow='ellipsis'
										overflow='hidden'
										maxW='400px'
									>
										{currentUrlInput}
										{currentUrlInput}
										{currentUrlInput}
									</Text>
									{/* <Input
										placeholder='https://google.com'
										value={currentUrlInput}
										disabled
										_placeholder={{ color: '#667085', fontSize: '14px' }} /> */}
								</Flex>
							</Box>
						</Flex>
					</Box>
					<Box w='full'>
						<Input
							placeholder='Email_id, account, username...'
							value={searchQueryInput}
							onChange={(e) => handleSearch(e.target.value)}
							_placeholder={{ color: '#667085', fontSize: '14px' }} />
					</Box>
					<Box
						w='full'
						scrollMarginLeft={10}
						overflow='auto'
						maxHeight='500px'
						borderWidth={1}
						borderRadius={10}
					>
						<TableContainer>
							<Table
								variant='simple'
								border='ActiveBorder'
								borderRadius='4px'
								borderColor='black'
							>
								<Thead>
									<Tr>

										<Th>
											Content Type
										</Th>
										<Th>
											Request URL
										</Th>
										<Th>
											Request Type
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									{
										filteredNetworkRequestStore.map((e: any) => (
											<Tr
												key={e.id}
												onClick={
													() => {
														handleRequestClick(e)
														onOpen()

													}
												}
												cursor='pointer'
												_hover={
													tempSelectedNetworkRequest?.id === e.id
														? { bg: 'blue.200' }
														: { bg: 'gray.100' }
												}
												bg={
													tempSelectedNetworkRequest?.id === e.id
														? 'blue.200'
														: 'white'
												}
											>
												<Td>
													<Code
														backgroundColor='transparent'
														color={
															tempSelectedNetworkRequest?.id === e.id
																? 'white'
																: 'black'
														}>
														{e.contentType}
													</Code>
												</Td>
												<Td
													maxW='500px'
													overflow='hidden'
													whiteSpace='nowrap'
													textOverflow='ellipsis'>
													<Text
														color={
															tempSelectedNetworkRequest?.id === e.id
																? 'white'
																: 'black'
														}>
														{e.url}
													</Text>

												</Td>

												<Td>
													<Text
														color={
															tempSelectedNetworkRequest?.id === e.id
																? 'white'
																: 'black'
														}>
														{e.requestType}
													</Text>
												</Td>
											</Tr>
										))
									}

								</Tbody>
							</Table>
						</TableContainer>
					</Box>
					<Box
						w='full'
						mt={10}>
						<Flex
							w='full'
							justifyContent='space-between'>
							<Button onClick={() => dispatch(updateActiveStep(activeStep - 1))}>
								Back
							</Button>

						</Flex>
					</Box>
				</VStack>
				<Modal
					onClose={onClose}
					size='6xl'
					isOpen={isOpen}
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader
							backgroundColor='#FCFCFD'
							borderBottom='1px solid #EFF1F5'>
							<Text color='#667085'>
								Request Data
							</Text>
						</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<RequestDetails selectedNetworkRequest={tempSelectedNetworkRequest} />
						</ModalBody>
						<ModalFooter>
							<Flex
								w='full'
								justifyContent='space-between'>
								<Button onClick={onClose}>
									Cancel
								</Button>
								<Button
									onClick={handleNext}
									bgColor='#332FED'
									color='white'
									isLoading={buttonLoading}
									isDisabled={!tempSelectedNetworkRequest?.url}
								>
									Next
								</Button>
							</Flex>
						</ModalFooter>
					</ModalContent>
				</Modal>
				<VideoModal
					isOpen={videoOpen}
					closeModal={() => setVideoOpen(false)}
					buttonText='Need Help? Watch Video Tutorial'
					modalTitle='Video Tutorial'
				/>
			</Box>
		</>
	)
}


