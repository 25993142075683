/* eslint-disable react/jsx-indent */
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { toggleExpandedPath } from '../../../../redux/providerBuilder/jsonViewer'
import { getExpandedPaths } from '../../../../redux/providerBuilder/jsonViewer/selectors'

interface JsonViewerProps {
	data?: any
	path?: string
	onItemClick?: any
	level?: number
	searchQuery?: any
}

const CustomJsonViewer: React.FC<JsonViewerProps> = ({
	data,
	path = '',
	onItemClick,
	level = 0,
	searchQuery = '',
}) => {
	const expandedPaths = useAppSelector(getExpandedPaths)
	const dispatch = useAppDispatch()

	const toggleExpand = (path: any) => {
		dispatch(toggleExpandedPath(path))
	}


	if (typeof data !== 'object' || data === null) {
		return (
			<div
				className='json-item'
				style={{ paddingLeft: `${5}px` }}
				onClick={() => onItemClick(path)}
			>
				<span className='json-value'>
					{String(data)}
				</span>
			</div>
		)
	}

	const isArray: boolean = Array.isArray(data)
	const entries: any = Object.entries(data)
	const isExpanded: boolean = expandedPaths[path]

	return (
		<div className='json-container font-roboto-mono-regular'>
			<span style={{ paddingLeft: `${5}px` }}>
				{isArray ? '[' : '{'}
				{
					entries.length > 0 && (
						<span
							className='toggle-button'
							onClick={() => toggleExpand(path)}>
							{isExpanded ? '[-]' : '[+]'}
						</span>
					)
				}
			</span>
			{
				isExpanded &&
				entries.map(([key, value]: any[], index: number) => (
					<div
						key={path + key}
						className='json-item font-roboto-mono-regular'>
						{
							!isArray &&
							(String(value)?.includes('{{REQ_BODY_') ||
								String(value)?.includes('{{REQ_SECRET_') ? (
									<span
										className='json-key highlight font-roboto-mono-regular'
										onClick={() => onItemClick(path + (isArray ? `[${key}]` : `.${key}`))}
									>
										{key}
									</span>
								) : (
									<span
										className='json-key font-roboto-mono-regular'
										onClick={() => onItemClick(path + (isArray ? `[${key}]` : `.${key}`))}
									>
										{key}
									</span>
								))
						}
						{!isArray && ': '}
						{
							typeof value === 'object' && value !== null ? (
								<div
									style={
										{
											display: 'flex',
											flexDirection: 'column',
										}
									}
								>
									<CustomJsonViewer
										data={value}
										path={`${path}${isArray ? `[${key}]` : `.${key}`}`}
										onItemClick={onItemClick}
										level={level + 1}
										searchQuery={searchQuery}
									/>
								</div>
							) : (
								<span
									className={
										`json-value ${(String(value)?.includes('{{REQ_BODY_') ||
											String(value)?.includes('{{REQ_SECRET_')) &&
										'highlight'
										} font-roboto-mono-regular`
									}
									onClick={() => onItemClick(path + (isArray ? `[${key}]` : `.${key}`))}
									style={{ marginLeft: '5px' }}
								>
									{
										typeof value === 'string' && (
											<>
												&quot;
											</>
										)
									}
									{String(value)}
									{
										typeof value === 'string' && (
											<>
												&quot;
											</>
										)
									}
									{index < entries.length - 1 && ','}
								</span>
							)
						}
					</div>
				))
			}
			<span style={{ paddingLeft: `${5}px` }}>
				{isArray ? ']' : '}'}
			</span>
		</div>
	)
}

export default CustomJsonViewer
