import React from 'react'
import {
	Box,
	Center,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react'

export const VideoModal = ({ isOpen, closeModal, modalTitle }: any) => {


	return (
		<Box>

			<Modal
				size='2xl'
				isOpen={isOpen}
				onClose={closeModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{modalTitle}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box p='30px'>
							<Center>
								<iframe
									width='560'
									height='315'
									src='https://www.youtube.com/embed/RE9u1_4r5Xw'
									title='YouTube video player'
									frameBorder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
									allowFullScreen
								/>
							</Center>
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	)
}

