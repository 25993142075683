import { chatGPT } from '../chat-gpt'

// Extract Variable Names from the text containing {{}} and return a list of them
function getAllVariableNamesInText(text: string): string[] {
	const regex: RegExp = /({{.*?}})|\S+/g
	const matches: string[] = text.match(regex) || []
	const variableNames = matches
		.filter((match) => match.startsWith('{{') && match.endsWith('}}'))
		.map((match) => match.substring(2, match.length - 2))
	return variableNames
}

// Extract Unused Variable Names from the proof card text to give suggestions
function getUnusedVariables(proofText: string, selectedInstances: any): any {
	const currentUsedVariables = getAllVariableNamesInText(proofText)
	const newAvailableVariables = selectedInstances
		.map((instance: any) => {
			if(currentUsedVariables.includes(instance.variableName)) {
				return { name: instance.variableName, isUsed: true }
			}

			return { name: instance.variableName, isUsed: false }
		})
		.filter((v: any) => v !== null)
	return newAvailableVariables
}

// Split Proof Text into plain text and variables for styling purposes
function seperateProofCardText(proofText: string): any {
	// Define a regular expression to match anything inside double curly braces, including '{{' and '}}'
	const regex: RegExp = /({{.*?}})|\S+/g

	// Extract matches from the input string
	const matches: string[] = proofText.match(regex) || []

	const finalList = []
	let strval = ''
	for(let i = 0; i < matches.length; i++) {
		const match = matches[i]
		if(match.startsWith('{{') && match.endsWith('}}')) {
			if(strval.length) {
				finalList.push({ type: 'string', value: strval.trim() })
			}

			strval = ''
			finalList.push({ type: 'variable', value: match })
		} else {
			strval += match + ' '
		}
	}

	if(strval.length) {
		finalList.push({ type: 'string', value: strval.trim() })
	}

	return finalList
}

// get proof card text from chatGPT
async function generateChatGPTProofCardText(selectedInstanaces: any): Promise<string> {
	const contextStr =
        'We are building an App which shows data ownership to end users. We need a human like message for data ownership and use all variable names'
	let promptStr =
        'Give a short string template in active voice for end user indicating that they own the data with variable names '
	promptStr += selectedInstanaces
		.map((variable: any) => {
			return '{{' + variable.variableName + '}}'
		})
		.join(' ')
	try {
		const responseVal = await chatGPT(contextStr, promptStr)
		if(responseVal.status === 'success') {
			return responseVal.message.toString()
		} else {
			const proofCardTextVal = ['Owns Data']
			proofCardTextVal.push(
				...selectedInstanaces.map((variable: any) => {
					return '{{' + variable.variableName + '}}'
				})
			)
			return proofCardTextVal.join(' ')
		}
	} catch(e) {
		console.log('error while generating proof card text from chatGPT' + e)
		return ''
	}

}

// Extract Domain Name from the url
function extractDomain(url: string): string {
	const domain = new URL(url)
	return domain.host.toString()
}


export {
	getAllVariableNamesInText,
	getUnusedVariables,
	seperateProofCardText,
	generateChatGPTProofCardText,
	extractDomain,
}