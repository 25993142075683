import { useEffect, useState } from 'react'
import { HiClipboard } from 'react-icons/hi'
import { useLocation, useParams } from 'react-router-dom'
import { Alert, AlertIcon, Box, Button, Flex, Heading, HStack, IconButton, Input, InputGroup, InputRightElement, Spinner, Text, useColorMode, useToast } from '@chakra-ui/react'
import axios from 'axios'
import { DOCS_LINKS, pxToRem, ZKFETCH_APPLICATION_BY_ID } from '../../utils'
import { getFirebaseIdToken } from '../../utils/utils'
import { DocsCard } from './components/DocsCard'
import { CodeSegment } from './components/public-data-code.component'

interface Application {
	id: string
	appId: string
	createdAt: string
	updatedAt: string
	applicationName: string
	publicKey: string
	creatorUid: string
	description: string
	projectName: string
}


export const IntegrationDetailsForPublicData = () => {


	const [showPrivateKey, setShowPrivateKey] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const [application, setApplication] = useState<Application | null>(null)

	const { appId } = useParams()
	const { state } = useLocation()

	const { colorMode } = useColorMode()
	const toast = useToast()

	const fetchApplication = async () => {
		setIsLoading(true)
		try {
			const accessToken = await getFirebaseIdToken()
			const response = await axios.get(`${ZKFETCH_APPLICATION_BY_ID(appId as string)}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			})
			setApplication(response?.data?.application)
			setIsLoading(false)
		} catch (error) {
			toast({
				title: 'Error',
				description: 'Error Fetching Application',
				status: 'error',
				duration: 3000,
				isClosable: true,
			})
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchApplication()
	}, [])


	return (
		<>
			{
				isLoading ? <Spinner /> : (
					<>

						<Box p='6px'>
							<Box mt='16px'>
								<Box mt='16'>
									<Heading
										as='h1'
										fontWeight={700}
										size='lg'
										fontSize='24px'>
										Integrate with your App
									</Heading>
									<Text mt='8px'>
										You have Successfully registered the application. follow the guide
										to integrate
									</Text>
								</Box>

								<Box
									mt='30px'
									bgColor={colorMode === 'dark' ? '#131417' : '#fff'}
									p={pxToRem(18)}

									borderRadius='md'
								>
									<Box
										mb='10px'

									>
										<Text
											fontWeight={500}
											fontSize='16px'
											mb={pxToRem(8)}>
											App Name
										</Text>
										<HStack>
											<InputGroup minW='450px'>
												<Input
													type='text'
													value={application?.applicationName}
													readOnly
												/>
											</InputGroup>
										</HStack>
									</Box>
									<Box>
										<Text
											fontWeight={500}
											fontSize='16px'
											mb={pxToRem(8)}>
											App ID
										</Text>
										<HStack>
											<InputGroup minW='450px'>
												<Input
													type='text'
													value={application?.appId}
													readOnly
												/>
											</InputGroup>
											<IconButton
												ml='0'
												size='sm'
												icon={<HiClipboard />}
												onClick={
													() => {
														//copy the value and show toast
														navigator.clipboard.writeText(application?.appId as string)
														toast({
															title: 'Public Key Copied',
															status: 'success',
															duration: 3000,
															isClosable: true,
														})
													}
												}
												aria-label='Copy Secret Key'
											/>
										</HStack>
									</Box>

									<Box mt='10px'>
										<Text
											fontWeight={500}
											fontSize='16px'
											mb={pxToRem(8)}>
											App Secret
										</Text>
										<HStack>
											<InputGroup minW='450px'>
												<Input
													type={showPrivateKey ? 'text' : 'password'}
													value={state?.privateKey ? state?.privateKey : 'YOUR APP SECRET'}
													readOnly
												/>
												<InputRightElement width='4.5rem'>
													<Button
														h='1.75rem'
														size='sm'
														onClick={() => setShowPrivateKey(!showPrivateKey)}
													>
														{showPrivateKey ? 'Hide' : 'Show'}
													</Button>
												</InputRightElement>
											</InputGroup>
											<IconButton
												ml='0'
												size='sm'
												icon={<HiClipboard />}
												onClick={
													() => {
														window.navigator.clipboard.writeText(state?.privateKey as string ?? '')
														toast({
															title: 'Secret Key Copied',
															status: 'success',
															duration: 3000,
															isClosable: true,
														})
													}
												}
												aria-label='Copy Secret Key'
											/>
										</HStack>
									</Box>

									{
										state?.privateKey ? (
											<Alert
												borderRadius='md'
												status='warning'
												mt='4'>
												<AlertIcon />
												Before leaving this page, please be aware that you will no longer
												have access to view your private key, and we wont be able to
												assist you in recovering it. Make sure to copy it and securely
												store it before proceeding.
											</Alert>
										) : (
											<Alert
												borderRadius='md'
												status='warning'
												mt='4'>
												<AlertIcon />
												Use your App Secret Key, which was generated while creating the
												application
											</Alert>
										)
									}


									<>
										<Flex
											flexDir='column'
											gap={2}
											mt='40px'>
											<Text mb='16px'>
												Choose a Library/Framework:
												{' '}
												{/* {isProduction ? 'Production' : 'Prototype'} */}
											</Text>
											<CodeSegment
												application={{ ...application, privateKey: state?.privateKey ?? 'YOUR_APP_SECRET' }}

											/>
										</Flex>
									</>
								</Box>

								<Box mt={pxToRem(30)}>
									<Heading
										as='h1'
										fontWeight={700}
										size='lg'
										fontSize='24px'>
										View Docs
									</Heading>
									<Text mt='8px'>
										Looking for in Depth Guide? Checkout the docs for detailed
										explaination
									</Text>

									<HStack
										borderRadius='lg'
										bgColor={colorMode === 'dark' ? '#131417' : 'white'}
										mt='40px'
										p={pxToRem(10)}
									>
										{
											DOCS_LINKS?.map((d) => (
												<DocsCard
													key={d.name}
													name={d.name}
													docsLink={d.docsLink}
													icon={d.icon}
												/>
											))
										}
									</HStack>
								</Box>
							</Box>
						</Box>
					</>
				)
			}
		</>
	)
}
