import { useEffect } from 'react'
import { redirect, useNavigate, useSearchParams } from 'react-router-dom'

export const FirebaseSessionScreen = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	useEffect(() => {
		const payload = searchParams.get('payload')
		if(!payload) {
			redirect('/')
		} else {
			const payloadJson = JSON.parse(payload)
			Object.keys(payloadJson).map((key) => localStorage.setItem(key, payloadJson[key])
			)
			navigate('/dashboard')
		}
	}, [searchParams])

	return <div />
}
