import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HttpsProvider } from '../../httpsProviders/types'

interface manualVerificationHttpsProviders {
	isLoading?: boolean
	manualVerificationHttpsProviders: HttpsProvider[]
	selectedManualVerificationSessionProviderData: any
}

const initialState: manualVerificationHttpsProviders = {
	isLoading: false,
	manualVerificationHttpsProviders: [],
	selectedManualVerificationSessionProviderData: {}
}

const manualVerificationHttpProvidersSlice = createSlice({
	name: 'manualVerificationHttpsProviders',
	initialState,
	reducers: {
		setLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload
		},
		setManualVerificationHttpsProvider(state, action: PayloadAction<HttpsProvider[]>) {
			state.manualVerificationHttpsProviders = action.payload
		},
		setSelectedManualVerificationSessionProviderData(state, action: PayloadAction<HttpsProvider>) {
			state.selectedManualVerificationSessionProviderData = action.payload
		},
	},
})

export const {
	setLoading,
	setManualVerificationHttpsProvider,
	setSelectedManualVerificationSessionProviderData
} = manualVerificationHttpProvidersSlice.actions
export default manualVerificationHttpProvidersSlice.reducer
