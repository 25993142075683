import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProviderDetails } from './types'

const initialState: ProviderDetails = {
	name: '',
	httpProviderId: '',
	description: '',
	loginUrl: '',
	url: '',
	urlType: '',
	responseSelections: [],
	bodySniff: {
		enabled: false,
		template: '',
		regex: '',
	},
	customInjection: '',
	geoLocation: '',
	proofCardText: '',
	proofCardTitle: '',
	logoUrl: '',
	method: '',
	isApproved: false,
	isActive: false,
	isVerified: false,
	userAgent: {
		ios: '',
		android: '',
	},
	providerType: 'PUBLIC',
	verificationType: 'WITNESS'
}

const providerDetailsSlice = createSlice({
	name: 'providerDetails',
	initialState,
	reducers: {
		// set only partial state
		updateProviderDetails(state, action: PayloadAction<Partial<ProviderDetails>>) {
			Object.assign(state, action.payload)
		},
		resetProviderDetails() {
			return { ...initialState }
		}
	},
})

export const { updateProviderDetails, resetProviderDetails } = providerDetailsSlice.actions

export default providerDetailsSlice.reducer
