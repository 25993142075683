/* eslint-disable @typescript-eslint/no-unused-vars */
import { SetStateAction, useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
	Box,
	Button,
	Collapse,
	Flex,
	FormControl,
	FormLabel,
	Icon,
	Input,
	Select,
	Text,
	Textarea,
	Tooltip,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateProviderDetails } from '../../../../redux/providerBuilder/providerDetails'
import { getProviderDetails } from '../../../../redux/providerBuilder/providerDetails/selectors'
import {
	deleteSelectedInstance,
	updateResponseSelection,
} from '../../../../redux/providerBuilder/selectedInstances'
import { getSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances/selectors'

export const OptionalFields = () => {
	const dispatch = useAppDispatch()

	const selectedInstances = useAppSelector(getSelectedInstances)
	const providerDetails = useAppSelector(getProviderDetails)
	const userType = useAppSelector((state) => state.user.userType)

	const countryCodes = require('../../../../utils/data/country-codes.json')

	const [jsInjection, setJsInjection] = useState('')
	const [geoLocation, setGeoLocation] = useState('')
	const [providerType, setProviderType] = useState(providerDetails?.providerType ?? 'PRIVATE')

	const [show, setShow] = useState(false)

	const handleToggle = () => setShow(!show)


	useEffect(() => {
		dispatch(
			updateProviderDetails({
				customInjection: jsInjection,
				isApproved: !jsInjection || userType === 'ADMIN',
			})
		)
	}, [jsInjection])

	useEffect(() => {
		dispatch(updateProviderDetails({ geoLocation: geoLocation }))
	}, [geoLocation])

	useEffect(() => {
		dispatch(updateProviderDetails({ providerType: providerType }))
	}, [providerType])

	useEffect(() => {
		const allResponseSelections = selectedInstances.map(
			(instance: any) => instance.responseSelection
		)
		dispatch(
			updateProviderDetails({ responseSelections: allResponseSelections })
		)
	}, [selectedInstances])


	return (
		<>
			<Box mt='20px'>
				<Button
					onClick={handleToggle}
					colorScheme='black'
					variant='outline'
					borderColor='black.500'
					borderWidth='1px'
					width='100%'
					rightIcon={show ? <ChevronUpIcon /> : <ChevronDownIcon />}
					mb={4}
				>
					Additional Config
				</Button>
				<Collapse in={show}>
					<Box
						p={4}
						borderWidth='1px'
						borderRadius='md'>
						{/* Geolocation Select */}
						<FormControl
							id='geolocation'
							mb={4}>
							<FormLabel>
								Geolocation
							</FormLabel>
							<Select
								placeholder='None'
								value={geoLocation}
								onChange={
									(e) => {
										setGeoLocation(e.target.value)
									}
								}
							>
								{
									countryCodes.map(
										(countryCode: { Code: string, Name: string }) => (
											<option
												key={countryCode.Code}
												value={countryCode.Code}>
												{countryCode.Name}
											</option>
										)
									)
								}
							</Select>
						</FormControl>

						{/* Provider Type Select */}
						<FormControl
							id='provider-type'
							mb={4}>
							<FormLabel>
								Provider Type
							</FormLabel>
							<Tooltip
								label={
									providerType === 'PUBLIC'
										? 'Public Providers Need Successful Proof Generation to be published'
										: ''
								}
								aria-label='A tooltip'
							>
								<Select
									value={providerType}
									onChange={
										(e) => {
											setProviderType(e.target.value)
										}
									}
								>
									<option value='PRIVATE'>
										PRIVATE
									</option>

									<option value='PUBLIC'>
										PUBLIC
									</option>
								</Select>
							</Tooltip>
						</FormControl>

						{/* JS Injection Textarea */}
						<FormControl
							id='provider-variables'
							mb={4}>
							<FormLabel>
								Variable Details
							</FormLabel>
							<Box>
								{
									selectedInstances.map((instance: any, i: any) => (
										<Box
											key={i}
											p={1}
											width='100%'
											mb={5}>
											<Flex
												direction='column'
												gap={2}>
												<Flex
													alignItems='center'
													mt={1}>
													<Text
														fontSize='md'
														minW='120px'
														mt='1.5'
														fontWeight='bold'
														flex={1}>
														{instance?.variableName ?? ''}

													</Text>
													{
														selectedInstances.length > 1 && (
															<Button maxW='30%'>
																<Icon
																	as={MdDelete}
																	w={10}
																	h={5}
																	color='white.500'
																	onClick={
																		() => {
																			dispatch(deleteSelectedInstance(i))
																		}
																	}
																/>
															</Button>
														)
													}
												</Flex>
												<Flex>
													<Text
														fontSize='md'
														minW='120px'
														mt='1.5'
														fontWeight='bold'>
														Regex:
													</Text>

													<Input
														value={instance.responseSelection.responseMatch}
														style={
															{
																color: 'black',
																borderRadius: '5px',
																border: '1px solid black',
															}
														}
														onChange={
															(e) => {
																dispatch(
																	updateResponseSelection({
																		index: i,
																		responseSelection: {
																			xPath: instance.responseSelection.xPath,
																			jsonPath: instance.responseSelection.jsonPath,
																			responseMatch: e.target.value,
																			invariant: instance.invariant,
																		},
																	})
																)
															}
														}
													/>
												</Flex>

												<Flex>
													<Text
														fontSize='md'
														minW='120px'
														mt='1.5'
														fontWeight='bold'>
														{
															instance.responseSelection?.xPath
																? 'Xpath:'
																: 'JsonPath:'
														}
													</Text>

													<Input
														// maxW='30%'
														// mr='80px'
														value={
															instance.responseSelection?.xPath
																? instance.responseSelection.xPath
																: instance.responseSelection.jsonPath
														}
														style={
															{
																color: 'black',
																borderRadius: '5px',
																border: '1px solid black',
															}
														}
														onChange={
															(e) => {
																if (instance.responseSelection.xPath) {
																	dispatch(
																		updateResponseSelection({
																			index: i,
																			responseSelection: {
																				xPath: e.target.value,
																				jsonPath: instance.responseSelection.jsonPath,
																				responseMatch:
																					instance.responseSelection.responseMatch,
																				invariant: instance.invariant,
																			},
																		})
																	)
																} else {
																	dispatch(
																		updateResponseSelection({
																			index: i,
																			responseSelection: {
																				xPath: instance.responseSelection.xPath,
																				jsonPath: e.target.value,
																				responseMatch:
																					instance.responseSelection.responseMatch,
																				invariant: instance.invariant,
																			},
																		})
																	)
																}
															}
														}
													/>
												</Flex>


											</Flex>
										</Box>
									))
								}
							</Box>
						</FormControl>
						<FormControl id='js-injection'>
							<FormLabel>
								JS Injection
							</FormLabel>
							<Text>
								<Tooltip
									label='Providers with Custom Injection are subjected to admin approval'
									aria-label='A tooltip'
								>
									<Textarea
										placeholder='JS Injection to run on the website'
										required={false}
										value={jsInjection}
										style={
											{
												color: 'black',
												borderRadius: '5px',
												border: '1px solid black',
											}
										}
										onChange={
											(e: {
												target: { value: SetStateAction<string> }
											}) => setJsInjection(e.target.value)
										}
									/>
								</Tooltip>
							</Text>
						</FormControl>
					</Box>
				</Collapse>
			</Box>
		</>
	)
}
