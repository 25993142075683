import { useEffect } from 'react'
import { getAuth } from 'firebase/auth'
import { app } from '../configs/firebaseApp'

declare global {
  interface Window {
    Papercups: any
  }
}

export const usePapercupsWidget = () => {
	const user = getAuth(app)
	useEffect(() => {
		// Papercups configuration
		window.Papercups = {
			config: {
				token: 'c2b8d6bc-469d-49c0-9494-e9811a78cbb4', //TODO: move to env
				inbox: 'e7a96b6e-183c-4c64-9e87-0f7b691c7ef1',
				title: 'Welcome to Reclaim Support.',
				subtitle: 'Ask us anything in the chat window below 😊',
				primaryColor: '#1890ff',
				newMessagePlaceholder: 'Start typing...',
				showAgentAvailability: true,
				agentAvailableText: "We're online right now!",
				agentUnavailableText: "We're away at the moment.",
				requireEmailUpfront: false,
				iconVariant: 'outlined',
				baseUrl: 'https://app.papercups.io',
				customer:{
					name: user?.currentUser?.displayName ? user?.currentUser?.displayName : 'Anonymous',
					email: user?.currentUser?.email ? user.currentUser.email : '',
					metadata:{
						userId: user?.currentUser?.uid ? user?.currentUser?.uid : ''
					}
				}

			},
		}

		// Create a script element
		const script = document.createElement('script')
		script.type = 'text/javascript'
		script.async = true
		script.defer = true
		script.src = 'https://app.papercups.io/widget.js'
		script.className = 'papercups-script'

		document.body.appendChild(script)

		return () => {
			document.body.removeChild(script)
		}
	}, [])
}

