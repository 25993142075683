import { useContext, useState } from 'react'
import { MdThumbUp } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { Alert, Box, Button, Card, CardBody, CardHeader, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import { AmplitudeAnalyticsContext } from '../../../contexts/useAnalytics'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { resetNetworkStore } from '../../../redux/providerBuilder/networkRequestStore'
import { resetProviderDetails } from '../../../redux/providerBuilder/providerDetails'
import { resetSelectedInstances } from '../../../redux/providerBuilder/selectedInstances'
import { resetSelectedNetworkRequest } from '../../../redux/providerBuilder/selectedNetworkRequest'
import { resetActiveStep } from '../../../redux/providerBuilder/stepperDetails'
import { getActiveStep, getStepperTitles } from '../../../redux/providerBuilder/stepperDetails/selectors'

export const ReviewProvider = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const activeStep = useAppSelector(getActiveStep)
	const stepperTitles = useAppSelector(getStepperTitles)

	const [buttonLoading, setButtonLoading] = useState(false)

	const { logEvent } = useContext(AmplitudeAnalyticsContext)

	function handleFinish() {
		setButtonLoading(true)
		dispatch(resetNetworkStore())
		dispatch(resetProviderDetails())
		dispatch(resetSelectedInstances())
		dispatch(resetSelectedNetworkRequest())
		dispatch(resetActiveStep())
		logEvent('PROVIDER_CREATED')
		navigate('/new-application')
	}

	return (
		<>
			<Box
				marginTop='2.5rem'
				marginBottom='5rem'>
				<Card>
					<CardHeader>
						<Heading fontSize='larger'>
							{stepperTitles[activeStep]}
						</Heading>
					</CardHeader>
					<CardBody height='80%' >
						<Box>
							<Alert status='success'>
								You have Successfully built a data provider&nbsp;&nbsp;
								<Icon as={MdThumbUp} />
							</Alert>
							<Heading
								mt='40px'
								fontSize='large'
								mb='20px'>
								Test your provider in the Reclaim Protocol app
							</Heading>
							<Text
								mt='10px'
								fontSize='medium'
								mb='50px'>
								Note: Please ensure that you are getting the correct data from the provider and the Proof Card is being generated as per your customized Configurations.
							</Text>
							<Flex
								mt='20px'
								justifyContent='right'
								maxW='100%'>
								<Button
									marginTop='1.5rem'
									variant='solid'
									mr='20px'
									onClick={handleFinish}
									colorScheme='blue'
									isLoading={buttonLoading}
								>
									Finish
								</Button>
							</Flex>
						</Box>
					</CardBody>
				</Card>
			</Box>
		</>
	)
}