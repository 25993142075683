/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import { useEffect, useState } from 'react'
import { AddIcon, ArrowBackIcon, ChevronDownIcon, ChevronUpIcon, DeleteIcon } from '@chakra-ui/icons'
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Card,
    CardBody,
    Code,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Table,
    TableContainer,
    Td,
    Text,
    Th,
    Tooltip,
    Tr,
    useDisclosure,
    useToast,
    VStack
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { getSelectedManualVerificationSessionData, getSelectRequestScreenClickedParameterName, getSelectRequestScreenSearchQuery } from '../../../../redux/manualVerification/sessionsDetails/selectors'
import { updateActiveStep as updateManualVerificationActiveStep } from '../../../../redux/manualVerification/stepperDetails'
import { updateExpandedPaths } from '../../../../redux/providerBuilder/jsonViewer'
import {
    deleteSelectedInstance,
    updateSelectedInstances,
} from '../../../../redux/providerBuilder/selectedInstances'
import { getSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances/selectors'
import { getSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest/selectors'
import {
    createXPathFromElement,
    escapeRegExp,
    getAllPaths,
    getMatchIndexes,
    getValueByPath,
} from '../../../../utils/provider-builder/instance-selection-utils'
import CustomJsonViewer from '../../../provider-builder-v3/components/request-selection/custom-json-viewer'

export const ManualVerificationSelectDataScreen = () => {
    const toast = useToast()
    const dispatch = useAppDispatch()

    const selectedNetworkRequest = useAppSelector(getSelectedNetworkRequest)
    const selectedInstances = useAppSelector(getSelectedInstances)
    const selectedSessionData = useAppSelector(getSelectedManualVerificationSessionData)
    const searchText = useAppSelector(getSelectRequestScreenSearchQuery)
    const clickedParameterName = useAppSelector(getSelectRequestScreenClickedParameterName)

    const {
        isOpen: isVariableNameModelOpen,
        onOpen: onVariableNameModelOpen,
        onClose: onVariableNameModelClose,
    } = useDisclosure()

    const [sections, setSections] = useState<any>([])
    const [queryInstanceCount, setQueryInstanceCount] = useState<number>(0)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [variableNameOfIntanceInput, setVariableNameOfIntanceInput] =
        useState(clickedParameterName)
    const [error, setError] = useState('')
    const [selectedQueryInstance, setSelectedQueryInstance] = useState<number>(0)
    const [searchInstanceInput, setSearchInstanceInput] = useState(searchText)
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null)
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const [selectedJsonInstanceData, setSelectedJsonInstanceData] = useState<any>(
        {}
    )
    const [requiredVariableNames, setRequiredVariableNames] = useState<any[]>([])

    useEffect(() => {
        if (timerId) {
            clearTimeout(timerId)
        }

        const newTimerId = setTimeout(() => {
            handleSelectNetworkRequest()
        }, 500)
        setTimerId(newTimerId)
        return () => {
            clearTimeout(newTimerId)
        }
    }, [searchInstanceInput])

    useEffect(() => {
        if (selectedNetworkRequest.contentType.includes('json')) {
            const data = JSON.parse(selectedNetworkRequest?.response?.body)
            const expandedPaths = getAllPaths('', data, '')
            expandedPaths[''] = true
            dispatch(updateExpandedPaths(expandedPaths))
        }
    }, [sections])

    useEffect(() => {
        if (selectedSessionData?.id && selectedSessionData?.parameters?.length) {
            const tempUsernames = selectedSessionData?.parameters?.map((param: any) => param?.key)
            setRequiredVariableNames(tempUsernames)
        }
    }, [selectedSessionData])

    function generateXpath(htmlString: string, index: number) {
        const lt = getMatchIndexes(htmlString.substring(0, index), '<')
        const gt = getMatchIndexes(htmlString.substring(index), '>')
        let element = null
        const parser = new DOMParser()
        const parsed = parser.parseFromString(htmlString, 'text/html')
        const allElements = parsed.getElementsByTagName('*')
        for (const e of allElements) {
            if (e?.outerHTML) {
                if (
                    e.outerHTML?.includes(
                        htmlString.substring(lt[lt.length - 1], index + gt[0] + 1)
                    )
                ) {
                    if (element === null) {
                        element = e
                    } else if (
                        element?.outerHTML &&
                        element?.outerHTML?.length > e.outerHTML.length
                    ) {
                        element = e
                    }
                }
            }
        }

        const xpath = createXPathFromElement(element, parsed) ?? ''
        return xpath
    }

    function generateRegexHtml(
        htmlString: string,
        start: number,
        searchQueryInputsize: number,
        variableName?: string
    ) {
        const characterOffset = 20
        let leftLimit = start > characterOffset ? start - characterOffset : 0
        let sqoutes = false
        let dqoutes = false
        for (let i = 0; i < characterOffset && start - i > 0; i++) {
            const c = htmlString.charAt(start - i)
            if (c === '<') {
                leftLimit = start - i + 1
                break
            }

            if (c === "'") {
                if (sqoutes) {
                    leftLimit = start - i + 1
                    break
                }

                sqoutes = true
            }

            if (c === '"') {
                if (dqoutes) {
                    leftLimit = start - i + 1
                    break
                }

                dqoutes = true
            }
        }

        let sqoutesr = false
        let dqoutesr = false
        const claimDataEndPos = start + searchQueryInputsize
        let rightLimit = claimDataEndPos + characterOffset
        for (
            let i = 0;
            i < characterOffset && claimDataEndPos + i < htmlString.length;
            i++
        ) {
            const c = htmlString.charAt(claimDataEndPos + i)
            if (c === '>') {
                rightLimit = claimDataEndPos + i + 1
                break
            }

            if (c === "'") {
                if (sqoutesr) {
                    rightLimit = claimDataEndPos + i + 1
                    break
                }

                sqoutesr = true
            }

            if (c === '"') {
                if (dqoutesr) {
                    rightLimit = claimDataEndPos + i + 1
                    break
                }

                dqoutesr = true
            }
        }

        const regex = escapeRegExp(
            htmlString
                .substring(leftLimit, rightLimit)
                .replace(searchInstanceInput, variableName ?? 'CLAIM_DATA')
        ).replace(variableName, '{{' + variableName + '}}')
        return regex
    }

    // set selected text to the selectedText state
    const handleInstanceSelect = () => {
        const selection = window.getSelection()?.toString()
        if (selection?.length) {
            setSearchInstanceInput(selection)
        }
    }

    function handleSelectNetworkRequest(): void {
        const responseBody = selectedNetworkRequest.response.body
        const chunks: {
            t: any
            marked: boolean
            i?: number
            start?: number
            last?: number
            regx?: any
            jsonPath?: string
        }[] = []
        let last = 0
        let count = 0
        if (searchInstanceInput.length) {
            if (selectedNetworkRequest.contentType.includes('json')) {
                handleNext(true)
                return
            }

            const searchQueryInputsize = searchInstanceInput.length
            const matchIndexs = getMatchIndexes(responseBody, searchInstanceInput)
            if (
                matchIndexs.length === 0 &&
                selectedNetworkRequest.contentType.includes('html')
            ) {
                toast({
                    title: 'No Match Found. Please try again',
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                    position: 'top-right',
                })
                return
            }

            if (selectedNetworkRequest.contentType.includes('html')) {
                matchIndexs.forEach((index) => {
                    const start = index
                    count += 1
                    chunks.push({
                        t: responseBody.substring(last, start),
                        marked: false,
                    })
                    last = start + searchQueryInputsize
                    chunks.push({
                        i: count,
                        t: responseBody.substring(start, last),
                        marked: true,
                        start,
                        last,
                    })
                })
            }
        }

        setSelectedQueryInstance(count ? 1 : 0)

        chunks.push({ t: responseBody.substring(last), marked: false })
        setSections(chunks)
        setQueryInstanceCount(count)

        setTimeout(() => {
            document?.getElementById('queryMatch1')?.scrollIntoView()
            document?.getElementById('requestDataTitle')?.scrollIntoView()
        }, 100)
    }

    function checkIfVariableNameExists(variableName: string) {
        return !!selectedInstances.some(
            (instance) => instance.variableName === variableName
        )
    }

    function isValidVariableName(variableName: string) {
        return /^[a-zA-Z0-9_]+$/.test(variableName)
    }

    function addSelectedInstanceToList(e: any) {

        if (!variableNameOfIntanceInput.trim()) {
            setError('Input cannot be empty')
            return
        }

        if (checkIfVariableNameExists(variableNameOfIntanceInput)) {
            setError('Variable name already exists')
            return
        }

        if (!isValidVariableName(variableNameOfIntanceInput)) {
            setError('Special characters or spaces not allowed')
            return
        }

        const xPath = selectedNetworkRequest.contentType.includes('json')
            ? ''
            : generateXpath(selectedNetworkRequest.response.body, e.start)
        if (e.regx) {
            const regexMat = /({{.*?}})/g
            const matches = e.regx.match(regexMat) || []
            if (matches.length > 0) {
                e.regx = e.regx.replace(
                    matches[0],
                    '{{' + variableNameOfIntanceInput + '}}'
                )
            }
        }

        const regex =
            e.regx ??
            generateRegexHtml(
                selectedNetworkRequest.response.body,
                e.start,
                searchInstanceInput.length,
                variableNameOfIntanceInput
            )
        const newSelectedInstanceList = {
            data: e.t,
            variableName: variableNameOfIntanceInput,
            responseSelection: {
                xPath: xPath,
                jsonPath: e.jsonPath ?? '',
                responseMatch: regex,
                invariant: false,
            },
        }

        dispatch(updateSelectedInstances(newSelectedInstanceList))

        setError('')
        setVariableNameOfIntanceInput('')
        onVariableNameModelClose()
    }

    function addSelectedJsonInstanceToList() {
        if (!variableNameOfIntanceInput.trim()) {
            setError('Input cannot be empty')
            return
        }

        if (checkIfVariableNameExists(variableNameOfIntanceInput)) {
            setError('Variable name already exists')
            return
        }

        if (!isValidVariableName(variableNameOfIntanceInput)) {
            setError('Special characters or spaces not allowed')
            return
        }

        const xPath = ''

        const selectedInstData = { ...selectedJsonInstanceData }

        if (selectedInstData?.regx) {
            const regexMat = /({{.*?}})/g
            const matches = selectedInstData.regx.match(regexMat) || []
            if (matches.length > 0) {
                selectedInstData.regx = selectedInstData?.regx.replace(
                    matches[0],
                    '{{' + variableNameOfIntanceInput + '}}'
                )
            }
        }

        const regex = selectedInstData?.regx

        const newSelectedInstanceList = {
            data: selectedInstData?.data,
            variableName: variableNameOfIntanceInput,
            responseSelection: {
                xPath: xPath,
                jsonPath: selectedInstData?.jsonPath ?? '',
                responseMatch: regex,
                invariant: false,
            },
        }

        dispatch(updateSelectedInstances(newSelectedInstanceList))

        setError('')
        setVariableNameOfIntanceInput('')
        onVariableNameModelClose()
    }

    function handleItemClick(path: string) {
        const jsonPath = `$${path}`

        const pathAlreadyExists = selectedInstances.some(
            (instance) => instance.responseSelection.jsonPath === jsonPath
        )

        if (pathAlreadyExists) {
            return toast({
                title: 'Error',
                description: 'This Variable is already added',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top-right',
            })
        }

        let selectedValue = getValueByPath(
            JSON.parse(selectedNetworkRequest?.response?.body),
            path.substring(1)
        )

        let regx

        if (typeof selectedValue === 'object') {
            selectedValue = JSON.stringify(selectedValue)
            regx = `"${path.split('.').pop()}":${escapeRegExp(
                selectedValue.replace(selectedValue, 'CLAIM_DATA')
            ).replace('CLAIM_DATA', '{{CLAIM_DATA}}')}`
        } else if (
            typeof selectedValue === 'number' ||
            typeof selectedValue === 'boolean'
        ) {
            const selectedValueString = `${selectedValue}`
            regx = `"${path.split('.').pop()}":${escapeRegExp(
                selectedValueString?.replace(selectedValueString, 'CLAIM_DATA')
            ).replace('CLAIM_DATA', '{{CLAIM_DATA}}')}`
        } else {
            regx = `"${path.split('.').pop()}":"${escapeRegExp(
                selectedValue.replace(selectedValue, 'CLAIM_DATA')
            ).replace('CLAIM_DATA', '{{CLAIM_DATA}}')}"`
        }

        setSearchInstanceInput(selectedValue ?? '')
        setSelectedJsonInstanceData({
            data: selectedValue,
            jsonPath,
            regx,
        })

        onVariableNameModelOpen()
    }

    function handleNext(highlightFirst: boolean = false) {
        const allHighlights = document.querySelectorAll<HTMLElement>('.highlight')
        if (allHighlights.length > 0) {
            if (currentIndex >= 0 && currentIndex < allHighlights.length) {
                allHighlights[currentIndex].style.border = ''
                allHighlights[currentIndex].style.borderRadius = ''
                allHighlights[currentIndex].style.padding = '0px'
            }

            let nextIndex
            if (highlightFirst) {
                nextIndex = 0
            } else {
                nextIndex = (currentIndex + 1) % allHighlights.length
            }

            setCurrentIndex(nextIndex)
            allHighlights[nextIndex].style.border = '2px solid blue'
            allHighlights[nextIndex].style.borderRadius = '99999px'
            allHighlights[nextIndex].style.padding = '0 5px'
            allHighlights[nextIndex].scrollIntoView({ behavior: 'smooth' })
        }
    }

    function handlePrevious() {
        const allHighlights = document.querySelectorAll<HTMLElement>('.highlight')
        if (allHighlights.length > 0) {
            if (currentIndex >= 0 && currentIndex < allHighlights.length) {
                allHighlights[currentIndex].style.border = ''
                allHighlights[currentIndex].style.borderRadius = ''
                allHighlights[currentIndex].style.padding = '0px'
            }

            const prevIndex =
                currentIndex === 0 ? allHighlights.length - 1 : currentIndex - 1
            setCurrentIndex(prevIndex)

            allHighlights[prevIndex].style.border = '2px solid blue'
            allHighlights[prevIndex].style.borderRadius = '99999px'
            allHighlights[prevIndex].style.padding = '0 5px'

            allHighlights[prevIndex].scrollIntoView({ behavior: 'smooth' })
        }
    }

    function handleGoBack() {
        dispatch(updateManualVerificationActiveStep(3))
    }

    function handleExistingParameterDelete(key: string) {
        const index = selectedInstances.findIndex((instance) => instance.variableName === key)
        dispatch(deleteSelectedInstance(index))
    }

    return (
        <>
            <Card
                width='100%'
                height='100%'
            >
                <CardBody>
                    <VStack>
                        <Box w='full'>
                            <Flex gap='50px' >
                                <Box w='50%'>
                                    <Flex
                                        direction='column'
                                        gap={2}
                                        w='100%'
                                    >
                                        <Flex
                                            direction='column'
                                            gap={2}
                                            py={5}
                                            px={1}
                                            borderBottom='1px solid #EFF1F5'>
                                            <Flex
                                                alignItems='center'
                                                gap={3}>
                                                <IconButton
                                                    variant='outline'
                                                    size='sm'
                                                    icon={<ArrowBackIcon />}
                                                    onClick={handleGoBack}
                                                    aria-label='Go Back' />
                                                <Heading
                                                    fontSize='large'
                                                >
                                                    Select data instances
                                                </Heading>
                                            </Flex>
                                        </Flex>
                                        <Flex
                                            direction='column'
                                            gap={2}
                                            py={5}
                                            px={1}
                                            borderBottom='1px solid #EFF1F5'
                                        >
                                            <Heading
                                                fontSize='medium'
                                            >
                                                Selected URL
                                            </Heading>
                                            <Text
                                                color='#4CA30D'
                                                fontSize='13px'
                                                letterSpacing='1px'
                                            >
                                                {selectedNetworkRequest?.url}
                                            </Text>
                                        </Flex>
                                        <Flex
                                            direction='column'
                                            gap={2}
                                            py={5}
                                            px={1}
                                        >
                                            <Heading
                                                fontSize='medium'
                                                mb={2}
                                            >
                                                Search the data you want to verify
                                            </Heading>
                                            <Flex
                                                alignItems='center'
                                                gap={2}>
                                                <Input
                                                    placeholder='Email_id, account, username...'
                                                    _placeholder={{ color: '#667085', fontSize: '14px' }}
                                                    value={searchInstanceInput}
                                                    onChange={(e) => setSearchInstanceInput(e.target.value)}
                                                />
                                                {
                                                    selectedNetworkRequest.contentType.includes('json') ? (
                                                        <>
                                                            <IconButton
                                                                aria-label='up'
                                                                onClick={handlePrevious}
                                                                icon={
                                                                    <ChevronUpIcon
                                                                        fontSize='2rem'
                                                                    />
                                                                } />
                                                            <IconButton
                                                                aria-label='down'
                                                                onClick={() => handleNext()}
                                                                icon={
                                                                    <ChevronDownIcon
                                                                        fontSize='2rem'
                                                                    />
                                                                } />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <IconButton
                                                                aria-label='up'
                                                                onClick={
                                                                    () => {
                                                                        setSelectedQueryInstance(selectedQueryInstance - 1)
                                                                        document
                                                                            .getElementById(`queryMatch${selectedQueryInstance - 1}`)
                                                                            ?.scrollIntoView()
                                                                    }
                                                                }
                                                                isDisabled={queryInstanceCount === 0 || selectedQueryInstance === 1}
                                                                icon={
                                                                    <ChevronUpIcon
                                                                        fontSize='1.5rem'
                                                                    />
                                                                } />
                                                            <IconButton
                                                                aria-label='down'
                                                                isDisabled={selectedQueryInstance === queryInstanceCount}
                                                                onClick={
                                                                    () => {
                                                                        setSelectedQueryInstance(selectedQueryInstance + 1)
                                                                        document
                                                                            .getElementById(`queryMatch${selectedQueryInstance + 1}`)
                                                                            ?.scrollIntoView()
                                                                    }
                                                                }
                                                                icon={
                                                                    <ChevronDownIcon
                                                                        fontSize='1.5rem'
                                                                    />
                                                                }
                                                            />
                                                            <Tooltip
                                                                label='Search or select the data you want to add'
                                                                aria-label='A tooltip'
                                                                isDisabled={!!searchInstanceInput.length}
                                                            >
                                                                <IconButton
                                                                    className='purple-bg-btn'
                                                                    aria-label='add'
                                                                    colorScheme='blue'
                                                                    isDisabled={!selectedQueryInstance}
                                                                    onClick={
                                                                        () => {
                                                                            setError('')
                                                                            setVariableNameOfIntanceInput('')
                                                                            onVariableNameModelOpen()
                                                                        }
                                                                    }
                                                                    icon={
                                                                        <AddIcon
                                                                        />
                                                                    }
                                                                />
                                                            </Tooltip>

                                                        </>
                                                    )
                                                }

                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Box>
                                <Box w='50%'>
                                    <Box
                                        border='1px solid #D0D5DD'
                                        height='100%'
                                        borderRadius='12px'
                                        backgroundColor='#FCFCFD'
                                        overflow='hidden'
                                    >
                                        <Flex direction='column'>
                                            <Text
                                                color='#667085'
                                                py={3}
                                                px={4}
                                                fontWeight={500}
                                            >
                                                Total selected instances
                                            </Text>

                                            <TableContainer>
                                                <Table
                                                    variant='simple'
                                                >
                                                    <Tr>
                                                        <Th
                                                            fontWeight='bold'
                                                            fontSize='sm'
                                                            textAlign='center'
                                                            border='1px solid'
                                                            borderColor='gray.200'
                                                            borderLeft='none'
                                                            w='20%'
                                                        >
                                                            <Text
                                                                fontSize='small'
                                                                color='#475467'>
                                                                Variable Name
                                                            </Text>

                                                        </Th>
                                                        <Th
                                                            fontWeight='bold'
                                                            fontSize='sm'
                                                            textAlign='center'
                                                            border='1px solid'
                                                            borderColor='gray.200'
                                                            borderRight='none'
                                                            w='80%'
                                                        >
                                                            <Text
                                                                fontSize='small'
                                                                color='#475467'>
                                                                Data
                                                            </Text>
                                                        </Th>
                                                    </Tr>
                                                    {
                                                        selectedSessionData?.parameters?.length > 0 &&
                                                        selectedSessionData?.parameters?.map((param: any) => {
                                                            const addedToInstanceList = selectedInstances.some((instance: any) => instance?.variableName === param?.key)
                                                            return (
                                                                <Tr
                                                                    key={`${param?.key}_${param?.value}`}
                                                                    opacity={addedToInstanceList ? 1 : 0.2}
                                                                    cursor={addedToInstanceList ? 'pointer' : 'not-allowed'}
                                                                >
                                                                    <Td
                                                                        textAlign='center'
                                                                        overflow='hidden'
                                                                        whiteSpace='pre-wrap'
                                                                        border='1px solid'
                                                                        borderColor='gray.200'
                                                                        borderLeft='none'
                                                                    >
                                                                        <Text
                                                                            fontSize='small'
                                                                            color='#475467'
                                                                            overflow='hidden'
                                                                            whiteSpace='pre-wrap'

                                                                        >
                                                                            {param?.key}
                                                                        </Text>

                                                                    </Td>
                                                                    <Td
                                                                        textAlign='center'
                                                                        overflow='hidden'
                                                                        whiteSpace='pre-wrap'
                                                                        border='1px solid'
                                                                        borderColor='gray.200'
                                                                        borderRight='none'
                                                                    >
                                                                        <Flex
                                                                            alignItems='center'

                                                                        >
                                                                            <Text
                                                                                fontSize='small'
                                                                                color='#475467'
                                                                                whiteSpace='nowrap'
                                                                                textOverflow='ellipsis'
                                                                                overflow='hidden'
                                                                                flex={1}
                                                                            >
                                                                                {param?.value}
                                                                            </Text>
                                                                            <IconButton
                                                                                flex={0.1}
                                                                                variant='outline'
                                                                                colorScheme='red'
                                                                                aria-label='Search database'
                                                                                _hover={
                                                                                    {
                                                                                        borderColor: addedToInstanceList ? 'red' : 'none'
                                                                                    }
                                                                                }
                                                                                onClick={() => handleExistingParameterDelete(param?.key)}
                                                                                size='sm'
                                                                                icon={<DeleteIcon />} />
                                                                        </Flex>
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        selectedInstances.length > 0 &&
                                                        selectedInstances.map((instance, index) => (
                                                            <>
                                                                {
                                                                    !requiredVariableNames?.includes(instance.variableName) && (
                                                                        <Tr key={`${index}_${instance.variableName}`}>
                                                                            <Td
                                                                                textAlign='center'
                                                                                overflow='hidden'
                                                                                whiteSpace='pre-wrap'
                                                                                border='1px solid'
                                                                                borderColor='gray.200'
                                                                                borderLeft='none'

                                                                            >
                                                                                <Text
                                                                                    fontSize='small'
                                                                                    color='#475467'
                                                                                    overflow='hidden'
                                                                                    whiteSpace='pre-wrap'

                                                                                >
                                                                                    {instance.variableName}

                                                                                </Text>

                                                                            </Td>
                                                                            <Td
                                                                                textAlign='center'
                                                                                overflow='hidden'
                                                                                whiteSpace='pre-wrap'
                                                                                border='1px solid'
                                                                                borderColor='gray.200'
                                                                                borderRight='none'

                                                                            >
                                                                                <Flex
                                                                                    alignItems='center'
                                                                                >
                                                                                    <Text
                                                                                        fontSize='small'
                                                                                        color='#475467'
                                                                                        whiteSpace='nowrap'
                                                                                        textOverflow='ellipsis'
                                                                                        overflow='hidden'
                                                                                        flex={1}
                                                                                    >
                                                                                        {instance.data}
                                                                                    </Text>
                                                                                    <IconButton
                                                                                        flex={0.1}
                                                                                        variant='outline'
                                                                                        colorScheme='red'
                                                                                        aria-label='Search database'
                                                                                        _hover={
                                                                                            {
                                                                                                borderColor: 'red'
                                                                                            }
                                                                                        }
                                                                                        onClick={
                                                                                            () => {
                                                                                                dispatch(deleteSelectedInstance(index))
                                                                                            }
                                                                                        }
                                                                                        size='sm'
                                                                                        icon={<DeleteIcon />} />
                                                                                </Flex>
                                                                            </Td>
                                                                        </Tr>
                                                                    )
                                                                }
                                                            </>

                                                        ))
                                                    }


                                                </Table>
                                            </TableContainer>
                                        </Flex>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                        <Box w='full'>
                            <Flex
                                direction='column'
                                w='full'>
                                <Box>
                                    <Flex
                                        direction='column'
                                        gap={2}
                                        py={5}
                                        px={1}>
                                        <Heading fontSize='14px'>
                                            Body
                                        </Heading>
                                        <Box
                                            backgroundColor='gray.50'
                                            borderRadius='5px'
                                            p={2}
                                            minHeight='20vh'
                                            maxHeight='40vh'
                                            overflowY='auto'
                                        >
                                            {
                                                sections ? (
                                                    <>
                                                        {
                                                            selectedNetworkRequest.contentType.includes('json') ? (
                                                                <>
                                                                    <CustomJsonViewer
                                                                        data={
                                                                            selectedNetworkRequest?.response?.body
                                                                                ? JSON.parse(selectedNetworkRequest?.response?.body)
                                                                                : ''
                                                                        }
                                                                        onItemClick={handleItemClick}
                                                                        searchQuery={searchInstanceInput}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Text
                                                                        onMouseUp={handleInstanceSelect}
                                                                        style={
                                                                            {
                                                                                fontVariantNumeric: 'tabular-nums',
                                                                            }
                                                                        }
                                                                        color='#1849A9'
                                                                        fontWeight='400'
                                                                        fontSize='medium'
                                                                        className='font-roboto-mono-regular'
                                                                    >
                                                                        {
                                                                            sections.map((section: any, i: any) => section.marked ? (
                                                                                <span
                                                                                    key={i}
                                                                                    id={`queryMatch${section.i}`}
                                                                                    style={
                                                                                        {
                                                                                            backgroundColor: 'yellow',
                                                                                            cursor: 'pointer',
                                                                                            borderColor:
                                                                                                section.i === selectedQueryInstance
                                                                                                    ? 'red'
                                                                                                    : undefined,
                                                                                            borderWidth: '2px',
                                                                                            fontWeight:
                                                                                                section.i === selectedQueryInstance
                                                                                                    ? 'bold'
                                                                                                    : undefined,
                                                                                            padding:
                                                                                                section.i === selectedQueryInstance
                                                                                                    ? '0.2rem'
                                                                                                    : '0.1rem',
                                                                                            borderRadius: '30%',
                                                                                        }
                                                                                    }
                                                                                    onClick={() => setSelectedQueryInstance(section.i)}
                                                                                >
                                                                                    {section.t}
                                                                                </span>
                                                                            ) : (
                                                                                section.t
                                                                            )
                                                                            )
                                                                        }
                                                                    </Text>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                ) : (
                                                    <Code
                                                        style={
                                                            {
                                                                maxHeight: '600px',
                                                                overflow: 'auto',
                                                            }
                                                        }
                                                        className='font-roboto-mono-regular'
                                                    >
                                                        {selectedNetworkRequest.response.body}
                                                    </Code>
                                                )
                                            }
                                        </Box>
                                    </Flex>
                                </Box>

                            </Flex>
                        </Box>
                        <Box
                            w='full'
                            mt={5}>
                            <Flex
                                w='full'
                                justifyContent='flex-end'>
                                <Tooltip
                                    label={
                                        selectedInstances.length
                                            ? ''
                                            : 'Please add at least one data instance to proceed'
                                    }
                                >
                                    <Button
                                        onClick={
                                            () => {
                                                setButtonLoading(true)
                                                dispatch(updateManualVerificationActiveStep(5))
                                            }
                                        }
                                        bgColor='#332FED'
                                        color='white'
                                        isLoading={buttonLoading}
                                        isDisabled={!selectedInstances.length}>
                                        Next
                                    </Button>
                                </Tooltip>

                            </Flex>
                        </Box>
                    </VStack>
                </CardBody>
            </Card>
            <Modal
                isOpen={isVariableNameModelOpen}
                onClose={onVariableNameModelClose}
                size='xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Enter Variable Name
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl
                            isInvalid={!!error}>
                            <Flex flexDirection='column'>
                                <FormLabel
                                    fontSize='md'
                                    mt='7px'
                                >
                                    <Text>
                                        Variable Name
                                    </Text>
                                </FormLabel>
                                <Flex direction='column'>
                                    <Input
                                        borderRadius='5px'
                                        borderWidth={1}
                                        placeholder='Email_id, account, username...'
                                        required={true}
                                        value={variableNameOfIntanceInput}
                                        onChange={
                                            (e) => {
                                                setVariableNameOfIntanceInput(e.target.value)
                                            }
                                        }
                                    />
                                    <FormErrorMessage>
                                        {error}
                                    </FormErrorMessage>
                                </Flex>
                            </Flex>
                            <Flex
                                flexDirection='column'
                                mt='15px'>
                                <FormLabel
                                    fontSize='md'
                                    pl={1}>
                                    <Text>
                                        Variable Value
                                    </Text>
                                </FormLabel>
                                <FormLabel
                                    width='100%'
                                    fontSize='md'
                                    fontWeight='bold'
                                    ml='7px'
                                >
                                    <Box
                                        backgroundColor='gray.50'
                                        borderRadius='5px'
                                        p={2}
                                        minHeight='10vh'
                                        maxHeight='350px'
                                        overflowY='auto'
                                        ml={-1}
                                    >
                                        <Text
                                            color='#0366d6'
                                        >
                                            {searchInstanceInput}
                                        </Text>
                                    </Box>
                                </FormLabel>
                            </Flex>
                            {
                                searchInstanceInput?.toString()?.length >= 300 && (
                                    <Flex
                                        mt='15px'
                                        mb='15px'>
                                        <Alert status='error'>
                                            <AlertIcon />
                                            Character limit of 300 exceeded!
                                            <br />
                                            {' '}
                                            Proof may not be generated for this provider
                                        </Alert>
                                    </Flex>
                                )
                            }
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={onVariableNameModelClose}
                            mr={3}>
                            Cancel
                        </Button>
                        <Button
                            className='purple-bg-btn'
                            colorScheme='blue'
                            onClick={
                                () => selectedNetworkRequest.contentType.includes('json')
                                    ? addSelectedJsonInstanceToList()
                                    : addSelectedInstanceToList(
                                        sections.find(
                                            (e: any) => e.i === selectedQueryInstance
                                        )
                                    )
                            }
                        >
                            Add
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
