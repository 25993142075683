import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HttpsProvider } from './types'

interface httpsProviders {
	httpsProviders: HttpsProvider[]
	allCommunityProviders: HttpsProvider[]
	nonApprovedProviders: HttpsProvider[]
	nonActiveProviders: HttpsProvider[]
	selectedProvidersFromCreateApp: HttpsProvider[]
	navigateHistory?: string
	isLoading?: boolean
	approvalPendingProviderForUser?: HttpsProvider[]
}

const initialState: httpsProviders = {
	httpsProviders: [],
	allCommunityProviders: [],
	nonApprovedProviders: [],
	nonActiveProviders: [],
	selectedProvidersFromCreateApp: [],
	navigateHistory: undefined,
	isLoading: false,
	approvalPendingProviderForUser: []
}

const httpProvidersSlice = createSlice({
	name: 'httpsProviders',
	initialState,
	reducers: {
		setHttpsProviders(state, action: PayloadAction<HttpsProvider[]>) {
			state.httpsProviders = action.payload
		},
		setAllCommunityProviders(state, action: PayloadAction<HttpsProvider[]>) {
			state.allCommunityProviders = action.payload
		},
		setNonApprovedProviders(state, action: PayloadAction<HttpsProvider[]>) {
			state.nonApprovedProviders = action.payload
		},
		setNonActiveProviders(state, action: PayloadAction<HttpsProvider[]>) {
			state.nonActiveProviders = action.payload
		},
		setSelectedProvidersFromCreateApp(state, action: PayloadAction<HttpsProvider[]>) {
			state.selectedProvidersFromCreateApp = action.payload
		},
		setnavigationHistory(state, action: PayloadAction<string>) {
			state.navigateHistory = action.payload
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload
		},
		setAppRovalPendingProviderForUser(state, action: PayloadAction<HttpsProvider[]>) {
			state.approvalPendingProviderForUser = action.payload
		},
		updateHttpsProviders(state, action: PayloadAction<HttpsProvider>) {
			const provider = action.payload
			// append the new application to the list
			state.httpsProviders.push(provider)
		},
	},
})

export const {
	setHttpsProviders,
	updateHttpsProviders,
	setAllCommunityProviders,
	setNonApprovedProviders,
	setNonActiveProviders,
	setnavigationHistory,
	setSelectedProvidersFromCreateApp,
	setLoading,
	setAppRovalPendingProviderForUser
} = httpProvidersSlice.actions
export default httpProvidersSlice.reducer
