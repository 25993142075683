/* eslint-disable react/jsx-indent */
import { SetStateAction, useEffect, useState } from 'react'
import { QuestionIcon } from '@chakra-ui/icons'
import {
	Alert,
	AlertIcon,
	Box,
	Button,
	Code,
	Flex,
	Icon,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	StackDivider,
	Switch,
	Text,
	Tooltip,
	useToast,
	VStack,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateExpandedPaths } from '../../../../redux/providerBuilder/jsonViewer'
import { updateProviderDetails } from '../../../../redux/providerBuilder/providerDetails'
import { getProviderDetails } from '../../../../redux/providerBuilder/providerDetails/selectors'
import { ProviderDetails } from '../../../../redux/providerBuilder/providerDetails/types'
import { getSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances/selectors'
import { updateSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest'
import { getSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest/selectors'
import { updateActiveStep } from '../../../../redux/providerBuilder/stepperDetails'
import { getActiveStep } from '../../../../redux/providerBuilder/stepperDetails/selectors'
import {
	compareProviders,
	getAllDynamicPaths,
	getAllPaths,
	getValueByPath,
	setValueByPath,
} from '../../../../utils/provider-builder/instance-selection-utils'
import { extractDomain } from '../../../../utils/provider-builder/proof-card-utils'
import { editProvider, getProviderById, registedProvider } from '../../../../utils/provider-builder/register-provider-utils'
import {
	getDefaultProofCardText,
	initializeRegexBody,
	seperateURLComponents,
	urlSpanToRegexUrl,
	URLSpanType,
} from '../../../../utils/provider-builder/request-config-utils'
import { getFirebaseIdToken } from '../../../../utils/utils'
import CustomJsonViewerScreen3 from '../request-selection/custom-json-viewer-screen-3'
import { OptionalFields } from './optional-fields.component'

interface RequestConfigProps {
	setActiveStepInnerForm: (value: SetStateAction<number>) => void
}

export const RequestConfig = (props: RequestConfigProps) => {
	const toast = useToast()
	const dispatch = useAppDispatch()

	const activeStep = useAppSelector(getActiveStep)
	const selectedNetworkRequest = useAppSelector(getSelectedNetworkRequest)
	const selectedInstances = useAppSelector(getSelectedInstances)
	const providerDetails = useAppSelector(getProviderDetails)


	const [urlSpans, setUrlSpans] = useState<any>([])
	const [regexBody, setRegexBody] = useState('')
	const [selectedText, setSelectedText] = useState<any>(null)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [useJsonSelectionTool, setUseJsonSelectionTool] =
		useState<boolean>(true)


	useEffect(() => {

		if (selectedNetworkRequest) {

			if (providerDetails?.httpProviderId?.length && providerDetails?.url === selectedNetworkRequest?.templateUrl && providerDetails?.method === selectedNetworkRequest.method) {
				if (selectedNetworkRequest?.urlSpans?.length) {
					const urlSpans = [...selectedNetworkRequest?.urlSpans]
					setUrlSpans(urlSpans)
				} else {
					const newUrlSpans = seperateURLComponents(selectedNetworkRequest.url)
					setUrlSpans(newUrlSpans)
				}

				const regexBody = providerDetails?.bodySniff?.template

				try {
					if (regexBody !== '') {
						const data = JSON.parse(regexBody)
						const expandedPaths = getAllPaths('', data, '')
						expandedPaths[''] = true
						setUseJsonSelectionTool(true)
						dispatch(updateExpandedPaths(expandedPaths))
					}

				} catch (error) {
					console.log(error)
					setUseJsonSelectionTool(false)

				} finally {
					setRegexBody(regexBody)
				}
			} else {
				const newUrlSpans = seperateURLComponents(selectedNetworkRequest.url)
				setUrlSpans(newUrlSpans)
				const newRegexBody = initializeRegexBody(
					selectedNetworkRequest.request?.body
				)
				if (selectedNetworkRequest.contentType.includes('json') && newRegexBody?.length) {
					try {
						const data = JSON.parse(newRegexBody)
						const expandedPaths = getAllPaths('', data, '')
						expandedPaths[''] = true
						dispatch(updateExpandedPaths(expandedPaths))
					} catch (error) {
						console.log(error, '>>>', { newRegexBody })
					}
				}

				setRegexBody(newRegexBody)
			}


		}
	}, [selectedNetworkRequest])

	useEffect(() => {
		handleSubmit(providerDetails?.httpProviderId?.length > 0)
	}, [providerDetails])


	const handleSubmit = async (isEdit: boolean = false) => {
		if (buttonLoading) {
			if (isEdit) {
				await editHttpProvider()
			} else {

				await submitHttpProvider()
			}

			setButtonLoading(false)
		}
	}


	// Reset the body to its original state
	const resetRegexBody = () => {
		const newRegexBody = initializeRegexBody(
			selectedNetworkRequest.request?.body
		)
		setRegexBody(newRegexBody)
		setSelectedText(null)
	}

	// set selected text to the selectedText state
	const handleTextSelect = () => {
		const selection = window.getSelection()
		if (selection !== null) {
			setSelectedText(selection)
		}
	}

	// replace the selected text in the body and make it dynamic
	const handleReplaceRegexBody = () => {
		// Replace the selected text with the replacement text
		if (selectedText?.toString()?.length) {
			let startOffset = selectedText.anchorOffset
			let endOffset = selectedText.focusOffset

			if (startOffset > endOffset) {
				const endTemp = endOffset
				endOffset = startOffset
				startOffset = endTemp
			}

			let prevRegexBody = regexBody
			const prefix = prevRegexBody.slice(0, startOffset)
			const suffix = prevRegexBody.slice(endOffset)

			const specialString = '{{REQ_BODY_999}}'
			prevRegexBody = `${prefix}${specialString}${suffix}`

			let paramsCounter = 1
			const dynamicVariables = prevRegexBody.match(/{{REQ_BODY_\d+}}/g)

			if (dynamicVariables) {
				for (const dynamicVariable of dynamicVariables) {
					prevRegexBody = prevRegexBody.replace(
						dynamicVariable,
						`{{REQ_BODY_${paramsCounter}}}`
					)
					paramsCounter++
				}
			}

			setRegexBody(prevRegexBody)

			setSelectedText(null)
		} else {
			toast({
				title: 'No text selected',
				description: 'Select the text you want to make dynamic from the body',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}
	}

	// Makes all body content as dynamic in regex body
	const makeFullBodyDynamic = () => {
		setRegexBody('{{REQ_BODY_GRD_1}}')
		setSelectedText(null)
	}


	function toggleSelectedUrlSpan(index: number) {

		if (urlSpans[index].type !== 'STATIC') {
			let paramsCount = 1
			const totalLength = urlSpans.length


			const updatedUrlSpans = urlSpans.map((url: any) => ({ ...url }))

			updatedUrlSpans.forEach((url: any, i: number) => {

				if (index === i) {
					if (url.type?.includes('URL_PARAMS')) {
						updatedUrlSpans[i].type = URLSpanType.CONSTANT
					} else if (url.type === URLSpanType.CONSTANT) {
						// Check if the selected index is the last in the array
						if (index === totalLength - 1) {
							updatedUrlSpans[i].type = `{{URL_PARAMS_GRD_${paramsCount++}}}`
						} else {
							updatedUrlSpans[i].type = `{{URL_PARAMS_${paramsCount++}}}`
						}
					}
				} else if (url.type?.includes('URL_PARAMS') && index !== i) {
					if (i === totalLength - 1) {
						updatedUrlSpans[i].type = `{{URL_PARAMS_GRD_${paramsCount++}}}`
					} else {
						updatedUrlSpans[i].type = `{{URL_PARAMS_${paramsCount++}}}`
					}
				}
			})

			setUrlSpans(updatedUrlSpans)
		}
	}


	async function submitHttpProvider() {
		try {
			const accessToken = (await getFirebaseIdToken()) ?? ''
			const message = await registedProvider(providerDetails, accessToken)

			if (!message.isSuccess) {
				toast({
					title: 'Provider Creation Failed',
					description: message.message,
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				return
			} else {
				dispatch(updateProviderDetails({ httpProviderId: message.provider.httpProviderId, isActive: false }))
				dispatch(updateActiveStep(activeStep + 1))
				setButtonLoading(false)
			}
		} catch (e) {
			toast({
				title: 'Provider Creation Failed',
				description: 'Unknown Error while creating provider',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}
	}

	async function editHttpProvider() {
		try {
			const accessToken = (await getFirebaseIdToken()) ?? ''

			const providerDataFromDB = await getProviderById(accessToken, providerDetails.httpProviderId)
			const isEdited = compareProviders(providerDetails, providerDataFromDB)


			let payload: ProviderDetails = {
				...providerDetails,
				isActive: providerDataFromDB?.isActive ?? false,
			}

			if (isEdited) {
				payload = {
					...payload,
					isActive: false
				}
			}

			const message = await editProvider(payload, accessToken)
			if (!message.isSuccess) {
				toast({
					title: 'Provider Updation Failed',
					description: message.message,
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				return
			} else {
				dispatch(updateProviderDetails(payload))
				dispatch(updateActiveStep(activeStep + 1))
				setButtonLoading(false)
			}


		} catch (e) {
			toast({
				title: 'Provider Updation Failed',
				description: 'Unknown Error while updating provider',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}
	}

	const handleNext = async () => {
		setButtonLoading(true)
		const templateUrl = urlSpanToRegexUrl(urlSpans)

		const bodyEnabled = !!selectedNetworkRequest.request?.body
		const finalTemplateBody = regexBody

		const proofCardText = getDefaultProofCardText(selectedInstances)
		const domainName = extractDomain(selectedNetworkRequest.url)
		const proofCardTitle = domainName
		// const logoUrl = `https://www.google.com/s2/favicons?domain=${domainName}`

		dispatch(updateSelectedNetworkRequest({
			urlSpans,
			templateUrl
		}))

		dispatch(
			updateProviderDetails({
				url: templateUrl,
				urlType: 'TEMPLATE',
				bodySniff: {
					enabled: bodyEnabled,
					// regex: bodyEnabled ? finalRegexBody + "$" : "",
					regex: '',
					template: bodyEnabled ? finalTemplateBody : '',
				},
				proofCardText: proofCardText,
				proofCardTitle: proofCardTitle,
				// logoUrl: logoUrl,
			})
		)

	}

	const handleJsonKeyClick = (
		clickedPath: string,
		checked: boolean = false
	) => {
		const jsonObject = JSON.parse(regexBody)

		const allDynamicPaths = getAllDynamicPaths(
			'',
			jsonObject,
			'',
			{},
			false,
			clickedPath,
			checked
		)

		for (const dynamicPath in allDynamicPaths) {
			let value = allDynamicPaths?.[dynamicPath] ?? '{{REQ_BODY_1}}'

			if (value === 'ORIGINAL_VALUE') {
				const orginalJSON = JSON.parse(selectedNetworkRequest.request?.body)
				value = getValueByPath(orginalJSON, dynamicPath.substring(1))
			}

			setValueByPath(jsonObject, dynamicPath.substring(1), value)
		}

		const updatedJsonString = JSON.stringify(jsonObject)
		setRegexBody(updatedJsonString)

	}

	const toggleSelectionTool = (value: boolean) => {
		resetRegexBody()
		setUseJsonSelectionTool(value)
	}


	return (
		<>
			<Box
				marginBottom='2.5rem'
				width='100%'>
				<VStack
					divider={<StackDivider borderColor='gray.500' />}
					spacing={5}
					align='stretch'
					mt='10'
					ml='3'
				>
					<Box width='100%'>
						<Flex
							direction='column'
							gap='2'>
							<Text
								fontSize='larger'
								minW='140px'
								fontWeight='bold'>
								{/* URL * */}
								Select parts of URL which contains personal data *
							</Text>

							<Flex>
								<Text
									fontSize='large'
									overflow='hidden'
									whiteSpace='pre-wrap'>
									{
										urlSpans.map((span: any, idx: number) => (
											<span
												key={idx}
												className={
													span.type !== 'STATIC'
														? 'urlDynamicSpan'
														: 'urlStaticSpan'
												}
												onClick={() => toggleSelectedUrlSpan(idx)}
											>
												{span.type?.includes('URL_PARAMS') ? span.type : span.key}
											</span>
										))
									}
								</Text>
								<Tooltip
									whiteSpace='pre-line'
									label='Set parts of URL as dynamic.&#10;Eg. User Id, Post Id'
								>
									<Icon
										as={QuestionIcon}
										w={4}
										h={4}
										mt='7px'
										ml='15px'
										color='blue'
									/>
								</Tooltip>
							</Flex>
						</Flex>
					</Box>
					<Box width='100%'>
						<VStack
							align='top'
							width='95%'
							gap={5}>
							<Text
								fontSize='larger'
								minW='140px'
								mb='-10px'
								mt='10px'
								fontWeight='bold'
							>
								{/* Body * */}
								Select parts of the body that contain personal data *
							</Text>
							<Flex
								alignItems='center'
								justifyContent='space-between'
								mt={5}
							>
								<Text
									fontSize='md'
									minW='140px'
									fontWeight='bold'
									mb={0}>
									Use Json selection: &nbsp;
									<Switch
										isChecked={useJsonSelectionTool}
										onChange={(e) => toggleSelectionTool(e.target.checked)}
									/>
								</Text>
								<Button
									onClick={resetRegexBody}
									size='sm'
									style={
										{
											marginRight: '1rem',
											boxSizing: 'border-box',
										}
									}
								>
									<Tooltip
										label='Reset the body to its original state'
										style={
											{
												marginTop: '3rem',
												marginBottom: '3rem',
											}
										}
										aria-label='A tooltip'
									>
										Reset Selections
									</Tooltip>
								</Button>
							</Flex>


							{
								useJsonSelectionTool && (
									<Alert status='info'>
										<Flex
											alignItems='center'
											gap={5}>
											<AlertIcon />
											<ul>
												<li>
													Click on the keys of JSON Object containing personal
													data.
												</li>
												<li>
													You can select the checkbox next to the selected keys to
													hide the data from us.
												</li>
											</ul>
										</Flex>
									</Alert>
								)
							}

							{
								regexBody !== '' && (
									<Text
										ml='-10px'
										onMouseUp={handleTextSelect}
										width='100%'>
										<div style={{ overflow: 'auto' }}>
											{
												selectedNetworkRequest.contentType.includes('json') &&
													useJsonSelectionTool ? (
														<CustomJsonViewerScreen3
															data={JSON.parse(regexBody)}
															onItemClick={handleJsonKeyClick}
															searchQuery=''
														/>
													) : (
														<Code
															style={
																{
																	whiteSpace: 'pre-wrap',
																	width: '100%',
																}
															}
														>
															{regexBody}
														</Code>
													)
											}
										</div>

										<Box>

											{
												(!selectedNetworkRequest.contentType.includes('json') ||
													!useJsonSelectionTool) && (
													<>
														<Button
															onClick={handleReplaceRegexBody}
															isDisabled={!!!selectedText}
															size='sm'
															style={
																{
																	marginRight: '1rem',
																	marginTop: '1rem',
																	boxSizing: 'border-box',
																}
															}
														>
															<Tooltip
																label='Select the text you want to make dynamic and click this button'
																aria-label='A tooltip'
															>
																Make Dynamic
															</Tooltip>
														</Button>
														<Button
															onClick={makeFullBodyDynamic}
															size='sm'
															style={
																{
																	marginRight: '1rem',
																	marginTop: '1rem',
																	boxSizing: 'border-box',
																}
															}
														>
															<Tooltip
																label='Make all the body content dynamic'
																aria-label='A tooltip'
															>
																Make All Dynamic
															</Tooltip>
														</Button>
														<Popover closeOnBlur={false}>
															<PopoverTrigger>
																<Icon
																	as={QuestionIcon}
																	w={4}
																	h={4}
																	mt='13px'
																	color='blue'
																/>
															</PopoverTrigger>
															<PopoverContent color='black'>
																<PopoverArrow />
																<PopoverHeader>
																	What to do here?
																</PopoverHeader>
																<PopoverCloseButton />
																<PopoverBody>
																	<Box>
																		<iframe
																			src='https://www.loom.com/embed/93646bd34665411d9145991f171e6685?sid=62619623-30f4-43e8-ae63-e05854fa9f5f'
																			allowFullScreen={true}
																		/>
																		<Text
																			fontSize='md'
																			mt='20px'>
																			Highlight the parts of the body that are not
																			important in identifying this request, and
																			mark them as dynamic. Eg. Personal Data, IDs,
																			etc
																		</Text>
																	</Box>
																</PopoverBody>
															</PopoverContent>
														</Popover>
													</>
												)
											}
										</Box>
									</Text>
								)
							}
							{
								regexBody === '' && (
									<Text>
										No Body
									</Text>
								)
							}
						</VStack>
					</Box>
					<Box width='95%'>
						<OptionalFields />
					</Box>
				</VStack>
				<Flex
					maxW='100%'
					mt='40px'
					justifyContent='flex-end'>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={() => props.setActiveStepInnerForm(0)}
						width='10%'
						mr='30px'
						isDisabled={buttonLoading}
					>
						Back
					</Button>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={async () => handleNext()}
						colorScheme='blue'
						mr='30px'
						isLoading={buttonLoading}
					>
						{(providerDetails?.httpProviderId?.length && providerDetails?.url?.length) ? 'Update & Test Provider' : 'Test Provider'}

					</Button>

				</Flex>
			</Box>
		</>
	)
}
