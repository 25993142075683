import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SelectedInstanceType {
	data: string
	variableName: string
	responseSelection: {
		xPath: string
		jsonPath: string
		responseMatch: string
		invariant: boolean
	}
}

interface SelectedInstances {
	selectedInstances: SelectedInstanceType[]
}

const initialState: SelectedInstances = {
	selectedInstances: [],
}

const selectedInstancesSlice = createSlice({
	name: 'selectedInstances',
	initialState,
	reducers: {
		updateSelectedInstances(
			state,
			action: PayloadAction<SelectedInstanceType>
		) {
			state.selectedInstances = [...state.selectedInstances, action.payload]
		},
		deleteSelectedInstance(state, action: PayloadAction<number>) {
			if (
				action.payload < state.selectedInstances.length &&
				action.payload >= 0
			) {
				state.selectedInstances.splice(action.payload, 1)
			}
		},
		// update response selection of a particular instance
		updateResponseSelection(
			state,
			action: PayloadAction<{
				index: number
				responseSelection: SelectedInstanceType['responseSelection']
			}>
		) {
			if (
				action.payload.index < state.selectedInstances.length &&
				action.payload.index >= 0
			) {
				state.selectedInstances[action.payload.index].responseSelection =
					action.payload.responseSelection
			}
		},
		resetSelectedInstances() {
			return { ...initialState }
		},
	},
})

export const {
	updateSelectedInstances,
	deleteSelectedInstance,
	updateResponseSelection,
	resetSelectedInstances,
} = selectedInstancesSlice.actions

export default selectedInstancesSlice.reducer
