/* eslint-disable indent */
import { getAuth } from 'firebase/auth'
import { app } from '../configs/firebaseApp'
import { ManualVerificationSession } from '../redux/manualVerification/sessionsDetails/types'
import { GET_SIGNED_URL_FOR_APPLICATION_LOGO } from './constants'

export function exxtractCommaSeparatedList(list: string) {
	const parsedValues = list.trim().split(',')

	// Remove empty strings from the array
	const filteredValues = parsedValues.map((value) => value.trim()).filter((value: string) => {
		return value.trim() !== ''
	})

	return filteredValues
}

export async function getFirebaseIdToken() {
	const auth = getAuth(app)
	const token = await auth.currentUser?.getIdToken()
	return token
}

export const getApiUrl = () => {
	const environment = process.env.REACT_APP_ENVIRONMENT

	switch (environment) {
		case 'production':
			return process.env.REACT_APP_BACKEND_URL || 'https://api.reclaimprotocol.org'

		case 'development':
			return process.env.REACT_APP_LOCAL_BACKEND_URL || 'http://localhost:3003'
		default:

			return 'http://localhost:3003'
	}
}

export const sortByCreatedAtDesc = (array: ManualVerificationSession[] | any[]): ManualVerificationSession[] | any[] => {
	return array.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
}

export const getSignedUploadUrlFromS3ForApplicationImage = async (address: string, file: File) => {
	try {
		const fileType = file.type
		const imageFormat = fileType?.split('/')[1]
		const fileName = `${address}.${imageFormat}`
		const response = await fetch(GET_SIGNED_URL_FOR_APPLICATION_LOGO, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				fileName,
				fileType,
			})
		})

		if (!response.ok) {
			throw new Error('Failed to get pre-signed URL')
		}

		const { url } = await response.json()

		const uploadResponse = await fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': fileType
			},
			body: file
		})

		if (!uploadResponse.ok) {
			throw new Error('Failed to upload file')
		}

		return { error: null, url: url.split('?')[0] }

	} catch (error) {
		return { error: 'something went wrong while uploading image', url: '' }
	}
}