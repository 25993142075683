import { SetStateAction, useState } from 'react'
import { Box, Button, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, List, ListItem, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { getPagesVisted } from '../../../../redux/providerBuilder/networkRequestStore/selectors'
import { updateProviderDetails } from '../../../../redux/providerBuilder/providerDetails'
import { getProviderDetails } from '../../../../redux/providerBuilder/providerDetails/selectors'
import { getUniqueItems } from '../../../../utils/provider-builder/instance-selection-utils'

interface LoginUrlProps {
	setActiveStepInnerForm: (value: SetStateAction<number>) => void
}

export const LoginUrl = (props: LoginUrlProps) => {
	const dispatch = useAppDispatch()

	const pagesVisted = useAppSelector(getPagesVisted)
	const providerDetails = useAppSelector(getProviderDetails)

	const uniqueUrls = getUniqueItems(pagesVisted)

	const [loginUrl, setLoginUrl] = useState(providerDetails.loginUrl ? providerDetails.loginUrl : '')
	const [error, setError] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)

	const handleNext = () => {
		if(!loginUrl) {
			setError('Login url is required')
			return
		}

		setButtonLoading(true)
		dispatch(updateProviderDetails({ loginUrl: loginUrl }))
		props.setActiveStepInnerForm(2)
	}

	return (
		<>
			<Box
				marginBottom='2.5rem'
				maxW='80%'>
				<FormControl
					marginTop='1.5rem'
					isInvalid={!!error}>
					<FormLabel>
						Login Url *
					</FormLabel>
					<Input
						onChange={
							(e) => {
								setError('')
								setLoginUrl(e.target.value)
							}
						}
						required={true}
						value={loginUrl}
					/>
					<FormErrorMessage>
						{error}
					</FormErrorMessage>
					<List>
						{
							// get unique urls from pagesVisted
							uniqueUrls.map((item: string, index: number) => (
								<ListItem
									style={{ marginTop: '1rem' }}
									key={index}
									py='1'
								>
									<Button
										onClick={() => setLoginUrl(item)}
										size='sm'
										colorScheme='teal'
										variant='outline'
										maxWidth='100%'
									>
										<Text isTruncated>
											{item}
										</Text>
									</Button>
								</ListItem>
							))
						}
					</List>

					<FormHelperText>
						Initial login url for the provider
					</FormHelperText>
					<FormHelperText>
						eg : https://www.reddit.com/login/
					</FormHelperText>
				</FormControl>
				<Flex justifyContent='flex-end'>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={() => props.setActiveStepInnerForm(0)}
						width='10%'
						mr='30px'
						isLoading={buttonLoading}
					>
						Back
					</Button>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={handleNext}
						colorScheme='blue'
						width='10%'
						mr='30px'
						isLoading={buttonLoading}
					>
						Next
					</Button>
				</Flex>
			</Box>
		</>
	)
}