import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type NetworkRequest = {
    url: string
	method: string
	request: Object
	response: {
		body: string
	}
}

interface Session {
    targetUrl: string
    sessionId: string
    qrValue: string
    networkRequests: NetworkRequest[]
}

const initialState: Session = {
	targetUrl: '',
	sessionId: '',
	qrValue: '',
	networkRequests: []
}

const sessionSlice = createSlice({
	name: 'session',
	initialState,
	reducers: {
		setTargetUrl(state, action: PayloadAction<string>) {
			state.targetUrl = action.payload
		},
		setSessionId(state, action: PayloadAction<string>) {
			state.sessionId = action.payload
		},
		setQrValue(state, action: PayloadAction<string>) {
			state.qrValue = action.payload
		},
		setNetworkRequests(state, action: PayloadAction<NetworkRequest[]>) {
			const networkRequests = action.payload
			state.networkRequests = [...state.networkRequests, ...networkRequests]
		}
	}
})

export const { setTargetUrl, setSessionId, setQrValue, setNetworkRequests } = sessionSlice.actions
export default sessionSlice.reducer