import { useContext } from 'react'
import { Box, Button, Center, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import { AmplitudeAnalyticsContext } from '../../../../contexts/useAnalytics'
import { pxToRem } from '../../../../utils'
import { Container } from '../container/container.component'

// Assume you have a BlogCard component that represents a single blog card
const BlogCard = ({ title, description, navigateTo }: any) => {
	const { logEvent } = useContext(AmplitudeAnalyticsContext)

	return (
		<Box
			borderRadius='lg'
			style={
				{
					background:
        'linear-gradient(180deg, rgba(80, 80, 80, 0.15) 0%, rgba(0, 0, 0, 0) 70%',
				}
			}
			p={pxToRem(18)}
			mt={pxToRem(18)}
			mb={4}
			onClick={
				() => {
					logEvent('BLOG_CLICK', {
						title
					})
					window.open(navigateTo, '_blank')
				}
			}
		>
			<Heading
				fontSize='xl'
				color='white'
				mb={2}>
				{title}
			</Heading>
			<Text
				fontSize='16px'
				color='rgba(239,245,255,.693)'>
				{description}
			</Text>
			<Button
				colorScheme='blue'
				size='sm'
				mt={pxToRem(8)}>
				Read More
			</Button>
		</Box>
	)
}

export const BlogSection = () => {
	return (
		<Box
			padding='96px 24px'
			color='white'>
			<Center mb={8}>
				<Heading
					as='h1'
					fontWeight='bold'
					color='white'
					fontSize='40px'>
					Blogs
				</Heading>
			</Center>
			<Container>
				<SimpleGrid
					columns={{ base: 1, md: 2, lg: 3 }}
					spacing={8}
					mx='auto'
					// maxW='6xl'
					mt={pxToRem(24)}
				>
					<BlogCard
						title='Proof of Personhood in Crypto'
						description='Proof of personhood is essential in may dapps including airdrops, DAO voting, and Quadratic funding. However, there are not'
						navigateTo='https://blog.reclaimprotocol.org/posts/usecase-personhood/'
					/>
					<BlogCard
						title='What Is Reclaim Protocol'
						description='Reclaim Protocol unlocks unlimited possibilities by making HTTPS verifiable in zero knowledge. '
						navigateTo='https://blog.reclaimprotocol.org/posts/what-is-reclaimprotocol/'
					/>

					<BlogCard
						title='Onboarding & Loyalty Using Reclaim Protocol'
						description='Most apps start with a zerostate for a new user, primarily because there is no data available about the said user. Not any more.'
						navigateTo='https://blog.reclaimprotocol.org/posts/usecase-loyalty/'
					/>
				</SimpleGrid>
			</Container>
		</Box>
	)
}

