/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import {
    Button,
    Card,
    CardBody,
    Flex,
    Heading,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Tag,
    Text,
    UnorderedList,
} from '@chakra-ui/react'
import { useAppSelector } from '../../../../redux/hooks'
import { getSelectedManualVerificationSessionData } from '../../../../redux/manualVerification/sessionsDetails/selectors'
import { formatDate } from '../../../../utils/format-date'
import { statusTagColors, statusUpperCaseToLowerCase } from '../../../../utils/manual-verification-session/manual-verification-constants'

interface ManualVerificationWebhookDetailsModalProps {
    isWebhookDetailsModalOpen: boolean
    onWebhookDetailsModalClose: () => void
    webhookRetryBtnLoading: boolean
    handleRetryWebhook: any
}

export const ManualVerificationWebhookDetailsModal: React.FC<ManualVerificationWebhookDetailsModalProps> = ({
    isWebhookDetailsModalOpen,
    onWebhookDetailsModalClose,
    webhookRetryBtnLoading,
    handleRetryWebhook
}) => {

    const selectedSessionData = useAppSelector(getSelectedManualVerificationSessionData)

    return (
        <>
            <Modal
                size='2xl'
                isOpen={isWebhookDetailsModalOpen}
                onClose={onWebhookDetailsModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Heading
                            fontSize='large'
                        >
                            Webhook Details
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Card mb={3}>
                            <CardBody>
                                <UnorderedList
                                    listStyleType='none'
                                    spacing={3}>
                                    <ListItem>
                                        <Flex
                                            alignItems='flex-start'
                                            gap={10}>
                                            <Heading
                                                fontSize='medium'
                                            >
                                                Webhook Id
                                            </Heading>
                                            <Text
                                                fontSize='medium'
                                                lineHeight='24px'
                                                flex={1}
                                                textAlign='right'
                                            >
                                                {selectedSessionData?.webhooks?.[0]?.id ?? '-'}
                                            </Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem>
                                        <Flex
                                            alignItems='flex-start'
                                            gap={10}>
                                            <Heading
                                                fontSize='medium'
                                            >
                                                Session Id
                                            </Heading>
                                            <Text
                                                fontSize='medium'
                                                lineHeight='24px'
                                                flex={1}
                                                textAlign='right'
                                            >
                                                {selectedSessionData?.sessionId}
                                            </Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem>
                                        <Flex
                                            alignItems='flex-start'
                                            gap={10}>
                                            <Heading
                                                fontSize='medium'
                                            >
                                                Webhook URL
                                            </Heading>
                                            <Text
                                                fontSize='medium'
                                                lineHeight='24px'
                                                flex={1}
                                                textAlign='right'
                                            >
                                                {selectedSessionData?.webhookUrl}
                                            </Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem>
                                        <Flex
                                            alignItems='flex-start'
                                            gap={10}>
                                            <Heading
                                                fontSize='medium'
                                            >
                                                Webhook Status
                                            </Heading>
                                            <Text
                                                fontSize='medium'
                                                lineHeight='24px'
                                                flex={1}
                                                textAlign='right'
                                            >
                                                <Tag
                                                    colorScheme={statusTagColors?.[selectedSessionData?.webhooks?.[0]?.status ?? '-']}
                                                    size='md'
                                                    variant='subtle'
                                                    textTransform='capitalize'>
                                                    {statusUpperCaseToLowerCase?.[selectedSessionData?.webhooks?.[0]?.status ?? '-'] ?? '-'}
                                                </Tag>
                                            </Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem>
                                        <Flex
                                            alignItems='flex-start'
                                            gap={10}>
                                            <Heading
                                                fontSize='medium'
                                            >
                                                Total Attempts
                                            </Heading>
                                            <Text
                                                fontSize='medium'
                                                lineHeight='24px'
                                                flex={1}
                                                textAlign='right'
                                            >
                                                {selectedSessionData?.webhooks?.[0]?.attempts ?? 0}
                                            </Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem>
                                        <Flex
                                            alignItems='flex-start'
                                            gap={10}>
                                            <Heading
                                                fontSize='medium'
                                            >
                                                Created At
                                            </Heading>
                                            <Text
                                                fontSize='medium'
                                                lineHeight='24px'
                                                flex={1}
                                                textAlign='right'
                                            >
                                                {formatDate(selectedSessionData?.webhooks?.[0]?.createdAt ?? '-')}
                                            </Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem>
                                        <Flex
                                            alignItems='flex-start'
                                            gap={10}>
                                            <Heading
                                                fontSize='medium'
                                            >
                                                Last Attempted At
                                            </Heading>
                                            <Text
                                                fontSize='medium'
                                                lineHeight='24px'
                                                flex={1}
                                                textAlign='right'
                                            >
                                                {formatDate(selectedSessionData?.webhooks?.[0]?.lastAttemptedAt ?? '-')}
                                            </Text>
                                        </Flex>
                                    </ListItem>
                                    {
                                        selectedSessionData?.webhooks?.[0]?.status !== 'SUCCESS' && (
                                            <>
                                                <ListItem>
                                                    <Flex
                                                        alignItems='flex-start'
                                                        gap={10}>
                                                        <Heading
                                                            fontSize='medium'
                                                        >
                                                            Error Message
                                                        </Heading>
                                                        <Text
                                                            fontSize='medium'
                                                            lineHeight='24px'
                                                            flex={1}
                                                            textAlign='right'
                                                        >
                                                            {selectedSessionData?.webhooks?.[0]?.errorMessage ?? '-'}
                                                        </Text>
                                                    </Flex>
                                                </ListItem>
                                            </>
                                        )
                                    }
                                    {
                                        (selectedSessionData?.status === 'VERIFIED' || selectedSessionData?.status === 'REJECTED') && selectedSessionData?.webhooks?.[0]?.status === 'FAILED' && (
                                            <>
                                                <ListItem>
                                                    <Flex
                                                        alignItems='flex-start'
                                                        justifyContent='center'
                                                        gap={10}>
                                                        <Button
                                                            colorScheme='blue'
                                                            bgColor='#332fed'
                                                            color='#fff'
                                                            onClick={handleRetryWebhook}
                                                            isLoading={webhookRetryBtnLoading}>
                                                            Retry
                                                        </Button>
                                                    </Flex>
                                                </ListItem>
                                            </>
                                        )
                                    }
                                </UnorderedList>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}