import { useContext, useEffect, useRef, useState } from 'react'
import { MdThumbUp } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Card, CardBody, CardHeader, Center, Flex, Heading, Icon, Text, useDisclosure } from '@chakra-ui/react'
import { AmplitudeAnalyticsContext } from '../../../../contexts/useAnalytics'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { setSelectedProvidersFromCreateApp } from '../../../../redux/httpsProviders'
import { getAllActiveHttpsProviders } from '../../../../redux/httpsProviders/actions'
import { getSelectedProvidersFromCreateApp } from '../../../../redux/httpsProviders/selectors'
import { HttpsProvider } from '../../../../redux/httpsProviders/types'
import { resetNetworkStore } from '../../../../redux/providerBuilder/networkRequestStore'
import { resetProviderDetails } from '../../../../redux/providerBuilder/providerDetails'
import { getProviderDetails } from '../../../../redux/providerBuilder/providerDetails/selectors'
import { resetSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances'
import { resetSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest'
import { resetActiveStep, updateActiveStep, updateBackAction } from '../../../../redux/providerBuilder/stepperDetails'
import { getActiveStep, getStepperTitlesV2 } from '../../../../redux/providerBuilder/stepperDetails/selectors'
import { sendDevtoolLogs } from '../../../../utils/logs/send-logs'
import { getProviderById } from '../../../../utils/provider-builder/register-provider-utils'
import { getFirebaseIdToken } from '../../../../utils/utils'
import { VerifyProvider } from './verify-provider.component'

export const ReviewProvider = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const providerDetails = useAppSelector(getProviderDetails)
	const selectedProvidersFromCreateApp = useAppSelector(
		getSelectedProvidersFromCreateApp
	)

	const activeStep = useAppSelector(getActiveStep)
	const stepperTitles = useAppSelector(getStepperTitlesV2)

	const [providerDetailsFromBackend, setProviderDetailsFromBackend] = useState<HttpsProvider>()
	const [buttonLoading, setButtonLoading] = useState(false)
	const [providerVerified, setProviderVerified] = useState(providerDetails.isActive)
	const cancelRef = useRef<HTMLInputElement>(null)


	const {
		isOpen: isAlertDialogOpen,
		onOpen: onAlertDialogOpen,
		onClose: onAlertDialogClose,
	} = useDisclosure()

	const { logEvent } = useContext(AmplitudeAnalyticsContext)


	useEffect(() => {
		// Focus the button when the component mounts
		cancelRef.current?.focus()
	}, [])

	useEffect(() => {
		const fetchProvider = async () => {

			if (providerVerified) {

				const accessToken = (await getFirebaseIdToken()) ?? ''
				const provider = await getProviderById(accessToken, providerDetails.httpProviderId)
				setProviderDetailsFromBackend(provider)
			}
		}

		fetchProvider()
	}, [providerVerified])

	function handleEditProvider() {
		dispatch(updateBackAction(true))
		dispatch(updateActiveStep(activeStep - 1))
	}

	async function handleFinish() {
		setButtonLoading(true)
		if (providerVerified) {
			await sendDevtoolLogs('PROVIDER_PUBLISHED', providerDetails.httpProviderId)
			dispatch(getAllActiveHttpsProviders())
			if (providerDetailsFromBackend) {
				dispatch(
					setSelectedProvidersFromCreateApp([
						...selectedProvidersFromCreateApp,
						providerDetailsFromBackend, // Add null check here
					])
				)
			}

			logEvent('PROVIDER_CREATED')
		}

		dispatch(resetNetworkStore())
		dispatch(resetProviderDetails())
		dispatch(resetSelectedInstances())
		dispatch(resetSelectedNetworkRequest())
		dispatch(resetActiveStep())
		navigate('/new-application')
	}

	return (
		<>
			<Box
				marginTop='2.5rem'
				marginBottom='5rem'>
				<Card>
					<CardHeader>
						<Heading fontSize='larger'>
							{stepperTitles[activeStep]}
						</Heading>
					</CardHeader>
					<CardBody height='80%' >
						<Box>
							{
								providerVerified ? (

									<Alert status='success'>
										You have Successfully built a data provider&nbsp;&nbsp;
										<Icon as={MdThumbUp} />
									</Alert>
								) : (
									<Alert status='error'>
										Provider is not active. Please complete provider verification using the QR Code to activate it.
									</Alert>
								)
							}
							<Heading
								mt='40px'
								fontSize='large'
								mb='20px'>
								Test the Data Provider by Scanning the QR Code
							</Heading>
							<Text
								mt='10px'
								fontSize='medium'
								mb='50px'>
								Note: Please ensure that you are getting the correct data from the provider.
							</Text>
							<Center>
								<VerifyProvider
									httpProviderId={providerDetails?.httpProviderId}
									setProviderVerified={setProviderVerified} />
							</Center>
							<Flex
								mt='20px'
								justifyContent='flex-end'>
								<Button
									variant='solid'
									onClick={handleEditProvider}

									mr='30px'
									isLoading={buttonLoading}
								>
									Edit
								</Button>
								<Button
									variant='solid'
									onClick={
										async () => {
											if (providerVerified) {
												await handleFinish()
											} else {
												onAlertDialogOpen()
											}
										}
									}
									colorScheme='blue'
									mr='30px'
									isLoading={buttonLoading}
								>
									Finish
								</Button>
								<AlertDialog
									isOpen={isAlertDialogOpen}
									onClose={onAlertDialogClose}
									leastDestructiveRef={cancelRef}
								>
									<AlertDialogOverlay>
										<AlertDialogContent>
											<AlertDialogHeader
												fontSize='lg'
												fontWeight='bold'>
												Finish Provider Building?
											</AlertDialogHeader>

											<AlertDialogBody>
												You cannot use this provider. Provider Verification is Pending.
												{' '}
												<br />

												{' '}
												<br />
												Are you sure you want to finish provider building?
											</AlertDialogBody>

											<AlertDialogFooter>
												<Button
													// @ts-ignore
													ref={cancelRef}
													onClick={
														() => {
															onAlertDialogClose()
														}
													}>
													Cancel
												</Button>
												<Button
													isLoading={buttonLoading}
													colorScheme='red'
													onClick={
														async () => {
															await handleFinish()
														}
													}
													ml={3}
												>
													Finish
												</Button>
											</AlertDialogFooter>
										</AlertDialogContent>
									</AlertDialogOverlay>
								</AlertDialog>
							</Flex>
						</Box>

					</CardBody>
				</Card>
			</Box>
		</>
	)
}