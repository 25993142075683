import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
	Avatar,
	Box,
	Flex,
	Image,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spacer,
	Text,
	useColorMode,
	useDisclosure,
} from '@chakra-ui/react'
import { getAuth, signOut } from 'firebase/auth'
import { app } from '../../../configs/firebaseApp'
import { AmplitudeAnalyticsContext } from '../../../contexts/useAnalytics'
import { useTokenExpiration } from '../../../hooks/useTokenExpiration'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { resetManualVerificationStepperState } from '../../../redux/manualVerification/stepperDetails'
import { logoutClient } from '../../../redux/user/actions'
import { getPhotoURL, getUserDisplayName, getUserType } from '../../../redux/user/selectors'
import { pxToRem } from '../../../utils'
// import withLogin, { withLoginProps } from '../../AuthWrapper'


const Navbar = () => {
	const { colorMode } = useColorMode()
	const { onOpen, onClose } = useDisclosure()
	const navigate = useNavigate()
	const auth = getAuth(app)
	// const { user } = props.authData
	const userName = useAppSelector(getUserDisplayName)
	const photoURL = useAppSelector(getPhotoURL)
	const userType = useAppSelector(getUserType)

	const user = getAuth(app).currentUser

	const dispatch = useAppDispatch()

	const { amplitudeLogOut, setDashboardVisitCount } = useContext(AmplitudeAnalyticsContext)


	const handleSignOut = () => {
		onClose()
		signOut(auth)

		// clear redux states
		dispatch(logoutClient())
		navigate('/')
	}

	const navigateToManualVerificationScreen = () => {
		dispatch(resetManualVerificationStepperState())
		navigate('/manual-verification')
	}

	useTokenExpiration()

	return (
		<Box
			bg={colorMode === 'light' ? '#fff' : '#131417'}
			p={4}>
			<Flex
				align='center'
				maxWidth={pxToRem(1444)}
				margin='0 auto'>
				<Image
					src={colorMode === 'dark' ? '/assets/logo-light.png' : '/assets/logo.png'}
					alt='Logo'
					boxSize='40px'
					cursor='pointer'
					onClick={() => navigate('/')}
				/>

				{/* <Box>
          <Text fontSize='lg' fontWeight='bold'>
            My App
          </Text>
        </Box> */}

				<Spacer />

				{/* Menu Items */}
				<Flex
					gap='30px'
					display={{ base: 'none', md: 'flex' }}>
					<Link
						style={
							{
								fontWeight:
									location.pathname === '/applications' ||
										location.pathname === '/dashboard'
										? 'bold'
										: 'normal',
								textDecoration:
									location.pathname === '/applications' ||
										location.pathname === '/dashboard'
										? 'underline'
										: 'none',
							}
						}
						to='/applications'
					>
						Applications
					</Link>

					<Link
						style={
							{
								fontWeight: location.pathname === '/explore' ? 'bold' : 'normal',
								textDecoration:
									location.pathname === '/explore' ? 'underline' : 'none',
							}
						}
						to='/explore'
					>
						Data Providers
					</Link>

					{
						userType === 'ADMIN' && (
							<Link
								style={
									{
										fontWeight:
											location.pathname === '/admin/dashboard' ? 'bold' : 'normal',
										textDecoration:
											location.pathname === '/admin/dashboard'
												? 'underline'
												: 'none',
									}
								}
								to='/admin/dashboard'
							>
								Admin
							</Link>
						)
					}
				</Flex>

				{/* Dark/Light Mode Toggle */}
				{/* <IconButton
					ml={pxToRem(30)}
					icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
					onClick={toggleColorMode}
					aria-label=''
				/> */}

				{/* User Icon and Dropdown */}
				<Menu>
					<MenuButton onClick={onOpen}>
						<Avatar
							name={userName as string}
							src={photoURL as string}
							size='md'
							width='40px'
							height='40px'
							ml='30px'
						/>
					</MenuButton>

					<MenuList>
						<MenuItem onClick={() => navigate('/profile')}>
							<Text fontWeight='bold'>
								{userName ? userName : user?.displayName}
							</Text>
						</MenuItem>
						{
							userType === 'ADMIN' && (
								<MenuItem onClick={navigateToManualVerificationScreen}>
									<Text fontWeight='normal'>
										Manual Verification
									</Text>
								</MenuItem>
							)
						}
						<MenuItem
							onClick={
								() => {
									setDashboardVisitCount(0)
									handleSignOut()
									amplitudeLogOut()
								}
							}
						>
							Sign Out
						</MenuItem>
					</MenuList>
				</Menu>

				{/* Hamburger Menu for Mobile */}
				<Box display={{ base: 'block', md: 'none' }}>
					{/* <Avatar
            name='Dan Abrahmov'
            src='https://bit.ly/dan-abramov'
            onClick={onOpen}
          /> */}
					{/* <IconButton
            rounded={"50%"}
            icon={<Image src='/menu-icon.png' alt='Menu' boxSize='30px' />}
            onClick={onOpen}
            aria-label={""}
          /> */}
				</Box>
			</Flex>
		</Box>
	)
}

export default Navbar
