import { RootState } from '../store'

export const getUserEmailId = (state: RootState) => state.user.emailId
export const getIsLoggedIn = (state: RootState) => state.user.isLoggedIn
export const getIsRegistrationSuccessful = (state: RootState) => state.user.isRegistrationSuccessful
export const getUserType = (state: RootState) => state.user.userType
export const getAccessToken = (state: RootState) => state.user.accessToken
export const getPhotoURL = (state: RootState) => state.user.photoURL
// export const getActionStatus = (state: RootState) => state.user.actionStatus
// export const getActionStatusMessage = (state: RootState) => state.user.actionStatusMessage
export const getAllUsers = (state: RootState) => state.user.allUsers
export const getUserDisplayName = (state: RootState) => state.user.displayName
export const getUserAuthInfo = (state: RootState) => {
	state.user
}