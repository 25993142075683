import { useContext, useEffect, useRef, useState } from 'react'
import { MdKeyboardBackspace } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { Box, Heading, IconButton, Step, StepIcon, StepIndicator, StepNumber, Stepper, StepSeparator, StepStatus, StepTitle, useToast } from '@chakra-ui/react'
import {
	ChatMessage,
	ChatMessageType,
} from '@reclaim-developer-experience/dev-tool-client-sdk/dist'
import { getAuth } from 'firebase/auth'
//import withLogin, { withLoginProps } from '../../components/AuthWrapper'
import { app } from '../../configs/firebaseApp'
import { AmplitudeAnalyticsContext } from '../../contexts/useAnalytics'
import { getWebClient } from '../../lib/client'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setCurrentUrlInput, updateAppConnected } from '../../redux/providerBuilder/mobileClient'
import { getAppConnected, getCurrentUrlInput } from '../../redux/providerBuilder/mobileClient/selectors'
import { updateNetworkStore, updatePagesVisted } from '../../redux/providerBuilder/networkRequestStore'
import { getAllNetworkRequest, getPagesVisted } from '../../redux/providerBuilder/networkRequestStore/selectors'
import { updateActiveStep } from '../../redux/providerBuilder/stepperDetails'
import { getActiveStep, getStepperTitles } from '../../redux/providerBuilder/stepperDetails/selectors'
import { generateSession } from '../../redux/session/actions'
import { getSessionId } from '../../redux/session/selectors'
import { getFirebaseIdToken } from '../../utils/utils'
import { InitialiseSession } from './components/initialise-session/initialise-session.component'
import { ProofCard } from './components/proof-card.component'
import { StepperForm } from './components/provider-details/stepper-form.component'
import { RequestSelection } from './components/request-selection/request-selection.component'
import { ReviewProvider } from './components/review-provider.component'


export const ProviderBuilderScreen = () => {
	const toast = useToast()
	const dispatch = useAppDispatch()
	const sessionId = useAppSelector(getSessionId)
	const networkRequests = useAppSelector(getAllNetworkRequest)
	const pagesVisited = useAppSelector(getPagesVisted)
	const activeStep = useAppSelector(getActiveStep)
	const stepperTitles = useAppSelector(getStepperTitles)
	const appConnected = useAppSelector(getAppConnected)
	const currentUrlInput = useAppSelector(getCurrentUrlInput)

	const auth = getAuth(app)

	const [accessToken, setAccessToken] = useState('')
	const [allPagesVisited, setAllPagesVisited] = useState<string[]>([])
	const sessionInit = useRef(false)

	const { logEvent } = useContext(AmplitudeAnalyticsContext)

	useEffect(() => {
		generateSessionHandler()
		refreshAccessToken()
	}, [accessToken])

	useEffect(() => {
		if(sessionId) {
			const interval = setInterval(() => {
				receiveMessages()
			}, 2000)
			return () => {
				clearInterval(interval)
			}
		}
	}, [sessionId, pagesVisited])

	const generateSessionHandler = async() => {
		if(!sessionInit.current) {
			sessionInit.current = true
			await dispatch(generateSession({ accessToken }))
		}
	}

	async function refreshAccessToken() {
		const newToken = await getFirebaseIdToken()
		if(newToken !== accessToken) {
			setAccessToken(newToken as string)
		}

		return newToken
	}

	function makeid(length: number) {
		let result = ''
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
		const charactersLength = characters.length
		let counter = 0
		while(counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength))
			counter += 1
		}

		return result
	}

	const newHandler = (message: ChatMessage) => {
		if(message.type !== ChatMessageType.CONNECTION_PENDING) {
			dispatch(updateAppConnected(true))
		}

		if(message.type === ChatMessageType.CONNECTED) {
			dispatch(updateAppConnected(true))
			logEvent('DEVTOOL_MOBILE_CLIENT_CONNECTED')
			toast({
				title: 'Mobile Client Connected Successfully',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}

		if(message.type === ChatMessageType.M_RECLAIM_URL_RECEIVED) {
			dispatch(setCurrentUrlInput(message.message))
		}

		if(message.type === ChatMessageType.M_NETWORK_REQUESTS_AND_RESPONSES) {
			const data = JSON.parse(message.message)
			data.id = makeid(5)

			data.intId = networkRequests.length

			if(data.request.headers) {
				data.request.headers = Object.keys(data.request.headers).map(
					(key: string) => ({
						value: data.request.headers[key],
						key,
					})
				)
			}

			if(data.cookies) {
				data.cookies = Object.values(data.cookies)
			}

			if(data.currentPageUrl && currentUrlInput !== data.currentPageUrl) {
				dispatch(setCurrentUrlInput(data.currentPageUrl))
			}

			if(!allPagesVisited.includes(data.currentPageUrl)) {
				setAllPagesVisited([...allPagesVisited, data.currentPageUrl])
				dispatch(updatePagesVisted(data.currentPageUrl))
			}

			dispatch(updateNetworkStore(data))

		}
	}

	const receiveMessages = async() => {
		const newAccessToken = await refreshAccessToken()
		const client = getWebClient(newAccessToken as string)
		// const redirectUrlValue = 'https://dev.reclaimprotocol.org/devtool/redirect'
		try {
			const response = await client.receiveMessage(sessionId)
			if(response.length) {
				response.forEach((message: ChatMessage) => {
					try {
						newHandler(message)
						// if(message.type === ChatMessageType.CONNECTED) {
						// 	client.sendMessage(
						// 		{ message: redirectUrlValue, type: ChatMessageType.M_SET_CURRENT_URL },
						// 		sessionId
						// 	)
						// }

					} catch(e: unknown) {
					}
				})
			}

			if(appConnected && newAccessToken !== accessToken) {
				client.sendMessage(
					{
						message: newAccessToken as string,
						type: ChatMessageType.W_SET_NEW_ACCESS_TOKEN,
					},
					sessionId
				)
			}
		} catch(e) {
			console.log('error' + e)
		}
	}

	const navigate = useNavigate()
	return (
		<Box>
			<IconButton
				mt='30px'
				variant='outline'
				colorScheme='blue'
				aria-label=''
				onClick={() => navigate(-1)}
				icon={<MdKeyboardBackspace />}
				width='fit-content'
			/>
			<Heading
				marginTop='10px'
				fontSize='30px'
				marginBottom='1.5rem'>
				Provider Dev Tool
			</Heading>
			<Stepper
				index={activeStep}
				colorScheme='green'>
				{
					stepperTitles.map((step, index) => (
						<Step key={index}>
							<StepIndicator>
								<StepStatus
									complete={
										<StepIcon
											onClick={
												() => activeStep >= index
													? dispatch(updateActiveStep(index))
													: null
											}
											cursor='pointer'
										/>
									}
									incomplete={<StepNumber />}
									active={<StepNumber />}
								/>
							</StepIndicator>

							<Box
								flexShrink='0'
								cursor={activeStep >= index ? 'pointer' : 'not-allowed'}
								onClick={() => activeStep >= index ? dispatch(updateActiveStep(index)) : null}
							>
								<StepTitle>
									{step}
								</StepTitle>
							</Box>

							<StepSeparator />
						</Step>
					))
				}
			</Stepper>
			{
				activeStep === 0 && (
					<InitialiseSession
						accessToken={accessToken}
						userUid={auth.currentUser?.uid as string} />
				)
			}
			{activeStep === 1 && <RequestSelection />}
			{activeStep === 2 && <StepperForm />}
			{activeStep === 3 && <ProofCard />}
			{activeStep === 4 && <ReviewProvider />}
		</Box>
	)
}
