import { useContext, useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { AmplitudeAnalyticsContext } from '../../contexts/useAnalytics'
import { useTokenExpiration } from '../../hooks/useTokenExpiration'
import { sendDevtoolLogs } from '../../utils/logs/send-logs'
import { BlogSection, FooterSection, HeroSection, IntegrateScreen, Navbar, WhySection } from './components'

export const LandingScreen = () => {
	const { setIsFirstVisit } = useContext(AmplitudeAnalyticsContext)

	useEffect(() => {
		setIsFirstVisit(true)
		sendDevtoolLogs('WEBSITE_OPENED')
	}, [])
	useTokenExpiration()
	return (
		<Box bgColor='#0C111D'>
			<Navbar />
			<HeroSection />
			<WhySection />
			<IntegrateScreen />
			<BlogSection />
			<FooterSection />
		</Box>
	)
}