/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBackIcon, QuestionIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Card,
    CardBody,
    Code,
    Flex,
    Heading,
    Icon,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Text,
    Tooltip,
    useToast,
    VStack,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { resetSelectedManualVerificationSessionData } from '../../../../redux/manualVerification/sessionsDetails'
import { getSelectedManualVerificationSessionData } from '../../../../redux/manualVerification/sessionsDetails/selectors'
import { setSelectedSessionId, updateActiveStep as updateManualVerificationActiveStep } from '../../../../redux/manualVerification/stepperDetails'
import { getManualVerificationSelectedSessionId } from '../../../../redux/manualVerification/stepperDetails/selectors'
import { updateExpandedPaths } from '../../../../redux/providerBuilder/jsonViewer'
import { resetNetworkStore } from '../../../../redux/providerBuilder/networkRequestStore'
import { resetProviderDetails, updateProviderDetails } from '../../../../redux/providerBuilder/providerDetails'
import { getProviderDetails } from '../../../../redux/providerBuilder/providerDetails/selectors'
import { ProviderDetails } from '../../../../redux/providerBuilder/providerDetails/types'
import { resetSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances'
import { getSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances/selectors'
import { resetSelectedNetworkRequest, updateSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest'
import { getSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest/selectors'
import { createParamValues } from '../../../../utils/manual-verification-session/create-param-values'
import { updateManualVerificationSession } from '../../../../utils/manual-verification-session/update-manual-verification-session-utils'
import {
    getAllDynamicPaths,
    getAllPaths,
    getValueByPath,
    setValueByPath,
} from '../../../../utils/provider-builder/instance-selection-utils'
import { extractDomain } from '../../../../utils/provider-builder/proof-card-utils'
import { editProvider } from '../../../../utils/provider-builder/register-provider-utils'
import {
    getDefaultProofCardText,
    initializeRegexBody,
    seperateURLComponents,
    urlSpanToRegexUrl,
    URLSpanType,
} from '../../../../utils/provider-builder/request-config-utils'
import { getFirebaseIdToken } from '../../../../utils/utils'
import CustomJsonViewerScreen3 from '../../../provider-builder-v3/components/request-selection/custom-json-viewer-screen-3'
import { OptionalFields } from './optional-fields.component'


export const ManualVerificationFinalConfigScreen = () => {
    const toast = useToast()
    const dispatch = useAppDispatch()

    const params = useParams()
    const navigate = useNavigate()

    const selectedNetworkRequest = useAppSelector(getSelectedNetworkRequest)
    const selectedInstances = useAppSelector(getSelectedInstances)
    const providerDetails = useAppSelector(getProviderDetails)
    const sessionId = useAppSelector(getManualVerificationSelectedSessionId)
    const selectedSessionData = useAppSelector(getSelectedManualVerificationSessionData)

    const [urlSpans, setUrlSpans] = useState<any>([])
    const [regexBody, setRegexBody] = useState('')
    const [selectedText, setSelectedText] = useState<any>(null)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [dynamicURLValues, setDynamicURLValues] = useState<any>({})
    const [dynamicBodyValues, setDynamicBodyValues] = useState<any>({})

    useEffect(() => {
        if (selectedNetworkRequest) {
            if (providerDetails?.httpProviderId?.length && providerDetails?.url === selectedNetworkRequest?.templateUrl && providerDetails?.method === selectedNetworkRequest.method) {
                if (selectedNetworkRequest?.urlSpans?.length) {
                    const urlSpans = [...selectedNetworkRequest?.urlSpans]
                    setUrlSpans(urlSpans)
                } else {
                    const newUrlSpans = seperateURLComponents(selectedNetworkRequest.url)
                    setUrlSpans(newUrlSpans)
                }

                const regexBody = providerDetails?.bodySniff?.template

                try {
                    if (regexBody !== '') {
                        const data = JSON.parse(regexBody)
                        const expandedPaths = getAllPaths('', data, '')
                        expandedPaths[''] = true
                        dispatch(updateExpandedPaths(expandedPaths))
                    }

                } catch (error) {
                    console.log(error)

                } finally {
                    setRegexBody(regexBody)
                }
            } else {
                const newUrlSpans = seperateURLComponents(selectedNetworkRequest.url)
                setUrlSpans(newUrlSpans)
                const newRegexBody = initializeRegexBody(
                    selectedNetworkRequest.request?.body
                )
                if (selectedNetworkRequest?.contentType?.includes('json') && newRegexBody?.length) {
                    try {
                        const data = JSON.parse(newRegexBody)
                        const expandedPaths = getAllPaths('', data, '')
                        expandedPaths[''] = true
                        dispatch(updateExpandedPaths(expandedPaths))
                    } catch (error) {
                        console.log(error, '>>>', { newRegexBody })
                    }
                }

                setRegexBody(newRegexBody)
            }


        }
    }, [selectedNetworkRequest])

    useEffect(() => {
        handleClaimSubmit()
    }, [providerDetails])

    async function handleClaimSubmit() {
        if (buttonLoading) {
            await editHttpProvider()
            setButtonLoading(false)
        }
    }

    async function editHttpProvider() {
        try {
            const accessToken = (await getFirebaseIdToken()) ?? ''

            const payload: ProviderDetails = {
                ...providerDetails,
            }
            const message1 = await editProvider(payload, accessToken)

            if (selectedSessionData?.status === 'PENDING') {
                const paramValues = createParamValues(selectedInstances, dynamicURLValues, dynamicBodyValues)
                const message2: any = await updateManualVerificationSession(accessToken, sessionId, 'VERIFIED', paramValues)
                if (!message2.isSuccess) {
                    toast({
                        title: 'Error',
                        description: 'something went wrong',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                        position: 'top-right',
                    })
                    return
                }
            }

            if (!message1.isSuccess) {
                toast({
                    title: 'Error',
                    description: 'something went wrong',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right',
                })
                return
            } else {
                toast({
                    title: 'Successfull',
                    description: '',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right',
                })
                dispatch(resetProviderDetails())
                dispatch(resetNetworkStore())
                dispatch(resetSelectedInstances())
                dispatch(resetSelectedNetworkRequest())
                dispatch(resetSelectedManualVerificationSessionData())
                setButtonLoading(false)

                if (params?.sessionId?.length) {
                    dispatch(setSelectedSessionId(''))
                    dispatch(updateManualVerificationActiveStep(1))
                    navigate('/manual-verification')
                } else {
                    dispatch(updateManualVerificationActiveStep(2))
                }
            }


        } catch (e) {
            toast({
                title: 'Error',
                description: 'something went wrong',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top-right',
            })
        }
    }

    function handleGoBack() {
        dispatch(updateManualVerificationActiveStep(4))
    }

    function toggleSelectedUrlSpan(index: number) {

        if (urlSpans[index].type !== 'STATIC') {
            let paramsCount = 1
            const totalLength = urlSpans.length
            const tempURLValues: any = {}


            const updatedUrlSpans = urlSpans.map((url: any) => ({ ...url }))

            updatedUrlSpans.forEach((url: any, i: number) => {

                if (index === i) {
                    if (url.type?.includes('URL_PARAMS')) {
                        updatedUrlSpans[i].type = URLSpanType.CONSTANT
                    } else if (url.type === URLSpanType.CONSTANT) {
                        // Check if the selected index is the last in the array
                        if (index === totalLength - 1) {
                            updatedUrlSpans[i].type = `{{URL_PARAMS_GRD_${paramsCount++}}}`
                            tempURLValues[`{{URL_PARAMS_GRD_${paramsCount - 1}}}`] = updatedUrlSpans[i].key

                        } else {
                            updatedUrlSpans[i].type = `{{URL_PARAMS_${paramsCount++}}}`
                            tempURLValues[`{{URL_PARAMS_${paramsCount - 1}}}`] = updatedUrlSpans[i].key
                        }
                    }
                } else if (url.type?.includes('URL_PARAMS') && index !== i) {
                    if (i === totalLength - 1) {
                        updatedUrlSpans[i].type = `{{URL_PARAMS_GRD_${paramsCount++}}}`
                        tempURLValues[`{{URL_PARAMS_GRD_${paramsCount - 1}}}`] = updatedUrlSpans[i].key
                    } else {
                        updatedUrlSpans[i].type = `{{URL_PARAMS_${paramsCount++}}}`
                        tempURLValues[`{{URL_PARAMS_${paramsCount - 1}}}`] = updatedUrlSpans[i].key
                    }
                }
            })
            setDynamicURLValues({ ...tempURLValues })
            setUrlSpans(updatedUrlSpans)
        }
    }

    function handleJsonKeyClick(
        clickedPath: string,
        checked: boolean = false
    ) {
        const jsonObject = JSON.parse(regexBody)
        const tempBodyValues: any = {}
        const allDynamicPaths = getAllDynamicPaths(
            '',
            jsonObject,
            '',
            {},
            false,
            clickedPath,
            checked
        )

        for (const dynamicPath in allDynamicPaths) {
            let value = allDynamicPaths?.[dynamicPath] ?? '{{REQ_BODY_1}}'
            const orginalJSON = JSON.parse(selectedNetworkRequest.request?.body)

            let skipDynamicValue = false

            if (value === 'ORIGINAL_VALUE') {
                value = getValueByPath(orginalJSON, dynamicPath.substring(1))
                skipDynamicValue = true
            }

            if (!skipDynamicValue) {
                tempBodyValues[value] = getValueByPath(orginalJSON, dynamicPath.substring(1))
            }

            setValueByPath(jsonObject, dynamicPath.substring(1), value)
        }

        const updatedJsonString = JSON.stringify(jsonObject)
        setDynamicBodyValues({ ...tempBodyValues })
        setRegexBody(updatedJsonString)
    }

    function resetRegexBody() {
        const newRegexBody = initializeRegexBody(
            selectedNetworkRequest.request?.body
        )
        setDynamicBodyValues({})
        setRegexBody(newRegexBody)
        setSelectedText(null)
    }

    // replace the selected text in the body and make it dynamic
    function handleReplaceRegexBody() {
        // Replace the selected text with the replacement text
        if (selectedText?.toString()?.length) {
            let startOffset = selectedText.anchorOffset
            let endOffset = selectedText.focusOffset

            const tempDynamicBodyValues = { ...dynamicBodyValues }

            if (startOffset > endOffset) {
                const endTemp = endOffset
                endOffset = startOffset
                startOffset = endTemp
            }

            let prevRegexBody = regexBody
            const prefix = prevRegexBody.slice(0, startOffset)
            const suffix = prevRegexBody.slice(endOffset)

            const specialString = '{{REQ_BODY_999}}'
            prevRegexBody = `${prefix}${specialString}${suffix}`

            let paramsCounter = 1
            const dynamicVariables = prevRegexBody.match(/{{REQ_BODY_\d+}}/g) ?? []

            tempDynamicBodyValues[`{{REQ_BODY_${dynamicVariables?.length}}}`] = selectedText?.toString()

            if (dynamicVariables) {
                for (const dynamicVariable of dynamicVariables) {
                    prevRegexBody = prevRegexBody.replace(
                        dynamicVariable,
                        `{{REQ_BODY_${paramsCounter}}}`
                    )
                    paramsCounter++
                }
            }

            setRegexBody(prevRegexBody)
            setDynamicBodyValues((prev: any) => ({ ...prev, ...tempDynamicBodyValues }))
            setSelectedText(null)
        } else {
            toast({
                title: 'No text selected',
                description: 'Select the text you want to make dynamic from the body',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top-right',
            })
        }
    }

    // Makes all body content as dynamic in regex body
    function makeFullBodyDynamic() {
        setRegexBody('{{REQ_BODY_GRD_1}}')
        const newRegexBody = initializeRegexBody(
            selectedNetworkRequest.request?.body
        )
        setDynamicBodyValues({ '{{REQ_BODY_GRD_1}}': newRegexBody })
        setSelectedText(null)
    }

    // set selected text to the selectedText state
    function handleTextSelect() {
        const selection = window.getSelection()
        if (selection !== null) {
            setSelectedText(selection)
        }
    }

    function handleNext() {
        setButtonLoading(true)
        const templateUrl = urlSpanToRegexUrl(urlSpans)

        const bodyEnabled = !!selectedNetworkRequest.request?.body
        const finalTemplateBody = regexBody

        const proofCardText = getDefaultProofCardText(selectedInstances)
        const domainName = extractDomain(selectedNetworkRequest.url)
        const proofCardTitle = domainName
        // const logoUrl = `https://www.google.com/s2/favicons?domain=${domainName}`

        dispatch(updateSelectedNetworkRequest({
            urlSpans,
            templateUrl
        }))

        dispatch(
            updateProviderDetails({
                url: templateUrl,
                urlType: 'TEMPLATE',
                bodySniff: {
                    enabled: bodyEnabled,
                    regex: '',
                    template: bodyEnabled ? finalTemplateBody : '',
                },
                proofCardText: proofCardText,
                proofCardTitle: proofCardTitle,
                // logoUrl: logoUrl,
                verificationType: 'WITNESS'
            })
        )
    }


    return (
        <>
            <Card
                marginBottom='2.5rem'
                width='100%'>
                <CardBody>
                    <VStack>
                        <Box w='full'>
                            <Flex gap='50px' >
                                <Box w='100%'>
                                    <Flex
                                        direction='column'
                                        gap={2}
                                        w='100%'
                                    >
                                        <Flex
                                            direction='column'
                                            gap={2}
                                            py={5}
                                            px={1}
                                            borderBottom='1px solid #EFF1F5'>
                                            <Flex
                                                alignItems='center'
                                                gap={3}>
                                                <IconButton
                                                    variant='outline'
                                                    size='sm'
                                                    icon={<ArrowBackIcon />}
                                                    onClick={handleGoBack}
                                                    aria-label='Go Back' />
                                                <Heading
                                                    fontSize='large'
                                                >
                                                    Add Provider Configurations
                                                </Heading>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                        <Box w='full'>
                            <Flex gap='50px'>
                                <Box flex={1}>
                                    <Flex
                                        direction='column'
                                        gap={2}>
                                        <Flex
                                            direction='column'
                                            gap={2}
                                            py={5}
                                            px={1}
                                            borderBottom='1px solid #EFF1F5'>
                                            <Heading
                                                fontSize='large'
                                            >
                                                Select parts of URL which contains personal data *
                                            </Heading>

                                            <Flex
                                                alignItems='center'
                                                gap={3}>
                                                <Text
                                                    fontSize='medium'
                                                    overflow='hidden'
                                                    whiteSpace='pre-wrap'
                                                >
                                                    {
                                                        urlSpans.map((span: any, idx: number) => (
                                                            <span
                                                                key={idx}
                                                                className={
                                                                    span.type !== 'STATIC'
                                                                        ? 'urlDynamicSpan urlDynamicSpanManualVerification'
                                                                        : 'urlStaticSpan urlStaticSpanManualVerification'
                                                                }

                                                                onClick={() => toggleSelectedUrlSpan(idx)}
                                                            >
                                                                {span.type?.includes('URL_PARAMS') ? span.type : span.key}
                                                            </span>
                                                        ))
                                                    }
                                                </Text>
                                                <Tooltip
                                                    whiteSpace='pre-line'
                                                    label='Set parts of URL as dynamic.&#10;Eg. User Id, Post Id'
                                                >
                                                    <Icon
                                                        as={QuestionIcon}
                                                        w={4}
                                                        h={4}
                                                        color='#0366d6'
                                                        cursor='pointer'
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        </Flex>


                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                        <Box w='full'>
                            <Flex gap='50px' >
                                <Box flex={1}>
                                    <Flex
                                        direction='column'
                                        gap={2}
                                        borderBottom='1px solid #EFF1F5'>
                                        <Flex
                                            direction='column'
                                            gap={2}
                                            py={5}
                                            px={1}
                                        >
                                            <Flex alignItems='center' >
                                                <Heading
                                                    fontSize='large'
                                                    flex={1}
                                                >
                                                    Select parts of the body that contain personal data *
                                                </Heading>
                                                {
                                                    selectedNetworkRequest?.contentType?.includes('json') && regexBody !== '' && (
                                                        <Button
                                                            onClick={resetRegexBody}
                                                            size='sm'
                                                            variant='outline'
                                                            borderColor='#332FED'
                                                            color='#332FED'
                                                        >
                                                            <Tooltip
                                                                label='Reset the body to its original state'
                                                                style={
                                                                    {
                                                                        marginTop: '3rem',
                                                                        marginBottom: '3rem',
                                                                    }
                                                                }
                                                                aria-label='A tooltip'
                                                            >
                                                                Reset Selections
                                                            </Tooltip>
                                                        </Button>
                                                    )
                                                }

                                            </Flex>
                                            {
                                                !selectedNetworkRequest?.contentType?.includes('json') && regexBody !== '' && (
                                                    <Flex
                                                        py={2}
                                                        alignItems='center'
                                                        gap={5}>
                                                        <Button
                                                            onClick={resetRegexBody}
                                                            size='sm'
                                                            variant='outline'
                                                            borderColor='#332FED'
                                                            color='#332FED'
                                                        >
                                                            <Tooltip
                                                                label='Reset the body to its original state'
                                                                style={
                                                                    {
                                                                        marginTop: '3rem',
                                                                        marginBottom: '3rem',
                                                                    }
                                                                }
                                                                aria-label='A tooltip'
                                                            >
                                                                Reset Selections
                                                            </Tooltip>
                                                        </Button>
                                                        <Button
                                                            onClick={handleReplaceRegexBody}
                                                            isDisabled={!!!selectedText}
                                                            size='sm'
                                                            variant='outline'
                                                            borderColor='#332FED'
                                                            color='#332FED'
                                                        >
                                                            <Tooltip
                                                                label='Select the text you want to make dynamic and click this button'
                                                                aria-label='A tooltip'
                                                            >
                                                                Make Dynamic
                                                            </Tooltip>
                                                        </Button>
                                                        <Button
                                                            onClick={makeFullBodyDynamic}
                                                            size='sm'
                                                            variant='outline'
                                                            borderColor='#332FED'
                                                            color='#332FED'
                                                        >
                                                            <Tooltip
                                                                label='Make all the body content dynamic'
                                                                aria-label='A tooltip'
                                                            >
                                                                Make All Dynamic
                                                            </Tooltip>
                                                        </Button>
                                                        <Popover closeOnBlur={false}>
                                                            <PopoverTrigger>
                                                                <Icon
                                                                    as={QuestionIcon}
                                                                    w={4}
                                                                    h={4}
                                                                    color='#332FED'
                                                                />
                                                            </PopoverTrigger>
                                                            <PopoverContent color='black'>
                                                                <PopoverArrow />
                                                                <PopoverHeader>
                                                                    What to do here?
                                                                </PopoverHeader>
                                                                <PopoverCloseButton />
                                                                <PopoverBody>
                                                                    <Box>
                                                                        <iframe
                                                                            src='https://www.loom.com/embed/93646bd34665411d9145991f171e6685?sid=62619623-30f4-43e8-ae63-e05854fa9f5f'
                                                                            allowFullScreen={true}
                                                                        />
                                                                        <Text
                                                                            fontSize='md'
                                                                            mt='20px'>
                                                                            Highlight the parts of the body that are not
                                                                            important in identifying this request, and
                                                                            mark them as dynamic. Eg. Personal Data, IDs,
                                                                            etc
                                                                        </Text>
                                                                    </Box>
                                                                </PopoverBody>
                                                            </PopoverContent>
                                                        </Popover>
                                                    </Flex>
                                                )
                                            }
                                            <Box
                                                backgroundColor='gray.50'
                                                borderRadius='5px'
                                                p={2}
                                                minHeight='20vh'
                                                maxHeight='55vh'
                                                overflowY='auto'
                                            >
                                                {
                                                    regexBody === '' ? (
                                                        <Text>
                                                            No Body
                                                        </Text>
                                                    ) : (
                                                        <>
                                                            {
                                                                selectedNetworkRequest?.contentType?.includes('json') ? (
                                                                    <CustomJsonViewerScreen3
                                                                        data={JSON.parse(regexBody)}
                                                                        onItemClick={handleJsonKeyClick}
                                                                        searchQuery=''
                                                                    />

                                                                ) : (
                                                                    <Code
                                                                        style={
                                                                            {
                                                                                whiteSpace: 'pre-wrap',
                                                                                width: '100%',
                                                                            }
                                                                        }
                                                                        onMouseUp={handleTextSelect}
                                                                    >
                                                                        {regexBody}
                                                                    </Code>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }

                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                        <OptionalFields />
                        <Box
                            w='full'
                            mt={5}>
                            <Flex
                                w='full'
                                justifyContent='flex-end'>
                                {/* <Flex gap={10}>
                                    <Button onClick={handleGoBack}>
                                        Back
                                    </Button>
                                </Flex> */}
                                <Button
                                    onClick={handleNext}
                                    bgColor='#332FED'
                                    color='white'
                                    isLoading={buttonLoading}
                                >
                                    Create Claim
                                </Button>
                            </Flex>
                        </Box>
                    </VStack>
                </CardBody>
            </Card>
        </>
    )
}

