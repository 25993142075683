import { SetStateAction, useState } from 'react'
import { Box, Button, Flex, Select, StackDivider, Text, Textarea, Tooltip, VStack } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateProviderDetails } from '../../../../redux/providerBuilder/providerDetails'
import { getSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances/selectors'
import { getSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest/selectors'
import { updateActiveStep } from '../../../../redux/providerBuilder/stepperDetails'
import { getActiveStep } from '../../../../redux/providerBuilder/stepperDetails/selectors'
import { extractDomain, generateChatGPTProofCardText } from '../../../../utils/provider-builder/proof-card-utils'

interface OptionalFieldsProps {
	setActiveStepInnerForm: (value: SetStateAction<number>) => void
}

export const OptionalFields = (props: OptionalFieldsProps) => {
	const dispatch = useAppDispatch()

	const activeStep = useAppSelector(getActiveStep)
	const userType = useAppSelector((state) => state.user.userType)
	const selectedNetworkRequest = useAppSelector(getSelectedNetworkRequest)
	const selectedInstances = useAppSelector(getSelectedInstances)

	const countryCodes = require('../../../../utils/data/country-codes.json')

	const [jsInjection, setJsInjection] = useState('')
	const [geoLocation, setGeoLocation] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)


	const handleNext = async () => {
		setButtonLoading(true)

		const proofCardText = await generateChatGPTProofCardText(selectedInstances)
		const domainName = extractDomain(selectedNetworkRequest.url)
		const proofCardTitle = domainName
		// const logoUrl = `https://www.google.com/s2/favicons?domain=${domainName}`

		dispatch(updateProviderDetails({
			customInjection: jsInjection,
			isActive: true,
			geoLocation: geoLocation,
			isApproved: !jsInjection || userType === 'ADMIN',
			proofCardText: proofCardText,
			proofCardTitle: proofCardTitle,
			// logoUrl: logoUrl
		}))

		dispatch(updateActiveStep(activeStep + 1))
	}

	return (
		<>
			<Box marginBottom='2.5rem'>
				<VStack
					divider={<StackDivider borderColor='gray.200' />}
					spacing={5}
					align='stretch'
					mt='10'
					ml='3'
				>
					<Box width='100%'>
						<Flex>
							<Text
								fontSize='md'
								minW='140px'
								mt='1.5'>
								JS Injection
							</Text>
							<Text width='80%'>
								<Tooltip
									label='Providers with Custom Injection are subjected to admin approval'
									aria-label='A tooltip'
								>
									<Textarea
										placeholder='JS Injection to run on the website'
										required={false}
										value={jsInjection}
										style={
											{
												color: 'black',
												borderRadius: '5px',
												border: '1px solid black',
											}
										}
										onChange={
											(e: {
												target: { value: SetStateAction<string> }
											}) => setJsInjection(e.target.value)
										}
									/>
								</Tooltip>
							</Text>
						</Flex>
					</Box>
					<Box width='100%'>
						<Flex>
							<Text
								fontSize='md'
								minW='140px'
								mt='1.5'>
								GeoLocation
							</Text>
							<Select
								width='20%'
								placeholder='Select option'
								value={geoLocation}
								onChange={
									(e) => {
										setGeoLocation(e.target.value)
									}
								}>
								{
									countryCodes.map((countryCode: { Code: string, Name: string }) => (
										<option
											key={countryCode.Code}
											value={countryCode.Code}>
											{countryCode.Name}
										</option>
									))
								}
							</Select>
						</Flex>
					</Box>
				</VStack>
				<Flex justifyContent='flex-end'>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={() => props.setActiveStepInnerForm(2)}
						width='10%'
						mr='30px'
						isDisabled={buttonLoading}
					>
						Back
					</Button>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={async () => await handleNext()}
						colorScheme='blue'
						width='10%'
						mr='30px'
						isLoading={buttonLoading}
					>
						{jsInjection.length || geoLocation.length ? 'Next' : 'Skip'}
					</Button>
				</Flex>
			</Box>
		</>
	)
}