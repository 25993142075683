import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Heading, Text, useToast, VStack } from '@chakra-ui/react'
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth'
import { app } from '../../../../configs/firebaseApp'
import { AmplitudeAnalyticsContext } from '../../../../contexts/useAnalytics'
import { useAppDispatch } from '../../../../redux/hooks'
import { setUserType } from '../../../../redux/user'
import { loginClient } from '../../../../redux/user/actions'
import { pxToRem } from '../../../../utils'
import { sendDevtoolLogs } from '../../../../utils/logs/send-logs'
import { Container } from '../container/container.component'

export const HeroSection = () => {
	const content = [
		'Amazon orders',
		'KYC on Binance',
		'KYC on Synapse',
		'Rides on Uber',
		'Address on Uber',
		'IRS legal name',
		'Aadhaar registered Address',
		'Miles run on Strava',
		'Songs listened on Spotify',
		'Health',
		'Fitness',
		'Finance',
	]
	const [category, setCategory] = useState('')
	const toast = useToast()
	const dispatch = useAppDispatch()
	const navigation = useNavigate()

	const { logEvent } = useContext(AmplitudeAnalyticsContext)


	const handleCategory = () => {
		const currentIndex = content.indexOf(category)
		const nextIndex = (currentIndex + 1) % content.length
		setCategory(content[nextIndex])
	}


	useEffect(() => {
		const timer = setInterval(() => {
			handleCategory()
		}, 1500)

		return () => clearInterval(timer)
	}, [category])

	const auth = getAuth(app)
	onAuthStateChanged(auth, (user) => {
		if(user) {
			navigation('/')
		}
	})

	async function handleGoogleLogin() {
		logEvent('SIGN_UP')
		const provider = new GoogleAuthProvider()
		try {
			const user = await signInWithPopup(auth, provider)

			if(user) {
				dispatch(loginClient())
					.then(async (res: any) => {
						if(res.meta.requestStatus === 'fulfilled') {
							sendDevtoolLogs('LOGIN')
							dispatch(setUserType(res.payload.type))
							toast({
								title: 'Welcome back',
								description: 'You have been logged in successfully',
								status: 'success',
								duration: 3000,
								isClosable: true,
								position: 'top-right',
							})
						} else {
							toast({
								title: 'Error',
								description: res.payload as string,
								status: 'error',
								duration: 3000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch((err: any) => {
						toast({
							title: 'Error',
							description: err.message,
							status: 'error',
							duration: 3000,
							isClosable: true,
							position: 'top-right',
						})
					})
			}
		} catch(e: any) {
			toast({
				title: e?.message.replace('Firebase: ', ''),
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}
	}


	return (
		<Box py={20}>
			<Container>
				<VStack
					spacing={6}
					mb={pxToRem(40)}
					align='center'>
					<Heading
						mt={pxToRem(140)}
						as='h1'
						fontSize={pxToRem(64)}
						fontWeight='bold'
						color='#ffffffda'
						textAlign='center'
						mb={4}
						css={
							{
								'@media screen and (max-width: 480px)': {
									fontSize: '30px',
									marginTop: '50px',
								},
							}
						}
					>
						Let Users Bring Their
						{' '}
						<br />
						<span
							className='animated-text'
							style={
								{
									color: '#3385FF',
									opacity: 0,
									transform: 'translateY(100%)',
									animation: 'slideInUp 0.5s forwards',
								}
							}
						>
							{category}
						</span>
						<br />
						{' '}
						Data To Your App
					</Heading>
					<Text
						textAlign='center'
						color='rgba(239,245,255,.693)'
						fontSize={pxToRem(20)}
						mb={8}
					>
						Reclaim SDK Brings Consumer Data From Any Website To Your Fingertips
					</Text>
					<Button
						onClick={handleGoogleLogin}
						colorScheme='blue'
						fontSize={pxToRem(20)}
						size='lg'
						color='#fff'
					>
						Start Building for Free
					</Button>
				</VStack>
			</Container>
		</Box>
	)
}
