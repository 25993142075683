import React from 'react'
import { MdPublic } from 'react-icons/md'
import { RiGitRepositoryPrivateLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { logEvent } from '@amplitude/analytics-browser'
import {
	Box,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react'
import { pxToRem } from '../../../utils'


export const AppTypeModal = ({ isOpen, onClose }: any) => {

	const navigate = useNavigate()


	return (
		<>


			<Modal
				isOpen={isOpen}
				onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						Application Type
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box
							p={pxToRem(20)}
							display='flex'
							justifyContent='space-around'>
							<VStack
								onClick={
									() => {
										logEvent('NEW_APPLICATION_BUTTON_CLICK', {
											screen: 'applications.screen.tsx',
										})
										navigate('/new-application')
									}
								}
								borderWidth='1px'
								borderRadius='lg'
								cursor='pointer'
								p={4}
								w='40%'
								alignItems='center'
							>
								<RiGitRepositoryPrivateLine size={50} />
								<Text align='center'>
									Private Data
								</Text>
							</VStack>

							<VStack
								cursor='pointer'
								borderWidth='1px'
								borderRadius='lg'
								p={4}
								w='40%'
								alignItems='center'
								onClick={
									() => {
										logEvent('NEW_APPLICATION_BUTTON_CLICK', {
											screen: 'applications.screen.tsx',
										})

										navigate('/new/public-data')

									}
								}
							>
								<MdPublic size={50} />
								<Text align='center'>
									Public Data (zkFetch App)
								</Text>
							</VStack>

						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)

}