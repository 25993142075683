import React, { useContext } from 'react'
import { FaGithub, FaLinkedin, FaTelegram, FaTwitter } from 'react-icons/fa'
import { Box, Grid, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { AmplitudeAnalyticsContext } from '../../../../contexts/useAnalytics'
import { pxToRem } from '../../../../utils'
import { Container } from '../container/container.component'

export const FooterSection = () => {
	const openLinkInNewTab = (url: string) => {
		window.open(url, '_blank')
	}

	const { logEvent } = useContext(AmplitudeAnalyticsContext)

	return (
		<Box color='rgba(239,245,255,.693)'>
			<Container>
				<Grid
					templateColumns={['1fr 1fr', '1fr 1fr 1fr 1fr']}
					gap={[4, 8]} // Adjust the gap as needed
					pt={['40px', '80px']} // Responsive padding-top
					pb={['40px', '80px']} // Responsive padding-bottom
					textAlign={['center', 'left']} // Center text on small screens, align left on larger screens
				>
					<VStack align={['center', 'start']}>
						{/* logo */}
						<HStack>
							<Image
								src='/assets/logo-light.png'
								alt='Logo'
								boxSize='40px'
								mr={4}
								height={pxToRem(30)}
								width={pxToRem(30)}
								mb={pxToRem(20)}
								cursor='pointer'
								//onClick={() => navigate('/')}
							/>
						</HStack>
						<HStack spacing={2}>
							<FaTelegram
								aria-label='telegram'
								fontSize='20px'
								onClick={() => openLinkInNewTab('https://t.me/protocolreclaim')}
								style={{ cursor: 'pointer', marginRight: '8px' }}
							/>
							<FaTwitter
								aria-label='Twitter'
								fontSize='20px'
								onClick={() => openLinkInNewTab('https://twitter.com/protocolreclaim')}
								style={{ cursor: 'pointer', marginRight: '8px' }}
							/>
							<FaGithub
								aria-label='GitHub'
								fontSize='20px'
								onClick={() => openLinkInNewTab('https://github.com/reclaimprotocol')}
								style={{ cursor: 'pointer', marginRight: '8px' }}
							/>
							<FaLinkedin
								aria-label='LinkedIn'
								fontSize='20px'
								onClick={() => openLinkInNewTab('https://linkedin.com/in/reclaimprotocol')}
								style={{ cursor: 'pointer' }}
							/>
						</HStack>
					</VStack>

					{/* Developer Docs */}
					<VStack
						align={['center', 'start']}
						spacing={2}>
						<Text
							fontWeight='bold'
							fontSize='14px'
							color='white'
							mb='8px'>
							Developers
						</Text>

						<Text
							fontSize='14px'
							color='rgba(239,245,255,.693)'
							onClick={() => openLinkInNewTab('https://docs.reclaimprotocol.org/')}
						>
							Documentation
						</Text>
						<Text
							fontSize='14px'
							color='rgba(239,245,255,.693)'
							onClick={() => openLinkInNewTab('https://blog.reclaimprotocol.org/')}
						>
							Blog
						</Text>
						<Text
							fontSize='14px'
							color='rgba(239,245,255,.693)'
							onClick={
								() => openLinkInNewTab(
									'https://drive.google.com/file/d/1wmfdtIGPaN9uJBI1DHqN903tP9c_aTG2/view'
								)
							}
						>
							White Paper
						</Text>
					</VStack>

					{/* Privacy */}
					<VStack
						align={['center', 'start']}
						spacing={2}>
						<Text
							fontWeight='bold'
							fontSize='14px'
							color='white'
							mb='8px'>
							Privacy
						</Text>
						<Text
							fontSize='14px'
							color='rgba(239,245,255,.693)'
							onClick={() => openLinkInNewTab('/resources/blog')}
						>
							Privacy Policy
						</Text>
						<Text
							fontSize='14px'
							color='rgba(239,245,255,.693)'
							onClick={() => openLinkInNewTab('/resources/blog')}
						>
							Terms & Conditions
						</Text>
					</VStack>

					{/* Community */}
					<VStack
						align={['center', 'start']}
						spacing={2}>
						<Text
							fontWeight='bold'
							fontSize='14px'
							color='white'
							mb='8px'
							onClick={
								() => {
									logEvent('JOIN_RECLAIM_COMMUNITY_CLICKED', {
										from: 'FOOTER'
									})
									openLinkInNewTab('https://t.me/+I7OZhOVVGKs0YTE1')
								}
							}
						>
							Community
						</Text>
						<Text
							fontSize='14px'
							color='rgba(239,245,255,.693)'
							onClick={
								() => {
									logEvent('TALK_TO_FOUNDER_CLICKED', { from: 'FOOTER' })
									openLinkInNewTab('https://t.me/sriharshakaramchati')
								}
							}
						>
							Talk to Founder
						</Text>
						<Text
							fontSize='14px'
							color='rgba(239,245,255,.693)'
							onClick={() => openLinkInNewTab('https://t.me/+N8pgnu39H_I5YmQ9')}
						>
							Telegram
						</Text>
					</VStack>
				</Grid>
			</Container>
		</Box>
	)
}
