import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import {
	RouterProvider
} from 'react-router-dom'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
//import { Global } from '@emotion/react'
import './index.css'
import { AmplitudeProvider } from './contexts/useAnalytics'
import store from './redux/store'
import { routerConfig } from './routes'
import { theme } from './themes'


const container = document.getElementById('root')
if(!container) {
	throw new Error('Failed to find the root element')
}

// eslint-disable-next-line no-console
console.log(`
_    _                              _     _      _             _ 
| |  | |                            | |   (_)    (_)           | |
| |  | | ___      __ _ _ __ ___     | |__  _ _ __ _ _ __   __ _| |
| |/\\| |/ _ \\    / _\` | '__/ _ \\    | '_ \\| | '__| | '_ \\ / _\` | |
\\  /\\  /  __/   | (_| | | |  __/    | | | | | |  | | | | | (_| |_|
\\/  \\/ \\___|    \\__,_|_|  \\___|    |_| |_|_|_|  |_|_| |_|\\__, (_)
														  __/ |  
														 |___/   

If you are here, we're hiring hackers like you - integration engineering. 
You will be looking at chrome devtools and building reclaim providers

Apply :
https://x.com/madhavanmalolan/status/1792949714813419792
`)

const root = ReactDOM.createRoot(container)

root.render(
	<ChakraProvider theme={theme}>
		{/* <Global
			styles={'@import url(\'https://fonts.googleapis.com/css2?family=Martian+Mono:wght@300;400&display=swap\');'}
		 /> */}
		<Provider store={store}>
			<React.StrictMode>
				<AmplitudeProvider>
					<RouterProvider router={routerConfig} />
					<ColorModeScript initialColorMode='dark' />
				</AmplitudeProvider>
			</React.StrictMode>
		</Provider>
	</ChakraProvider>
)
