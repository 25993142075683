import { useContext, useState } from 'react'
import { HamburgerIcon, LinkIcon } from '@chakra-ui/icons'
import {
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	HStack,
	IconButton,
	Image,
	Text,
} from '@chakra-ui/react'
import { AmplitudeAnalyticsContext } from '../../../../contexts/useAnalytics'
import { Container } from '../container/container.component'

export const Navbar = () => {
	const openLinkInNewTab = (url: string) => {
		window.open(url, '_blank')
	}

	const { logEvent } = useContext(AmplitudeAnalyticsContext)

	// state
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

	// Function to toggle the mobile menu drawer
	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen)
	}

	return (
		<Box
			bgColor=''
			p='4px 4px'>
			<Container>
				<Flex
					as='nav'
					align='center'
					justify='space-between'
					pt={4}
					pb={4}
					color='white'
					mx='auto'
				>
					{/* Logo */}
					<Image
						src='/assets/logo-light.png'
						alt='Logo'
						boxSize='40px'
						mr={4}
						cursor='pointer'
					/>

					{/* Mobile Menu Toggle Button */}
					<IconButton
						display={{ base: 'flex', md: 'none' }}
						icon={
							<HamburgerIcon
								fill='white'
								color='#fff' />
						}
						bgColor='transparent'
						onClick={toggleMobileMenu}
						aria-label='Open Mobile Menu'
					/>

					{/* Navigation Links (Desktop) */}
					<Flex
						align='center'
						cursor='pointer'
						display={{ base: 'none', md: 'flex' }}>
						<HStack as='div'>
							<Text
								onClick={
									() => {
										logEvent('DOCS_CLICKED')
										openLinkInNewTab('https://docs.reclaimprotocol.org/')
									}
								}
								ml={4}
								fontWeight={500}
								color='white'
							>
								{' '}
								Docs
							</Text>
							<LinkIcon />
						</HStack>

						<HStack>
							<Text
								color='white'
								onClick={
									() => {
										logEvent('GITHUB_CLICKED')
										openLinkInNewTab('https://github.com/reclaimprotocol')
									}
								}
								ml={4}
								fontWeight={500}
							>
								{' '}
								GitHub
							</Text>
							<LinkIcon />
						</HStack>
					</Flex>

					{/* Mobile Menu (Drawer) */}
					<Drawer
						isOpen={isMobileMenuOpen}
						onClose={toggleMobileMenu}
						placement='right'
					>
						<DrawerOverlay />
						<DrawerContent>
							<DrawerCloseButton />
							<DrawerHeader
								bg='#0C111D'
								color='#fff'>
								Menu
							</DrawerHeader>
							<DrawerBody bgColor='#0C111D'>
								<Text
									onClick={
										() => {
											logEvent('DOCS_CLICKED')
											openLinkInNewTab('https://docs.reclaimprotocol.org/')
										}
									}
									mb={4}
									color='white'
									fontWeight={500}
								>
									Docs
								</Text>
								<Text
									onClick={
										() => {
											logEvent('GITHUB_CLICKED')
											openLinkInNewTab('https://github.com/reclaimprotocol')
										}
									}
									mb={4}
									color='white'
									fontWeight={500}
								>
									GitHub
								</Text>
								<Text
									onClick={
										() => {
											logEvent('TALK_TO_FOUNDER_CLICKED', { from: 'MOBILE_MENU' })
											openLinkInNewTab('https://t.me/sriharshakaramchati')
										}
									}
									mb={4}
									color='white'
									fontWeight={500}
								>
									Talk to our Founder
								</Text>
								<Text
									onClick={
										() => {
											logEvent('JOIN_RECLAIM_COMMUNITY_CLICKED', {
												from: 'MOBILE_MENU'
											})
											openLinkInNewTab('https://t.me/+I7OZhOVVGKs0YTE1')
										}
									}
									mb={4}
									fontWeight={500}
									color='white'
								>
									Join Reclaim Community
								</Text>
							</DrawerBody>
						</DrawerContent>
					</Drawer>

					{/* Additional Navigation Links (Desktop) */}
					<Flex
						cursor='pointer'
						display={{ base: 'none', md: 'flex' }}>
						<HStack>
							<Text
								color='white'
								onClick={
									() => {
										logEvent('TALK_TO_FOUNDER_CLICKED', { from: 'NAVBAR' })
										openLinkInNewTab('https://t.me/sriharshakaramchati')
									}
								}
								fontWeight={500}
								mr={4}
							>
								{' '}
								Talk to our Founder
							</Text>
						</HStack>
						<HStack>
							<Text
								color='white'
								onClick={
									() => {
										logEvent('JOIN_RECLAIM_COMMUNITY_CLICKED', {
											from: 'NAVBAR'
										})
										openLinkInNewTab('https://t.me/+I7OZhOVVGKs0YTE1')
									}
								}
								fontWeight={500}
								mr={4}
							>
								{' '}
								Join Reclaim Community
							</Text>
						</HStack>
					</Flex>
				</Flex>
			</Container>
		</Box>
	)
}
