/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { FaCode, FaNode, FaReact, FaReacteurope } from 'react-icons/fa'
import { FiCopy } from 'react-icons/fi'
import { IoMdCheckmark } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Center, CodeProps, Heading, Icon, IconButton, Image, Link, Text, useClipboard, useColorModeValue, useToast } from '@chakra-ui/react'
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth'
import Highlight, { defaultProps } from 'prism-react-renderer'
import vsDark from 'prism-react-renderer/themes/vsDark'
import { app } from '../../../../configs/firebaseApp'
import { useAppDispatch } from '../../../../redux/hooks'
import { setUserType } from '../../../../redux/user'
import { loginClient } from '../../../../redux/user/actions'
import { pxToRem } from '../../../../utils'
//@ts-ignore
import FlutterIcon from '../../assets/flutter.svg'
//@ts-ignore
import SolanaIcon from '../../assets/solana.svg'
//@ts-ignore
import SolidityIcon from '../../assets/solidity.svg'
//import { CodeBlock } from '../../../../components'
import { Container } from '../container/container.component'
import { CodeBlock } from './code-block'

export const IntegrateScreen = () => {
	const techIcons = [
		{ name: 'React', icon: FaReact },
		{ name: 'React Native', icon: FaReacteurope },
		{ name: 'Flutter', icon: FlutterIcon },
		{ name: 'Node', icon: FaNode },
		{ name: 'Solidity', icon: SolidityIcon },
		{ name: 'Solana', icon: SolanaIcon },
	]
	const [selectedTech, setSelectedTech] = useState('React')


	const handleTechClick = (tech: any) => {
		setSelectedTech(tech)
	}


	const getCodeSnippet = () => {
		switch (selectedTech) {
		case 'React':
			return `
      import { Reclaim } from '@reclaimprotocol/js-sdk'
      
      
      async function generateVerificationRequest() {
        
        const providerId = 'PROVIDER_ID' 
        reclaimClient.setSignature(
          await reclaimClient.generateSignature(
            APP_SECRET //TODO : replace with your APP_SECRET
          )
        )
     
        const { requestUrl, statusUrl } =
        await reclaimClient.createVerificationRequest()
     
        setUrl(requestUrl)
     
        await reclaimClient.startSession({
          onSuccessCallback: proofs => {
            console.log('Verification success', proofs)
            // Your business logic here
          },
          onFailureCallback: error => {
            console.error('Verification failed', error)
            // Your business logic here to handle the error
          }
        })
        
      }
  
      
     
`

		case 'React Native':
			return `
      import { Reclaim } from '@reclaimprotocol/reactnative-sdk'
      import { SafeAreaView, Text, View, Pressable } from 'react-native'
 
      function App() {
      
        const APP_ID = 'YOUR_APPLICATION_ID_HERE'
        const reclaimClient = new Reclaim.ProofRequest(APP_ID)

        async function startVerificationFlow() {
        const providerId = 'provider_id' 
    
        const appDeepLink = 'YOUR_APP_DEEP_LINK_HERE' 
        reclaimClient.setAppCallbackUrl(appDeepLink)
        
        await reclaimClient.buildProofRequest(providerId)
 
        reclaimClient.setSignature(await reclaimClient.generateSignature(APP_SECRET))
 
        const { requestUrl, statusUrl } =
        await reclaimClient.createVerificationRequest(providerIds)
 
        await reclaimClient.startSession({
          onSuccessCallback: proof => {
           console.log('Verification success', proof)
           // Your business logic here
        },
        onFailureCallback: error => {
        console.error('Verification failed', error)
          // Your business logic here to handle the error
          }
        })
      }
 
      return (
        <SafeAreaView>
             <View>
           <Pressable onPress={startVerificationFlow}>
             <Text>Start Verification Flow</Text>
           </Pressable>
         </View>
       </SafeAreaView>
      )
}
      
      
      `

		case 'Flutter':
			return `
      
      import 'package:reclaim_sdk/flutter_reclaim.dart';
      import 'package:reclaim_sdk/types.dart';
 
      class ReclaimScreen extends StatefulWidget {
          const ReclaimScreen({super.key, required this.title});
          final String title;
 
          @override
        State<ReclaimScreen> createState() => _ReclaimScreenState();
 
      }
 
      class _ReclaimScreenState extends State<ReclaimScreen> {
      String data = "";
      ProofRequest proofRequest = ProofRequest(applicationId: 'YOUR_APPLICATION_ID_HERE');
 
      void startVerificationFlow() async {
        final providerId = 'YOUR_PROVIDER_ID_HERE';
 
        final appDeepLink = 'YOUR_APP_DEEP_LINK_HERE'; //TODO: replace with your app deep link
        proofRequest.setAppCallbackUrl(appDeepLink);
 
        proofRequest.addContext('YOUR_CONTEXT_ADDRESS', 'YOUR_CONTEXT_MESSAGE');
 
        await proofRequest.buildProofRequest(providerId);
 
        proofRequest.setSignature(
            proofRequest.generateSignature(
                'YOUR_APP_SECRET_HERE'
            )
        );
 
        final verificationRequest = await proofRequest.createVerificationRequest();
 
    }
 
    Widget build(BuildContext context) {
        return Scaffold(
            appBar: AppBar(
                backgroundColor: Theme.of(context).colorScheme.inversePrimary,
                title: Text(widget.title),
            ),
            floatingActionButton: FloatingActionButton(
                onPressed: startVerificationFlow,
                tooltip: 'Verify ',
                child: const Text('Verify'),
            ),
        );
    }
}
`

		case 'Node':
			return `app.get("/request-proofs", async(req, res) => {
    const request = reclaim.requestProofs({
        title: "My DeFi app", // Name of your application
        baseCallbackUrl: "http://<YOUR_BACKEND_SERVER_URL>/callback", 
        callbackId: "<UNIQUE_IDENTIFIER_GOES_HERE>", // optional
        contextMessage: "Airdrop for Reclaim Users!", //optional
        contextAddress: userAddress, //optional
        requestedProofs: [
            new reclaim.CustomProvider({
                provider: 'bybit-balance',
                payload: {}
            }),
        ],
    });
 
    const { callbackId } = request;
    const reclaimUrl = await request.getReclaimUrl();
    // Store the callback Id and Reclaim URL in your database
    // ...
    res.json({ reclaimUrl });
     // display this reclaimUrl as a QR code on laptop or as a link on mobile devices for users to initiate creating proofs
})`
		case 'Solidity':
			return `
      
// SPDX-License-Identifier: MIT
pragma solidity 0.8.20;
 
 
import "@reclaimprotocol/verifier-solidity-sdk/contracts/Reclaim.sol";
import "@reclaimprotocol/verifier-solidity-sdk/contracts/Addresses.sol";
 
 
contract Attestor {
   address public reclaimAddress;
   // add providersHashes for your permitted providers
   string[] public providersHashes;
 
   constructor(string[] memory _providersHashes){
      providersHashes = _providersHashes;
        // TODO: Replace with network you are deploying on
      reclaimAddress = Addresses.PLOYGON_MUMBAI_TESTNET; 
 
   }  
 
   function verifyProof(Reclaim.Proof memory proof) public view{
       Reclaim(reclaimAddress).verifyProof(proof, providersHashes);
       // TODO: your business logic upon success
       // verify proof.context is what you expect
   }
}
      
`
		case 'Solana':
			return `use anchor_lang::prelude::*;
use anchor_lang::prelude::*;

use reclaim::cpi::accounts::VerifyProof;
use reclaim::cpi::verify_proof;
use reclaim::instructions::VerifyProofArgs;
use reclaim::program::Reclaim;
use reclaim::state::ClaimData as ReclaimClaimData;
use reclaim::state::ClaimInfo as ReclaimClaimInfo;
use reclaim::state::SignedClaim as ReclaimSignedClaim;
use reclaim::state::{Epoch, EpochConfig};

declare_id!("your program id here");

#[derive(AnchorSerialize, AnchorDeserialize)]
pub struct VerifyArgs {
    pub claim_info: ClaimInfo,
    pub signed_claim: SignedClaim,
}

#[derive(AnchorSerialize, AnchorDeserialize, Clone)]
pub struct SignedClaim {
    pub claim_data: ClaimData,
    pub signatures: Vec<[u8; 65]>,
}

#[derive(AnchorSerialize, AnchorDeserialize, Clone)]
pub struct ClaimInfo {
    pub provider: String,
    pub parameters: String,
    pub context_address: Pubkey,
    pub context_message: String,
}

#[derive(AnchorSerialize, AnchorDeserialize, Clone)]
pub struct ClaimData {
    pub identifier: [u8; 32],
    pub owner: String,
    pub timestamp: u32,
    pub epoch_index: u32,
}

#[program]
pub mod base {
    use super::*;

    pub fn verify<'info>(
        ctx: Context<'_, '_, '_, 'info, Verify<'info>>,
        args: VerifyArgs,
    ) -> Result<()> {
        let VerifyArgs {
            claim_info,
            signed_claim,
        } = args;

        let signer_account_info = ctx.accounts.signer.to_account_info();
        let reclaim_program_info = ctx.accounts.reclaim_program.to_account_info();

        let epoch_config_account_info = ctx.accounts.epoch_config.to_account_info();
        let epoch_account_info = ctx.accounts.epoch.to_account_info();

        verify_proof(
            CpiContext::new(
                reclaim_program_info,
                VerifyProof {
                    epoch_config: epoch_config_account_info,
                    epoch: epoch_account_info,
                    signer: signer_account_info,
                },
            ),
            VerifyProofArgs {
                claim_info: ReclaimClaimInfo {
                    parameters: claim_info.parameters,
                    context_message: claim_info.context_message,
                    provider: claim_info.provider,
                    context_address: claim_info.context_address,
                },
                signed_claim: ReclaimSignedClaim {
                    claim_data: ReclaimClaimData {
                        epoch_index: signed_claim.claim_data.epoch_index,
                        timestamp: signed_claim.claim_data.timestamp,
                        identifier: signed_claim.claim_data.identifier,
                        owner: signed_claim.claim_data.owner,
                    },
                    signatures: signed_claim.signatures,
                },
            },
        )?;

        Ok(())
    }
}

#[derive(Accounts)]
pub struct Verify<'info> {
    #[account(mut)]
    pub signer: Signer<'info>,
    pub epoch_config: Account<'info, EpochConfig>,
    pub epoch: Account<'info, Epoch>,
    pub reclaim_program: Program<'info, Reclaim>,
    pub system_program: Program<'info, System>,
}	
`

		default:
			return '// Default code here'
		}
	}

	const toast = useToast()
	const navigation = useNavigate()
	const auth = getAuth(app)
	const dispatch = useAppDispatch()

	onAuthStateChanged(auth, (user) => {
		if (user) {
			navigation('/dashboard')
		}
	})

	async function handleGoogleLogin() {
		const provider = new GoogleAuthProvider()
		try {
			const user = await signInWithPopup(auth, provider)

			if (user) {
				dispatch(loginClient())
					.then((res: any) => {
						if (res.meta.requestStatus === 'fulfilled') {
							dispatch(setUserType(res.payload.type))
							toast({
								title: 'Welcome back',
								description: 'You have been logged in successfully',
								status: 'success',
								duration: 3000,
								isClosable: true,
								position: 'top-right',
							})
						} else {
							toast({
								title: 'Error',
								description: res.payload as string,
								status: 'error',
								duration: 3000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch((err: any) => {
						toast({
							title: 'Error',
							description: err.message,
							status: 'error',
							duration: 3000,
							isClosable: true,
							position: 'top-right',
						})
					})
			}
		} catch (e: any) {
			toast({
				title: e?.message.replace('Firebase: ', ''),
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}
	}

	return (
		<Box padding='96px 24px'>
			<Container>
				<Heading
					as='h1'
					fontWeight='bold'
					textAlign='center'
					color='white'
					fontSize='40px'
				>
					Integrate Reclaim Into Your App Now
				</Heading>

				<Box
					mt={pxToRem(30)}
					mb={4}
					display='flex'
					justifyContent='center'>
					{
						techIcons.map((techObj) => (
							<Box
								key={techObj.name}
								mx={4}
								fontSize='lg'
								color={selectedTech === techObj.name ? 'teal.500' : 'white'}
								cursor='pointer'
								onClick={() => handleTechClick(techObj.name)}
								display='flex'
								alignItems='center'
							>
								{
									['Flutter', 'Solidity', 'Solana'].includes(techObj.name) ? (
										<Image
											src={techObj.icon}
											alt={techObj.name}
											boxSize={8}
											mr={2}
										/>
									) : (
										<Icon
											as={techObj.icon}
											boxSize={8}
											mr={2} />
									)
								}

								<Text
									//color='white'
									color={selectedTech === techObj.name ? '#3385ff' : 'white'}
								>
									{techObj.name}
								</Text>
							</Box>
						))
					}
				</Box>

				<Box margin='0 auto'>
					<CodeBlock
						language='javascript'
						code={getCodeSnippet()}
						framework=''
					/>
				</Box>
			</Container>

			<Center mt={pxToRem(24)}>
				<Button
					onClick={handleGoogleLogin}
					colorScheme='blue'
					fontSize={pxToRem(20)}
					size='lg'
					color='#fff'
				>
					Start Building for Free
				</Button>
			</Center>
		</Box>
	)
}
