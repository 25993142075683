//TODO: WIP - make it reuable
import {
	Box,
	Heading,
	HStack,
	Skeleton,
	VStack,
} from '@chakra-ui/react'
import { pxToRem } from '../../../utils'

export const ProviderCardSkeleton = () => {
	return (
		<Box
			borderRadius='lg'
			border='1px solid transparent'
			bg='#1E1E20'
			p='16px'
			alignItems='center'
		>
			<HStack justifyContent='space-between'>
				<VStack
					alignItems='flex-start'
					justifyContent='flex-start'>
					<Skeleton size={pxToRem(30)} />
				</VStack>
			</HStack>

			<Heading
				as='h2'
				mt='16px'
				fontSize={pxToRem(14)}>
				<Skeleton height={pxToRem(14)} />
			</Heading>

			<Box>
				<Skeleton height={pxToRem(14)} />
			</Box>

			<HStack
				justifyContent='space-between'
				mt='20px'>
				{/* Additional content or actions can be added here */}
			</HStack>
		</Box>
	)
}
