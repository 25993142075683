import { SLACK_ALERT } from './constants'
import { getFirebaseIdToken } from './utils'

// Make post api call for backend api using fetch
export const sendSlackAlert = async(message: string, channelId: string) => {
	try {
		const bodyParams = JSON.stringify({ message: message, channelId: channelId })
		const accessToken = await getFirebaseIdToken()
		const response = await fetch(SLACK_ALERT, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'sec-fetch-mode': 'cors',
				'Content-Length': bodyParams.length.toString(),
				Authorization: `Bearer ${accessToken}`,
			},
			body: bodyParams,
		})
		const data = await response.json()
		return { status: data.status, message: data.message }
	} catch(err) {
		return { status: 'error', message: 'Some error occured while accessing backend API' }
	}
}