/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import { HiClipboard } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
	Avatar,
	Box,
	Button,
	Heading,
	HStack,
	IconButton,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	Tooltip,
	useColorMode,
	useDisclosure,
	useToast,
	VStack
} from '@chakra-ui/react'
import { pxToRem } from '../../../utils'
import { DeleteApplication } from './delete-application.component'
import { EditApplication } from './edit-application.component'


export const ApplicalicationList = ({ application, allHTTPproviders }: any) => {

	const { isOpen, onOpen, onClose } = useDisclosure()
	const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure()

	const toast = useToast()
	const { colorMode } = useColorMode()
	const handleCopyKey = (appId: string) => {
		//copy to clipboard
		navigator.clipboard.writeText(appId)
		toast({
			title: 'Secret Key Copied',
			description: 'The secret key has been copied to the clipboard.',
			status: 'success',
			duration: 3000,
			isClosable: true,
		})
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [copiedText, setCopiedText] = useState('')
	//@ts-ignore
	const [hoveredId, setHoveredId] = useState('')
	const handleCopy = (id: string) => {
		navigator.clipboard.writeText(id)
		setCopiedText(id)
		toast({
			title: 'Provider ID Copied',
			description: 'The ID has been copied to the clipboard.',
			status: 'success',
			duration: 3000,
			isClosable: true,
		})
	}

	  const listRemainingProviders = () => {
		let remainingProviders = ''
		application?.httpProvider?.map((provider: any, index: any) => {
		  if(index > 9) {
				remainingProviders += `${provider.name} : ${provider.httpProviderId} , `
		  }
		})
		  return remainingProviders
	  }


	const navigate = useNavigate()
	return (
		<>
			<Box
				_hover={
					{
						borderColor: 'blue.500',
						// backgroundColor: '#feede6',
						// backgroundImage: 'linear-gradient(12deg, #fff 50%, hsla(0, 0%, 100%, 0))',
					}
				}
				bg={colorMode === 'dark' ? '#131417' : '#fff'}
				borderRadius='lg'
				borderColor='#e5e5ea'
				border={`1px solid ${colorMode === 'dark' ? 'transparent' : '#e5e5ea'}`}
			>
				<Box p={pxToRem(14)}>
					<HStack justifyContent='space-between'>
						<HStack >
							{
								application?.httpProvider?.map((provider: any, index: any) => {
									if(index < 10) {
										return (
											<Tooltip
												label={`Click to copy ID of - ${provider?.name}`}
												key={provider?.httpProviderId}
											>
												<Avatar
													style={
														{
															border: '4px solid #e5e5ea',

															marginLeft: index > 0 ? pxToRem(-20) : pxToRem(0),
														}
													}
													name={provider?.name || ''}
													//name={provider?.logoUrl === '' ? '' : provider?.logoUrl}
													cursor='pointer'
													height={pxToRem(40)}
													width={pxToRem(40)}
													onMouseEnter={() => setHoveredId(provider.httpProviderId)} // Set hoveredId on mouse enter
													onMouseLeave={() => setHoveredId('')} // Clear hoveredId on mouse leave
													onClick={() => handleCopy(provider.httpProviderId)} // Copy ID on click
													src={provider?.logoUrl}
												/>
											</Tooltip>

										)
									} else {
										return null
									}

								})
							}
							{
								application?.httpProvider && application?.httpProvider?.length >= 10 && (
									<Tooltip
										label={listRemainingProviders()}
									>
										<Text>
											...
											{' '}
											{application?.httpProvider?.length - 10}
											{' '}
											more
										</Text>
									</Tooltip>
								)
							}
						</HStack>

						<Menu>
							<MenuButton>
								<IconButton
									ml='2'
									size='sm'
									icon={<FaEllipsisH />}
									onClick={onOpen}
									aria-label='Copy Secret Key'
								/>
							</MenuButton>
							<MenuList>
								<MenuItem
									as='a'
									onClick={onOpen}>
									<EditIcon mr={pxToRem(8)} />
									Edit
								</MenuItem>
								<MenuItem
									as='a'
									onClick={deleteOnOpen}>
									<DeleteIcon mr={pxToRem(8)} />
									Delete
								</MenuItem>
							</MenuList>
						</Menu>
					</HStack>
					<Heading
						as='h2'
						fontWeight={600}
						lineHeight='21px'
						size='sm'
						mt={pxToRem(10)}
						fontSize='14px'
					>
						{application?.name}
					</Heading>
					<Text
						mt='4px'
						fontWeight={400}
						fontSize='14px'>
						{
							application?.description
								? application?.description
								: 'No Description'
						}
					</Text>
				</Box>

				<Box
					borderTop='1px'
					borderColor='#e5e5ea'
					pt={pxToRem(10)}
					pl={pxToRem(14)}
					pr={pxToRem(14)}
					pb={pxToRem(14)}
				>
					<HStack
						justifyContent='space-between'
						alignItems='flex-end'>
						<HStack alignItems='flex-end'>
							<VStack
								alignItems='flex-start'
								gap={pxToRem(4)}>
								<Text
									fontWeight={600}
									fontSize='14px'>
									App ID
								</Text>
								<Input
									type='text'
									value={application?.publicKey}
									readOnly
									size='sm'
									fontSize={pxToRem(12)}
									borderRadius='lg'
									minW={pxToRem(300)}
									style={
										{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}
									}
								/>
							</VStack>

							<IconButton
								ml='0'
								size='sm'
								// w={pxToRem(24)}
								// h={pxToRem(24)}
								onClick={() => handleCopyKey(application?.publicKey)}
								icon={<HiClipboard />}
								aria-label='Copy Secret Key'
							/>
						</HStack>
						<Button
							colorScheme='blue'
							size='sm'
							fontSize={pxToRem(12)}
							variant='outline'
							onClick={
								() => {
									navigate(`/code-preview/${application?.publicKey}`, {
										state: { application },
									})
								}
							}
						>
							View Code
						</Button>
					</HStack>
				</Box>
				<EditApplication
					isOpen={isOpen}
					application={application}
					onClose={onClose}
					allHTTPproviders={allHTTPproviders}
				/>
				<DeleteApplication
					isOpen={deleteIsOpen}
					application={application}
					onClose={deleteOnClose}
				/>
			</Box>
		</>
	)
}
