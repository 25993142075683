import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface MobileClient {
	appConnected: boolean
	currentUrlInput: string
}

const initialState: MobileClient = {
	appConnected: false,
	currentUrlInput: '',
}

const mobileClientSlice = createSlice({
	name: 'mobileClient',
	initialState,
	reducers: {
		updateAppConnected(state, action: PayloadAction<boolean>) {
			state.appConnected = action.payload
		},
		setCurrentUrlInput(state, action: PayloadAction<string>) {
			state.currentUrlInput = action.payload
		},
		resetMobileClient() {
			return { ...initialState }
		},
	},
})

export const { updateAppConnected, setCurrentUrlInput, resetMobileClient } = mobileClientSlice.actions

export default mobileClientSlice.reducer
