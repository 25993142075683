import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface NetworkStore {
	networkRequests: []
	pagesVisted: string[]
}

const initialState: NetworkStore = {
	networkRequests: [],
	pagesVisted: [],
}

const networkStoreSlice = createSlice({
	name: 'networkRequestStore',
	initialState,
	reducers: {
		updatePagesVisted(state, action: PayloadAction<string>) {
			state.pagesVisted = [...state.pagesVisted, action.payload]
		},
		updateNetworkStore(state, action: PayloadAction<any>) {
			// @ts-ignore
			state.networkRequests = [...state.networkRequests, action.payload]
		},
		resetNetworkStore() {
			return { ...initialState }
		}
	},
})

export const { updatePagesVisted, updateNetworkStore, resetNetworkStore } = networkStoreSlice.actions

export default networkStoreSlice.reducer
