/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { Box, Button, Center, CircularProgress, Flex, Link, Text, useToast, VStack } from '@chakra-ui/react'
import { Reclaim } from '@reclaimprotocol/js-sdk'
import { makeProviderActive } from '../../../../utils/provider-builder/register-provider-utils'
import { getFirebaseIdToken } from '../../../../utils/utils'

interface VerifyProviderProps {
	httpProviderId: string
	setProviderVerified: (value: boolean) => void
}

export const VerifyProvider = (props: VerifyProviderProps) => {
	const toast = useToast()


	const reclaimClient = new Reclaim.ProofRequest(process.env.REACT_APP_DEMO_APP_ID || '')
	const { httpProviderId, setProviderVerified } = props
	const providerId = httpProviderId

	const [url, setUrl] = useState('')
	const [isVerified, setIsVerified] = useState(false)

	useEffect(() => {
		if (!url) {
			generateVerificationRequest()
		}
	}, [url])


	async function updateProviderOnSuccessfulProof() {
		try {
			const accessToken = (await getFirebaseIdToken()) ?? ''
			const message = await makeProviderActive(accessToken, providerId)
			if (!message.isSuccess) {
				toast({
					title: 'Error while updating provider',
					description: message.message,
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				return
			} else {
				toast({
					title: 'Provider Verified Successfully',
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				setProviderVerified(true)
				return
			}
		} catch (e) {
			console.error(e)
			toast({
				title: 'Error while updating provider',
				description: 'Unknown Error while updating provider',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}
	}

	async function generateVerificationRequest() {

		await reclaimClient.buildProofRequest(providerId)
		reclaimClient.setSignature(
			await reclaimClient.generateSignature(process.env.REACT_APP_DEMO_APP_SECRET || '')
		)
		const { requestUrl, statusUrl } =
			await reclaimClient.createVerificationRequest()

		setUrl(requestUrl)

		await reclaimClient.startSession({
			onSuccessCallback: async proofs => {
				if (!isVerified) {
					setIsVerified(true)
					await updateProviderOnSuccessfulProof()
				}
			},
			onFailureCallback: error => {
				console.error('Verification failed', error)

			}
		})
	}

	return (
		<Box
			minH='300px'
			h='100%'
			gap={4}>
			<Center>
				{
					url ? (
						<>
							<VStack>
								<QRCode
									size={230}
									value={`${url}`}
								/>

								<Text mt='20px'>
									Or Visit
								</Text>

								<Link
									href={url}
									isExternal
									color='blue.500'
									_hover={{ textDecoration: 'underline' }}>
									{url}
								</Link>

							</VStack>
						</>
					) : (
						<Flex
							width='230px'
							height='230px'
							alignItems='center'
							justifyContent='center'>
							<CircularProgress
								isIndeterminate
								size='50px' />
						</Flex>
					)
				}

			</Center>
			<Center>

				<Button
					colorScheme='blue'
					mt='30px'
					onClick={generateVerificationRequest}>
					Create New Verification Link
				</Button>
			</Center>

		</Box >
	)

}