import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface JsonViewerDetails {
  expandedPaths: { [key: string]: boolean }
}

const initialState: JsonViewerDetails = {
	expandedPaths: {},
}

const jsonViewerSlice = createSlice({
	name: 'jsonViewer',
	initialState,
	reducers: {
		updateExpandedPaths(
			state,
			action: PayloadAction<{ [key: string]: boolean }>
		) {
			state.expandedPaths = { ...action.payload }
		},
		toggleExpandedPath(state, action: PayloadAction<string>) {
			state.expandedPaths[action.payload] =
        !state.expandedPaths?.[action.payload]
		},
	},
})

export const { updateExpandedPaths, toggleExpandedPath } =
  jsonViewerSlice.actions

export default jsonViewerSlice.reducer
