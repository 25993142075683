import { ethers } from 'ethers'


export const generateEncryptionKeys = async() => {
	// Create a new random wallet
	const wallet = ethers.Wallet.createRandom()

	// Get the address and private key
	const address = wallet.address
	const privateKey = wallet.privateKey
	return { address, privateKey }
}

