/* eslint-disable react/jsx-indent */
import { useState } from 'react'
import { Alert, AlertIcon, Box, Button, Code, Flex, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Textarea, useToast, VStack } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { getAllActiveHttpsProviders } from '../../../redux/httpsProviders/actions'
import { getUserType } from '../../../redux/user/selectors'
import { pxToRem, VERIFY_PROVIDER } from '../../../utils'
import { getApiUrl, getFirebaseIdToken } from '../../../utils/utils'

export const ProviderDetails = ({
	onClose,
	isOpen,
	providerDetail,
}: any) => {
	const [isLoading, setIsLoading] = useState(false)

	const toast = useToast()
	const dispatch = useAppDispatch()
	const userType = useAppSelector(getUserType)


	const verifyProvider = async () => {
		try {
			const token = await getFirebaseIdToken()
			setIsLoading(true)
			const res = await fetch(VERIFY_PROVIDER(providerDetail.httpProviderId), {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			})
			//dispatch(getAllActiveHttpsProviders())

			if (res.status === 200) {
				setIsLoading(false)
				dispatch(getAllActiveHttpsProviders())
				toast({
					title: 'Provider Verified',
					description: 'Provider has been verified successfully',
					status: 'success',
					duration: 3000,
					isClosable: true,
				})

				onClose()
			}
		} catch (error) {
			toast({
				title: 'Error',
				description: 'Some error occured while verifying provider',
				status: 'error',
				duration: 3000,
				isClosable: true,
			})
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size='xl'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					Provider Details
				</ModalHeader>
				<ModalCloseButton />

				<ModalBody>
					<Box
						mt={pxToRem(8)}
						p='4'
						borderWidth='1px'
						borderRadius='lg'
						borderColor='borderColor'
					>
						{/* Logo */}
						<Flex
							align='center'
							mb='4'
							justifyContent='space-between'>
							<Text
								fontWeight='bold'
								fontSize='lg'
								mr='2'>
								Logo:
							</Text>
							<Image
								src={
									providerDetail.logoUrl
										? providerDetail.logoUrl
										: `${getApiUrl}/assets/logo-light.png`
								}
								width='30px'
							/>
						</Flex>

						{/* Key-Value Pairs */}
						{
							Object.entries({
								Name: providerDetail?.name,
								'Provider Id': providerDetail.httpProviderId,
								Description: providerDetail.description
									? `Proves ${providerDetail.description}`
									: 'Lorem ipsum...',
								'Hash Value': providerDetail?.providerHash
									? providerDetail.providerHash
									: 'No Hash found',
								Method: providerDetail.method,
								'Login URL': providerDetail.loginUrl,
								Verified: providerDetail?.isVerified
									? 'Verified'
									: 'Not Verified',
								// 'Created By': `${providerDetail?.createdBy?.name} (${providerDetail?.createdBy?.email})`,
								'Body Sniff': providerDetail?.bodySniff?.enabled
									? 'Enabled'
									: 'Not Enabled',
								'Response Selection': providerDetail?.responseSelections?.map(
									(item: any, i: number) => (
										<div key={i}>
											Json Path:
											<Textarea
												// type='text'
												value={item?.jsonPath ? item?.jsonPath : 'No Json Path found'}
												disabled
											/>
											XPath:
											<Input
												type='text'
												value={item?.xPath ? item?.xPath : 'No XPath found'}
												disabled
											/>
										</div>
									)
								),

								'User Agent': (
									<VStack gap={pxToRem(10)}>
										<Box>
											Android
											<Textarea value={providerDetail?.userAgent?.android ? providerDetail?.userAgent?.android : 'No User Agent Found'} />
										</Box>
										<Box>
											IOS
											<Textarea value={providerDetail?.userAgent?.ios ? providerDetail?.userAgent?.ios : 'No User Agent Found'} />
										</Box>

									</VStack>
								),
								Regex: providerDetail?.bodySniff?.regex
									? providerDetail.bodySniff.regex
									: 'No RegeX found',
								'Custom JS Injections': providerDetail.customInjection
									? providerDetail.customInjection
									: 'No Custom JS Injections found',
							}).map(([key, value], index) => (
								<Flex
									key={index}
									mt='4'
									justifyContent='space-between'
									alignItems='flex-start'
								>
									<Text
										fontWeight='bold'
										fontSize='lg'
										mr='30px'
										flexShrink={0}
										minW='auto'
									>
										{key}
										:
									</Text>
									{
										key === 'Custom JS Injections' ||
											key === 'Method' ||
											key === 'Regex' ? (
												<Code
													colorScheme='red'
													wordBreak='break-word'>
													{value}
												</Code>
											) : (
												<Text wordBreak='break-word'>
													{value}
												</Text>
											)
									}
								</Flex>
							))
						}
					</Box>

					{
						!providerDetail?.isVerified && (
							<Alert
								borderRadius='lg'
								status='warning'
								mt={pxToRem(20)}>
								<AlertIcon />
								Providers created by Reclaim Community. Expect Low Stability.
							</Alert>
						)
					}

					{
						userType === 'ADMIN' && (
							<Box my={pxToRem(20)}>
								{
									!providerDetail?.isVerified && (
										<Box>
											<Button
												isLoading={isLoading}
												width='100%'
												colorScheme='blue'
												onClick={verifyProvider}
											>
												Verify Provider
											</Button>
										</Box>
									)
								}
							</Box>
						)
					}
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
