/* eslint-disable */

import { useContext, useEffect, useState } from "react"
import { MdKeyboardBackspace } from "react-icons/md"
import { useLocation, useNavigate } from "react-router-dom"

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  Skeleton,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Textarea,
  useColorMode,
  useSteps,
  useToast,
  VStack,
} from "@chakra-ui/react"
import axios from "axios"
import { getAuth } from "firebase/auth"
import { EmptyState, ProviderCard } from "../../components"
import { app } from "../../configs/firebaseApp"
import { registerApplication } from "../../redux/applications/actions"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { getAllActiveHttpsProviders } from "../../redux/httpsProviders/actions"
import { HttpsProvider } from "../../redux/httpsProviders/types"
import { GET_ALL_ACTIVE_PROVIDERS, PRIVATE_PROVIDERS_BY_USER, pxToRem } from "../../utils"
import { chatGPT } from "../../utils/chat-gpt"
import { RepeatIcon } from "@chakra-ui/icons"
import { AmplitudeAnalyticsContext } from "../../contexts/useAnalytics"
import { generateEncryptionKeys } from "../../utils/generate-wallet-address"
import {
  setnavigationHistory,
  setSelectedProvidersFromCreateApp,
} from "../../redux/httpsProviders"
import { getSelectedProvidersFromCreateApp } from "../../redux/httpsProviders/selectors"
import { getFirebaseIdToken, getSignedUploadUrlFromS3ForApplicationImage } from "../../utils/utils"
import { debounce, logSearchQuery } from "../../utils/logs/logger"
import { sendSearchLogs, useDebounce } from "../../utils/logs/send-logs"

export const NewApplicationScreen = () => {
  const [applicationName, setApplicationName] = useState<string>("")
  const [applicationDescription, setApplicationDescription] = useState("")
  const [projectName, setProjectName] = useState<string>("")
  const [applicationImage, setApplicationImage] = useState<File | null>(null);
  //const [attemptedRegistration, setAttemptedRegistration] = useState(false)
  const [errors, setErrors] = useState({
    applicationName: "",
    domainNameInput: "",
    providersInput: "",
    validDomainName: "",
  })
  const [domainNameInput, setDomainNameInput] = useState<string>("")
  const [loading, setLoading] = useState(false)
  const [callbackUrl, setCallbackUrl] = useState<string>("")
  const [domainNames, setDomainNames] = useState<string[]>([])

  const [unsafeProviderWarning, setUnsafeProviderWarning] = useState(false)
  const [toastShown, setToastShown] = useState(false)

  const selectedProviderFromRedux = useAppSelector(
    getSelectedProvidersFromCreateApp
  )
  //console.log("selectedProviderFromRedux", selectedProviderFromRedux)
  const [selectedProviders, setSelectedProviders] = useState<HttpsProvider[]>(
    selectedProviderFromRedux.length > 0 ? selectedProviderFromRedux : []
  )
  const navigate = useNavigate()
  const auth = getAuth(app)
  const toast = useToast()
  const location = useLocation()

  const [providerOptions, setProviderOptions] = useState<any>([])
  const [searchQuery, setSearchQuery] = useState("")
  const debouncedSearchTerm = useDebounce(searchQuery, 2500);
  const [searchFilterResponse, setSearchFilterResponse] =
    useState(providerOptions)
  const [isProviderSelected, setIsProviderSelected] = useState(false)
  const dispatch = useAppDispatch()

  const { logEvent } = useContext(AmplitudeAnalyticsContext)

  const resetState = () => {
    setSelectedProviders([])
    setApplicationName("")
    setDomainNames([])
    setCallbackUrl("")
  }

  const getAllProviders = async () => {
    try {
      setLoading(true)
      const accessToken = await getFirebaseIdToken()
      const { data } = await axios.get(GET_ALL_ACTIVE_PROVIDERS, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
      const res = await fetch(PRIVATE_PROVIDERS_BY_USER, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      })
      const { providers } = await res.json()
      if (data) {
        const combined = [...data.providers, ...(providers ?? [])]
        setProviderOptions(combined)
      }
      setLoading(false)
    } catch (error) {
      console.log(
        "something went erong, couldnt fetch the provider list,",
        error
      )
    }
  }

  // Debounced version of logSearchQuery
  const debouncedLogSearchQuery = debounce(logSearchQuery, 1000); // Adjust 500ms to your needs
  useEffect(() => {
    getAllProviders()
  }, [])

  useEffect(() => {
    if (searchQuery.length > 0 && !selectedProviders.length) {
      // debouncedLogSearchQuery(searchQuery);
      sendSearchLogs(searchQuery, false, '', '')
    }
  }, [debouncedSearchTerm]);


  // Search filter
  const searchFilter = () => {
    const lowerCaseSearchQuery = searchQuery.toLowerCase()
    const filteredRes = providerOptions?.filter((item: any) =>
      item.name.toLowerCase().includes(lowerCaseSearchQuery)
    )
    setSearchFilterResponse(filteredRes)
  }

  useEffect(() => {
    searchFilter()
  }, [searchQuery, providerOptions])

  const handleProviderSelect = (selectedProvider: HttpsProvider) => {
    sendSearchLogs(searchQuery, true, selectedProvider.httpProviderId, selectedProvider.name)
    setIsProviderSelected(!isProviderSelected)

    //check if selected provider is already in the list
    const isProviderAlreadySelected = selectedProviders.some(
      (provider) => provider.id === selectedProvider.id
    )

    let updatedSelectedProviders

    if (isProviderAlreadySelected) {
      // if provider is already selected remove it from the array
      updatedSelectedProviders = selectedProviders.filter(
        (provider) => provider.id !== selectedProvider.id
      )
    } else {
      // if provider is not selected add it to the array
      updatedSelectedProviders = [...selectedProviders, selectedProvider]
    }

    const useSafeProviderWarning = updatedSelectedProviders?.some(
      (p: HttpsProvider) => !p?.isVerified
    )

    setSelectedProviders(updatedSelectedProviders)
    dispatch(setSelectedProvidersFromCreateApp(updatedSelectedProviders))
    dispatch(setnavigationHistory(location.pathname))
  }



  useEffect(() => {
    const useUnsafeProviderWarning = selectedProviders?.some(
      (p: HttpsProvider) =>
        !p?.isVerified && p?.creatorUid !== auth.currentUser?.uid
    )

    if (useUnsafeProviderWarning) {
      setUnsafeProviderWarning(true)
    } else {
      setUnsafeProviderWarning(false)
    }
  }, [selectedProviders])


  const removeSelectedProviders = (id: string) => {
    const updatedSelectedProviders = selectedProviders.filter(
      (provider: any) => provider.id !== id
    )
    setSelectedProviders(updatedSelectedProviders)
    dispatch(setSelectedProvidersFromCreateApp(updatedSelectedProviders))
    dispatch(setnavigationHistory(location.pathname))
  }

  const validateForm = () => {
    const newErrors = {
      applicationName: "",
      domainNameInput: "",
      providersInput: "",
      validDomainName: "",
    }

    let isValid = true

    // Validate applicationName
    if (!applicationName) {
      newErrors.applicationName = "Application name is required"
      isValid = false
    }

    // validate providersInput
    if (selectedProviders.length === 0) {
      newErrors.providersInput = "At least one provider is required"
      isValid = false
    }

    // Update the errors state
    setErrors(newErrors)
    //TODO: fix this hack
    return true
  }

  const handleImageChange = (e: any) => {
    const file = e.target.files[0]
    if (file) {
      // const reader = new FileReader();
      // reader.onloadend = () => {
      //   setApplicationImage(reader.result as string);
      // };
      // reader.readAsDataURL(file);
      setApplicationImage(file);
    }
  }

  const handleApplicationRegistration = async () => {
    const isValid = validateForm()
    setLoading(true)
    if (isValid) {
      const httpProviderIds = selectedProviders.map(
        (provider: any) => provider.id
      )

      const { address, privateKey } = await generateEncryptionKeys()

      let uploadedImageUrl = ''

      if (applicationImage) {

        const { error, url } = await getSignedUploadUrlFromS3ForApplicationImage(address, applicationImage)

        if (error) {
          return toast({
            title: "Application creation failed.",
            description: "Unable to upload image",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          })
        }

        uploadedImageUrl = url
      }

      dispatch(
        registerApplication({
          name: applicationName ? applicationName : aiGeneratedName, //TODO: Add description
          whitelistedDomains: domainNames.length > 0 ? domainNames : [],
          customSubProviders: [],
          httpProviders: httpProviderIds,
          callbackUrl: callbackUrl,
          description: applicationDescription
            ? applicationDescription
            : aiGeneratedDescription,
          publicKey: address,
          projectName: projectName,
          image: uploadedImageUrl,
        })
      ).then((res: any) => {
        if (res.payload.isSuccess) {
          setLoading(false)
          logEvent("APPLICATION_CREATED", {
            applicationName: applicationName,
            appId: res?.payload?.appId,
          })
          setApplicationName("")
          setSelectedProviders([])
          dispatch(setSelectedProvidersFromCreateApp([]))
          dispatch(getAllActiveHttpsProviders())
          resetState()
          toast({
            title: "Application created.",
            description: "",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          })
          navigate(`/code-preview/${res?.payload?.app?.appId}`, {
            state: { app: res?.payload?.app, privateKey },
          })
          //  setActiveStep(activeStep + 1)
        } else {
          toast({
            title: "Application creation failed.",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          })
          resetState()
        }
      })
    }
  }

  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: 3, //No of steps in stepper
  })
  const { colorMode } = useColorMode()

  const [aiGeneratedName, setAiGeneratedName] = useState<string>("")
  const [aiGeneratedDescription, setAiGeneratedDescription] =
    useState<string>("")

  const [isLoadingName, setIsLoadingName] = useState(false)
  const [isLoadingDescription, setIsLoadingDescription] = useState(false)

  const fillNameUsingGPT = async () => {
    setIsLoadingName(true)
    const appNameContext = "Application Name"
    const appNamePrompt = `I am building a tool. These are the selected providers ${selectedProviders.map(
      (p) => p.name
    )}. Please suggest a concise and compelling name based on selected providers for the application.`

    const appNameResponse = await chatGPT(appNameContext, appNamePrompt)
    setAiGeneratedName((appNameResponse?.message || "").replace(/"/g, ""))
    setIsLoadingName(false)
  }

  const fillDescriptionUsingGPT = async () => {
    setIsLoadingDescription(true)
    const appDescriptionContext = "Application Description"
    const appDescriptionPrompt = `I am building an internal tool, and I want to provide users with information about selected providers like ${selectedProviders.map(
      (p) => p.name
    )}. Please suggest a concise and compelling description for the application.`

    const appDescriptionResponse = await chatGPT(
      appDescriptionContext,
      appDescriptionPrompt
    )
    setAiGeneratedDescription(
      (appDescriptionResponse?.message || "").replace(/"/g, "")
    )
    setIsLoadingDescription(false)
  }

  const fillInfoUsingGPT = async () => {
    await fillNameUsingGPT()
    await fillDescriptionUsingGPT()
  }

  useEffect(() => {
    if (activeStep === 2) {
      fillInfoUsingGPT()
    }
  }, [selectedProviders, activeStep])

  useEffect(() => {
    logEvent("NEW_APPLICATION_SCREEN_VISITED")
  }, [])
  return (
    <>
      <Box
        style={{
          background:
            "linear-gradient(145.96deg, rgb(65, 10, 182) 5.07%, rgb(60, 132, 246) 100%)",
        }}
        mt={pxToRem(50)}
        borderRadius='lg'
        bgColor='white'
        p={pxToRem(50)}
      >
        <Center>
          <Heading size='lg' fontSize={pxToRem(26)} color='white'>
            Create Your Own Data Provider
          </Heading>
        </Center>
        <Center>
          <Button
            colorScheme='blue'
            mt={pxToRem(20)}
            onClick={() => {
              logEvent("CREATE_NEW_PROVIDER_BUTTON_CLICK", {
                screen: "newApplicationScreen",
              })
              navigate("/devtoolv2")
            }}
            color={colorMode === "dark" ? "white" : "white"}
          >
            Create New Data Provider
          </Button>
        </Center>
      </Box>

      {activeStep === 1 && (
        <>
          <Box p='6px'>
            <Box mt='16'>
              <Heading as='h1' fontWeight={700} size='lg' fontSize='24px'>
                Integrate with your App
              </Heading>
              <Text mt='8px'>
                Select the list of providers and integrate them in 2 steps.
              </Text>
            </Box>
            <Box mt='19px'>
              <HStack>
                <Input
                  borderColor='borderColor'
                  bg={colorMode === "dark" ? "transparent" : "#fff"}
                  placeholder='Search for Data Providers'
                  value={searchQuery}
                  onChange={(e) =>
                    setSearchQuery(e.target.value.toLocaleLowerCase())
                  }
                />{" "}

                <Button
                  colorScheme='blue'
                  color={colorMode === "dark" ? "white" : "white"}
                  isDisabled={selectedProviders.length <= 0}
                  onClick={() => {
                    logEvent("PROVIDERS_SELECTED")
                    setActiveStep(activeStep + 1)
                  }}
                >
                  Next
                </Button>
              </HStack>
            </Box>

            <Box mt='20px'>
              <Box>
                <Heading>
                  All Providers -{" "}
                  {searchFilterResponse?.length
                    ? searchFilterResponse?.length
                    : 0}
                </Heading>
                <HStack mt={pxToRem(8)} gap={2} wrap='wrap'>
                  {selectedProviders.map((p: any, i) => (
                    <Tag size='lg' variant='outline' colorScheme='blue' key={i}>
                      <TagLabel>{p?.name}</TagLabel>
                      <TagCloseButton
                        onClick={() => removeSelectedProviders(p?.id)}
                      />
                    </Tag>
                  ))}
                </HStack>

                <HStack>
                  {unsafeProviderWarning && (
                    <Box mt={pxToRem(20)} w='100%'>
                      <Alert status='warning' borderRadius='lg' w='100%'>
                        <AlertIcon />
                        <AlertTitle mr={2}>Warning</AlertTitle>
                        <AlertDescription>
                          You are about to use a Provider that has not been
                          Verified. Please proceed with caution or try using
                          Verified Providers.
                        </AlertDescription>
                      </Alert>
                    </Box>
                  )}
                </HStack>
                <Box
                >
                  <Grid
                    mt='20px'
                    gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
                    gap='22px'
                  >
                    {providerOptions?.length > 0 && (
                      <>
                        {searchFilterResponse
                          ?.sort(
                            (
                              a: {
                                name: string
                                id: string
                                isVerified: boolean
                              },
                              b: { name: any; id: string; isVerified: boolean }
                            ) => {
                              // Sort selected providers first
                              const aSelected = selectedProviders.some(
                                (selectedProvider) =>
                                  selectedProvider.id === a.id
                              )
                              const bSelected = selectedProviders.some(
                                (selectedProvider) =>
                                  selectedProvider.id === b.id
                              )

                              if (aSelected && !bSelected) {
                                return -1
                              } else if (!aSelected && bSelected) {
                                return 1
                              }

                              // Sort verified providers next
                              if (a.isVerified && !b.isVerified) {
                                return -1
                              } else if (!a.isVerified && b.isVerified) {
                                return 1
                              }

                              // If names are the same or both are selected/unselected, use alphabetical order
                              return a.name.localeCompare(b.name)
                            }
                          )
                          .map((p: any) => (
                            <ProviderCard
                              providerDetail={p}
                              key={p?.id}
                              isSelected={
                                selectedProviders.some(
                                  (selectedProvider) =>
                                    selectedProvider.id === p.id
                                ) ||
                                selectedProviderFromRedux.some(
                                  (selectedProvider) =>
                                    selectedProvider.id === p.id
                                )
                              }
                              onSelect={() => handleProviderSelect(p)}
                            />
                          ))}
                      </>
                    )}
                  </Grid>

                  {loading && (
                    <Grid
                      mt='20px'
                      gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
                      gap='22px'
                    >
                      {new Array(10).fill(0).map((_, i) => (
                        <Skeleton
                          key={i}
                          height={pxToRem(117)}
                          borderRadius='lg'
                          width={pxToRem(371)}
                        />
                      ))}
                    </Grid>
                  )}
                </Box>
                {searchFilterResponse.length <= 0 && !loading && (
                  <EmptyState
                    message={
                      "No Data Provider Found. Create a Provider tailored to your usecase."
                    }
                    navigateTo={"/devtoolv2"}
                    buttonText='Create New Provider'
                  />
                )}
                {/* {searchFilterResponse.length > 0 && (
                  <HStack
                    mt='20px'
                    justifyContent='flex-end'
                    bgColor={colorMode === "dark" ? "#131417" : "white"}
                    borderRadius='lg'
                    p='20px'
                  >
                    <Button
                      colorScheme='blue'
                      color={colorMode === "dark" ? "white" : "white"}
                      isDisabled={selectedProviders.length <= 0}
                      onClick={() => {
                        logEvent("PROVIDERS_SELECTED")
                        setActiveStep(activeStep + 1)
                      }}
                    >
                      Next
                    </Button>
                  </HStack>
                )} */}
              </Box>
            </Box>
          </Box>
        </>
      )}

      {activeStep === 2 && (
        <>
          <Box p='6px'>
            <Box mt='16px'>
              <Box mt='16'>
                <Heading as='h1' fontWeight={700} size='lg' fontSize='24px'>
                  Integrate with your App
                </Heading>
                <Text mt='8px'>
                  Select the list of providers and integrate them in 2 steps.
                </Text>
              </Box>

              <HStack>
                {unsafeProviderWarning && (
                  <Box mt={pxToRem(20)} w='100%'>
                    <Alert status='warning' borderRadius='lg' w='100%'>
                      <AlertIcon />
                      <AlertTitle mr={2}>Warning</AlertTitle>
                      <AlertDescription>
                        You are about to use a provider that has not been
                        verified. Please proceed with caution or try with
                        verified providers.
                      </AlertDescription>
                    </Alert>
                  </Box>
                )}
              </HStack>

              <Box p={pxToRem(2)}>
                <Box
                  mt='20px'
                  bgColor={colorMode === "dark" ? "#131417" : "#fff"}
                  borderRadius='lg'
                  p={pxToRem(30)}
                >
                  <IconButton
                    variant='outline'
                    colorScheme='blue'
                    aria-label=''
                    onClick={() => setActiveStep(activeStep - 1)}
                    icon={<MdKeyboardBackspace />}
                    width='fit-content'
                  />
                  <VStack spacing={4} align='stretch' mt='20px'>
                    <FormControl>
                      <FormLabel>Selected Providers</FormLabel>

                      <HStack gap={2} wrap='wrap'>
                        {selectedProviders.map((p: any, i) => (
                          <Tag
                            size='lg'
                            variant='outline'
                            colorScheme='blue'
                            key={i}
                          >
                            <TagLabel>{p?.name}</TagLabel>
                            <TagCloseButton
                              onClick={() => removeSelectedProviders(p?.id)}
                            />
                          </Tag>
                        ))}
                      </HStack>
                    </FormControl>

                    {/* Application Name */}
                    <FormControl>
                      <FormLabel htmlFor='appName'>Application Name</FormLabel>
                      <HStack>
                        <Input
                          type='text'
                          id='appName'
                          value={aiGeneratedName || applicationName}
                          onChange={(e) => {
                            setApplicationName(e.target.value)
                            if (aiGeneratedName) {
                              setAiGeneratedName(e.target.value)
                            } else {
                              setApplicationName(e.target.value)
                            }
                          }}
                          placeholder='Enter application name'
                        />
                        <IconButton
                          icon={
                            isLoadingName ? (
                              <Spinner size='sm' />
                            ) : (
                              <RepeatIcon />
                            )
                          }
                          aria-label='Refresh'
                          onClick={fillNameUsingGPT}
                        />
                      </HStack>
                    </FormControl>


                    {/* Project Name/ Company Name */}
                    <FormControl>
                      <FormLabel htmlFor='appName'>Project/Company Name * </FormLabel>
                      <HStack>
                        <Input
                          type='text'
                          id='appName'
                          value={projectName}
                          onChange={(e) => {
                            setProjectName(e.target.value)
                          }}
                          placeholder='Enter application name'
                        />
                      </HStack>
                    </FormControl>

                    {/* Application Description */}
                    <FormControl>
                      <FormLabel htmlFor='appDescription'>
                        Application Description (Optional)
                      </FormLabel>
                      <HStack>
                        <Textarea
                          value={
                            aiGeneratedDescription || applicationDescription
                          }
                          onChange={(e) => {
                            //setApplicationDescription(e.target.value)
                            if (aiGeneratedDescription) {
                              setAiGeneratedDescription(e.target.value)
                            } else {
                              setApplicationDescription(e.target.value)
                            }
                          }}
                          id='appDescription'
                          placeholder='Enter application description'
                        />
                        <IconButton
                          icon={
                            isLoadingDescription ? (
                              <Spinner size='sm' />
                            ) : (
                              <RepeatIcon />
                            )
                          }
                          aria-label='Refresh'
                          onClick={fillDescriptionUsingGPT}
                        />
                      </HStack>
                    </FormControl>

                    {/* Image Upload */}
                    <FormControl>
                      <FormLabel>
                        Application Logo (Optional)
                      </FormLabel>
                      <Input type='file' accept="image/*" onChange={handleImageChange} />
                    </FormControl>

                    <Button
                      colorScheme='blue'
                      isLoading={loading}
                      color={colorMode === "dark" ? "white" : "white"}
                      onClick={handleApplicationRegistration}
                    >
                      Register
                    </Button>
                  </VStack>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}
