import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SelectedNetworkRequest {
	selectedNetworkRequest: any
}

const initialState: SelectedNetworkRequest = {
	selectedNetworkRequest: {},
}

const selectedNetworkRequestSlice = createSlice({
	name: 'selectedNetworkRequest',
	initialState,
	reducers: {
		setSelectedNetworkRequest(state, action: PayloadAction<any>) {
			state.selectedNetworkRequest = action.payload
		},
		updateSelectedNetworkRequest(state, action: PayloadAction<any>) {
			state.selectedNetworkRequest = {
				...state.selectedNetworkRequest, ...action.payload
			}
		},
		resetSelectedNetworkRequest() {
			return { ...initialState }
		}
	},
})

export const { setSelectedNetworkRequest, updateSelectedNetworkRequest, resetSelectedNetworkRequest, } = selectedNetworkRequestSlice.actions

export default selectedNetworkRequestSlice.reducer
