import { SetStateAction, useEffect, useState } from 'react'
import { MdArrowForward } from 'react-icons/md'
import { Box, Button, Flex, Heading, Input, InputGroup, Select, Text, Tooltip } from '@chakra-ui/react'
import { ChatMessageType } from '@reclaim-developer-experience/dev-tool-client-sdk/dist'
import UserAgent from 'user-agents'
import { getWebClient } from '../../../../lib/client'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateAppConnected } from '../../../../redux/providerBuilder/mobileClient'
import { getCurrentUrlInput } from '../../../../redux/providerBuilder/mobileClient/selectors'
import { updateProviderDetails } from '../../../../redux/providerBuilder/providerDetails'

interface EnterUrlProps {
	accessToken: string
	sessionId: string
	setActiveStepInnerForm: (value: SetStateAction<number>) => void
}

export const EnterUrl = (props: EnterUrlProps) => {
	const dispatch = useAppDispatch()
	const currentUrlInput = useAppSelector(getCurrentUrlInput)

	const [localCurrentUrlInput, setLocalCurrentUrlInput] = useState(currentUrlInput)
	const [defaultUserAgent, setDefaultUserAgent] = useState<any[]>([])
	const [userAgent, setUserAgent] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)
	const { accessToken, sessionId, setActiveStepInnerForm } = props

	const getDefaultUserAgent = () => {
		const devices = ['desktop', 'mobile']
		const allUserAgents: { name: string, value: string }[] = []
		devices.forEach((device) => {
			const userAgentChrom = new UserAgent([ /Chrome/, { deviceCategory: device },])
			const userAgentSafari = new UserAgent([ /Safari/, { deviceCategory: device },])
			const userAgentMozilla = new UserAgent([ /Mozilla/, { deviceCategory: device },])
			allUserAgents.push({
				name: device + ' - Chrome',
				value: userAgentChrom.toString()
			})
			allUserAgents.push({
				name: device + ' - Safari',
				value: userAgentSafari.toString()
			})
			allUserAgents.push({
				name: device + ' - Mozilla',
				value: userAgentMozilla.toString()
			})
		})
		setDefaultUserAgent(allUserAgents)
	}


	const handleNavigate = () => {
		const client = getWebClient(accessToken)
		if(userAgent !== '') {
			client.sendMessage(
				{ message: userAgent, type: ChatMessageType.W_SEND_USER_AGENT },
				sessionId
			)
		}

		client.sendMessage(
			{ message: localCurrentUrlInput, type: ChatMessageType.M_SET_CURRENT_URL },
			sessionId
		)

		dispatch(updateProviderDetails({ userAgent: { android: userAgent, ios: userAgent } }))
		setButtonLoading(true)
		setActiveStepInnerForm(3)
		setButtonLoading(false)
	}

	useEffect(() => {
		getDefaultUserAgent()
	}, [])

	return (
		<>
			<Box
				marginBottom='2.5rem'
				alignItems='centre'
				justifyContent='center'
				height='100%'
			>
				<Box minH='80%'>
					<Flex
						fontSize='lg'
						alignItems='center'
						gap={4}
						mb='50px'>
						<Heading
							fontSize='larger'
						>
							Enter the website URL from where you want to verify data.
						</Heading>
					</Flex>
					<Flex
						fontSize='lg'
						alignItems='center'
						gap={4}>
						<InputGroup
							size='md'
							gap={4}>
							<Input
								placeholder='http://google.com'
								value={localCurrentUrlInput}
								onChange={(e) => setLocalCurrentUrlInput(e.target.value)}
							/>
						</InputGroup>
					</Flex>
					<Flex mt='30px'>
						<Text
							fontSize='md'
							minW='140px'
							ml='3px'
							color='black'
							mt='1.5'>
							User Agent:
						</Text>
						<Select
							width='70%'
							placeholder='Select option'
							value={userAgent}
							onChange={
								(e) => {
									setUserAgent(e.target.value)
								}
							}>
							{
								defaultUserAgent.map((userAgent, index) => {
									return (
										<option
											key={index}
											value={userAgent.value}>
											{userAgent.name}
										</option>
									)
								})
							}
						</Select>
					</Flex>
				</Box>
				<Flex justifyContent='flex-end'>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={
							() => {
								dispatch(updateAppConnected(false))
								setActiveStepInnerForm(1)
							}
						}
						width='10%'
						mr='30px'
					>
						Back
					</Button>
					<Tooltip label='This will navigate your connected mobile session to the specified url'>
						<Button
							marginTop='30px'
							variant='solid'
							justifySelf='right'
							colorScheme='blue'
							width='15%'
							mr='30px'
							rightIcon={<MdArrowForward />}
							isLoading={buttonLoading}
							onClick={handleNavigate}
						>
							Navigate
						</Button>
					</Tooltip>
				</Flex>
			</Box>
		</>
	)
}