import { SetStateAction, useState } from 'react'
import { Box, Button, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Select } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateProviderDetails } from '../../../../redux/providerBuilder/providerDetails'
import { getProviderDetails } from '../../../../redux/providerBuilder/providerDetails/selectors'
import { getSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest/selectors'

interface ProviderNameProps {
	setActiveStepInnerForm: (value: SetStateAction<number>) => void
}

export const ProviderName = (props: ProviderNameProps) => {
	const dispatch = useAppDispatch()

	const providerDetails = useAppSelector(getProviderDetails)
	const selectedNetworkRequest = useAppSelector(getSelectedNetworkRequest)

	const [providerName, setProviderName] = useState(providerDetails.name ? providerDetails.name : '')
	const [providerDescription, setProviderDescription] = useState(providerDetails.description ? providerDetails.description : '')
	const [nameError, setNameError] = useState('')
	const [descriptionError, setDescriptionError] = useState('')
	const [provividerType, setProviderType] = useState('PRIVATE') //Todo: Temp change- change this later
	const [buttonLoading, setButtonLoading] = useState(false)
	const handleNext = () => {
		if (!providerName) {
			setNameError('Provider name is required')
			return
		}

		if (!providerDescription) {
			setDescriptionError('Provider description is required')
			return
		}

		setButtonLoading(true)
		dispatch(
			updateProviderDetails({
				name: providerName,
				description: providerDescription,
				method: selectedNetworkRequest.method,
				providerType: provividerType,
			})
		)
		props.setActiveStepInnerForm(1)
	}

	return (
		<>
			<Box
				marginBottom='1rem'
				gap='30px'>
				<FormControl
					marginTop='1.5rem'
					isInvalid={!!nameError}>
					<FormLabel>
						Name Your Provider *
					</FormLabel>
					<Input
						onChange={
							(e) => {
								setNameError('')
								setProviderName(e.target.value)
							}
						}
						required={true}
						value={providerName}
					/>
					<FormErrorMessage>
						{nameError}
					</FormErrorMessage>
					<FormHelperText>
						A friendly name that users will see on the reclaim app
					</FormHelperText>
				</FormControl>
				<FormControl
					marginTop='1.5rem'
					isInvalid={!!descriptionError}>
					<FormLabel>
						Description *
					</FormLabel>
					<Input
						onChange={
							(e) => {
								setDescriptionError('')
								setProviderDescription(e.target.value)
							}
						}
						required={true}
						value={providerDescription}
					/>
					<FormErrorMessage>
						{descriptionError}
					</FormErrorMessage>
					<FormHelperText>
						Short description of the provider
					</FormHelperText>
				</FormControl>
				<FormControl marginTop='1.5rem'>
					<FormLabel>
						Provider Type *
					</FormLabel>
					<Select
						value={provividerType}
						onChange={(e) => setProviderType(e.target.value)}
					>
						<option value='PUBLIC'>
							Public
						</option>
						<option value='PRIVATE'>
							Private
						</option>
					</Select>
					<FormHelperText>
						{
							provividerType === 'PUBLIC'
								? 'Public - Visible to all. can be used by others'
								: 'Private - Only You can view this Provider'
						}
					</FormHelperText>
				</FormControl>
				<Flex justifyContent='flex-end'>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={handleNext}
						colorScheme='blue'
						width='10%'
						mr='30px'
						isLoading={buttonLoading}
					>
						Next
					</Button>
				</Flex>
			</Box>
		</>
	)
}