import { useEffect, useState } from 'react'
import { Box, Grid, Skeleton } from '@chakra-ui/react'
import { EmptyState } from '../../../components'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { getAllActiveHttpsProviders } from '../../../redux/httpsProviders/actions'
import { getHttpsProviders, getLoading } from '../../../redux/httpsProviders/selectors'
import { HttpsProvider } from '../../../redux/httpsProviders/types'
import { pxToRem } from '../../../utils'
import { ProviderCard } from './provider-card.component'

export const AllProviders = ({ searchQuery }: any) => {
	const allHTTPproviders = useAppSelector(getHttpsProviders)
	const loading = useAppSelector(getLoading)
	const dispatch = useAppDispatch()
	const [flteredResponse, setFilteredResponse] = useState<
    HttpsProvider[] | null
  >(null)

	const searchFilter = () => {
		if(allHTTPproviders) {
			const response = allHTTPproviders.filter(
				(p) => p.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        p.name.toUpperCase().includes(searchQuery.toUpperCase())
			)
			setFilteredResponse(response)
		}
	}

	useEffect(() => {
		searchFilter()
	}, [searchQuery, allHTTPproviders])

	useEffect(() => {
		// Fetch data only if it hasn't been fetched yet

		if(!allHTTPproviders.length) {
			dispatch(getAllActiveHttpsProviders())
		}


	}, [dispatch, allHTTPproviders])

	if(flteredResponse === null) {
		return (
			<Grid
				mt='20px'
				gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
				gap='22px'
			>
				{
					new Array(10).fill(0).map((_, i) => (
						<Skeleton
							key={i}
							height={pxToRem(117)}
							borderRadius='lg'
							width={pxToRem(371)}
						/>
					))
				}
			</Grid>
		)
	}

	return (
		<Box>
			<Grid
				mt='20px'
				gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
				gap='22px'
			>
				{
					flteredResponse?.length > 0 && (
						<>
							{
								flteredResponse
									?.sort((a, b) => {
										// Sort by isVerified (verified providers first)
										if(a.isVerified && !b.isVerified) {
											return -1
										} else if(!a.isVerified && b.isVerified) {
											return 1
										}

										// If both providers have the same isVerified status, sort alphabetically by name
										return a.name.localeCompare(b.name)
									})
									.map((providerDetails: HttpsProvider) => (
										<ProviderCard
											providerData={providerDetails}
											key={providerDetails?.id}
										/>
									))
							}
						</>
					)
				}
			</Grid>

			{
				loading && (
					<Grid
						mt='20px'
						gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
						gap='22px'
					>
						{
							new Array(10).fill(0).map((_, i) => (
								<Skeleton
									key={i}
									height={pxToRem(117)}
									borderRadius='lg'
									width={pxToRem(371)}
								/>
							))
						}
					</Grid>
				)
			}
			{
				!loading && flteredResponse?.length === 0 && (
					<>
						<EmptyState
							message='No Data Provider Found. Create a Provider tailored to your usecase.'
							navigateTo='/devtoolv2'
							buttonText='Create Data Provider'
						/>
					</>
				)
			}
		</Box>
	)
}
