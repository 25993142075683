import { initializeApp } from 'firebase/app'

const firebaseConfig = {
	apiKey: 'AIzaSyDeK71Frp_xe1JWo3wuCQp2mW4v3ft9Si8',
	authDomain: 'reclaim-dev-388410.firebaseapp.com',
	projectId: 'reclaim-dev-388410',
	storageBucket: 'reclaim-dev-388410.appspot.com',
	messagingSenderId: '799994060845',
	appId: '1:799994060845:web:c25f160f87643fec1198a4',
	measurementId: 'G-7LL2WZ5P74'
}

export const app = initializeApp(firebaseConfig)
