/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import QRCode from 'react-qr-code'
import { Box, Button, Center, CircularProgress, Flex, Heading, Icon, Link, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react'
import { getAuth } from 'firebase/auth'
import { app } from '../../../../configs/firebaseApp'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateAppConnected } from '../../../../redux/providerBuilder/mobileClient'
import { getAppConnected } from '../../../../redux/providerBuilder/mobileClient/selectors'
import { updateActiveStep } from '../../../../redux/providerBuilder/stepperDetails'

interface ScanQRProps {
	sessionId: string
	accessToken: string
	userUid: string
}

export const ScanQR = (props: ScanQRProps) => {
	const dispatch = useAppDispatch()

	const appConnected = useAppSelector(getAppConnected)
	const { sessionId, accessToken, userUid } = props
	const [newAccessToken, setNewAccessToken] = useState(accessToken)

	const refreshAccessToken = async () => {
		const newAccessToken = await getAuth(app).currentUser?.getIdToken() || ''
		if (newAccessToken !== accessToken && newAccessToken !== '') {
			setNewAccessToken(newAccessToken)
		}
	}

	// Refresh the access token on page load
	useEffect(() => {
		const interval = setInterval(async () => {
			await refreshAccessToken()
		}
		, 500)
		return () => clearInterval(interval)
	}
	, [])

	const handleNext = () => {
		dispatch(updateActiveStep(1))
	}

	useEffect(() => {
		dispatch(updateAppConnected(false))
	}, [dispatch])

	useEffect(() => {
		if (appConnected) {
			handleNext()
		}
	}, [appConnected])


	return (
		<>
			<Box
				h='65vh'
				alignContent='center'
				justifySelf='center'>
				<Flex
					h='45%'
					borderBottom='2px solid'
					borderColor='gray.200'>
					<Box
						w='50%'
						p={4}>
						<Text
							fontSize='x-large'
							fontWeight='bold'>
							Instructions for Scanning QR Code
						</Text>
						<OrderedList
							mt='20px'
							spacing='20px'>
							<ListItem fontSize='large'>
								<Text
									as='span'>
									Download the
									{' '}
									<Link
										target='_blank'
										color='blue.500'
										href='https://docs.reclaimprotocol.org/install'
									>
										Reclaim Protocol App
									</Link>
									{' '}
									on your mobile
								</Text>
							</ListItem>
							<ListItem fontSize='large'>
								<Text>
									Open Reclaim Protocol App and Select
									{' '}
									<Text
										as='span'
										fontWeight='bold'>
										Build Providers
									</Text>
								</Text>
							</ListItem>
							<ListItem fontSize='large'>
								<Text>
									Point your camera towards the QR code on the right
								</Text>
							</ListItem>
						</OrderedList>
					</Box>
					<Box
						w='50%'
						display='flex'
						mt='-80px'
						alignItems='center'
						justifyContent='center'>
						<Center>
							{
								newAccessToken && userUid && sessionId ? (

									<QRCode
										size={230}
										value={`dev_tool_${sessionId}_${newAccessToken}_${userUid}`}
									/>
								) : (
									<CircularProgress
										isIndeterminate
										size='36px' />
								)
							}
						</Center>
					</Box>
				</Flex>
				<Box
					h='50%'
					justifyContent='center'
					justifyItems='center'
					p={4}>
					<Center mt='40px'>
						<iframe
							width='560'
							height='315'
							src='https://www.youtube.com/embed/RE9u1_4r5Xw'
							title='YouTube video player'
							frameBorder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
						/>
					</Center>
				</Box>
			</Box>
		</>
	)
}