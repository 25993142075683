import { FaCheckCircle, FaHeart, FaStar } from 'react-icons/fa'
import { Box, Heading, Icon, SimpleGrid, Text } from '@chakra-ui/react'
import { pxToRem } from '../../../../utils'
import { Container } from '../container/container.component'

const FeatureCard = ({ icon, title, description }: any) => (
	<Box
		borderRadius='lg'
		style={
			{
				background:
        'linear-gradient(180deg, rgba(80, 80, 80, 0.15) 0%, rgba(0, 0, 0, 0) 70%',
			}
		}
		p={pxToRem(18)}
		mt={pxToRem(18)}
		mb={4}
	>
		<Icon
			as={icon}
			boxSize={8}
			color='blue.500'
			mb={4} />
		<Heading
			as='h3'
			color='white'
			size='md'
			mb={2}>
			{title}
		</Heading>
		<Text color='rgba(239,245,255,.693)'>
			{description}
		</Text>
	</Box>
)

export const WhySection = () => {
	return (
		<Box padding='96px 24px'>
			<Heading
				as='h1'
				textAlign='center'
				fontWeight='bold'
				color='white'
				fontSize='40px'
				mb={pxToRem(30)}
			>
				Why you Will Love Reclaim SDK?
			</Heading>
			<Container>
				<SimpleGrid
					columns={{ base: 1, md: 2, lg: 3 }}
					spacing={8}
					mx='auto'>
					<FeatureCard
						icon={FaCheckCircle}
						title='Omni Channel'
						description='Get any Data from any website around the World. We already support integrations for over 250 websites.'
					/>
					<FeatureCard
						icon={FaStar}
						title='Compliance Baked-In'
						description='Never need to tore sensitive user information like login Credentials on your Backend.'
					/>
					<FeatureCard
						icon={FaHeart}
						title='Web3 Ready'
						description='Publish and use verifiable credentials on Ethereum, Arbitrum, Optimism, Solana, Polkadot, Cosmos.'
					/>
				</SimpleGrid>
			</Container>
		</Box>
	)
}


