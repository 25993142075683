/* eslint-disable indent */
import { GET_MANUAL_VERIFICATION_CAPTURED_NETWORK_REQUESTS } from '../constants'
import { getFirebaseIdToken } from '../utils'

export const getManualVerifcationCapturedNetworkRequests = async (sessionId: string) => {
    try {
        const accessToken = await getFirebaseIdToken()
        const response = await fetch(GET_MANUAL_VERIFICATION_CAPTURED_NETWORK_REQUESTS(sessionId), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        })
        const result = await response.json()
        return result
    } catch (error) {
        console.log(error)
    }
}