import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import {
	browserLocalPersistence,
	getAuth,
	onAuthStateChanged,
	setPersistence,
} from 'firebase/auth'
import { app } from '../../../../configs/firebaseApp'
import { useAppDispatch } from '../../../../redux/hooks'
import {
	setIsLoggedIn,
	setUserDisplayName,
	setUserEmailId,
} from '../../../../redux/user'
import { logoutClient } from '../../../../redux/user/actions'

export const ProtectedRouteWrapper = ({ children }: any) => {
	const [authChecked, setAuthChecked] = useState(false)
	const auth = getAuth(app)
	const dispatch = useAppDispatch()
	useEffect(() => {
		const storedUser = localStorage.getItem('user')
		if(storedUser) {
			const user = JSON.parse(storedUser)
			dispatch(setIsLoggedIn(true))
			dispatch(setUserEmailId(user?.email as string))
			dispatch(setUserDisplayName(user?.displayName as string))
		}

		const unsubscribe = onAuthStateChanged(auth, async(user) => {
			setAuthChecked(true)

			if(user) {
				// Update Redux state when the user is authenticated
				dispatch(setIsLoggedIn(true))
				dispatch(setUserEmailId(user?.email as string))
				dispatch(setUserDisplayName(user.displayName as string))
				//@ts-ignore
				//dispatch(setApplications(JSON.parse(storedApplications)))
				// Store user information in localStorage
				localStorage.setItem('user', JSON.stringify(user))
			} else {

				dispatch(setIsLoggedIn(false))
				dispatch(logoutClient())
				// Remove user information from localStorage
				localStorage.removeItem('user')

				window.location.replace('/')
			}
		})

		// Set Auth State Persistence to 'local'
		setPersistence(auth, browserLocalPersistence)

		return () => unsubscribe()
	}, [auth, dispatch])

	if(!authChecked) {
		return null
	}

	return auth ? children : (
		<Navigate
			to='/'
			replace />
	)
}
