import { MdKeyboardBackspace } from 'react-icons/md'
import {
	Box,
	Card,
	CardBody,
	CardHeader,
	Flex,
	Heading,
	IconButton,
	Step,
	StepIcon,
	StepIndicator,
	StepNumber,
	Stepper,
	StepSeparator,
	StepStatus,
	StepTitle,
	useSteps,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateActiveStep } from '../../../../redux/providerBuilder/stepperDetails'
import {
	getActiveStep,
	getStepperTitles,
} from '../../../../redux/providerBuilder/stepperDetails/selectors'
import { LoginUrl } from './login-url.component'
import { OptionalFields } from './optional-fields.component'
import { ProviderName } from './provider-name.component'
import { RequestConfig } from './request-config.component'

/* eslint-disable indent */
export const StepperForm = () => {
    const dispatch = useAppDispatch()

    const activeStep = useAppSelector(getActiveStep)
    const stepperTitles = useAppSelector(getStepperTitles)

    const steps = [
        'Provider Name',
        'Login URL',
        'Request Configurations',
        'Optional Fields',
    ]

    const {
        activeStep: activeStepInnerForm,
        setActiveStep: setActiveStepInnerForm,
    } = useSteps({
        index: 0,
        count: steps.length,
    })

    return (
	<>
		<Box
                marginTop='2.5rem'
                marginBottom='5rem'>
			<Card>
				<CardHeader>
					<Heading fontSize='larger'>
						<IconButton
                                variant='outline'
                                colorScheme='teal'
                                aria-label=''
                                onClick={() => dispatch(updateActiveStep(activeStep - 1))}
                                icon={<MdKeyboardBackspace />}
                                marginRight='20px'
                            />
						{stepperTitles[activeStep]}
					</Heading>
				</CardHeader>
				<CardBody height='80%'>
					<Flex>
						<Stepper
                                index={activeStepInnerForm}
                                orientation='vertical'
                                height='400px'
                                gap='0'
                            >
							{
                                    steps.map((step, index) => (
	<Step key={index}>
		<Flex align='baseline'>
			<Box
                                                    cursor={
                                                        activeStepInnerForm >= index
                                                            ? 'pointer'
                                                            : 'not-allowed'
                                                    }
                                                    onClick={
                                                        () => activeStepInnerForm >= index
                                                            ? setActiveStepInnerForm(index)
                                                            : null
                                                    }
                                                    width='145px'
                                                >
				<StepTitle>
					{step}
				</StepTitle>
			</Box>
			<StepIndicator>
				<StepStatus
                                                        complete={
	<StepIcon
                                                                onClick={
                                                                    () => activeStepInnerForm >= index
                                                                        ? setActiveStepInnerForm(index)
                                                                        : null
                                                                }
                                                                cursor='pointer'
                                                            />
                                                        }
                                                        incomplete={<StepNumber />}
                                                        active={<StepNumber />}
                                                    />
			</StepIndicator>
		</Flex>
		<StepSeparator style={{ marginLeft: '82%' }} />
	</Step>
                                    ))
                                }
						</Stepper>
						<Box
                                mt='-3%'
                                ml='5%'
                                padding='3%'
                                width='100%'
                                alignItems='center'
                            >
							{
                                    activeStepInnerForm === 0 && (
                                        <ProviderName
                                            setActiveStepInnerForm={setActiveStepInnerForm}
                                        />
                                    )
                                }
							{
                                    activeStepInnerForm === 1 && (
                                        <LoginUrl setActiveStepInnerForm={setActiveStepInnerForm} />
                                    )
                                }
							{
                                    activeStepInnerForm === 2 && (
                                        <RequestConfig
                                            setActiveStepInnerForm={setActiveStepInnerForm}
                                        />
                                    )
                                }
							{
                                    activeStepInnerForm === 3 && (
                                        <OptionalFields
                                            setActiveStepInnerForm={setActiveStepInnerForm}
                                        />
                                    )
                                }
						</Box>
					</Flex>
				</CardBody>
			</Card>
		</Box>
	</>
    )
}
