import { chatGPT } from '../chat-gpt'

const URLSpanType = {
	CONSTANT: 'CONSTANT',
	DYNAMIC: 'DYNAMIC',
}

// Initialize the regex body with the selected network request body
function initializeRegexBody(body: string): string {
	let newRegexBody = body
		? JSON.stringify(body, null, 2)
		: ''
	newRegexBody = newRegexBody.replace(/\\"/g, '"')
	// remove first and last character from regex body as those are additional "" added by JSON.stringify
	if (newRegexBody.startsWith('"')) {
		newRegexBody = newRegexBody.substring(1, newRegexBody.length - 1)
	}

	return newRegexBody
}

// Create a map of all url components to add to the span tag
function seperateURLComponents(url: string): any[] {

	const parser = document.createElement('a')
	parser.href = url
	const newUrlSpans = []
	// Adding protocol to url map. It cannot be modified by users
	newUrlSpans.push({
		key: parser.protocol + '//' + parser.hostname,
		type: 'STATIC',
	})


	// Adding path to url map. It can be made dynamic by users
	if (parser.pathname.length > 1) {
		newUrlSpans.push({ key: '/', type: 'STATIC' })
		const pathParams = parser.pathname.substring(1).split('/')

		pathParams.forEach((param, idx) => {
			newUrlSpans.push({ key: param, type: URLSpanType.CONSTANT })
			if (idx !== pathParams.length - 1) {
				newUrlSpans.push({ key: '/', type: 'STATIC' })
			}
		})
	}


	// Adding query params to url map. It can be made dynamic by users
	if (parser.search.length) {
		newUrlSpans.push({ key: '?', type: 'STATIC' })
		const queryParams = parser.search.substring(1).split('&')
		queryParams.forEach((param, idx) => {
			if (param.length) {
				const [key, value] = param.split('=')
				newUrlSpans.push({ key: key + '=', type: 'STATIC' })
				value.length > 0 ? newUrlSpans.push({ key: value, type: URLSpanType.CONSTANT }) : null
			}

			if (idx !== queryParams.length - 1) {
				newUrlSpans.push({ key: '&', type: 'STATIC' })
			}

		})
	}

	return newUrlSpans
}

function urlSpanToRegexUrl(urlSpans: any): string {
	let urlCounter = 1
	const newRegexURL = urlSpans
		.map((span: any) => {
			if(span.type === URLSpanType.DYNAMIC) {
				return `{{URL_PARAMS_${urlCounter++}}}`
			// } else if(span.key.startsWith('?')) {
			// 	return '\\\\' + span.key
			} else if (span.type?.includes('{{URL_PARAMS_')) {
				return span.type
			} else {
				return span.key
			}
		})
		.join('')

	const regexUrl = newRegexURL
	return regexUrl
}

function getLoginURLs(uniqueUrls: string[]) {
	// filter all the urls containing keywords like login, auth, signin, etc
	const filteredUrls = uniqueUrls.filter((url) => {
		const urlLower = url.toLowerCase()
		return urlLower.includes('login') || urlLower.includes('auth') || urlLower.includes('signin') ||
			urlLower.includes('register') || urlLower.includes('signup') || urlLower.includes('oauth') ||
			urlLower.includes('authorize') || urlLower.includes('log') || urlLower.includes('sign')
	})
	return filteredUrls
}


async function generateProviderNameFromGPT(url: string, selectedInstanaces: any): Promise<string> {
	const contextStr =
		'I am building a data provider which shows data ownership to end users. I need a concise and compelling name based on the URL and the data selected. Give more importance to the URL domain while naming the data provider.'
	let promptStr =
		'Give a name within 2-3 words for the following URL and data variables: '
	promptStr += url + ' '
	promptStr += selectedInstanaces
		.map((variable: any) => {
			return variable.variableName
		})
		.join(' ')
	try {
		const responseVal = await chatGPT(contextStr, promptStr)
		if (responseVal.status === 'success') {
			return responseVal.message.toString().replace(/"/g, '')
		} else {
			return 'Data Provider'
		}
	} catch (e) {
		console.log('error while generating proof card text from chatGPT' + e)
		return ''
	}
}

async function generateProviderDescriptionFromGPT(providerName: string, url: string, selectedInstanaces: any): Promise<string> {
	const contextStr =
		'I am building a data provider which shows data ownership to end users. I need a concise and compelling description based on the name of data provider, URL and the data selected. Give more importance on the data variables usage while describing the data provider.'
	let promptStr =
		'Give a description within 6-7 words for the following provider name, URL and data variables: '
	promptStr += providerName + ' '
	promptStr += url + ' '
	promptStr += selectedInstanaces
		.map((variable: any) => {
			return variable.variableName
		})
		.join(' ')
	try {
		const responseVal = await chatGPT(contextStr, promptStr)
		if (responseVal.status === 'success') {
			return responseVal.message.toString().replace(/"/g, '')
		} else {
			return 'Data Provider Description'
		}
	} catch (e) {
		console.log('error while generating proof card text from chatGPT' + e)
		return ''
	}
}

function getDefaultProofCardText(selectedInstanaces: any): string {
	let proofCardText = 'Owns data: '
	proofCardText += selectedInstanaces
		.map((variable: any) => {
			return variable.variableName.toUpperCase() + ': ' + '{{' + variable.variableName + '}}'
		})
		.join(', ')
	return proofCardText
}


export {
	initializeRegexBody,
	seperateURLComponents,
	URLSpanType,
	urlSpanToRegexUrl,
	generateProviderNameFromGPT,
	generateProviderDescriptionFromGPT,
	getLoginURLs,
	getDefaultProofCardText
}