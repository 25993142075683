/* eslint-disable @typescript-eslint/no-unused-vars */
//@ts-nocheck
import { useEffect, useState } from 'react'
import React from 'react'
import { MdKeyboardBackspace } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { QuestionIcon } from '@chakra-ui/icons'
import {
	Alert,
	Box,
	Button,
	Center,
	CloseButton,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Icon,
	IconButton,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Spinner,
	Textarea,
	Tooltip,
	useColorMode,
	useDisclosure,
	useToast
} from '@chakra-ui/react'
import axios from 'axios'
import { useAppDispatch } from '../../redux/hooks'
import { getAllActiveHttpsProviders, updateHttpsProvider } from '../../redux/httpsProviders/actions'
import { pxToRem } from '../../utils'
import countryCodes from '../../utils/data/country-codes.json'
import { getApiUrl, getFirebaseIdToken } from '../../utils/utils'

export const ProviderEditScreen = () => {
	const { id: providerId } = useParams()
	const [provider, setProvider] = useState<any>([])
	const [formData, setFormData] = useState({
		name,
		responseSelections: [],
		userAgent: {},
	})
	const [loading, setLoading] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [newResponseSelectionData, setNewResponseSelectionData] = useState({ jsonPath: '', responseMatch: '', xPath: '', matchType: 'greedy' })
	const [newResponseSelectionValidationError, setNewSelectionValidationError] = useState({ jsonPath: false, responseMatch: false, xPath: false, matchType: false })

	const { colorMode } = useColorMode()
	const toast = useToast()
	const navigate = useNavigate()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const fetchProvider = async () => {
		try {
			setLoading(true)
			const token = await getFirebaseIdToken()
			const { data } = await axios.get(
				`${getApiUrl()}/api/providers/${providerId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			if (data?.isSuccess) {
				setLoading(false)
				setProvider(data?.providers)
				setFormData(data?.providers)
			}
		} catch (error) {
			toast({
				title: 'Error',
				description: 'Something went wrong while fetching provider. Please try again later.',
				status: 'error',
				duration: 9000,
				isClosable: true,
			})
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchProvider()
	}, [])

	if (loading) {
		return (
			<Center p={4}>
				{' '}
				<Spinner />
				{' '}
				Loading...
			</Center>
		)
	}

	const handleChange = (e) => {
		const { name, value } = e.target
		if (name.startsWith('userAgent.')) {
			// If the field belongs to userAgent, update it in a nested manner
			const userAgentField = name.split('.')[1] // Extract the userAgent field name
			setFormData((prevData) => ({
				...prevData,
				userAgent: {
					...prevData.userAgent,
					[userAgentField]: value, // Update the specific userAgent field
				},
			}))
		} else {
			// For other fields, update normally
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}))
		}
	}


	const handleResponseSelectionChange = (
		index: number,
		field: string,
		value: any
	) => {
		const updatedResponseSelections = formData.responseSelections.map(
			(selection, i) => {
				if (i !== index) {
					return selection
				} // Keep unchanged selections as they are

				return {
					...selection, // Keep other properties unchanged
					[field]: value, // Update only the specified field
				}
			}
		)

		// Update the state with the modified response selections array and userAgent
		setFormData((prevState) => ({
			...prevState,
			responseSelections: updatedResponseSelections,
		}))
	}


	const dispatch = useAppDispatch()


	const submitHandler = async () => {
		try {
			setIsSubmitting(true)
			const data = await dispatch(updateHttpsProvider({
				httpProviderId: providerId,
				formData
			}))
			setIsSubmitting(false)
			dispatch(getAllActiveHttpsProviders())
			if (data.meta.requestStatus === 'fulfilled') {
				dispatch(getAllActiveHttpsProviders())
				toast({
					title: 'Provider Edited Successfully',
					description: 'Provider Edited Successfully',
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				// navigate('/explore')
			}
		} catch (error) {
			toast({
				title: 'Error Editing Provider',
				description: 'Error Editing Provider',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		} finally {
			setIsSubmitting(false)

		}
	}

	const closeNewResponseModal = () => {
		setNewResponseSelectionData({ jsonPath: '', responseMatch: '', xPath: '', matchType: 'greedy' })
		onClose()
	}

	const handleNewResponseSelectionFieldChange = (e) => {
		setNewResponseSelectionData(prev => ({ ...prev, [e.target.name]: e.target.value }))
		if (e.target.name === 'responseMatch' && newResponseSelectionValidationError?.responseMatch) {
			setNewSelectionValidationError(prev => ({ ...prev, responseMatch: false }))
		}
	}

	const addNewResponseSelection = () => {
		if (!newResponseSelectionData?.responseMatch?.length) {
			setNewSelectionValidationError(prev => ({ ...prev, responseMatch: true }))
			return
		}

		const tempResponseSelections = [...formData.responseSelections]
		tempResponseSelections.push({
			...newResponseSelectionData,
			invert: false,
		})
		setFormData((prevState) => ({
			...prevState,
			responseSelections: tempResponseSelections,
		}))
		setProvider((prevState) => ({
			...prevState,
			responseSelections: tempResponseSelections,
		}))
		closeNewResponseModal()
	}

	const handleRemoveResponseSelection = (index: number) => {
		const tempResponseSelections = [...formData.responseSelections]

		tempResponseSelections.splice(index, 1)

		setFormData((prevState) => ({
			...prevState,
			responseSelections: tempResponseSelections,
		}))
		setProvider((prevState) => ({
			...prevState,
			responseSelections: tempResponseSelections,
		}))
	}


	return (
		<Box p={4}>
			<Box>
				<Box
					mt='32px'
					p='20px'
					borderRadius='lg'
					borderWidth='1px'
					borderStyle='solid'
					borderColor='borderColor'
					bgColor={colorMode === 'dark' ? '' : 'white'}
				>
					<IconButton
						variant='outline'
						colorScheme='blue'
						aria-label=''
						onClick={() => navigate(-1)}
						icon={<MdKeyboardBackspace />}
						width='fit-content'
						mb={pxToRem(20)}
					/>
					<Heading
						as='h2'
						size='xl'
						mb='20px'>
						Provider Details
					</Heading>

					<Box p={4}>
						<Box style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ marginBottom: '20px' }}>
								<FormControl
									flex='1'
									mr='2'>
									<FormLabel htmlFor='name'>
										Name:
									</FormLabel>
									<Input
										type='text'
										id='name'
										name='name'
										value={formData?.name}
										onChange={handleChange}
										placeholder={provider?.name}
										required
									/>
								</FormControl>
							</div>
							<div style={{ marginBottom: '20px' }}>
								<FormControl
									flex='1'
									ml='0'>
									<FormLabel htmlFor='description'>
										Description:
									</FormLabel>
									<Textarea
										placeholder={provider?.description}
										id='description'
										value={formData?.description}
										onChange={handleChange}
										name='description'
										resize='vertical'
										required
									/>
								</FormControl>
							</div>
							<div
								style={
									{
										display: 'flex',
										flexDirection: 'row',
										marginBottom: '20px',
									}
								}
							>
								<FormControl
									flex='1'
									mr='2'>
									<FormLabel htmlFor='logoUrl'>
										Logo URL:
									</FormLabel>
									<Input
										value={formData?.logoUrl}
										placeholder={provider?.logoUrl}
										onChange={handleChange}
										type='text'
										id='logoUrl'
										name='logoUrl'
									/>
								</FormControl>
								<FormControl
									flex='1'
									ml='2'>
									<FormLabel htmlFor='url'>
										URL:
									</FormLabel>
									<Input
										value={formData?.url}
										placeholder={provider?.url}
										onChange={handleChange}
										type='text'
										id='url'
										name='url'
										required
									/>
								</FormControl>
							</div>
							<div
								style={
									{
										display: 'flex',
										flexDirection: 'row',
										// marginBottom: '20px',
									}
								}
							>
								<FormControl
									flex='1'
									mr='2'>
									<FormLabel htmlFor='loginUrl'>
										Login URL:
									</FormLabel>
									<Input
										value={formData?.loginUrl}
										onChange={handleChange}
										placeholder={provider?.loginUrl}
										type='text'
										id='loginUrl'
										name='loginUrl'
									/>
								</FormControl>
							</div>

							<div />

							<HStack mb={pxToRem(20)}>
								<FormControl
									flex='1'
									mt='4'>
									<FormLabel htmlFor='method'>
										Method:
									</FormLabel>
									<Select
										placeholder={formData.method}
										onChange={handleChange}
										name='method'
									>
										<option value='GET'>
											GET
										</option>
										<option value='POST'>
											POST
										</option>
									</Select>
								</FormControl>

								<FormControl
									flex='1'
									mt='4'>
									<FormLabel htmlFor='geoLocation'>
										Geo Location:
									</FormLabel>
									<Select
										name='geoLocation'
										placeholder={
											provider?.geoLocation
												? provider?.geoLocation
												: 'Select option'
										}
										value={formData.geoLocation}
										onChange={handleChange}
									>
										{
											countryCodes.map(
												(countryCode: { Code: string, Name: string }) => (
													<option
														key={countryCode.Code}
														value={countryCode.Code}>
														{countryCode.Name}
													</option>
												)
											)
										}
									</Select>
								</FormControl>
							</HStack>

							<HStack mb={pxToRem(20)}>
								<FormControl
									flex='1'
									mt='0'>
									<FormLabel htmlFor='method'>
										Provider Type:
									</FormLabel>
									<Select
										placeholder={formData.providerType}
										onChange={handleChange}
										name='providerType'
									>
										<option value='PUBLIC'>
											PUBLIC
										</option>
										<option value='PRIVATE'>
											PRIVATE
										</option>
									</Select>
								</FormControl>
							</HStack>

							<Box mb={pxToRem(20)}>
								<FormControl>
									<FormLabel>
										Proof Card Title
									</FormLabel>
									<Input
										type='text'
										value={formData?.proofCardTitle}
										placeholder={formData?.proofCardTitle}
										onChange={handleChange}
										name='proofCardTitle'
									/>
								</FormControl>
							</Box>

							<Box mb={pxToRem(20)}>
								<FormControl>
									<FormLabel>
										Proof Card Text
									</FormLabel>
									<Textarea
										value={formData?.proofCardText}
										placeholder={formData?.proofCardText}
										onChange={handleChange}
										name='proofCardText'
									/>
								</FormControl>
							</Box>

							<div>
								<FormControl>
									<FormLabel>
										Custom JS Injection
									</FormLabel>
									<Textarea
										value={formData?.customInjection}
										placeholder={
											formData?.customInjection
												? formData?.customInjection
												: 'Add Custom Injection'
										}
										onChange={handleChange}
										name='customInjection'
									/>
								</FormControl>
							</div>

							<Box mt={pxToRem(20)}>
								<FormControl
									flex='1'
									ml='0'>
									<FormLabel htmlFor='responseSelection'>
										Response Selection:
									</FormLabel>
									{
										provider?.responseSelections?.length <= 0 && (
											<Alert borderRadius='lg'>
												No Response Selection Found
											</Alert>
										)
									}
									{
										provider?.responseSelections?.map(
											(selection: any, index: number) => (
												<Box
													style={{ border: '1px solid #e5e5ea' }}
													key={index}
													p={pxToRem(8)}
													borderRadius='lg'
													mb={pxToRem(10)}
													position='relative'
												>
													<Tooltip label='Remove Response'>
														<CloseButton
															size='md'
															position='absolute'
															right={2}
															onClick={() => handleRemoveResponseSelection(index)}
														/>
													</Tooltip>


													<label htmlFor=''>
														{index + 1}
														{' '}
														- Item
													</label>

													<Box
														mt={pxToRem(15)}
														ml={pxToRem(10)}
													>
														<FormControl mb={pxToRem(20)}>
															<FormLabel>
																Json Path
															</FormLabel>
															<Input
																placeholder={selection.jsonPath}
																value={
																	formData?.responseSelections[index]?.jsonPath ||
																	''
																}
																name='jsonPath'
																onChange={
																	(e) => handleResponseSelectionChange(
																		index,
																		'jsonPath',
																		e.target.value
																	)
																}
															/>
														</FormControl>
														<FormControl mb={pxToRem(20)}>
															<FormLabel>
																Response Match
															</FormLabel>
															<Textarea
																name='responseMatch'
																value={
																	formData.responseSelections[index]
																		?.responseMatch || ''
																}
																onChange={
																	(e) => handleResponseSelectionChange(
																		index,
																		'responseMatch',
																		e.target.value
																	)
																}
																placeholder={selection.responseMatch}
															/>
														</FormControl>
														<FormControl mb={pxToRem(20)}>
															<FormLabel>
																X Path
															</FormLabel>
															<Input
																name='xPath'
																value={formData.responseSelections[index]?.xPath || ''}
																onChange={
																	(e) => handleResponseSelectionChange(
																		index,
																		'xPath',
																		e.target.value
																	)
																}
																placeholder={selection.xPath}
															/>
														</FormControl>
														<FormControl
															mb={pxToRem(20)}
														>
															<FormLabel>
																Match Type
															</FormLabel>

															<Select
																name='matchType'
																value={formData.responseSelections[index]?.matchType || 'Greedy'}
																onChange={
																	(e) => handleResponseSelectionChange(
																		index,
																		'matchType',
																		e.target.value
																	)
																}

															>
																<option value='greedy'>
																	Greedy
																</option>

																<option value='lazy'>
																	Lazy
																</option>
															</Select>

														</FormControl>
													</Box>
												</Box>
											)
										)
									}
								</FormControl>
								<Flex
									justifyContent='flex-end'
									mt={3}
									mb={4} >
									<Button
										colorScheme='blue'
										onClick={onOpen}>
										Add Response Selection
									</Button>
									<Modal
										size='lg'
										isOpen={isOpen}
										onClose={closeNewResponseModal}>
										<ModalOverlay />
										<ModalContent>
											<ModalHeader>
												Add New Response Selection
											</ModalHeader>
											<ModalCloseButton />
											<ModalBody>
												<FormControl mb={pxToRem(20)}>
													<FormLabel>
														Json Path
													</FormLabel>
													<Input
														placeholder='Json Path'
														value={newResponseSelectionData.jsonPath}
														name='jsonPath'
														onChange={handleNewResponseSelectionFieldChange}
													/>
												</FormControl>
												<FormControl
													mb={pxToRem(20)}
													isInvalid={newResponseSelectionValidationError?.responseMatch ?? false}>
													<FormLabel>
														Response Match
														<Tooltip
															whiteSpace='pre-line'
															label='The variable name should be in the format {{variable_name}}'
															placement='right'

														>
															<Icon
																as={QuestionIcon}
																w={4}
																h={4}
																mt='-3px'
																ml='10px'
																color='blue'
																cursor='pointer'
															/>
														</Tooltip>
													</FormLabel>
													<Textarea
														name='responseMatch'
														value={newResponseSelectionData.responseMatch}
														onChange={handleNewResponseSelectionFieldChange}
														placeholder='responseMatch'
														required
													/>
													<FormErrorMessage>
														Response match is required
													</FormErrorMessage>
												</FormControl>
												<FormControl mb={pxToRem(20)}>
													<FormLabel>
														X Path
													</FormLabel>
													<Input
														name='xPath'
														value={newResponseSelectionData.xPath}
														onChange={handleNewResponseSelectionFieldChange}
														placeholder='X Path'
													/>
												</FormControl>
												<FormControl
													id='matchType'
													mb={4}>
													<FormLabel>
														Match Type
													</FormLabel>

													<Select
														value={newResponseSelectionData?.matchType}
														onChange={handleNewResponseSelectionFieldChange}
														name='matchType'
													>
														<option value='greedy'>
															Greedy
														</option>

														<option value='lazy'>
															Lazy
														</option>
													</Select>

												</FormControl>
											</ModalBody>

											<ModalFooter>
												<Button
													mr={3}
													onClick={closeNewResponseModal}>
													Close
												</Button>
												<Button
													colorScheme='blue'
													onClick={addNewResponseSelection}>
													Add Response
												</Button>
											</ModalFooter>
										</ModalContent>
									</Modal>
								</Flex>
								<Alert
									borderRadius='lg'
									border={colorMode === 'dark' ? '1px solid #e5e5ea' : 'none'}
									status='warning'>
									{' '}
									Editing the response selection may break your provider, so please only edit it if you are sure about the Response Selections.
									{' '}
								</Alert>
							</Box>
							<div
								style={
									{
										display: 'flex',
										flexDirection: 'row',
										marginBottom: '20px',
									}
								}
							>
								<FormControl
									flex='1'
									mt='4'>
									<Box mb={pxToRem(20)}>
										<FormLabel htmlFor='android'>
											Android
										</FormLabel>
										<Input
											onChange={handleChange}
											value={formData?.userAgent?.android}
											placeholder={provider?.userAgent?.android || 'Add User Agent'}
											id='android'
											name='userAgent.android'
										/>
									</Box>
									<Box mb={pxToRem(10)}>
										<FormLabel htmlFor='ios'>
											iOS
										</FormLabel>
										<Input
											onChange={handleChange}
											value={formData?.userAgent?.ios}
											placeholder={provider?.userAgent?.ios || 'Add User Agent'}
											id='ios'
											name='userAgent.ios'
										/>
									</Box>
								</FormControl>
							</div>

							<Button
								mt={0}
								isLoading={isSubmitting}
								colorScheme='blue'
								onClick={submitHandler}>
								Submit
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
