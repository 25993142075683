/* eslint-disable @typescript-eslint/no-unused-vars */
import { SetStateAction, useState } from 'react'
import { MdSettings } from 'react-icons/md'
import { Box, Button, Flex, Icon, Link, Text } from '@chakra-ui/react'
import { VideoModal } from '../../../../components'
import { useAppDispatch } from '../../../../redux/hooks'
import { updateAppConnected } from '../../../../redux/providerBuilder/mobileClient'

interface InstallAppProps {
	setActiveStepInnerForm: (value: SetStateAction<number>) => void
}

export const InstallApp = (props: InstallAppProps) => {
	const dispatch = useAppDispatch()
	const [buttonLoading, setButtonLoading] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const handleNext = () => {

		setButtonLoading(true)
		dispatch(updateAppConnected(false))
		props.setActiveStepInnerForm(1)
	}

	return (
		<>
			<Box
				marginBottom='2.5rem'
				height='100%'>
				<Flex
					fontSize='lg'
					gap={4}
					mb='50px'>
					<Icon
						as={MdSettings}
						w={8}
						h={8}
						color='blue.500' />
					<Text as='span'>
						Download the
						{' '}
						<Link
							target='_blank'
							color='blue.500'
							href='https://docs.reclaimprotocol.org/install'
						>
							Reclaim Protocol App
						</Link>
						{' '}
						on your mobile device
					</Text>
				</Flex>
				<Box
					minH='50%'
					mt='60px'>
					<Flex
						ml='50px'
						alignItems='center'
						justifyContent='left'
						gap='120px'>
						<Link
							href='https://apps.apple.com/in/app/reclaim-protocol/id6475267895'
							isExternal
						>
							<Box
								as='img'
								src='https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg'
								alt='App Store'
								width='150px' // Set the width as needed
							/>
						</Link>
						<Link
							href='https://play.google.com/store/apps/details?id=com.reclaim.protocol&pcampaignid=web_share'
							isExternal
						>
							<Box
								as='img'
								src='https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg'
								alt='Play Store'
								width='150px' // Set the width as needed
							/>
						</Link>
					</Flex>
				</Box>
				<VideoModal
					isOpen={isOpen}
					closeModal={() => setIsOpen(false)}
					buttonText='Need Help? Watch Video Tutorial'
					modalTitle='Video Tutorial'
				/>
				<Button
					ml='15%'
					onClick={() => setIsOpen((open) => !open)}>
					Need Help? Watch Video Tutorial
				</Button>

				<Flex justifyContent='flex-end'>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={handleNext}
						colorScheme='blue'
						width='10%'
						mr='30px'
						isLoading={buttonLoading}
					>
						Next
					</Button>
				</Flex>
			</Box>
		</>
	)
}