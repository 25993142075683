import { LOG_SEARCH_QUERY_API } from '../constants'
import { getFirebaseIdToken } from '../utils'

const logSearchQuery = async(searchQuery: string) => {
	const accessToken = await getFirebaseIdToken()
	fetch(`${LOG_SEARCH_QUERY_API}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
		},
		body: JSON.stringify({ searchQuery: searchQuery }),
	})
		.then(response => response.json())
		.then(data => console.log('Search logged:', data))
		.catch((error) => console.error('Error logging search:', error))
}

function debounce<T extends(...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
	let timeout: ReturnType<typeof setTimeout> | undefined
	// eslint-disable-next-line func-names
	return function executedFunction(...args: Parameters<T>): void {
		const later = () => {
			clearTimeout(timeout as ReturnType<typeof setTimeout>)
			func(...args)
		}

		clearTimeout(timeout as ReturnType<typeof setTimeout>)
		timeout = setTimeout(later, wait)
	}
}

export {
	logSearchQuery,
	debounce
}
