import { Box } from '@chakra-ui/react'

export const Container = ({ children }: any) => {
	return (
		<Box
			maxW='1232px'
			margin='0 auto' >
			{children}
		</Box>
	)
}