/* eslint-disable */
import { Outlet, useLocation } from "react-router-dom"
import { Box } from "@chakra-ui/react"
//import { SideBar } from '../common/sidebar/sidebar.component'
import Navbar from "../common/navbar/navbar.component"
import { ChakraBanner } from "../banner/banner.component"

export const Layout = () => {
  const { pathname } = useLocation()

  return (
    <Box bgColor='backgroundBody'>
      {/* <Navbar /> */}
      {/* <ChakraBanner
        message='We are currently undergoing rapid changes, including some breaking updates. To ensure compatibility and access the latest features, please upgrade to the most recent version of the SDK.'
        linkText='Upgrade now'
        link='https://docs.reclaimprotocol.org/node/quickstart'
      /> */}

      {pathname !== "/" && pathname !== "/welcome" && <Navbar />}
      <Box
        height='100%'
        minHeight='100vh'
        maxWidth={
          pathname !== "/" && pathname !== "/welcome" ? "1170px" : "100%"
        }
        margin='0 auto'
      >
        <Outlet />
      </Box>
    </Box>
  )
}
