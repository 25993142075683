import { RETRY_MANUAL_VERIFICATION_WEBHOOK, UPDATE_MANUAL_VERIFICATION_SESSION } from '../constants'


async function updateManualVerificationSession(accessToken: string, sessionId: string, status: string, paramValues: any = null): Promise<any> {
	if (!accessToken) {
		return { isSuccess: false, message: 'Access token is required' }
	}

	try {
		let payload: any = {
			status, sessionId
		}

		if (paramValues) {
			payload = {
				...payload,
				paramValues
			}
		}

		const data = await fetch(`${UPDATE_MANUAL_VERIFICATION_SESSION(sessionId)}`, {
			headers: {
				authorization: `Bearer ${accessToken}`,
				'content-type': 'application/json',
			},
			method: 'PUT',
			body: JSON.stringify(payload),
		})
		const message = await data.json()

		return message
	} catch (e) {
		return { isSuccess: false, message: 'Error while fetching provider' }
	}
}

async function retryManualVerificationWebhook(accessToken: string, sessionId: string, paramValues: any): Promise<any> {
	if (!accessToken) {
		return { isSuccess: false, message: 'Access token is required' }
	}

	try {
		const payload: any = {
			sessionId,
			paramValues
		}

		const data = await fetch(`${RETRY_MANUAL_VERIFICATION_WEBHOOK(sessionId)}`, {
			headers: {
				authorization: `Bearer ${accessToken}`,
				'content-type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify(payload),
		})
		const message = await data.json()

		return message
	} catch (e) {
		return { isSuccess: false, message: 'Error while retrying webhook' }
	}
}

export {
	updateManualVerificationSession,
	retryManualVerificationWebhook
}