/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EditIcon } from '@chakra-ui/icons'
import {
	Avatar,
	Badge,
	Box,
	Heading,
	HStack,
	IconButton,
	Image,
	Switch,
	Text,
	Tooltip,
	useColorMode,
	useDisclosure,
	useToast,
} from '@chakra-ui/react'
import { getAuth } from 'firebase/auth'
//@ts-ignore
import Verified from '../../../assets/icons/verified.svg'
import { app } from '../../../configs/firebaseApp'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
	getAllActiveHttpsProviders,
	getAllNonApprovedProviders,
	//getAllNonApprovedProviders,
	updateHttpsProvider,
} from '../../../redux/httpsProviders/actions'
//import { getNonActiveProviders } from '../../../redux/httpsProviders/selectors'
import { HttpsProvider } from '../../../redux/httpsProviders/types'
import { getUserType } from '../../../redux/user/selectors'
import { pxToRem } from '../../../utils'
import { ProviderDetails } from './provider-details.component'
import { ProviderCardSkeleton } from './provider-skeleton.component'
interface ProviderCardProps {
	providerData?: HttpsProvider | any
	isLoading?: boolean
}

export const ProviderCard = ({
	providerData,
	isLoading,
}: ProviderCardProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const dispatch = useAppDispatch()
	const toast = useToast()
	const [toggleSwitch, setToggleSwitch] = useState(providerData?.isActive)
	const loggedInUser = getAuth(app).currentUser?.uid

	const isProviderCreatedBySameUser = providerData?.creatorUid === loggedInUser

	useEffect(() => {
		setToggleSwitch(providerData?.isActive)
		//dispatch(getAllCommunityProviders())
	}, [providerData?.isActive])


	const handleSwitchChange = async (e: any, provider: HttpsProvider) => {
		e.stopPropagation()
		setToggleSwitch(!toggleSwitch)
		const status = await dispatch(
			updateHttpsProvider({
				httpProviderId: provider?.httpProviderId,
				isActive: !toggleSwitch,
			})
		)
		//@ts-ignore
		if (status?.payload?.isSuccess) {
			toast({
				title: 'Provider status updated successfully',
				status: 'success',
				duration: 3000,
				isClosable: true,

			})
		}

		await dispatch(getAllNonApprovedProviders())
		await dispatch(getAllActiveHttpsProviders())
	}

	const userType = useAppSelector(getUserType)
	const { colorMode } = useColorMode()
	const navigate = useNavigate()

	return (
		<>
			{
				isLoading ? (
					<ProviderCardSkeleton />
				) : (
					<div
						onClick={
							(e) => {
								e.stopPropagation()
								onOpen()
							}
						}
						style={{ cursor: 'pointer' }}
					>
						<Box
							border={
								`1px solid ${colorMode === 'dark' ? 'transparent' : '#e5e5ea'
								}`
							}
							position='relative'
							borderRadius='lg'
							// bg={
							// 	colorMode === 'dark'
							// 		? '#131417'
							// 		: 'linear-gradient(180deg,transparent,hsla(0,0%,95%,.288) 47.39%),linear-gradient(89.84deg,rgba(230,36,174,.15) .14%,rgba(94,58,255,.15) 16.96%,rgba(10,136,255,.15) 34.66%,rgba(75,191,80,.15) 50.12%,rgba(137,206,0,.15) 66.22%,rgba(239,183,0,.15) 82%,rgba(246,73,0,.15) 99.9%),linear-gradient(0deg,hsla(0,0%,100%,.15),hsla(0,0%,100%,.15))'
							// }
							bg={colorMode === 'dark' ? '#131417' : '#fff'}
							p='16px'
							_hover={
								{
									borderColor: 'blue.500',
									//backgroundColor: '#feede6',
									//backgroundImage: 'linear-gradient(12deg, #fff 50%, hsla(0, 0%, 100%, 0))',
								}
							}
							minH={pxToRem(143)}
							transition='background 100ms ease, border-color 100ms ease'
							alignItems='center'
						>
							<HStack justifyContent='space-between'>
								<HStack
									position='relative'
									alignItems='flex-start'
									justifyContent='flex-start'
								>
									<Avatar
										name={providerData?.name}
										src={providerData?.logoUrl}
										width={pxToRem(30)}
										height={pxToRem(30)}
										size='sm'
										textAlign='center'
									//objectFit='cover'
									/>
									{
										providerData?.isVerified && (
											<Tooltip
												label='Verified Official providers maintained by Reclaim Team'
												placement='top'
												hasArrow
											>
												<Image
													position='absolute'
													top={2.5}
													left={5}
													src={Verified}
													alt='verified'
													width={pxToRem(22)}
													height={pxToRem(22)}
												/>
											</Tooltip>
										)
									}
									<HStack />
								</HStack>
								<HStack>


									{
										(userType === 'ADMIN' || isProviderCreatedBySameUser) && (
											<IconButton
												onClick={
													(e) => {
														e.stopPropagation()
														navigate(`/provider/${providerData?.httpProviderId}`)
													}
												}
												variant='ghost'
												aria-label='' >
												<EditIcon />
											</IconButton>
										)
									}
									{
										userType === 'ADMIN' && (
											<span onClick={(e) => e.stopPropagation()}>
												<Switch
													isChecked={toggleSwitch}
													onChange={(e) => handleSwitchChange(e, providerData)}
													isDisabled={userType !== 'ADMIN'}
												/>
											</span>
										)
									}
									{
										!providerData.isApproved && (
											<Badge colorScheme='red'>
												Inactive
											</Badge>
										)
									}
								</HStack>
							</HStack>
							<Box cursor='pointer'>
								<Heading
									as='h2'
									mt='16px'
									fontSize={pxToRem(14)}>
									{providerData?.name}
								</Heading>
								<Box>
									<Text
										mt='6px'
										fontSize={pxToRem(14)}>
										{
											providerData?.description
												? providerData?.description
												: `Proves ${providerData?.name
													? providerData.name
													: providerData?.providerDisplayName
												} Data`
										}
									</Text>
								</Box>
								<ProviderDetails
									onClose={onClose}
									isOpen={isOpen}
									providerDetail={providerData}
								/>
							</Box>
							{
								providerData.providerType === 'PRIVATE' && (
									<Badge
										bottom={0}
										right={0}
										fontSize={pxToRem(12)}
										mb={pxToRem(12)}
										mr={pxToRem(12)}
										colorScheme='green'
										variant='outline'
										position='absolute'
									>
										Private
									</Badge>
								)
							}
						</Box>
					</div>
				)
			}
		</>
	)
}
