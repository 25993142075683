/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react'
import { FiCopy } from 'react-icons/fi'
//import { Text } from "../text/text.component"
import { IoMdCheckmark } from 'react-icons/io'
import {
	Box,
	Code,
	CodeProps,
	Icon,
	IconButton,
	Text,
	useClipboard,
	useColorModeValue,
} from '@chakra-ui/react'
import Highlight, { defaultProps } from 'prism-react-renderer'
import Dracula from 'prism-react-renderer/themes/nightOwl'
import vsDark from 'prism-react-renderer/themes/vsDark'
//import vsLight from 'prism-react-renderer/themes/vsLight'
import Prism from 'prismjs'
//import { AmplitudeAnalyticsContext } from '../../../contexts/useAnalytics'

const darkThemeDefault = Dracula
//const lightThemeDefault = 'vsLight' //TODO: temp fix

type PrismTheme = typeof darkThemeDefault

// add solidity lang support for code
;(typeof global !== 'undefined' ? global : window).Prism = Prism
require('prismjs/components/prism-solidity')
// end add solidity support

export interface CodeBlockProps extends Omit<CodeProps, 'size'> {
  code: string
  codeValue?: string
  language?: string
  canCopy?: boolean
  framework?: string
  wrap?: boolean
  prefix?: string
  darkTheme?: PrismTheme
  lightTheme?: PrismTheme
}
export const CodeBlock: React.FC<CodeBlockProps> = ({
	code,
	codeValue,
	language = 'javascript',
	px = 4,
	py = 2,
	w = 'full',
	borderRadius = 'md',
	borderColor = 'borderColor',
	borderWidth = '0px',
	fontFamily = 'mono',
	backgroundColor,
	prefix,
	canCopy = true,
	wrap = true,
	framework,
	...restCodeProps
}) => {
	const { onCopy, hasCopied, setValue } = useClipboard(codeValue || code)

	//const { logEvent } = useContext(AmplitudeAnalyticsContext)

	useEffect(() => {
		if(code) {
			setValue(codeValue || code)
		}
	}, [code, codeValue, setValue])

	return (
		<Highlight
			{...defaultProps}
			code={prefix ? `${prefix} ${code}` : code}
			//@ts-ignore
			language={language ? language : 'javascript'}
			//theme={darkThemeDefault}
			theme={darkThemeDefault}
		>
			{
				({ className, style, tokens, getLineProps, getTokenProps }: any) => (
					<Text
						borderRadius={borderRadius}
						py={py}
						px={px}
						w={w}
						borderWidth={borderWidth}
						borderColor={borderColor}
						position='relative'
						className={className}
						style={style}
						fontFamily={fontFamily}
						whiteSpace={wrap ? 'pre-wrap' : 'pre'}
						{...restCodeProps}
						as={Code}
					>
						{
							canCopy && code && (
								<IconButton
									mr={-2}
									onClick={onCopy}
									float='right'
									aria-label='Copy'
									borderRadius='md'
									variant='ghost'
									colorScheme='gray'
									size='sm'
									icon={
										<Icon
											as={hasCopied ? IoMdCheckmark : FiCopy}
											fill={hasCopied ? 'green.500' : undefined}
										/>
									}
								/>
							)
						}
						<Box
							as='span'
							display='block'
							my={1}
							color='heading'
							h='full'>
							{
								tokens.map((line: any[], i: any) => (
									// eslint-disable-next-line react/jsx-key
									<Box {...getLineProps({ line, key: i })}>
										{
											line.map((token: any, key: any) => (
												// eslint-disable-next-line react/jsx-key
												<span {...getTokenProps({ token, key })} />
											))
										}
									</Box>
								))
							}
						</Box>
					</Text>
				)
			}
		</Highlight>
	)
}
