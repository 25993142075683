import {
	Action,
	AsyncThunkPayloadCreator,
	createAsyncThunk,
	ThunkDispatch,
} from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { AppDispatch, RootState } from './store'

export const typedCreateAsyncThunk = <ReturnType = void, ThunkArg = void>(
	typePrefix: string,
	payloadCreator: AsyncThunkPayloadCreator<
    ReturnType,
    ThunkArg,
    { dispatch: AppDispatch, state: RootState }
  >
) => createAsyncThunk(typePrefix, payloadCreator)

export type ThunkAction<
  R, // Return type of the thunk function
  S, // state type used by getState
  E, // any "extra argument" injected into the thunk
  A extends Action // known types of actions that can be dispatched
> = (
  dispatch: ThunkDispatch<S, E, A>,
  getState: () => S,
  extraArgument: E
) => R;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
