import { FaHistory, FaTrash } from 'react-icons/fa'
import { FiSettings, FiShield, FiUser } from 'react-icons/fi'
import { Box, Flex, Grid, Icon, Text, useColorMode } from '@chakra-ui/react'

interface SettingListProps {
  name: string
  email: string
  icon: any
  color?: string
}

const SettingList: React.FC<SettingListProps> = ({
	name,
	email,
	icon,
	color,
}) => {
	return (
		<Flex
			alignItems='center'
			mt='30px'
			cursor='pointer'>
			<Box
				borderRadius='50%'
				h='40px'
				w='40px'
				backgroundColor={color ? color : 'orange'}
				p='2'
				display='flex'
				alignItems='center'
				justifyContent='center'
			>
				<Icon
					as={icon}
					h='18px'
					w='18px'
					color='white' />
			</Box>
			<Box ml='3'>
				<Text
					fontSize='sm'
					fontWeight='semibold'>
					{name}
				</Text>
				<Text
					fontSize='sm'
					color='gray.500'>
					{email}
				</Text>
			</Box>
		</Flex>
	)
}

export const SettingsScreen = () => {
	const { colorMode } = useColorMode()
	return (
		<Box p='32px'>
			<Text
				fontSize='md'
				fontWeight='bold'>
				Settings
			</Text>

			<Box mt='40px'>
				<Box mb='60px'>
					<Text
						fontSize='sm'
						fontWeight='semibold'
						textTransform='uppercase'>
						Account
					</Text>
					<Grid
						templateColumns='repeat(3, 1fr)'
						gap={3}
						mt='10px'
						mb='20px'
						borderTop={
							`1px solid ${
								colorMode === 'dark' ? '#22252a' : '#e6e9eb'
							}`
						}
					>
						<SettingList
							name='General'
							email='Edit your profile, bio, and more'
							icon={FiSettings}
						/>
						<SettingList
							name='koushith'
							email='Kousth97@gmail.com'
							icon={FiUser}
							color='green'
						/>
						<SettingList
							name='koushith'
							email='Kousth97@gmail.com'
							icon={FiUser}
						/>
						<SettingList
							name='koushith'
							email='Kousth97@gmail.com'
							icon={FiUser}
							color='red.200'
						/>
					</Grid>
				</Box>

				<Box mb='60px'>
					<Text
						fontSize='sm'
						fontWeight='semibold'
						textTransform='uppercase'>
						Advanced
					</Text>
					<Grid
						templateColumns='repeat(3, 1fr)'
						gap={3}
						mt='10px'
						mb='20px'
						borderTop={
							`1px solid ${
								colorMode === 'dark' ? '#22252a' : '#e6e9eb'
							}`
						}
					>
						<SettingList
							name='koushith'
							email='Kousth97@gmail.com'
							icon={FiUser}
						/>

						<SettingList
							name='koushith'
							email='Kousth97@gmail.com'
							icon={FiUser}
							color='red.200'
						/>
					</Grid>
				</Box>

				<Box mb='60px'>
					<Text
						fontSize='sm'
						fontWeight='semibold'
						textTransform='uppercase'>
						Advanced
					</Text>
					<Grid
						templateColumns='repeat(3, 1fr)'
						gap={3}
						mt='10px'
						mb='20px'
						borderTop={
							`1px solid ${
								colorMode === 'dark' ? '#22252a' : '#e6e9eb'
							}`
						}
					>
						<SettingList
							name='Reclaim SDK Change Log'
							email='View the latest changes from SDK'
							icon={FiShield}
							color='teal'
						/>
						<SettingList
							name='DevTool Change Log'
							email='View the latest changes'
							icon={FaHistory}
							color='pink'
						/>

						<SettingList
							name='Delete your Account'
							email='Back to square one, eh?'
							icon={FaTrash}
							color='red.800'
						/>
					</Grid>
				</Box>
			</Box>
		</Box>
	)
}
