import { CHAT_GPT } from './constants'
import { getFirebaseIdToken } from './utils'

// Make post api call for backend api using fetch
export const chatGPT = async(context: string, promptStr: string) => {
	try {
		const bodyParams = JSON.stringify({ contextStr: context, promptStr: promptStr })
		const accessToken = await getFirebaseIdToken()
		const response = await fetch(CHAT_GPT, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'sec-fetch-mode': 'cors',
				'Content-Length': bodyParams.length.toString(),
				Authorization: `Bearer ${accessToken}`,
			},
			body: bodyParams,
		})
		const data = await response.json()
		return data
	} catch(err) {
		return { status: 'error', message: 'Some error occured while accessing backend API' }
	}
}