import { useEffect, useState } from 'react'
import { ViewIcon } from '@chakra-ui/icons'
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, HStack, IconButton, Input, Stat, StatArrow, StatGroup, StatHelpText, StatLabel, StatNumber, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react'
import { VideoModal } from '../../../../components'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { getAllNetworkRequest, getPagesVisted } from '../../../../redux/providerBuilder/networkRequestStore/selectors'
import { resetSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances'
import { setSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest'
import { getSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest/selectors'
import { pxToRem } from '../../../../utils'
import { getUniqueItems } from '../../../../utils/provider-builder/instance-selection-utils'
import { RequestDetails } from './request-details.component'

interface SelectRequestProps {
    setActiveStepInnerForm: (value: any) => void
    setSearchQueryInput: (value: string) => void
    searchQueryInput: string
}

export const SelectRequest = (props: SelectRequestProps) => {
	const dispatch = useAppDispatch()

	const pagesVisited = useAppSelector(getPagesVisted)
	const networkRequests = useAppSelector(getAllNetworkRequest)
	const selectedNetworkRequest = useAppSelector(getSelectedNetworkRequest)

	const { setActiveStepInnerForm, setSearchQueryInput, searchQueryInput } = props

	const { isOpen, onOpen, onClose } = useDisclosure()
	const [buttonLoading, setButtonLoading] = useState(false)
	const [filteredNetworkRequestStore, setFilteredNetworkRequestStore] = useState<any>([...networkRequests])

	const [videoOpen, setVideoOpen] = useState(false)

	useEffect(() => {
		handleSearch(searchQueryInput)
	}, [networkRequests])

	function handleSearch(searchString: string): void {
		setSearchQueryInput(searchString)
		const filteredRequests = networkRequests.filter((networkReq: any) => {
			return (
				networkReq.url.includes(searchString) ||
                (networkReq.response.body
                	? networkReq.response?.body.includes(searchString)
                	: false)
			)
		})
		setFilteredNetworkRequestStore([...filteredRequests])
	}

	function handleRequestClick(networkRequest: any) {
		dispatch(setSelectedNetworkRequest(networkRequest))
		dispatch(resetSelectedInstances())
	}

	function handleNext() {
		setButtonLoading(true)
		setActiveStepInnerForm(1)
	}

	return (
		<>
			<Box
				width='80%'
				height='40%'>
				<StatGroup>
					<Stat>
						<StatLabel>
							Pages Visited
						</StatLabel>
						<StatNumber>
							{getUniqueItems(pagesVisited).length}
						</StatNumber>
						<StatHelpText>
							<StatArrow type='increase' />
							Number of unique pages visited
						</StatHelpText>
					</Stat>

					<Stat>
						<StatLabel>
							Requests Captured
						</StatLabel>
						<StatNumber>
							{networkRequests.length}
						</StatNumber>
						<StatHelpText>
							<StatArrow type='increase' />
							Number of Requests Captured by the dev tool
						</StatHelpText>
					</Stat>
				</StatGroup>
				<Box
					marginTop='1.5rem'
					overflowX='auto'
					overflowY='auto'>
					<FormControl
						marginTop='1.5rem'
						marginBottom='1rem'>
						<FormLabel>
							Search the data you want to verify
						</FormLabel>
						<HStack>
							<Input
								placeholder='1 Karma, email id, username'
								value={searchQueryInput}
								onChange={(e) => handleSearch(e.target.value)}
							/>
						</HStack>
					</FormControl>
					<Box
						scrollMarginLeft={10}
						overflow='auto'
						maxHeight='500px'
						borderWidth={1}
					>
						<TableContainer>
							<Table
								variant='simple'
								border='ActiveBorder'
								borderRadius='4px'
								borderColor='black'
							>
								<Thead>
									<Tr>
										<Th>
											View
										</Th>
										<Th>
											Content Type
										</Th>

										<Th>
											Request URL
										</Th>
										<Th>
											Page URL
										</Th>
										<Th>
											Request Type
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									{
										filteredNetworkRequestStore.map((e: any) => (
											<Tr
												key={e.id}
												onClick={
													() => {
														handleRequestClick(e)
														// handleSelectNetworkRequest(e)
													}
												}
												_hover={
													selectedNetworkRequest?.id === e.id
														? { bg: 'blue.200' }
														: { bg: 'gray.100' }
												} // Background color when hovered
												bg={
													selectedNetworkRequest?.id === e.id
														? 'blue.200'
														: 'white'
												} // Background color when selected
												cursor='pointer'
												color={
													selectedNetworkRequest?.id === e.id
														? 'gray.100'
														: 'black'
												}
											>
												<Td
													overflow='hidden'
													whiteSpace='nowrap'
													textOverflow='ellipsis'
												>
													<Flex
														justifyContent='center'
														alignItems='center'
														padding='2px'
													>
														<IconButton
															size=''
															variant={
																selectedNetworkRequest?.id === e.id
																	? 'solid'
																	: 'outline'
															}
															colorScheme={
																selectedNetworkRequest?.id === e.id
																	? 'black'
																	: 'blue'
															}
															icon={<ViewIcon />}
															onClick={onOpen}
															aria-label=''
														/>
													</Flex>
												</Td>
												<Td
													maxW='200px'
													overflow='hidden'
													whiteSpace='nowrap'
													textOverflow='ellipsis'
												>
													{e.contentType}
												</Td>
												<Td
													maxW='400px'
													overflow='hidden'
													whiteSpace='nowrap'
													textOverflow='ellipsis'
												>
													{e.url}
												</Td>
												<Td
													maxW='300px'
													overflow='hidden'
													whiteSpace='nowrap'
													textOverflow='ellipsis'
												>
													{e.currentPageUrl}
												</Td>
												<Td
													maxW='100px'
													overflow='hidden'
													whiteSpace='nowrap'
													textOverflow='ellipsis'
												>
													{e.requestType}
												</Td>
											</Tr>
										))
									}
								</Tbody>
							</Table>
						</TableContainer>
					</Box>
					{
						selectedNetworkRequest && (
							<Drawer
								isOpen={isOpen}
								placement='right'
								onClose={
									() => {
										onClose()
									}
								}
								size='xl'
							>
								<DrawerOverlay />

								<DrawerContent>
									<DrawerCloseButton />
									<DrawerHeader>
										<Flex>
											<Text
												fontSize='2xl'
												marginTop='30px'
												marginBottom='10px'
												fontFamily={"'Martian Mono', monospace"}
											>
												Response Data
											</Text>
										</Flex>
									</DrawerHeader>
									<DrawerBody>
										<RequestDetails />
									</DrawerBody>
									<DrawerFooter>
										<Button
											colorScheme='blue'
											mr={3}
											onClick={onClose}>
											Close
										</Button>
									</DrawerFooter>
								</DrawerContent>
							</Drawer>
						)
					}
				</Box>
				<VideoModal
					isOpen={videoOpen}
					closeModal={() => setVideoOpen(false)}
					buttonText='Need Help? Watch Video Tutorial'
					modalTitle='Video Tutorial'
				/>
				<HStack
					justifyContent='flex-end'
					mt={pxToRem(30)}>
					<Button onClick={() => setVideoOpen((open) => !open)}>
						{' '}
						Need Help? Watch Tutorial
					</Button>
					<Tooltip
						label={
							selectedNetworkRequest?.url
								? ''
								: 'Please select a network request to proceed'
						}
					>
						<Button
							variant='solid'
							justifySelf='right'
							onClick={handleNext}
							colorScheme='blue'
							width='10%'
							isLoading={buttonLoading}
							isDisabled={!selectedNetworkRequest?.url}
						>
							Next
						</Button>
					</Tooltip>
				</HStack>
			</Box>
		</>
	)
}