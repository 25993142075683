import { useEffect, useState } from 'react'
import { FiRefreshCw } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBackIcon } from '@chakra-ui/icons'
import {
	Box,
	Button,
	Card,
	CardBody,
	Flex,
	Heading,
	IconButton,
	Input,
	Table,
	TableContainer,
	Tag,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tooltip,
	Tr,
	useDisclosure,
	useToast,
	VStack,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { getManualVerificationSelectedSessionProviderData } from '../../../../redux/manualVerification/providerDetails/actions'
import { getSelectedManualVerificationSessionProviderData } from '../../../../redux/manualVerification/providerDetails/selectors'
import { resetSelectedManualVerificationSessionData, setSelectRequestScreenClickedParamerterName, setSelectRequestScreenSearchQuery } from '../../../../redux/manualVerification/sessionsDetails'
import { fetchManualVerificationSessionData } from '../../../../redux/manualVerification/sessionsDetails/actions'
import { getSelectedManualVerificationSessionData } from '../../../../redux/manualVerification/sessionsDetails/selectors'
import { setSelectedSessionId, updateActiveStep as updateManualVerificationActiveStep } from '../../../../redux/manualVerification/stepperDetails'
import { getManualVerificationSelectedSessionId } from '../../../../redux/manualVerification/stepperDetails/selectors'
import { resetSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances'
import { setSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest'
import { isJSONParsable } from '../../../../utils/is-json-parsable'
import { arrayToObject, createParamValuesFromExistingData, extractParamsFromBody, extractParamsValuesFromURL, stringifyWithBooleanConversion } from '../../../../utils/manual-verification-session/create-param-values'
import { filterRequestBodyByRegex, filterRequestUrlByRegex, sortByContentType } from '../../../../utils/manual-verification-session/filter-requests'
import { statusTagColors, statusUpperCaseToLowerCase } from '../../../../utils/manual-verification-session/manual-verification-constants'
import { retryManualVerificationWebhook, updateManualVerificationSession } from '../../../../utils/manual-verification-session/update-manual-verification-session-utils'
import { getManualVerifcationCapturedNetworkRequests } from '../../../../utils/manual-verification-session/utils'
import { NetworkRequest } from '../../../../utils/types'
import { getFirebaseIdToken } from '../../../../utils/utils'
import { ManualVerificationSessionDetailsCard } from './session-details-card.component'
import { ManualVerificationWebhookDetailsModal } from './webhook-details-modal.component'


export const ManualVerificationSelectRequests = () => {

	const params = useParams()
	const navigate = useNavigate()

	const { isOpen: isWebhookDetailsModalOpen,
		onOpen: onWebhookDetailsModalOpen,
		onClose: onWebhookDetailsModalClose } = useDisclosure()

	const sessionId = useAppSelector(getManualVerificationSelectedSessionId)
	const [decryptedData, setDecryptedData] = useState<NetworkRequest[]>([])
	const [filteredNetworkRequestStore, setFilteredNetworkRequestStore] = useState<NetworkRequest[]>([...decryptedData])
	const [searchQueryInput, setSearchQueryInput] = useState('')
	const [webhookRetryBtnLoading, setWebhookRetryBtnLoading] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const selectedSessionData = useAppSelector(getSelectedManualVerificationSessionData)
	const httpProviderId = selectedSessionData?.httpProviderId

	const selectedManualVerificationSessionProviderData = useAppSelector(getSelectedManualVerificationSessionProviderData)

	const toast = useToast()

	useEffect(() => {
		getCapturedRequests()
	}, [])

	useEffect(() => {
		dispatch(fetchManualVerificationSessionData({ sessionId }))
	}, [])

	useEffect(() => {
		if (httpProviderId) {
			dispatch(getManualVerificationSelectedSessionProviderData({ httpProviderId }))
		}
	}, [httpProviderId])


	async function getCapturedRequests() {

		try {

			const result = await getManualVerifcationCapturedNetworkRequests(sessionId)

			if (!result?.isSuccess) {
				return toast({
					title: 'Error',
					description: 'Unable to fetch network requests from db',
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
			}

			let decrypted = result?.data

			for (let i = 0; i < decrypted?.length; i++) {
				const data = decrypted[i]
				decrypted[i].contentType = isJSONParsable(data?.responseBody ?? '') ? 'application/json' : 'text/html'
			}

			decrypted = sortByContentType(decrypted)
			setDecryptedData(decrypted)
			setFilteredNetworkRequestStore(decrypted)
		} catch (error) {
			console.error('Error getting network requests from db:', error)
		}
	}

	function handleRequestClick(networkRequest: any) {
		const tempNetworkRequest = {
			...networkRequest,
			response: { body: networkRequest.responseBody },
			request: { body: networkRequest.requestBody },
		}
		dispatch(setSelectedNetworkRequest(tempNetworkRequest))
		dispatch(resetSelectedInstances())
		createProviderHandle()
	}

	function handleGoBack() {
		dispatch(setSelectedSessionId(''))
		dispatch(setSelectRequestScreenSearchQuery(''))
		dispatch(resetSelectedManualVerificationSessionData())

		if (params?.sessionId?.length) {
			navigate(-1)
		} else {
			dispatch(updateManualVerificationActiveStep(2))
		}
	}

	function handleSearch(searchString: string): void {

		const lowerCaseSearchString = searchString.toLowerCase()

		const filteredRequests = decryptedData.filter((networkReq: NetworkRequest) => {
			const urlMatch = networkReq.url.toLowerCase().includes(lowerCaseSearchString)
			const responseBodyMatch = networkReq.responseBody
				? networkReq.responseBody.toLowerCase().includes(lowerCaseSearchString)
				: false

			return urlMatch || responseBodyMatch
		})

		setSearchQueryInput(searchString)
		setFilteredNetworkRequestStore(filteredRequests)
	}

	function createProviderHandle(): void {
		dispatch(setSelectRequestScreenSearchQuery(searchQueryInput))
		dispatch(updateManualVerificationActiveStep(4))
	}

	async function makeClaimHandler(status: string, withParamsValues: boolean = false) {
		try {
			let paramValues = {}
			if (withParamsValues) {
				const templateURL: string = selectedManualVerificationSessionProviderData?.url ?? ''
				const templateBody: string = selectedManualVerificationSessionProviderData?.bodySniff.template ?? ''
				const allMatchingRequests = decryptedData.filter((req) => {
					try {
						const reqBody = stringifyWithBooleanConversion(JSON.parse(req.requestBody))
						return filterRequestUrlByRegex(req.url, templateURL) && filterRequestBodyByRegex(reqBody, templateBody) && req.method === selectedManualVerificationSessionProviderData?.method
					} catch (error) {
						return filterRequestUrlByRegex(req.url, templateURL) && filterRequestBodyByRegex(req.requestBody, templateBody) && req.method === selectedManualVerificationSessionProviderData?.method
					}

				})

				if (!allMatchingRequests?.length) {
					return toast({
						title: 'Error',
						description: 'Unable to find URL',
						status: 'error',
						duration: 3000,
						isClosable: true,
						position: 'top-right',
					})
				}

				const filteredURL = allMatchingRequests[0]
				const extractedURLParams = extractParamsValuesFromURL(filteredURL?.url, templateURL)
				const extractedBodyParams = extractParamsFromBody(filteredURL?.requestBody, templateBody)
				const extractedParameterValues = arrayToObject(selectedSessionData?.parameters ?? [])

				paramValues = {
					...extractedURLParams ?? {},
					...extractedBodyParams ?? {},
					...extractedParameterValues ?? {}
				}
			}

			const accessToken = await getFirebaseIdToken() ?? ''
			let message: any = ''
			if (withParamsValues) {
				message = await updateManualVerificationSession(accessToken, sessionId, status, paramValues)
			} else {
				message = await updateManualVerificationSession(accessToken, sessionId, status)
			}

			if (!message.isSuccess) {
				toast({
					title: status === 'VERIFIED' ? 'Claim Verification Failed' : 'Claim Rejection Failed',
					description: message.message,
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
			} else {
				toast({
					title: status === 'VERIFIED' ? 'Claim Verification Successfull' : 'Claim Rejection Successfull',
					description: message.message,
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				handleGoBack()
			}

		} catch (error) {
			console.error(error)

			toast({
				title: 'verification Failed',
				description: 'Unknown Error while verifying',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}

	}

	async function handleRetryWebhook() {
		try {
			setWebhookRetryBtnLoading(true)
			const templateURL: string = selectedManualVerificationSessionProviderData?.url ?? ''
			const templateBody: string = selectedManualVerificationSessionProviderData?.bodySniff.template ?? ''

			const { isSuccess, errorMessage, paramValues } = createParamValuesFromExistingData({
				templateURL,
				templateBody,
				requestsData: decryptedData,
				method: selectedManualVerificationSessionProviderData?.method,
				parameters: selectedSessionData?.parameters
			})

			if (!isSuccess) {
				onWebhookDetailsModalClose()
				return toast({
					title: 'Error',
					description: errorMessage ?? 'Unable to find URL',
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
			}

			const accessToken = await getFirebaseIdToken() ?? ''

			const message = await retryManualVerificationWebhook(accessToken, sessionId, paramValues)
			if (!message.isSuccess) {
				toast({
					title: 'Failed',
					description: message.message,
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
			} else {
				toast({
					title: 'Successfull',
					description: message.message,
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
			}
		} catch (error) {
			console.error(error)
			toast({
				title: 'Error',
				description: 'Something went wrong while retrying',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		} finally {
			dispatch(fetchManualVerificationSessionData({ sessionId }))
			setWebhookRetryBtnLoading(false)
			onWebhookDetailsModalClose()
		}
	}

	async function handleRefreshAllData() {
		setLoading(true)
		await dispatch(fetchManualVerificationSessionData({ sessionId }))
		await dispatch(getManualVerificationSelectedSessionProviderData({ httpProviderId }))
		setLoading(false)
	}

	return (
		<Card>
			<CardBody position='relative'>
				<Box
					width='100%'
					height='100%'>
					<VStack>
						<Box
							borderBottom='2px solid #F2F4F7'
							w='full'
							pb={10}
							mb={5}
						>
							<Flex mt={8}>
								<Box
									position='absolute'
									right={5}
									top={2}>
									<Tooltip label='refresh'>
										<IconButton
											isLoading={loading}
											variant='outline'
											size='sm'
											icon={<FiRefreshCw />}
											onClick={handleRefreshAllData}
											aria-label='Go Back' />
									</Tooltip>
								</Box>
								<Box flex={0.8}>
									<Flex
										alignItems='center'
										mb={2}
										gap={3}>
										<IconButton
											variant='outline'
											size='sm'
											icon={<ArrowBackIcon />}
											onClick={handleGoBack}
											aria-label='Go Back' />
										<Heading
											fontSize='large'
										>
											Requests Captured
										</Heading>
									</Flex>
									<Text
										fontSize='medium'
										lineHeight='24px'
										color='#98A2B3'
									>
										This data represents the amount of events captured by
										<br />
										our dev tool from the Reclaim App
									</Text>
									<Flex
										direction='column'
										alignItems='flex-start'
										my={3}
										gap={2}>
										<Flex
											alignItems='center'
											gap={10}
											w='full'>
											{
												selectedSessionData?.webhooks?.length > 0 && (
													<Flex
														alignItems='center'
														gap={3}>
														<Heading
															fontSize='medium'
														>
															Webhook Status
														</Heading>
														<Tag
															borderRadius={5}
															fontWeight='bold'
															colorScheme={statusTagColors?.[selectedSessionData?.webhooks?.[0]?.status]}
															px={3}
															textTransform='capitalize'>
															{statusUpperCaseToLowerCase?.[selectedSessionData?.webhooks?.[0]?.status] ?? '-'}
														</Tag>
													</Flex>
												)
											}
										</Flex>
										<Flex
											alignItems='center'
											gap={10}
											w='full'>
											{
												selectedSessionData?.webhooks?.length > 0 && (
													<Button
														size='sm'
														colorScheme='blue'
														variant='link'
														color='#332fed'
														fontSize='medium'
														lineHeight='24px'
														onClick={onWebhookDetailsModalOpen}
													>
														View Webhook Details
													</Button>
												)
											}

										</Flex>
									</Flex>
								</Box>
								<Box
									flex={0.5}
								>
									<Box
										border='1px solid #D0D5DD'
										height='100%'
										borderRadius='12px'
										backgroundColor='#FCFCFD'
										overflow='hidden'
									>
										<Flex direction='column'>
											<Text
												color='#667085'
												py={3}
												px={4}
												fontWeight={500}
											>
												Parameters to generate Proof
											</Text>
											<TableContainer>
												<Table
													variant='simple'
												>
													<Tr>
														<Th
															fontWeight='bold'
															fontSize='sm'
															textAlign='center'
															border='1px solid'
															borderColor='gray.200'
															borderLeft='none'
															w='20%'
														>
															<Text
																fontSize='small'
																color='#475467'>
																Parameter Name
															</Text>

														</Th>
														<Th
															fontWeight='bold'
															fontSize='sm'
															textAlign='center'
															border='1px solid'
															borderColor='gray.200'
															borderRight='none'
															w='80%'
														>
															<Text
																fontSize='small'
																color='#475467'>
																Parameter Value
															</Text>
														</Th>
													</Tr>
													{

														selectedSessionData?.parameters
															?.length > 0 &&
														selectedSessionData?.parameters?.map((param: any, i: number) => (
															<Tr key={`${param?.key}_${param?.value}_${i}`}>
																<Td
																	textAlign='center'
																	overflow='hidden'
																	whiteSpace='pre-wrap'
																	border='1px solid'
																	borderColor='gray.200'
																	borderLeft='none'
																	cursor='pointer'
																	_hover={{ fontWeight: 'bolder' }}
																	onClick={
																		() => {
																			handleSearch(param?.key)
																			dispatch(setSelectRequestScreenClickedParamerterName(param?.key))
																		}
																	}
																>
																	<Text
																		fontSize='small'
																		color='#475467'
																		overflow='hidden'
																		whiteSpace='pre-wrap'
																	>
																		{param?.key}

																	</Text>

																</Td>
																<Td
																	textAlign='center'
																	overflow='hidden'
																	whiteSpace='pre-wrap'
																	border='1px solid'
																	borderColor='gray.200'
																	borderRight='none'
																	cursor='pointer'
																	_hover={{ fontWeight: 'bolder' }}
																	onClick={() => handleSearch(param?.value)}
																>
																	<Flex
																		alignItems='center'

																	>
																		<Text
																			fontSize='small'
																			color='#475467'
																			whiteSpace='nowrap'
																			textOverflow='ellipsis'
																			overflow='hidden'
																			flex={1}
																		>
																			{param?.value}
																		</Text>

																	</Flex>
																</Td>
															</Tr>
														))
													}


												</Table>
											</TableContainer>
										</Flex>
									</Box>
								</Box>
							</Flex>
						</Box>
						<Flex
							borderBottom='2px solid #F2F4F7'
							w='full'
							pb={5}
							mb={5}
							alignItems='flex-start'
							justifyContent='flex-start'
							gap={5}
						>
							<Box w='full'>
								<Heading
									fontSize='large'
									mb={2}
								>
									Session Details
								</Heading>
								<ManualVerificationSessionDetailsCard
									requestsCount={decryptedData?.length}
								/>
							</Box>
						</Flex>
						{
							(selectedSessionData?.id && (selectedManualVerificationSessionProviderData?.url?.length > 0 && selectedManualVerificationSessionProviderData?.responseSelections?.length > 0 && selectedSessionData?.status !== 'VERIFIED') || selectedSessionData?.status === 'PENDING') && (
								<Flex
									borderBottom='2px solid #F2F4F7'
									w='full'
									pb={5}
									mb={5}
									alignItems='center'
									justifyContent='center'
									gap={10}
								>
									{
										selectedSessionData?.status === 'PENDING' && (
											<Button
												colorScheme='red'
												onClick={() => makeClaimHandler('REJECTED')}
												w={200}
											>
												Reject Claim
											</Button>
										)
									}
									{
										selectedManualVerificationSessionProviderData?.url?.length > 0 && selectedManualVerificationSessionProviderData?.responseSelections?.length > 0 && selectedSessionData?.status !== 'VERIFIED' && (
											<Button
												colorScheme='blue'
												onClick={() => makeClaimHandler('VERIFIED', true)}

											>
												Use Existing Provider Config To Create Claim
											</Button>
										)
									}

								</Flex>
							)
						}
						<Box
							w='full'
							mb={2}>
							<Flex alignItems='flex-start'>
								<Box flex={1}>
									<Heading
										fontSize='large'
										mb={2}
									>
										Search data instances
									</Heading>
									<Text
										fontSize='medium'
										lineHeight='24px'
										color='#98A2B3'>
										Search the data you want to verify
									</Text>
								</Box>

							</Flex>
						</Box>
						<Flex
							w='full'
							alignItems='center'
							gap={5}
							mb={2}>
							<Input
								placeholder='Email_id, account, username...'
								value={searchQueryInput}
								onChange={(e) => handleSearch(e.target.value)}
								_placeholder={{ color: '#667085', fontSize: '14px' }} />
						</Flex>
						<Box
							w='full'
							scrollMarginLeft={10}
							overflow='auto'
							maxHeight='500px'
							borderWidth={1}
							borderRadius={10}
						>
							<TableContainer>
								<Table
									variant='simple'
									border='ActiveBorder'
									borderRadius='4px'
									borderColor='black'
								>
									<Thead>
										<Tr>
											<Th />
											<Th>
												Request URL
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										{
											filteredNetworkRequestStore.map((e: any, i: any) => (
												<Tr
													key={`${e.url}-${i}`}
													onClick={
														() => {
															handleRequestClick(e)
														}
													}
													cursor='pointer'
													_hover={{ bg: 'gray.50' }}
												>
													<Td w={50}>
														<Flex justifyContent='flex-end'>
															{
																e?.contentType === 'application/json' ? (
																	<Tag
																		borderRadius={5}
																		bgColor='#ECFDF3'
																		border='1px solid #17B26A'
																		color='#067647'
																		fontWeight='bold'
																		px={3}>
																		Stable
																	</Tag>
																) : (
																	<Tag
																		borderRadius={5}
																		bgColor='#FFFAEB'
																		border='1px solid #FEDF89'
																		color='#B54708'
																		fontWeight='bold'
																		px={3}>
																		Beta
																	</Tag>
																)
															}
														</Flex>

													</Td>
													<Td
														maxW='500px'
														overflow='hidden'
														whiteSpace='nowrap'
														textOverflow='ellipsis'>
														<Text
														>
															{e.url}
														</Text>

													</Td>


												</Tr>
											))
										}

									</Tbody>
								</Table>
							</TableContainer>
						</Box>
					</VStack>
				</Box>
			</CardBody>
			<ManualVerificationWebhookDetailsModal
				isWebhookDetailsModalOpen={isWebhookDetailsModalOpen}
				onWebhookDetailsModalClose={onWebhookDetailsModalClose}
				webhookRetryBtnLoading={webhookRetryBtnLoading}
				handleRetryWebhook={handleRetryWebhook}
			/>
		</Card>
	)
}

