

import { pxToRem } from '../utils'
import { theme } from './theme'

export const fontWeights = {
	display: 800,
	title: 700,
	subtitle: 500,
	label: 600,
	body: 400,
} as const

export const lineHeights = {
	display: 1.2,
	title: 1.125,
	subtitle: 1.6,
	label: 1,
	body: 1.6,
} as const

export const letterSpacings = {
	display: -1.5,
	title: 0.15,
	subtitle: 0.1,
	label: 'initial',
	body: 'initial',
} as const

const FONT_SIZE_CSS_VAR_PREFIX = '--tw-font-size-' as const

export const baseFontSizes = {
	display: {
		sm: pxToRem(44),
		md: pxToRem(48),
		lg: pxToRem(52),
		xl: pxToRem(56),
		'2xl': pxToRem(64),
	},
	title: {
		sm: pxToRem(16),
		md: pxToRem(18),
		lg: pxToRem(20),
		xl: pxToRem(24),
		'2xl': pxToRem(28),
	},
	subtitle: {
		xs: pxToRem(12),
		sm: pxToRem(14),
		md: pxToRem(16),
		lg: pxToRem(18),
		xl: pxToRem(20),
		'2xl': pxToRem(24),
	},
	label: {
		sm: pxToRem(12),
		md: pxToRem(14),
		lg: pxToRem(16),
		xl: pxToRem(18),
		'2xl': pxToRem(20),
	},
	body: {
		sm: pxToRem(12),
		md: pxToRem(14),
		lg: pxToRem(16),
		xl: pxToRem(18),
		'2xl': pxToRem(20),
	},
} as const

export type FontSizeRecord = typeof baseFontSizes;

export const mdFontSizes: FontSizeRecord = {
	display: {
		sm: pxToRem(56),
		md: pxToRem(64),
		lg: pxToRem(72),
		xl: pxToRem(80),
		'2xl': pxToRem(88),
	},
	title: {
		sm: pxToRem(20),
		md: pxToRem(24),
		lg: pxToRem(32),
		xl: pxToRem(40),
		'2xl': pxToRem(48),
	},
	subtitle: {
		xs: pxToRem(12),
		sm: pxToRem(16),
		md: pxToRem(20),
		lg: pxToRem(24),
		xl: pxToRem(28),
		'2xl': pxToRem(32),
	},
	label: {
		sm: pxToRem(12),
		md: pxToRem(14),
		lg: pxToRem(16),
		xl: pxToRem(24),
		'2xl': pxToRem(28),
	},
	body: {
		sm: pxToRem(12),
		md: pxToRem(14),
		lg: pxToRem(16),
		xl: pxToRem(20),
		'2xl': pxToRem(24),
	},
}


export type TypographyBase =
  | 'display'
  | 'title'
  | 'subtitle'
  | 'label'
  | 'body';
export type TypographySize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export type TypographyFontSize = `${TypographyBase}.${TypographySize}`;

export type HeadingBase = Exclude<TypographyBase, 'body'>;

export type HeadingSizes = `${HeadingBase}.${TypographySize}`;

export type TextBase = Exclude<
  TypographyBase,
  'display' | 'title' | 'subtitle'
>;

export type TextSizes = `${TextBase}.${TypographySize}`;

export type LabelBase = Exclude<
  TypographyBase,
  'display' | 'title' | 'subtitle' | 'body'
>;

export type LabelSizes = `${LabelBase}.${TypographySize}`;

export type BodyBase = Exclude<
  TypographyBase,
  'display' | 'title' | 'subtitle' | 'label'
>;

export type BodySizes = `${BodyBase}.${TypographySize}`;


/**
 *
 * @param size size of text
 * @description This function will create a css variable
 */

function createCssVariable(size: TypographyFontSize) {
	return `${FONT_SIZE_CSS_VAR_PREFIX}${size.replace(/\./g, '-')}`
}


export function convertFontSizeToCSSVar(fontSize: TypographyFontSize) {
	return `var(${createCssVariable(fontSize)})`
}

export function generateBreakpointTypographyCssVars() {
	const baseCSSVars = Object.entries(baseFontSizes)
		.flatMap(([typographyBase, typographySizeMap]) => {
			return Object.entries(typographySizeMap).map(
				([typographySize, fontSizeValue]) => {
					return `${createCssVariable(
						`${typographyBase as TypographyBase}.${typographySize as TypographySize
						}`,
					)}: ${fontSizeValue};`
				},
			)
		})
		.join('\n')

	const mdCSSVars = Object.entries(mdFontSizes)
		.flatMap(([typographyBase, typographySizeMap]) => {
			return Object.entries(typographySizeMap).map(
				([typographySize, fontSizeValue]) => {
					return `${createCssVariable(
						`${typographyBase as TypographyBase}.${typographySize as TypographySize
						}`,
					)}: ${fontSizeValue};`
				},
			)
		})
		.join('\n')

	return `:root {
    ${baseCSSVars}
    @media screen and (min-width: ${theme.breakpoints.md}) {
      ${mdCSSVars}
    }
  }`
}
