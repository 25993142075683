/* eslint-disable react/jsx-max-props-per-line */
import { useState } from 'react'
import { HiClipboard } from 'react-icons/hi'
import { useLocation } from 'react-router-dom'
import { Alert, AlertIcon, Box, Button, Flex, Heading, HStack, IconButton, Input, InputGroup, InputRightElement, Text, useColorMode, useToast } from '@chakra-ui/react'
import { DOCS_LINKS, pxToRem } from '../../utils'
import { CodeSegment } from './components/code.component'
import { DocsCard } from './components/DocsCard'

export const IntegrationDetails = () => {
	const { state } = useLocation()
	// checkin if the state is coming from the view application or new application
	const application = state?.app || state?.application

	const [isProduction, setIsProduction] = useState(false)
	const [showPrivateKey, setShowPrivateKey] = useState(false)
	const toasts = useToast()
	const handleCopyClick = () => {
		navigator.clipboard.writeText(state?.privateKey)
		toasts({
			title: 'Private Key Copied',
			status: 'success',
			duration: 3000,
			isClosable: true,
		})
	}

	const { colorMode } = useColorMode()
	return (
		<Box p='6px'>
			<Box mt='16px'>
				<Box mt='16'>
					<Heading
						as='h1'
						fontWeight={700}
						size='lg'
						fontSize='24px'>
						Integrate with your App
					</Heading>
					<Text mt='8px'>
						You have Successfully registered the application. follow the guide
						to integrate
					</Text>
				</Box>

				<Box
					mt='30px'
					bgColor={colorMode === 'dark' ? '#131417' : '#fff'}
					p={pxToRem(18)}
					_hover={
						{
							background: colorMode === 'light' ? '#fff' : '131417',
						}
					}
					borderRadius='md'
				>
					<Box mb='10px'>
						<Text
							fontWeight={500}
							fontSize='16px'
							mb={pxToRem(8)}>
							App Name
						</Text>
						<HStack>
							<InputGroup minW='450px'>
								<Input
									type='text'
									value={application?.name}
									readOnly
								/>
							</InputGroup>
						</HStack>
					</Box>
					<Box>
						<Text
							fontWeight={500}
							fontSize='16px'
							mb={pxToRem(8)}>
							App ID
						</Text>
						<HStack>
							<InputGroup minW='450px'>
								<Input
									type='text'
									value={application?.publicKey}
									readOnly
								/>
							</InputGroup>
							<IconButton
								ml='0'
								size='sm'
								icon={<HiClipboard />}
								onClick={
									() => {
										//copy the value and show toast
										navigator.clipboard.writeText(application?.publicKey)
										toasts({
											title: 'Public Key Copied',
											status: 'success',
											duration: 3000,
											isClosable: true,
										})
									}
								}
								aria-label='Copy Secret Key'
							/>
						</HStack>
					</Box>

					<Box mt='10px'>
						<Text
							fontWeight={500}
							fontSize='16px'
							mb={pxToRem(8)}>
							App Secret
						</Text>
						<HStack>
							<InputGroup minW='450px'>
								<Input
									type={showPrivateKey ? 'text' : 'password'}
									value={state?.privateKey ? state?.privateKey : 'YOUR APP SECRET'}
									readOnly
								/>
								<InputRightElement width='4.5rem'>
									<Button
										h='1.75rem'
										size='sm'
										onClick={() => setShowPrivateKey(!showPrivateKey)}
									>
										{showPrivateKey ? 'Hide' : 'Show'}
									</Button>
								</InputRightElement>
							</InputGroup>
							<IconButton
								ml='0'
								size='sm'
								icon={<HiClipboard />}
								onClick={handleCopyClick}
								aria-label='Copy Secret Key'
							/>
						</HStack>
					</Box>

					{
						application ? (
							<Alert
								borderRadius='md'
								status='warning'
								mt='4'>
								<AlertIcon />
								Before leaving this page, please be aware that you will no longer
								have access to view your private key, and we wont be able to
								assist you in recovering it. Make sure to copy it and securely
								store it before proceeding.
							</Alert>
						) : (
							<Alert
								borderRadius='md'
								status='warning'
								mt='4'>
								<AlertIcon />
								Use your App Secret Key, which was generated while creating the
								application
							</Alert>
						)
					}
					{/* temporarly hiding Provider Hash */}

					{/* <Box>
						<Text
							fontWeight={500}
							fontSize='large'
							mt='20px'
							mb={pxToRem(8)}>
							Provider Hash
						</Text>
						{
							application?.httpProvider.map((provider: any, idx: number) => {
								return (
									<>
										<HStack
											mt='10px'
											justifyContent='space-between'>
											<Text
												key={idx}
												mr='10px'>
												{provider.name}
											</Text>
											<HStack minW='70%' justifyContent='flex-end'>
												<InputGroup minW='450px'>
													<Input
														type='text'
														value={provider?.providerHash}
														readOnly
													/>
												</InputGroup>
												<IconButton
													ml='0'
													size='sm'
													icon={<HiClipboard />}
													onClick={
														() => {
														//copy the value and show toast
															navigator.clipboard.writeText(provider?.providerHash)
															toasts({
																title: 'Provider Hash Copied',
																status: 'success',
																duration: 3000,
																isClosable: true,
															})
														}
													}
													aria-label='Copy Provider Hash'
												/>
											</HStack>
										</HStack>
									</>
								)
							})
						}
					</Box> */}
				</Box>

				{/* <HStack mt='30px'>
					<Alert borderRadius='lg'>
						<AlertIcon />
						Showing Prototype code. this is perfect for playing around and
						trying out. are you looking for a production build code?
						<Switch
							ml='20px'
							isChecked={isProduction}
							onChange={() => setIsProduction(!isProduction)}
						/>
					</Alert>
				</HStack> */}

				<>
					<Flex
						flexDir='column'
						gap={2}
						mt='40px'>
						<Text mb='16px'>
							Choose a Library/Framework:
							{' '}
							{/* {isProduction ? 'Production' : 'Prototype'} */}
						</Text>
						<CodeSegment
							isProduction={isProduction}
							setIsProduction={setIsProduction}
							application={
								{
									...application,
									privateKey: state?.privateKey
										? state?.privateKey
										: 'YOUR APP SECRET',
								}
							}
						/>
					</Flex>
				</>
			</Box>

			<Box p={pxToRem(30)}>
				<Heading
					as='h1'
					fontWeight={700}
					size='lg'
					fontSize='24px'>
					View Docs
				</Heading>
				<Text mt='8px'>
					Looking for in Depth Guide? Checkout the docs for detailed
					explaination
				</Text>

				<HStack
					borderRadius='lg'
					bgColor={colorMode === 'dark' ? '#131417' : 'white'}
					mt='40px'
					p={pxToRem(20)}
				>
					{
						DOCS_LINKS?.map((d) => (
							<DocsCard
								key={d.name}
								name={d.name}
								docsLink={d.docsLink}
								icon={d.icon}
							/>
						))
					}
				</HStack>
			</Box>
		</Box>
	)
}