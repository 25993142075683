import { ProviderDetails } from '../../redux/providerBuilder/providerDetails/types'

function search(
	path: string,
	obj: any,
	target: string,
	matches: { path: string, key: string, isString: boolean }[] = [],
	isArray = false
) {
	for (var k in obj) {
		if (obj.hasOwnProperty(k)) {
			if (typeof obj[k] === 'undefined') {
				continue
			}

			let newPath = path + '.' + k
			if (typeof obj[k] === 'object') {
				if (isArray) {
					newPath = path + '[' + k + ']'
				}

				if (Array.isArray(obj[k])) {
					search(newPath, obj[k], target, matches, true)
				} else {
					search(newPath, obj[k], target, matches)
				}
			} else if (obj[k].toString().includes(target)) {
				matches.push({
					path: path + '.' + k,
					key: k,
					isString: typeof obj[k] === 'string',
				})
			}
		}
	}

	return matches
}

function escapeRegExp(text: any) {
	return text.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
}

function createXPathFromElement(elm: any, doc: any) {
	const allNodes = doc.getElementsByTagName('*')
	let i = 0
	let sib
	for (var segs = []; elm && elm.nodeType === 1; elm = elm.parentNode) {
		if (elm.hasAttribute('id')) {
			var uniqueIdCount = 0
			for (var n = 0; n < allNodes.length; n++) {
				if (allNodes[n].hasAttribute('id') && allNodes[n].id === elm.id) {
					uniqueIdCount++
				}

				if (uniqueIdCount > 1) {
					break
				}
			}

			if (uniqueIdCount === 1) {
				segs.unshift('id("' + elm.getAttribute('id') + '")')
				return segs.join('/')
			} else {
				segs.unshift(
					elm.localName.toLowerCase() + '[@id="' + elm.getAttribute('id') + '"]'
				)
			}
		} else if (elm.hasAttribute('class')) {
			segs.unshift(
				elm.localName.toLowerCase() +
				'[@class="' +
				elm.getAttribute('class') +
				'"]'
			)
		} else {
			for (i = 1, sib = elm.previousSibling; sib; sib = sib.previousSibling) {
				if (sib.localName === elm.localName) {
					i++
				}
			}

			segs.unshift(elm.localName.toLowerCase() + '[' + i + ']')
		}
	}

	return segs.length ? '/' + segs.join('/') : null
}

function getMatchIndexes(str: string, toMatch: string | RegExp) {
	try {
		var re = new RegExp(toMatch, 'g'),
			indexMatches = [],
			match

		while ((match = re.exec(str))) {
			indexMatches.push(match.index)
		}

		return indexMatches
	} catch (e) {
		console.log(e)
		return []
	}
}

function getValuePositionInJson(
	jsonString: string,
	jsonPath: string,
	searchString: string,
	posList: Array<number>
): number {
	try {
		const jsonObject = JSON.parse(jsonString)
		jsonPath = jsonPath.slice(2)
		const keys = jsonPath.split('.')
		const keyType = keys.map((key) => {
			if (key.includes('[')) {
				return {
					type: 'array',
					key: key.split('[')[0],
					index: key.split('[')[1].split(']')[0],
				}
			} else {
				return { type: 'object', key }
			}
		})
		let pos = 0
		let currentObject = jsonObject
		let partialString = ''
		for (let i = 0; i < keyType.length; i++) {
			let currPos = 0
			const key = keyType[i].key
			const index = keyType[i]?.index ?? 0
			if (currentObject[key]) {
				partialString =
					keyType[i].type === 'object'
						? JSON.stringify(currentObject[key])
						: JSON.stringify(currentObject[key][index])
				const jsonCurrentString = JSON.stringify(currentObject)
				currentObject =
					keyType[i].type === 'object'
						? currentObject[key]
						: currentObject[key][index]

				currPos = jsonCurrentString.indexOf(partialString, 0)

				let tempCurrPos =
					typeof currentObject === 'string'
						? pos + currPos + 1
						: typeof currentObject === 'number'
							? pos + currPos
							: -1

				while (posList.includes(tempCurrPos) && i === keyType.length - 1) {
					currPos = jsonCurrentString.indexOf(partialString, currPos + 1)
					tempCurrPos =
						typeof currentObject === 'string'
							? pos + currPos + 1
							: typeof currentObject === 'number'
								? pos + currPos
								: -1
				}

				pos += currPos
			}
		}

		const currPos = partialString.indexOf(searchString, 0)
		pos += currPos

		return pos
	} catch (e) {
		console.log(e)
		return -1
	}
}

function getUniqueItems(arr: string[]): string[] {
	return Array.from(new Set(arr))
}

function getAllPaths(
	path: string,
	obj: any,
	target: string,
	matches: { [path: string]: boolean } = {},
	isArray = false
) {
	for (var k in obj) {
		if (obj.hasOwnProperty(k)) {
			if (typeof obj[k] === 'undefined') {
				continue
			}

			const newPath = isArray ? `${path}[${k}]` : `${path}.${k}`

			if (typeof obj[k] === 'object') {
				if (Array.isArray(obj[k])) {
					matches[newPath] = true
					getAllPaths(newPath, obj[k], target, matches, true)
				} else {
					matches[newPath] = true
					getAllPaths(newPath, obj[k], target, matches)
				}
			} else if (obj[k].toString().includes(target)) {
				matches[newPath] = true
			}
		}
	}

	return matches
}

function getAllDynamicPaths(
	path: string,
	obj: any,
	target: string,
	matches: { [path: string]: string } = {},
	isArray = false,
	clickedPath: string,
	checked: boolean = false
) {
	for (var k in obj) {
		if (obj.hasOwnProperty(k)) {
			if (typeof obj[k] === 'undefined') {
				continue
			}

			const newPath = isArray ? `${path}[${k}]` : `${path}.${k}`

			let matchedTxt = ''
			if (obj[k]?.toString()?.includes('{{REQ_BODY_')) {
				matchedTxt = `{{REQ_BODY_${Object.keys(matches)?.length + 1}}}`
			}

			if (obj[k]?.toString()?.includes('{{REQ_SECRET_')) {
				matchedTxt = `{{REQ_SECRET_${Object.keys(matches)?.length + 1}}}`
			}

			if (path + '.' + k === clickedPath) {
				if (checked) {
					matchedTxt = `{{REQ_SECRET_${Object.keys(matches)?.length + 1}}}`
				} else {
					if (obj[k].toString().includes('{{REQ_BODY_')) {
						matchedTxt = 'ORIGINAL_VALUE'
					} else {
						matchedTxt = `{{REQ_BODY_${Object.keys(matches)?.length + 1}}}`
					}
				}
			}

			if (matchedTxt?.length) {
				matches[newPath] = matchedTxt
				continue
			}

			if (typeof obj[k] === 'object') {
				getAllDynamicPaths(
					newPath,
					obj[k],
					target,
					matches,
					Array.isArray(obj[k]),
					clickedPath,
					checked
				)
			}
		}
	}

	return matches
}


function getValueByPath(obj: any, path: string) {
	return path.split('.').reduce((acc, part) => {
		// Check if the part is an array index
		const match = part.match(/(\w+)\[(\d+)\]/)
		if (match) {
			const [, arrayName, index] = match
			return acc?.[arrayName]?.[Number(index)]
		}

		return acc?.[part]
	}, obj)
}

function setValueByPath(obj: any, path: string, value: any) {
	const parts = path.split('.')
	let current = obj

	parts.forEach((part, index) => {
		// Check if the part is an array index
		const match = part.match(/(\w+)\[(\d+)\]/)
		if (match) {
			const [, arrayName, arrayIndex] = match
			// Initialize the array if it does not exist
			if (!current[arrayName]) {
				current[arrayName] = []
			}

			// Navigate to the array element
			current = current[arrayName]
			if (index === parts.length - 1) {
				current[arrayIndex] = value
			} else {
				// Initialize the object in the array if it does not exist
				if (!current[arrayIndex]) {
					current[arrayIndex] = {}
				}

				current = current[arrayIndex]
			}
		} else {
			if (index === parts.length - 1) {
				current[part] = value
			} else {
				// Initialize the object if it does not exist
				if (!current[part]) {
					current[part] = {}
				}

				current = current[part]
			}
		}
	})
}

function compareObjects(obj1: any, obj2: any, keys: string[] = []) {
	let allKeys = new Set([...keys])

	if (!keys?.length) {
		allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)])
	}

	const differingKeys: any = []

	allKeys.forEach(key => {
		if (obj1[key] !== obj2[key]) {
			differingKeys.push(key)
		}
	})

	return differingKeys
}

function compareProviders(provider1: ProviderDetails, provider2: ProviderDetails): boolean {
	const fieldsToCompare = ['customInjection', 'geoLocation', 'loginUrl', 'method', 'url', 'urlType']
	const bodySniffFields = ['enabled', 'regex']
	const responseSelectionFields = ['xPath', 'jsonPath', 'responseMatch']

	if (compareObjects(provider1, provider2, fieldsToCompare).length) {
		return true
	}

	if (compareObjects(provider1.bodySniff, provider2.bodySniff, bodySniffFields).length) {
		return true
	}

	if (provider1.responseSelections?.length !== provider2.responseSelections?.length) {
		return true
	}

	for (let i = 0; i < provider1.responseSelections?.length; i++) {
		if (compareObjects(provider1.responseSelections[i], provider2.responseSelections[i], responseSelectionFields).length) {
			return true
		}
	}

	try {
		const template1 = JSON.stringify(JSON.parse(provider1.bodySniff.template))
		const template2 = JSON.stringify(JSON.parse(provider2.bodySniff.template))
		if (template1 !== template2) {
			return true
		}
	} catch {
		if (provider1.bodySniff.template !== provider2.bodySniff.template) {
			return true
		}
	}

	return false
}


export {
	search,
	escapeRegExp,
	createXPathFromElement,
	getMatchIndexes,
	getUniqueItems,
	getValuePositionInJson,
	getAllPaths,
	getValueByPath,
	getAllDynamicPaths,
	setValueByPath,
	compareProviders,
	compareObjects
}
