/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { DeleteIcon } from '@chakra-ui/icons'
import {
	Avatar,
	Badge,
	Box,
	Heading,
	HStack,
	Image,
	Text,
	Tooltip,
	useColorMode,
	useDisclosure,
} from '@chakra-ui/react'
//@ts-ignore
import Verified from '../../assets/icons/verified.svg'
import { HttpsProvider } from '../../redux/httpsProviders/types'
import { pxToRem } from '../../utils'
import { ProviderCardSkeleton } from '../explore/components/provider-skeleton.component'
import { DeleteProvider } from './delete-provider.component'

interface ProviderCardProps {
  providerData?: HttpsProvider | any
  isLoading?: boolean
  revalidate?: any
}

export const ProviderCard = ({
	providerData,
	isLoading,
	revalidate,
}: ProviderCardProps) => {
	const {
		isOpen: deleteIsOpen,
		onOpen: deleteOnOpen,
		onClose: deleteOnClose,
	} = useDisclosure()

	const { colorMode } = useColorMode()

	return (
		<>
			{
				isLoading ? (
					<ProviderCardSkeleton />
				) : (
					<div
						onClick={
							(e) => {
								e.stopPropagation()
							}
						}
						style={{ cursor: 'pointer' }}
					>
						<DeleteProvider
							providerData={providerData}
							isOpen={deleteIsOpen}
							onClose={deleteOnClose}
							revalidate={revalidate}
						/>
						<Box
							border={
								`1px solid ${
									colorMode === 'dark' ? 'transparent' : '#e5e5ea'
								}`
							}
							borderRadius='lg'
							// bg={
							// 	colorMode === 'dark'
							// 		? '#131417'
							// 		: 'linear-gradient(180deg,transparent,hsla(0,0%,95%,.288) 47.39%),linear-gradient(89.84deg,rgba(230,36,174,.15) .14%,rgba(94,58,255,.15) 16.96%,rgba(10,136,255,.15) 34.66%,rgba(75,191,80,.15) 50.12%,rgba(137,206,0,.15) 66.22%,rgba(239,183,0,.15) 82%,rgba(246,73,0,.15) 99.9%),linear-gradient(0deg,hsla(0,0%,100%,.15),hsla(0,0%,100%,.15))'
							// }
							bg={colorMode === 'dark' ? '#131417' : '#fff'}
							p='16px'
							_hover={
								{
									borderColor: 'blue.500',
									//backgroundColor: '#feede6',
									//backgroundImage: 'linear-gradient(12deg, #fff 50%, hsla(0, 0%, 100%, 0))',
								}
							}
							minH={pxToRem(143)}
							transition='background 100ms ease, border-color 100ms ease'
							alignItems='center'
							position='relative'
						>
							<HStack justifyContent='space-between'>
								<HStack
									position='relative'
									alignItems='flex-start'
									justifyContent='flex-start'
								>
									<Avatar
										name={providerData?.name}
										src={providerData?.logoUrl}
										width={pxToRem(30)}
										height={pxToRem(30)}
										size='sm'
										textAlign='center'
									//objectFit='cover'
									/>
									{
										providerData?.isVerified && (
											<Tooltip
												label='Verified Official providers maintained by Reclaim Team'
												placement='top'
												hasArrow
											>
												<Image
													position='absolute'
													top={2.5}
													left={5}
													src={Verified}
													alt='verified'
													width={pxToRem(22)}
													height={pxToRem(22)}
												/>
											</Tooltip>
										)
									}
									<HStack />
								</HStack>
								<HStack>
									{/* {
										providerData?.isVerified && (
											<Tooltip
												label='Official providers maintained by Reclaim Team'
												placement='top'
												hasArrow
												fontSize={pxToRem(14)}
											>
												<Badge
													ml='1'
													variant='outline'
													fontSize={pxToRem(14)}
													colorScheme='green'
												>
													VERIFIED
												</Badge>
											</Tooltip>
										)
									} */}

									<span onClick={(e) => e.stopPropagation()}>
										<DeleteIcon onClick={deleteOnOpen} />
									</span>
								</HStack>
							</HStack>
							<Box cursor='pointer'>
								<Heading
									as='h2'
									mt='16px'
									fontSize={pxToRem(14)}>
									{providerData?.name}
								</Heading>
								<Box>
									<Text
										mt='6px'
										fontSize={pxToRem(14)}>
										{
											providerData?.description
												? providerData.description
												: `Proves ${
													providerData?.name
														? providerData.name
														: providerData?.providerDisplayName
												} Data`
										}
									</Text>
								</Box>
							</Box>
							{
								providerData.providerType === 'PRIVATE' && (
									<Badge
										bottom={0}
										right={0}
										fontSize={pxToRem(12)}
										mb={pxToRem(12)}
										mr={pxToRem(12)}
									 	colorScheme='green'
										variant='outline'
										position='absolute'
									>
										Private
									</Badge>
								)
							}
						</Box>
					</div>
				)
			}
		</>
	)
}
