import { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { MdKeyboardArrowUp } from 'react-icons/md'
import { Box, FormControl, FormErrorMessage, HStack, Icon, IconButton, Input, Text, VStack } from '@chakra-ui/react'


export const RedirectScreen = () => {
	const [searchText, setSearchText] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	const redirectGoogleSearch = () => {
		if(!searchText) {
			setErrorMessage('Enter a search text')
			return
		}

		window.location.href = `https://www.google.com/search?q=${searchText}`
	}

	return (
		<VStack
			minH='100vh'
			justifyContent='center'
			alignItems='center'
			spacing={4}
			p={2}>
			<Box
				textAlign='center'
				p={4}
				borderColor='gray.300'
				borderWidth='1px'
				borderRadius='lg'
				width='85%'
				boxShadow='sm'>
				<Text
					fontSize='lg'
					fontWeight='bold'
					mb={2}>
					Enter the URL of any website and navigate to the page containing the data you want to verify.
				</Text>
				<Icon
					as={MdKeyboardArrowUp}
					w={20}
					h={20}
					color='blue.500' />
				<Text
					fontSize='md'
					color='gray.600'>
					Look up! The URL bar is right there.
				</Text>
			</Box>
			<Text
				fontSize='larger'
				padding='16px'>
				OR
			</Text>
			<Box
				textAlign='center'
				p={4}
				width='85%'
				borderColor='gray.300'
				borderWidth='1px'
				boxShadow='sm' >
				<FormControl isInvalid={!!errorMessage}>
					<HStack
						textAlign='center'
						justifyContent='center'
						alignItems='center'>
						{/* Add google search option */}
						<Input
							placeholder='Search on Web'
							variant='filled'
							onChange={
								(e) => {
									setErrorMessage('')
									setSearchText(e.target.value)
								}
							}
							size='md'
							required={true}
							w='400px' />
						{/* Search Icon button */}
						<IconButton
							aria-label='Search'
							size='sm'
							onClick={() => redirectGoogleSearch()}
							icon={<FaSearch />}
							colorScheme='teal'
							variant='solid'
						/>
					</HStack>
					<FormErrorMessage>
						{errorMessage}
					</FormErrorMessage>
				</FormControl>
			</Box>
		</VStack>
	)
}