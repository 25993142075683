import { GET_ALL_MANUAL_VERIFICATION_SESSIONS, GET_MANUAL_VERIFICATION_SESSION_DATA } from '../../../utils'
import { getFirebaseIdToken } from '../../../utils/utils'
import { typedCreateAsyncThunk } from '../../extraConfig'
import { setManualVerificationSessions, setManualVerificationSessionsFetching, setSelectedManualVerificationSessionData } from '.'


export const fetchAllManualVerificationSessions = typedCreateAsyncThunk<any, { httpProviderId: string | undefined, queryType: string | undefined }>(
	'manualVerificationSessions/fetchAllManualVerificationSessions',
	async (payload, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		const httpProviderId = payload?.httpProviderId || ''
		const queryType = payload?.queryType || ''
		dispatch(setManualVerificationSessionsFetching(true))
		const response = await fetch(GET_ALL_MANUAL_VERIFICATION_SESSIONS(httpProviderId, queryType), {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		})
		const data = await response.json()

		if (data) {
			dispatch(setManualVerificationSessionsFetching(false))
			dispatch(setManualVerificationSessions(data.manualVerificationSessions))
			return { isSuccess: true, manualVerificationSessions: data.manualVerificationSessions }
		} else {
			return rejectWithValue(data.message)
		}
	}
)

export const fetchManualVerificationSessionData = typedCreateAsyncThunk<any, { sessionId: string | undefined }>(
	'manualVerificationSessions/fetchManualVerificationSession',
	async (payload, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		const sessionId = payload?.sessionId || ''

		const response = await fetch(GET_MANUAL_VERIFICATION_SESSION_DATA(sessionId), {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		})
		const data = await response.json()

		if (data) {
			dispatch(setSelectedManualVerificationSessionData(data?.session ?? {}))
			return { isSuccess: true }
		} else {
			return rejectWithValue(data.message)
		}
	}
)
