/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-indent */
import { useEffect } from 'react'
import { MdKeyboardBackspace } from 'react-icons/md'
import {
	Box,
	Card,
	CardBody,
	CardHeader,
	Flex,
	Heading,
	HStack,
	IconButton,
	Step,
	StepIcon,
	StepIndicator,
	StepNumber,
	Stepper,
	StepSeparator,
	StepStatus,
	StepTitle,
	useSteps,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateBackAction } from '../../../../redux/providerBuilder/stepperDetails'
import {
	getActiveStep,
	getBackAction,
	getStepperTitlesV2,
} from '../../../../redux/providerBuilder/stepperDetails/selectors'
import { ProviderName } from './provider-name.component'
import { RequestConfig } from './request-config.component'

/* eslint-disable indent */
export const StepperForm = (props: any) => {


    const dispatch = useAppDispatch()

    const activeStep = useAppSelector(getActiveStep)
    const stepperTitles = useAppSelector(getStepperTitlesV2)
    const backActionUsed = useAppSelector(getBackAction)

    const steps = [
        'Basic Details',
        'Provider Configurations',
    ]

    const {
        activeStep: activeStepInnerForm,
        setActiveStep: setActiveStepInnerForm,
    } = useSteps({
        index: 0,
        count: steps.length,
    })


    useEffect(() => {
        if (backActionUsed) {
            setActiveStepInnerForm(1)
            dispatch(updateBackAction(false))
        }
    }, [backActionUsed, dispatch])


    return (
        <>
            <Box
                marginTop='2.5rem'
                marginBottom='5rem'>
                <Card>
                    <CardHeader>
                        {/* <Heading fontSize='larger'>
                            <IconButton
                                variant='outline'
                                colorScheme='teal'
                                aria-label=''
                                onClick={() => dispatch(updateActiveStep(activeStep - 1))}
                                icon={<MdKeyboardBackspace />}
                                marginRight='20px'
                            />
                            {stepperTitles[activeStep]}
                        </Heading> */}
                    </CardHeader>
                    <CardBody height='80%'>
                        <HStack
                            align='flex-start'>
                            <Stepper
                                index={activeStepInnerForm}
                                orientation='vertical'
                                height='400px'
                                gap='0'
                            >
                                {
                                    steps.map((step, index) => (
                                        <Step key={index}>
                                            <Flex align='baseline'>
                                                <Box
                                                    cursor={
                                                        activeStepInnerForm >= index
                                                            ? 'pointer'
                                                            : 'not-allowed'
                                                    }
                                                    onClick={
                                                        () => activeStepInnerForm >= index
                                                            ? setActiveStepInnerForm(index)
                                                            : null
                                                    }
                                                    width='145px'
                                                >
                                                    <StepTitle>
                                                        {step}
                                                    </StepTitle>
                                                </Box>
                                                <StepIndicator>
                                                    <StepStatus
                                                        complete={
                                                            <StepIcon
                                                                onClick={
                                                                    () => activeStepInnerForm >= index
                                                                        ? setActiveStepInnerForm(index)
                                                                        : null
                                                                }
                                                                cursor='pointer'
                                                            />
                                                        }
                                                        incomplete={<StepNumber />}
                                                        active={<StepNumber />}
                                                    />
                                                </StepIndicator>
                                            </Flex>
                                            <StepSeparator style={{ marginLeft: '82%' }} />
                                        </Step>
                                    ))
                                }
                            </Stepper>
                            <Box
                                mt='-3%'
                                ml='5%'
                                width='80%'
                                padding='3%'
                                alignItems='center'
                            >
                                {
                                    activeStepInnerForm === 0 && (
                                        <ProviderName
                                            setActiveStepInnerForm={setActiveStepInnerForm}
                                        />
                                    )
                                }
                                {
                                    activeStepInnerForm === 1 && (
                                        <RequestConfig
                                            setActiveStepInnerForm={setActiveStepInnerForm}
                                        />
                                    )
                                }
                            </Box>
                        </HStack>
                    </CardBody>
                </Card>
            </Box>
        </>
    )
}
