import React from 'react'
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useToast } from '@chakra-ui/react'
import { deleteApplication } from '../../../redux/applications/actions'
import { useAppDispatch } from '../../../redux/hooks'

function DeleteApplication({ isOpen, onClose, application }: { isOpen: any, onClose: any, application: any}) {
	const [isLoaded, setIsLoaded] = React.useState(false)
	const cancelRef = React.useRef()
	const toast = useToast()
	const dispatch = useAppDispatch()
	const deleteApplicationHandler = (appId: string) => {
		setIsLoaded(true)
		//@ts-ignore
		dispatch(deleteApplication({ appId: appId })).then((res) => {
			if(res.meta.requestStatus === 'fulfilled') {
				setIsLoaded(false)
				toast({
					title: 'Application Deleted.',
					description: '',
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				onClose()
			} else {
				toast({
					title: 'Application Deletion Failed.',
					description: '',
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
			}
		})
	}

	return (
		<>
			<AlertDialog
				isOpen={isOpen}
				//@ts-ignore
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader
							fontSize='lg'
							fontWeight='bold'>
							Delete Application
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure Do you want to delete
							{' '}
							<strong>
								{application?.name}
							</strong>
							?, You cant undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								//@ts-ignore
								ref={cancelRef}
								onClick={onClose}
							>
								Cancel
							</Button>
							<Button
								isLoading={isLoaded}
								colorScheme='red'
								onClick={() => deleteApplicationHandler(application?.appId as string)}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
}

export { DeleteApplication }