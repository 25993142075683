
//import { BACKEND_URL, LOCAL_BACKEND_URL } from '../../configs/backend'
import { LOGIN } from '../../utils'
import { getApiUrl, getFirebaseIdToken } from '../../utils/utils'
import { setApplications } from '../applications'
import { AppThunk, typedCreateAsyncThunk } from '../extraConfig'
import { setHttpsProviders } from '../httpsProviders'
import {
	setAccessToken,
	setAllUsers,
	setIsLoggedIn,
	setIsRegistrationSuccessful,
	setUserDisplayName,
	setUserEmailId,
	setUserType,
	UserType,
} from './index'

// const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const registerClient = typedCreateAsyncThunk<
	void,
	{ email: string, password: string }
>('user/registerUser', async(payload, { dispatch, rejectWithValue }) => {
	const response = await fetch(`${getApiUrl()}/register/user`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	})
	const data = await response.json()
	if(data.isSuccess) {
		dispatch(setIsRegistrationSuccessful(true))
	} else {
		return rejectWithValue(data.message)
	}
})

export const loginClient = typedCreateAsyncThunk<void, undefined>(
	'user/loginUser',
	async(payload, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		const response = await fetch(LOGIN, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		})
		const data = await response.json()
		if(data.isSuccess) {
			dispatch(setIsLoggedIn(true))
			dispatch(setUserType(data.user.type))
			dispatch(setUserEmailId(data.user.email))
			dispatch(setAccessToken(accessToken as string))
			dispatch(setUserDisplayName(data.user.name))
			localStorage.setItem('user', JSON.stringify({ ...data, accessToken }))
			localStorage.setItem('userType', JSON.stringify(data?.user?.type))

			return data.user
		} else {
			dispatch(setIsLoggedIn(false))
			return rejectWithValue(data.message)
		}
	}
)

export const fetchAllUsers = typedCreateAsyncThunk<void, undefined>(
	'user/getAllUsers',
	async(_, { dispatch, rejectWithValue }) => {
		const response = await fetch(`${getApiUrl()}/users`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		})
		const data = await response.json()
		if(data.isSuccess) {

			dispatch(setAllUsers(data?.users))
		} else {
			return rejectWithValue(data.message)
		}

	}
)

export const logoutClient = (): AppThunk => (dispatch) => {
	dispatch(setIsLoggedIn(false))
	dispatch(setUserEmailId(''))
	dispatch(setApplications([]))
	dispatch(setHttpsProviders([]))
	dispatch(setUserType(UserType.USER))
	dispatch(setAccessToken(''))
	dispatch(setUserDisplayName(''))
	localStorage.removeItem('user')
	localStorage.removeItem('userType')
}
