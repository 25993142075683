/* eslint-disable indent */
/* eslint-disable react/jsx-indent */

import {
    Card,
    CardBody,
    Flex,
    Heading,
    ListItem,
    Tag,
    Text,
    UnorderedList,
} from '@chakra-ui/react'
import { useAppSelector } from '../../../../redux/hooks'
import { getSelectedManualVerificationSessionProviderData } from '../../../../redux/manualVerification/providerDetails/selectors'
import { getSelectedManualVerificationSessionData } from '../../../../redux/manualVerification/sessionsDetails/selectors'
import { formatDate } from '../../../../utils/format-date'
import { statusTagColors, statusUpperCaseToLowerCase } from '../../../../utils/manual-verification-session/manual-verification-constants'

interface ManualVerificationSessionDetailsCardProps {
    requestsCount: number
}

export const ManualVerificationSessionDetailsCard: React.FC<ManualVerificationSessionDetailsCardProps> = ({
    requestsCount
}) => {

    const selectedSessionData = useAppSelector(getSelectedManualVerificationSessionData)
    const selectedManualVerificationSessionProviderData = useAppSelector(getSelectedManualVerificationSessionProviderData)

    return (
        <>
            <Card
                bgColor='#fcfcfd'
                variant='outline'
                borderRadius={10}>
                <CardBody>
                    <UnorderedList
                        listStyleType='none'
                        spacing={3}
                        listStylePosition='inside'>
                        <ListItem>
                            <Flex
                                alignItems='flex-start'
                                gap={10}>
                                <Heading
                                    w='30%'
                                    fontSize='medium'
                                >
                                    Session Id
                                </Heading>
                                <Text
                                    fontSize='medium'
                                    lineHeight='24px'
                                    flex={1}
                                    textAlign='left'
                                >
                                    {selectedSessionData?.sessionId}
                                </Text>
                            </Flex>
                        </ListItem>
                        <ListItem>
                            <Flex
                                alignItems='flex-start'
                                gap={10}>
                                <Heading
                                    w='30%'
                                    fontSize='medium'
                                >
                                    App Id
                                </Heading>
                                <Text
                                    fontSize='medium'
                                    lineHeight='24px'
                                    flex={1}
                                    textAlign='left'
                                >
                                    {selectedSessionData?.appId}
                                </Text>
                            </Flex>
                        </ListItem>
                        <ListItem>
                            <Flex
                                alignItems='flex-start'
                                gap={10}>
                                <Heading
                                    w='30%'
                                    fontSize='medium'
                                >
                                    Provider Id
                                </Heading>
                                <Text
                                    fontSize='medium'
                                    lineHeight='24px'
                                    flex={1}
                                    textAlign='left'
                                >
                                    {selectedSessionData?.httpProviderId}
                                </Text>
                            </Flex>
                        </ListItem>
                        <ListItem>
                            <Flex
                                alignItems='flex-start'
                                gap={10}>
                                <Heading
                                    w='30%'
                                    fontSize='medium'
                                >
                                    Request URL
                                </Heading>
                                <Text
                                    fontSize='medium'
                                    lineHeight='24px'
                                    flex={1}
                                    textAlign='left'
                                    style={
                                        {
                                            textWrap: 'wrap',
                                            overflow: 'hidden'
                                        }
                                    }

                                >
                                    {
                                        selectedManualVerificationSessionProviderData
                                            ?.url
                                    }
                                </Text>
                            </Flex>
                        </ListItem>
                        <ListItem>
                            <Flex
                                alignItems='flex-start'
                                gap={10}>
                                <Heading
                                    w='30%'
                                    fontSize='medium'
                                >
                                    Unique Requests Captured
                                </Heading>
                                <Text
                                    fontSize='medium'
                                    lineHeight='24px'
                                    flex={1}
                                    textAlign='left'
                                >
                                    {requestsCount ?? 0}
                                </Text>
                            </Flex>
                        </ListItem>
                        <ListItem>
                            <Flex
                                alignItems='flex-start'
                                gap={10}>
                                <Heading
                                    w='30%'
                                    fontSize='medium'
                                >
                                    Session Status
                                </Heading>
                                <Text
                                    fontSize='medium'
                                    lineHeight='24px'
                                    flex={1}
                                    textAlign='left'
                                >
                                    <Tag
                                        colorScheme={statusTagColors?.[selectedSessionData?.status]}
                                        size='md'
                                        variant='subtle'
                                        textTransform='capitalize'>
                                        {statusUpperCaseToLowerCase?.[selectedSessionData?.status] ?? '-'}
                                    </Tag>
                                </Text>
                            </Flex>
                        </ListItem>
                        <ListItem>
                            <Flex
                                alignItems='flex-start'
                                gap={10}>
                                <Heading
                                    w='30%'
                                    fontSize='medium'
                                >
                                    Created At
                                </Heading>
                                <Text
                                    fontSize='medium'
                                    lineHeight='24px'
                                    flex={1}
                                    textAlign='left'
                                >
                                    {formatDate(selectedSessionData?.createdAt)}
                                </Text>
                            </Flex>
                        </ListItem>
                        <ListItem>
                            <Flex
                                alignItems='flex-start'
                                gap={10}>
                                <Heading
                                    w='30%'
                                    fontSize='medium'
                                >
                                    Last Updated
                                </Heading>
                                <Text
                                    fontSize='medium'
                                    lineHeight='24px'
                                    flex={1}
                                    textAlign='left'
                                >
                                    {formatDate(selectedSessionData?.updatedAt)}
                                </Text>
                            </Flex>
                        </ListItem>

                    </UnorderedList>
                </CardBody>
            </Card>

        </>
    )

}