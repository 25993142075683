

import { GET_ALL_PROVIDERS_WITH_MANUAL_VERIFICATION } from '../../../utils'
import { getProviderById } from '../../../utils/provider-builder/register-provider-utils'
import { getFirebaseIdToken } from '../../../utils/utils'
import { typedCreateAsyncThunk } from '../../extraConfig'
import { updateProviderDetails } from '../../providerBuilder/providerDetails'
import { setLoading, setManualVerificationHttpsProvider, setSelectedManualVerificationSessionProviderData } from '.'


// This will fetch all HTTP providers that are active and have manual verification verification
export const getAllActiveHttpsProvidersWithManualVerification = typedCreateAsyncThunk<void>(
	'httpsProvider/getAllManualVerificationHttpsProviders',
	async (_, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		dispatch(setLoading(true))
		const response = await fetch(GET_ALL_PROVIDERS_WITH_MANUAL_VERIFICATION, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		})
		const data = await response.json()

		if (data) {
			dispatch(setLoading(false))
			dispatch(setManualVerificationHttpsProvider([...data.providers]))
		} else {
			return rejectWithValue(data.message)
		}
	}
)


export const getManualVerificationSelectedSessionProviderData = typedCreateAsyncThunk<any, { httpProviderId: string | undefined }>(
	'httpsProvider/getManualVerificationProviderData',
	async (payload, { dispatch, rejectWithValue }) => {
		const accessToken = (await getFirebaseIdToken()) ?? ''
		const httpProviderId = payload?.httpProviderId || ''

		const provider = await getProviderById(accessToken, httpProviderId)

		if (provider?.httpProviderId) {
			dispatch(setSelectedManualVerificationSessionProviderData(provider))
			dispatch(updateProviderDetails(provider))
		} else {
			return rejectWithValue(provider?.message ?? 'something went wrong while fetching provider data')
		}

	}
)