import { FirebaseApp } from 'firebase/app'
import { getAuth, IdTokenResult } from 'firebase/auth'

export const isTokenExpired = async (app: FirebaseApp): Promise<boolean> => {
	try {
		const auth = getAuth(app)
		const user = auth.currentUser
		if (!user) {
			return true // No user is signed in, so the token is considered expired.
		}

		const tokenResult: IdTokenResult = await user.getIdTokenResult()
		const expirationTimestamp = tokenResult.claims.exp
		if (typeof expirationTimestamp === 'number') {
			return expirationTimestamp * 1000 < Date.now()
		} else {
			return true
		}
	} catch (error) {
		console.error('Error while checking token expiry:', error)
		return true
	}
}