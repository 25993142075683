/* eslint-disable */
//@ts-nocheck
import { useState } from "react"
import { FaNode, FaReact, FaReacteurope } from "react-icons/fa"
import {
  Box,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode,
} from "@chakra-ui/react"
import { CodeBlock } from "../../../components"
import { pxToRem } from "../../../utils"

const CodeRender = ({ language, application }: any) => {

  switch (language) {
    case "nodeJS":
      return (
        <Box>
          <Text mb='10px' mt='20px'>
            Install the latest version of the SDK
          </Text>
          <CodeBlock
            code={INSTALL_COMMANDS.javaScript}
            fontSize='14px'
            language='shell'
            canCopy={true}
          />
          <Box mt='20px'>
            <Text mb='10px'>Initialize the SDK and contract on your project:</Text>

            <CodeBlock
              fontSize='14px'
              code={
                getCodeSinppet(application).nodeJS
              }
              language='javascript'
              canCopy={true}
            />
          </Box>
        </Box>
      )





    default:
      return (
        <CodeBlock
          code='Select a Framework/Library/Chains'
          language='bash'
          canCopy={true}
        />
      )
  }
}

export const CodeSegment = ({ application }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedLanguage, setSelectedLanguage] = useState("nodeJS")
  // const [isProduction, setIsProduction] = useState(false)
  const { colorMode } = useColorMode()
  const handleTabChange = (index: any) => {
    const languages = [

      "nodeJS",

    ]
    setSelectedLanguage(languages[index])
  }

  return (
    <Tabs onChange={handleTabChange} isLazy>
      <TabList>
        <Tab>
          <HStack>
            <FaNode size='24px' /> <Text fontSize={pxToRem(14)}>JavaScript</Text>
          </HStack>
        </Tab>
      </TabList>
      <TabPanels p={0}>
        <TabPanel p={0}>
          <CodeRender
            language='nodeJS'
            application={application}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export const INSTALL_COMMANDS = {
  javaScript: "npm i @reclaimprotocol/zk-fetch",

}

export const getCodeSinppet = (application: string) => {
  const nodeJS = `
const { ReclaimClient } = require("@reclaimprotocol/zk-fetch");

const client = new ReclaimClient("${application?.publicKey}", "${application?.privateKey}")

const publicOptions = {
    method: 'GET', // or POST
    headers : {
        accept: 'application/json, text/plain, */*' 
    }
}

const proof = await client.zkFetch('https://your.url.org',publicOptions)
return proof;
  `

  return { nodeJS }
}

