import { createSlice, isRejectedWithValue, PayloadAction } from '@reduxjs/toolkit'
import { fetchAllUsers, loginClient } from './actions'

// eslint-disable-next-line no-restricted-syntax
export enum UserType {
	ADMIN = 'ADMIN',
	USER = 'USER',
}

interface User {
	emailId: string
	password: string
	isLoggedIn: boolean
	isRegistrationSuccessful?: Boolean
	userType?: UserType
	accessToken?: string
	photoURL?: string
	allUsers?: string[]
	displayName?: string
}

const initialState: User = (() => {
	const storedUser = localStorage.getItem('user')
	const userType = localStorage.getItem('userType')
	const parsedUserType = userType ? JSON.parse(userType) : UserType.USER
	if(storedUser) {
		const user = JSON.parse(storedUser)
		return {
			emailId: user.email,
			password: '',
			isLoggedIn: true,
			isRegistrationSuccessful: false,
			userType: user.type ? user.type : parsedUserType,
			accessToken: user.accessToken ? user.accessToken : user?.stsTokenManager?.accessToken,
			displayName: user.name,
		}
	}


	return {
		emailId: '',
		password: '',
		isLoggedIn: false,
		isRegistrationSuccessful: false,
		userType: UserType.USER,
		accessToken: '',
		displayName: '',
	}
})()


const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserEmailId(state, action: PayloadAction<string>) {
			state.emailId = action.payload
		},
		setIsLoggedIn(state, action: PayloadAction<boolean>) {
			state.isLoggedIn = action.payload
		},
		setIsRegistrationSuccessful(state, action: PayloadAction<boolean>) {
			state.isRegistrationSuccessful = action.payload
		},
		setUserType(state, action: PayloadAction<UserType>) {
			state.userType = action.payload
		},
		setAccessToken(state, action: PayloadAction<string>) {
			state.accessToken = action.payload
		},
		setPhotoURL(state, action: PayloadAction<string>) {
			state.photoURL = action.payload
		},
		setAllUsers(state, action: PayloadAction<string[]>) {
			state.allUsers = action.payload
		},
		setUserDisplayName(state, action: PayloadAction<string>) {
			state.displayName = action.payload
		}
	},
	extraReducers(builder) {
		builder
			.addMatcher(isRejectedWithValue(loginClient), (state, action) => {
				console.log('isRejectedWithValue', action)
			})
		builder.addMatcher(isRejectedWithValue(fetchAllUsers), (state, action) => {
			console.log('isRejectedWithValue- fetch user', action)
		})
	},
})

export const { setUserEmailId, setIsLoggedIn, setIsRegistrationSuccessful, setUserType, setAccessToken, setPhotoURL, setAllUsers, setUserDisplayName } =
	userSlice.actions

export default userSlice.reducer
