/* eslint-disable */

import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Button,
  Grid,
  Heading,
  HStack,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useClipboard } from "@chakra-ui/react"
import { EmptyState } from "../../components"
import { usePapercupsWidget } from "../../hooks/usePapercupsWidget"
import { fetchAllApplications } from "../../redux/applications/actions"
import {
  getApplicationLoadingStatus,
  getApplications,
} from "../../redux/applications/selectors"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { GET_ALL_ACTIVE_PROVIDERS, ZKFETCH_APPLICATION, pxToRem } from "../../utils"
import { ApplicalicationList } from "./components/application-list.component"
import { AmplitudeAnalyticsContext } from "../../contexts/useAnalytics"
import { getFirebaseIdToken } from "../../utils/utils"
import { AppTypeModal } from "./components/app-type-modal.component"
import { ZkFetchApplicationCard } from "./components/zkfetch-application-card.component"

export const ApplicationsScreen = () => {

  const [allHTTPproviders, setAllHTTPProviders] = useState([])
  const [zkFetchApplications, setZkFetchApplications] = useState([])
  const [currentTabIndex, setCurrentTabIndex] = useState(document?.referrer?.includes('zkfetch') ? 1 : 0)
  const { logEvent, setDashboardVisitCount } = useContext(
    AmplitudeAnalyticsContext
  )

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { isOpen, onOpen, onClose } = useDisclosure();


  useEffect(() => {
    dispatch(fetchAllApplications())
  }, [])

  const applications = useAppSelector(getApplications)
  const applicationLoadingStatus = useAppSelector(getApplicationLoadingStatus)

  usePapercupsWidget()

  const { colorMode } = useColorMode()
  const toast = useToast()


  const fetchAllActiveProviders = useCallback(async () => {
    try {
      const accessToken = await getFirebaseIdToken()
      const response = await fetch(GET_ALL_ACTIVE_PROVIDERS, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      setAllHTTPProviders(data.providers)
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while fetching the providers.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }, [])


  const fetchAllZkFetchApplications = useCallback(async () => {
    try {
      const accessToken = await getFirebaseIdToken()
      const response = await fetch(ZKFETCH_APPLICATION, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()

      setZkFetchApplications(data.applications)
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while fetching the applications.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }
    , [])



  useEffect(() => {
    fetchAllActiveProviders()
    fetchAllZkFetchApplications()
  }, [])

  useEffect(() => {
    setDashboardVisitCount((prev) => prev + 1)
  }, [])

  const handleTabChange = (index: number) => {
    setCurrentTabIndex(index)
  }


  return (
    <>
      <Box p='16px'>
        <Box mt='16'>
          <HStack align='center' justifyContent='space-between'>
            <Box>
              <Heading as='h1' fontWeight={700} size='lg' fontSize='24px'>
                All Applications
              </Heading>
              <Text mt='8px'>All your applications are listed here.</Text>
            </Box>
            {applications?.length > 0 && (
              <>
                <Button
                  color={colorMode === "dark" ? "white" : "white"}
                  onClick={() => {
                    onOpen()
                  }}
                  colorScheme='blue'

                >
                  New Application
                </Button>
              </>
            )}
          </HStack>
        </Box>
        <Tabs
          mt={pxToRem(50)}
          onChange={handleTabChange}
          defaultIndex={currentTabIndex}>
          <TabList>
            <Tab>
              Applications
            </Tab>
            <Tab>
              zkFetch Applications
            </Tab>
            {/* <Tab>
								My Private Providers
							</Tab> */}
          </TabList>

          <TabPanels>
            <TabPanel p={0}>
              {applicationLoadingStatus ? (
                <>
                  <Grid
                    mt='30px'
                    gridTemplateColumns='repeat(auto-fill,minmax(400px,1fr))'
                    gap='22px'
                  >
                    {new Array(6).fill(0).map((_, index) => (
                      <Skeleton
                        key={index}
                        borderRadius='lg'
                        height='200px'
                        width='100%'
                      />
                    ))}
                  </Grid>
                </>
              ) : (
                <>
                  <Box mt={pxToRem(50)}>
                    {applications?.length > 0 ? (
                      <Grid
                        mt='30px'
                        gridTemplateColumns='repeat(auto-fill,minmax(400px,1fr))'
                        gap='22px'
                      >
                        {applications?.map((application: any) => (
                          <ApplicalicationList
                            allHTTPproviders={allHTTPproviders}
                            application={application}
                            key={application?.appId}
                          />
                        ))}
                      </Grid>
                    ) : (
                      <EmptyState
                        navigateTo='/new-application'
                        buttonText='New Application'
                        message="No existing applications found. Click the 'Create New Application' button to start."
                      />
                    )}
                  </Box>



                </>
              )}
            </TabPanel>
            <TabPanel p={0}>
              <Box mt={pxToRem(50)}>
                {zkFetchApplications?.length > 0 ? (
                  <Grid
                    mt='30px'
                    gridTemplateColumns='repeat(auto-fill,minmax(400px,1fr))'
                    gap='22px'
                  >
                    {zkFetchApplications?.map((application: any) => (
                      <ZkFetchApplicationCard
                        zkFetchApplications={application}
                        key={application?.appId}
                      />
                    ))}
                  </Grid>
                ) : (
                  <EmptyState
                    navigateTo='/new/public-data'
                    buttonText='New Application'
                    message="No existing zkFetch applications found. Click the 'Create New zkFetch Application' button to start."
                  />
                )
                }
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>

      </Box>

      <AppTypeModal isOpen={isOpen} onClose={onClose} />

    </>
  )
}
