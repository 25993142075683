import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Center, Heading, Image, useColorMode, VStack } from '@chakra-ui/react'
//@ts-ignore
import EmptyImage from '../../assets/empty.png'
import { AmplitudeAnalyticsContext } from '../../contexts/useAnalytics'
import { pxToRem } from '../../utils'
interface EmptyStateProps {
	message: string
	navigateTo: string
	buttonText?: string
}

export const EmptyState = (props: EmptyStateProps) => {
	const { message, navigateTo, buttonText } = props
	const navigate = useNavigate()
	const { colorMode } = useColorMode()

	const { logEvent } = useContext(AmplitudeAnalyticsContext)

	const clickhandler = () => {
		if (buttonText === 'New Application') {
			logEvent('NEW_APPLICATION_BUTTON_CLICK', {
				screen: 'EmptyState'
			})
		}

		if (buttonText === 'Create New Provider') {
			logEvent('CREATE_NEW_PROVIDER_BUTTON_CLICK',
				{
					screen: 'EmptyState'
				})
		}

		navigate(navigateTo)
	}

	return (
		<Box h='100%'>
			<Center>
				<VStack mt='100px'>
					<Image
						height={pxToRem(350)}
						width={pxToRem(350)}
						src={EmptyImage}
						alt='Empty' />
					<Heading
						fontSize={{ base: '16px', md: '16px', lg: '18px' }}
						fontWeight={500}
						textAlign='center'
						//letterSpacing={{ base: '0.02ch', md: '0.03ch', lg: '0.04ch' }}
						maxW='800px'
					>
						{message}
					</Heading>
					{
						navigateTo?.length > 0 && (
							<Button
								color={colorMode === 'dark' ? 'white' : 'white'}
								onClick={clickhandler}
								mt='10px'
								colorScheme='blue'
							>
								{buttonText}
							</Button>
						)
					}

				</VStack>
			</Center>
		</Box>
	)
}
