import { ProviderDetails } from '../../redux/providerBuilder/providerDetails/types'
import { EDIT_PROVIDER, GET_PROVIDER_BY_ID, REGISTER_PROVIDER } from '../constants'

async function registedProvider(providerDetails: ProviderDetails, accessToken: string): Promise<any> {
	if (!accessToken) {
		return { isSuccess: false, message: 'Access token is required' }
	}


	try {
		const data = await fetch(REGISTER_PROVIDER, {
			headers: {
				authorization: `Bearer ${accessToken}`,
				'content-type': 'application/json',
			},

			body: JSON.stringify(providerDetails),
			method: 'POST',
		})
		const message = await data.json()
		return message
	} catch (e) {
		return { isSuccess: false, message: 'Error while registering provider' }
	}
}

async function makeProviderActive(accessToken: string, providerId: string): Promise<any> {
	if (!accessToken) {
		return { isSuccess: false, message: 'Access token is required' }
	}

	try {
		const data = await fetch(`${GET_PROVIDER_BY_ID(providerId)}`, {
			headers: {
				authorization: `Bearer ${accessToken}`,
				'content-type': 'application/json',
			},

			body: JSON.stringify({ isActive: true }),
			method: 'POST',
		})
		const message = await data.json()
		return message
	} catch (e) {
		return { isSuccess: false, message: 'Error while updating provider' }
	}
}

async function getProviderById(accessToken: string, providerId: string): Promise<any> {
	if (!accessToken) {
		return { isSuccess: false, message: 'Access token is required' }
	}

	try {
		const data = await fetch(`${GET_PROVIDER_BY_ID(providerId)}`, {
			headers: {
				authorization: `Bearer ${accessToken}`,
				'content-type': 'application/json',
			},
			method: 'GET',
		})
		const message = await data.json()
		return message.providers
	} catch (e) {
		return { isSuccess: false, message: 'Error while fetching provider' }
	}
}

async function editProvider(providerDetails: ProviderDetails, accessToken: string): Promise<any> {
	if (!accessToken) {
		return { isSuccess: false, message: 'Access token is required' }
	}

	try {
		const data = await fetch(EDIT_PROVIDER(providerDetails.httpProviderId), {
			headers: {
				authorization: `Bearer ${accessToken}`,
				'content-type': 'application/json',
			},

			body: JSON.stringify(providerDetails),
			method: 'POST',
		})
		const message = await data.json()
		return message
	} catch (e) {
		return { isSuccess: false, message: 'Error while editing provider' }
	}
}


export {
	registedProvider,
	makeProviderActive,
	getProviderById,
	editProvider
}