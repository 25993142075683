import { SetStateAction, useState } from 'react'
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { getCurrentUrlInput } from '../../../../redux/providerBuilder/mobileClient/selectors'
import { updateActiveStep } from '../../../../redux/providerBuilder/stepperDetails'

interface NavigateUrlProps {
    setActiveStepInnerForm: (value: SetStateAction<number>) => void
}

export const NavigateUrl = (props: NavigateUrlProps) => {
	const dispatch = useAppDispatch()
	const currentUrlInput = useAppSelector(getCurrentUrlInput)

	const [buttonLoading, setButtonLoading] = useState(false)
	const { setActiveStepInnerForm } = props


	const handleNext = () => {

		setButtonLoading(true)
		dispatch(updateActiveStep(1))
	}

	return (
		<>
			<Box
				marginBottom='2.5rem'
				alignItems='centre'
				justifyContent='center'
				height='100%'
			>
				<Box minH='80%'>
					<Flex
						fontSize='lg'
						alignItems='center'
						gap={4}
						mb='50px'>
						<Heading
							fontSize='larger'
						>
							Navigate to the webpage that has the data which you want to verify.
						</Heading>
					</Flex>
					<Flex
						fontSize='lg'
						maxW='830px'
						gap={4}>
						<Text
							fontSize='large'
							textAlign='left'
							verticalAlign='top'>
							Current URL:
						</Text>
						<Text
							fontSize='large'
							color='blue'
							overflow='hidden'
							whiteSpace='pre-wrap'>
							{currentUrlInput}
						</Text>
					</Flex>
				</Box>
				<Flex
					justifyContent='flex-end'
					minW='100%'>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={() => setActiveStepInnerForm(2)}
						width='10%'
						mr='30px'
					>
						Back
					</Button>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={handleNext}
						colorScheme='blue'
						width='10%'
						mr='30px'
						isLoading={buttonLoading}
					>
						Next
					</Button>
				</Flex>
			</Box>
		</>
	)
}