/* eslint-disable indent */

export function isJSONParsable(str: string) {
	try {
		JSON.parse(str)
		return true
	} catch (e) {
		return false
	}
}
