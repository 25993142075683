import { HiClipboard } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, Heading, HStack, IconButton, Input, Text, useColorMode, VStack } from '@chakra-ui/react'
import { pxToRem } from '../../../utils'


export const ZkFetchApplicationCard = ({ zkFetchApplications }: any) => {


	const { colorMode } = useColorMode()
	const navigate = useNavigate()
	return (
		<>
			<Box
				_hover={
					{
						borderColor: 'blue.500',
					}
				}
				bg={colorMode === 'dark' ? '#131417' : '#fff'}
				borderRadius='lg'
				borderColor='#e5e5ea'
				border={`1px solid ${colorMode === 'dark' ? 'transparent' : '#e5e5ea'}`}
			>
				<Box p={pxToRem(14)}>
					<HStack justifyContent='space-between'>
						<HStack >


							<Avatar

								name={zkFetchApplications?.applicationName || ''}
								//name={provider?.logoUrl === '' ? '' : provider?.logoUrl}
								cursor='pointer'
								height={pxToRem(40)}
								width={pxToRem(40)}
								//onMouseEnter={() => setHoveredId(provider.httpProviderId)} // Set hoveredId on mouse enter
								//onMouseLeave={() => setHoveredId('')} // Clear hoveredId on mouse leave
								//onClick={() => handleCopy(provider.httpProviderId)} // Copy ID on click
								//src={provider?.logoUrl}
							/>


						</HStack>

						{/* <Menu>
							<MenuButton>
								<IconButton
									ml='2'
									size='sm'
									icon={<FaEllipsisH />}
									onClick={() => true}
									aria-label='Copy Secret Key'
								/>
							</MenuButton>
							<MenuList>
								<MenuItem
									as='a'
									onClick={() => true}>
									<EditIcon mr={pxToRem(8)} />
									Edit
								</MenuItem>
								<MenuItem
									as='a'
									onClick={() => true}>
									<DeleteIcon mr={pxToRem(8)} />
									Delete
								</MenuItem>
							</MenuList>
						</Menu> */}
					</HStack>
					<Heading
						as='h2'
						fontWeight={600}
						lineHeight='21px'
						size='sm'
						mt={pxToRem(10)}
						fontSize='14px'
					>

						{zkFetchApplications?.applicationName ?? 'No Name'}
					</Heading>
					<Text
						mt='4px'
						fontWeight={400}
						fontSize='14px'>
						{
							zkFetchApplications?.description
								? zkFetchApplications?.description
								: 'No Description'
						}
					</Text>
				</Box>

				<Box
					borderTop='1px'
					borderColor='#e5e5ea'
					pt={pxToRem(10)}
					pl={pxToRem(14)}
					pr={pxToRem(14)}
					pb={pxToRem(14)}
				>
					<HStack
						justifyContent='space-between'
						alignItems='flex-end'>
						<HStack alignItems='flex-end'>
							<VStack
								alignItems='flex-start'
								gap={pxToRem(4)}>
								<Text
									fontWeight={600}
									fontSize='14px'>
									App ID
								</Text>
								<Input
									type='text'
									value={zkFetchApplications?.publicKey}
									readOnly
									size='sm'
									fontSize={pxToRem(12)}
									borderRadius='lg'
									minW={pxToRem(300)}
									style={
										{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}
									}
								/>
							</VStack>

							<IconButton
								ml='0'
								size='sm'
								icon={<HiClipboard />}
								aria-label='Copy Secret Key'
							/>
						</HStack>
						<Button
							colorScheme='blue'
							size='sm'
							fontSize={pxToRem(12)}
							variant='outline'
							onClick={
								() => {
									navigate(`/public-data/preview/${zkFetchApplications?.publicKey}`
									)
								}
							}
						>
							View Code
						</Button>
					</HStack>
				</Box>

			</Box>

		</>
	)

}