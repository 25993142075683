import { useEffect, useState } from 'react'
import { FiRefreshCcw } from 'react-icons/fi'
import { ArrowBackIcon, SearchIcon } from '@chakra-ui/icons'
import {
	Box,
	Card,
	CardBody,
	Code,
	Flex,
	Heading,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Select,
	Table,
	TableContainer,
	Tag,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tooltip,
	Tr,
	useColorMode,
	VStack
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { fetchAllManualVerificationSessions } from '../../../../redux/manualVerification/sessionsDetails/actions'
import { getAllManualVerificationSessions, getManualVerificationSessionLoadingStatus, getManualVerificationSessionProviderName } from '../../../../redux/manualVerification/sessionsDetails/selectors'
import { ManualVerificationSession } from '../../../../redux/manualVerification/sessionsDetails/types'
import { setSelectedProviderId, setSelectedSessionId, updateActiveStep } from '../../../../redux/manualVerification/stepperDetails'
import { getManualVerificationSelectedProvierId } from '../../../../redux/manualVerification/stepperDetails/selectors'
import { pxToRem } from '../../../../utils'
import { formatDate } from '../../../../utils/format-date'
import { statusTagColors, statusUpperCaseToLowerCase } from '../../../../utils/manual-verification-session/manual-verification-constants'
import { sortByCreatedAtDesc } from '../../../../utils/utils'


const availableSessionStatus: string[] = ['ALL', 'VERIFIED', 'PENDING', 'REJECTED']


export const ManualVerificationSessionsScreen = () => {
	const httpProviderName = useAppSelector(getManualVerificationSessionProviderName)
	const httpProviderId = useAppSelector(getManualVerificationSelectedProvierId)

	const allManualVerificationSessions = useAppSelector(getAllManualVerificationSessions)
	const fetchingManualVerificationSessions = useAppSelector(getManualVerificationSessionLoadingStatus)

	const dispatch = useAppDispatch()

	const { colorMode } = useColorMode()

	const [searchQuery, setSearchQuery] = useState('')
	const [filteredResponse, setFilteredResponse] = useState<ManualVerificationSession[]>([])
	const [selectedSessionStatusFilter, setSelectedSessionStatusFilter] = useState<string>('ALL')


	useEffect(() => {
		dispatch(fetchAllManualVerificationSessions({ httpProviderId, queryType: 'ALL' }))
	}, [])

	useEffect(() => {
		if (searchQuery || selectedSessionStatusFilter !== 'ALL') {
			const filtered = filterSessions(
				allManualVerificationSessions,
				searchQuery,
				selectedSessionStatusFilter,
			)
			setFilteredResponse(sortByCreatedAtDesc([...filtered]))
		} else {
			setFilteredResponse(sortByCreatedAtDesc([...allManualVerificationSessions]))
		}

	}, [searchQuery, allManualVerificationSessions, selectedSessionStatusFilter])


	function filterSessions(sessions: ManualVerificationSession[], searchQuery: string, selectedSessionStatusFilter: string) {
		return sessions.filter((session) => {
			const matchesSearchQuery = searchQuery?.length
				? session.parameters.some((param) => param.value?.toString().includes(searchQuery)) ||
				session.sessionId.toString().includes(searchQuery) ||
				session.appId.toString().includes(searchQuery)
				: true

			const matchesStatusFilter = selectedSessionStatusFilter !== 'ALL'
				? session.status === selectedSessionStatusFilter
				: true

			return matchesSearchQuery && matchesStatusFilter
		})
	}

	function handleGoBack() {
		dispatch(setSelectedProviderId(''))
		dispatch(updateActiveStep(1))
	}

	function showNetworkRequestDump(sessionId: string) {
		dispatch(setSelectedSessionId(sessionId))
		dispatch(updateActiveStep(3))
	}

	function handleRefreshSessionsData() {
		dispatch(fetchAllManualVerificationSessions({ httpProviderId, queryType: 'ALL' }))
	}

	// console.log({ filteredResponse })

	return (
		<>
			<Card>
				<CardBody>
					<Box
						width='100%'
						height='100%'>
						<VStack>
							<Box
								borderBottom='2px solid #F2F4F7'
								w='full'
								pb={5}
								mb={5}
							>
								<Flex>
									<Box flex={0.8}>
										<Flex
											alignItems='center'
											mb={1}
											gap={3}>
											<IconButton
												variant='outline'
												size='sm'
												icon={<ArrowBackIcon />}
												onClick={handleGoBack}
												aria-label='Go Back' />
											<Heading
												fontSize='large'
											>
												Explore Sessions for
												{' '}
												{httpProviderName}
											</Heading>
										</Flex>
										<Text
											fontSize='medium'
											lineHeight='24px'
											color='#98A2B3'
										>
											This data represents all the manual verification sessions
											<br />
											{' '}
											available for
											{' '}
											{httpProviderName}
										</Text>
									</Box>

								</Flex>
							</Box>

							<Box
								borderBottom='2px solid #F2F4F7'
								w='full'
								pb={5}
								mb={5}
							>
								<Flex
									gap={pxToRem(20)}
									alignItems='center'
									justify='space-between'
								>
									<InputGroup w={500}>
										<InputRightElement pointerEvents='none'>
											<SearchIcon color='gray.300' />
										</InputRightElement>
										<Input
											value={searchQuery}
											onChange={(e) => setSearchQuery(e.target.value)}
											type='search'
											placeholder='Search Sessions'
											bg={colorMode === 'light' ? 'white' : '#131417'}

										/>
									</InputGroup>
									<Flex gap={5}>
										<Flex
											alignItems='center'
											gap={2}>
											<Heading>
												Session Status:
											</Heading>
											<Select
												size='md'
												w={200}
												bgColor='transparent'
												onChange={e => setSelectedSessionStatusFilter(e.target.value)}
												value={selectedSessionStatusFilter}
											>
												{
													availableSessionStatus.map((status: string) => (
														<option
															key={status}
															value={status}>
															{status}
														</option>
													))
												}
											</Select>
										</Flex>
										<Tooltip
											label='Refresh'
											placement='right-start'>
											<IconButton
												variant='outline'
												colorScheme='teal'
												aria-label='Refresh Session'
												icon={<FiRefreshCcw />}
												isLoading={fetchingManualVerificationSessions}
												onClick={handleRefreshSessionsData}
											/>
										</Tooltip>
									</Flex>


								</Flex>
								<Box
									w='full'
									scrollMarginLeft={10}
									overflow='auto'
									maxHeight='500px'
									borderWidth={1}
									borderRadius={10}
									mt={5}
								>
									<TableContainer>
										<Table
											variant='simple'
											border='ActiveBorder'
											borderRadius='4px'
											borderColor='black'
										>
											<Thead>
												<Tr>
													<Th
														color='#000'
														style={{ fontWeight: 'bolder' }}>
														Session Id
													</Th>
													<Th
														color='#000'
														style={{ fontWeight: 'bolder' }}>
														Application Id
													</Th>
													<Th
														color='#000'
														style={{ fontWeight: 'bolder' }}>
														Parameters to generate proof
													</Th>
													<Th
														color='#000'
														style={{ fontWeight: 'bolder' }}>
														Session Status
													</Th>
													<Th
														color='#000'
														style={{ fontWeight: 'bolder' }}>
														Created At
													</Th>
													<Th
														color='#000'
														style={{ fontWeight: 'bolder' }}>
														Webhook Status
													</Th>
													<Th
														color='#000'
														style={{ fontWeight: 'bolder' }}>
														Last Webhook Attempt
													</Th>
													<Th
														color='#000'
														style={{ fontWeight: 'bolder' }}>
														Error Message
													</Th>
												</Tr>
											</Thead>
											<Tbody>
												{
													filteredResponse?.map((e: any) => (
														<Tr
															key={e.id}
															onClick={
																() => {
																	showNetworkRequestDump(e?.sessionId)
																}
															}
															cursor='pointer'
															_hover={{ bg: 'gray.50' }}

														>

															<Td
																maxW='500px'
																minW='400px'
																overflow='hidden'
																whiteSpace='nowrap'
																textOverflow='ellipsis'
																p={3}>
																<Text
																>
																	{e?.sessionId}
																</Text>

															</Td>
															<Td
																maxW='500px'
																minW='400px'
																overflow='hidden'
																whiteSpace='nowrap'
																textOverflow='ellipsis'
																p={3}>
																<Text
																>
																	{e?.appId}
																</Text>

															</Td>
															<Td
																maxW='100px'
																overflow='hidden'
																whiteSpace='wrap'
																textOverflow='ellipsis'>
																<Flex direction='column'>
																	{
																		e?.parameters?.map((param: any) => (
																			<Code
																				key={param?.key}
																				bgColor='transparent'>
																				{param?.key}
																				{' '}
																				-
																				{' '}
																				{param?.value}
																			</Code>
																		))
																	}
																</Flex>
															</Td>
															<Td
																maxW='500px'
																overflow='hidden'
																whiteSpace='nowrap'
																textOverflow='ellipsis'
															>
																<Tag
																	colorScheme={statusTagColors?.[e?.status]}
																	size='md'
																	variant='subtle'
																	textTransform='capitalize'>
																	{statusUpperCaseToLowerCase?.[e?.status] ?? '-'}
																</Tag>
															</Td>
															<Td
																maxW='500px'
																overflow='hidden'
																whiteSpace='nowrap'
																textOverflow='ellipsis'>
																<Text
																>
																	{formatDate(e?.createdAt)}
																</Text>
															</Td>
															<Td
																maxW='500px'
																overflow='hidden'
																whiteSpace='nowrap'
																textOverflow='ellipsis'>
																<Tag
																	colorScheme={statusTagColors?.[e?.webhooks?.[0]?.status]}
																	size='md'
																	textTransform='capitalize'>
																	{statusUpperCaseToLowerCase?.[e?.webhooks?.[0]?.status] ?? 'NA'}
																</Tag>
															</Td>
															<Td
																maxW='500px'
																overflow='hidden'
																whiteSpace='nowrap'
																textOverflow='ellipsis'>
																<Text
																>
																	{e?.webhooks?.[0]?.lastAttemptedAt ? formatDate(e?.webhooks?.[0]?.lastAttemptedAt) : 'NA'}
																</Text>
															</Td>
															<Td
																maxW='500px'
																overflow='hidden'
																whiteSpace='nowrap'
																textOverflow='ellipsis'>
																<Text
																>
																	{e?.webhooks?.[0]?.errorMessage ?? '-'}
																</Text>
															</Td>
														</Tr>
													))
												}
											</Tbody>
										</Table>
									</TableContainer>
								</Box>
							</Box>

						</VStack>
					</Box>
				</CardBody>
			</Card>
		</>
	)
}

