
import { createBrowserRouter } from 'react-router-dom'
import { Layout } from '../components'
//import Dashboard from '../components/Dashboard/Dashboard'
import { ErrorPage } from '../error-page'
import {
	AdminDashboardScreen,
	ApplicationsScreen,
	ExploreScreen,
	FirebaseSessionScreen,
	IntegrationDetails,
	IntegrationDetailsForPublicData,
	LandingScreen,
	LoaderScreen,
	ManualVerificationScreen,
	NewApplicationScreen,
	ProfileScreen,
	ProviderBuilderScreen,
	ProviderEditScreen,
	PublicDataVerificationScreen,
	SettingsScreen
} from '../screens'
import { ProtectedRouteWrapper } from '../screens/landing/components/protected-route-wrapper/protected-route-wrapper.component'
import { RedirectScreen } from '../screens/provider-builder-redirect/redirect-screen'
import { ProviderBuilderV3Screen } from '../screens/provider-builder-v3/provider-builder-v3.screen'
import { ProvidersScreen } from '../screens/providers/providers.screen'
//import AddProvider from './AddProvider'

export const routerConfig = createBrowserRouter([
	{
		path: '/',
		element: <Layout />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: '/',
				element: <LandingScreen />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/firebase-session',
				element: <FirebaseSessionScreen />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/dashboard',
				element: (
					<ProtectedRouteWrapper>
						<ApplicationsScreen />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			},
			// {
			// 	path: '/add/provider',
			// 	element: <AddProvider />,
			// 	errorElement: <ErrorPage />,
			// },
			{
				path: '/applications',
				element: (
					<ProtectedRouteWrapper>
						<ApplicationsScreen />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/new/public-data',
				element: (
					<ProtectedRouteWrapper>
						<PublicDataVerificationScreen />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,

			},
			{
				path: '/providers',
				element: (
					<ProtectedRouteWrapper>
						<ProvidersScreen />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/profile',
				element: <ProfileScreen />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/settings',
				element: <SettingsScreen />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/new-application',
				element: (
					<ProtectedRouteWrapper>
						{' '}
						<NewApplicationScreen />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/admin/dashboard',
				element: (
					<ProtectedRouteWrapper>
						<AdminDashboardScreen />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/explore',
				element: (
					<ProtectedRouteWrapper>
						<ExploreScreen />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/provider/:id',
				element: (
					<ProtectedRouteWrapper>
						<ProviderEditScreen />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/tutorials',
				element: <ErrorPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/devtoolv3',
				element: (
					<ProtectedRouteWrapper>
						<ProviderBuilderV3Screen />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/devtoolv2',
				element: (
					<ProtectedRouteWrapper>
						<ProviderBuilderScreen />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/code-preview/:appId',
				element: (
					<ProtectedRouteWrapper>
						<IntegrationDetails />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/public-data/preview/:appId',
				element: (
					<ProtectedRouteWrapper>
						<IntegrationDetailsForPublicData />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/welcome',
				element: <LandingScreen />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/admin/init',
				element: <LoaderScreen />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/manual-verification',
				element: (
					<ProtectedRouteWrapper>
						<ManualVerificationScreen />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/manual-verification/sessions/:sessionId',
				element: (
					<ProtectedRouteWrapper>
						<ManualVerificationScreen />
					</ProtectedRouteWrapper>
				),
				errorElement: <ErrorPage />,
			}
		],
	},
	{
		path: '/devtool/redirect',
		element: <RedirectScreen />,
		errorElement: <ErrorPage />,
	},
])
