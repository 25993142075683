/* eslint-disable @typescript-eslint/no-unused-vars */
import { SetStateAction, useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { ChevronDownIcon, ChevronUpIcon, DeleteIcon } from '@chakra-ui/icons'
import {
	Box,
	Button,
	Card,
	CardBody,
	Collapse,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	IconButton,
	Input,
	Select,
	Text,
	Textarea,
	Tooltip,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateProviderDetails } from '../../../../redux/providerBuilder/providerDetails'
import { getProviderDetails } from '../../../../redux/providerBuilder/providerDetails/selectors'
import {
	deleteSelectedInstance,
	updateResponseSelection,
} from '../../../../redux/providerBuilder/selectedInstances'
import { getSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances/selectors'

export const OptionalFields = () => {
	const dispatch = useAppDispatch()

	const selectedInstances = useAppSelector(getSelectedInstances)
	const providerDetails = useAppSelector(getProviderDetails)
	const userType = useAppSelector((state) => state.user.userType)

	const countryCodes = require('../../../../utils/data/country-codes.json')

	const [jsInjection, setJsInjection] = useState('')
	const [geoLocation, setGeoLocation] = useState('')
	const [providerType, setProviderType] = useState(providerDetails?.providerType ?? 'PRIVATE')

	const [show, setShow] = useState(false)

	const handleToggle = () => setShow(!show)


	useEffect(() => {
		dispatch(
			updateProviderDetails({
				customInjection: jsInjection,
				isApproved: !jsInjection || userType === 'ADMIN',
			})
		)
	}, [jsInjection])

	useEffect(() => {
		dispatch(updateProviderDetails({ geoLocation: geoLocation }))
	}, [geoLocation])

	useEffect(() => {
		dispatch(updateProviderDetails({ providerType: providerType }))
	}, [providerType])

	useEffect(() => {
		const allResponseSelections = selectedInstances.map(
			(instance: any) => instance.responseSelection
		)
		dispatch(
			updateProviderDetails({ responseSelections: allResponseSelections })
		)
	}, [selectedInstances])


	return (
		<>
			<Box
				w='full'
				borderBottom='1px solid #EFF1F5'
				py={5}>
				<Button
					onClick={handleToggle}
					colorScheme='black'
					variant='outline'
					borderColor='black.500'
					borderWidth='1px'
					width='100%'
					rightIcon={show ? <ChevronUpIcon /> : <ChevronDownIcon />}
					mb={4}
				>
					Additional Config
				</Button>
				<Collapse in={show}>
					<Box w='full'>
						<Flex gap='50px'>
							<Box flex={1}>
								<Flex gap={10}>
									<Flex
										direction='column'
										gap={2}
										py={5}
										px={1}
										borderBottom='1px solid #EFF1F5'
										flex={1}
									>
										<Heading
											fontSize='md'
										>
											Geolocation
										</Heading>

										<FormControl
											id='geolocation'
											mb={4}>
											<Select
												placeholder='None'
												value={geoLocation}
												onChange={
													(e) => {
														setGeoLocation(e.target.value)
													}
												}
												style={
													{
														color: 'black',
														borderRadius: '8px',
														border: '1px solid #D0D5DD',
														fontSize: '15px',
														backgroundColor: 'transparent'

													}
												}
											>
												{
													countryCodes.map(
														(countryCode: { Code: string, Name: string }) => (
															<option
																key={countryCode.Code}
																value={countryCode.Code}>
																{countryCode.Name}
															</option>
														)
													)
												}
											</Select>
										</FormControl>
									</Flex>
									<Flex
										direction='column'
										gap={2}
										py={5}
										px={1}
										flex={1}
										borderBottom='1px solid #EFF1F5'
									>
										<Heading
											fontSize='md'
										>
											Provider Type
										</Heading>
										<FormControl
											id='provider-type'
											mb={4}>
											<Tooltip
												label={
													providerType === 'PUBLIC'
														? 'Public Providers Need Successful Proof Generation to be published'
														: ''
												}
												aria-label='A tooltip'
											>
												<Select
													value={providerType}
													onChange={
														(e) => {
															setProviderType(e.target.value)
														}
													}
													style={
														{
															color: 'black',
															borderRadius: '8px',
															border: '1px solid #D0D5DD',
															fontSize: '15px',
															backgroundColor: 'transparent'

														}
													}
												>
													<option value='PRIVATE'>
														Private
													</option>

													<option value='PUBLIC'>
														Public
													</option>
												</Select>
											</Tooltip>
										</FormControl>
									</Flex>
								</Flex>

							</Box>
						</Flex>
					</Box>
					<Box w='full'>
						<Flex gap='50px'>
							<Box flex={1}>
								<Flex gap={5}>
									<Flex
										direction='column'
										gap={2}
										py={5}
										px={1}
										borderBottom='1px solid #EFF1F5'
										flex={1}
									>
										<Heading
											fontSize='md'
										>
											Variable Details
										</Heading>

										<Flex
											direction='column'
											gap={5}>
											{
												selectedInstances.map((instance: any, i: any) => (
													<Card
														variant='outline'
														key={i}
														position='relative'>
														{
															selectedInstances.length > 1 && (
																<IconButton
																	aria-label='Search database'
																	icon={<DeleteIcon fontSize='0.8rem' />}
																	position='absolute'
																	right={3}
																	top={2}
																	variant='outline'
																	colorScheme='red'
																	size='xs'
																	onClick={
																		() => {
																			dispatch(deleteSelectedInstance(i))
																		}
																	}
																/>
															)
														}
														<CardBody>
															<Flex
																direction='column'
																gap={2}

															>
																<Flex
																	alignItems='center'
																	mt={7}
																>
																	<Flex
																		alignItems='center'
																		w='full'>
																		<Text
																			style={{ textWrap: 'nowrap' }}
																			fontWeight={600}
																			fontSize='15px'
																			color='#475467'
																			whiteSpace='nowrap'
																			textOverflow='ellipsis'
																			minW='150px'>
																			Variable Name:
																		</Text>
																		<Text
																			border='1px solid #D0D5DD'
																			px='12px'
																			py='8px'
																			borderRadius='8px'
																			fontSize='15px'
																			color='#000'
																			whiteSpace='nowrap'
																			textOverflow='ellipsis'
																			overflow='hidden'
																			w='full'
																			cursor='not-allowed'
																		>
																			{instance?.variableName ?? ''}
																		</Text>
																	</Flex>

																</Flex>
																<Flex
																	alignItems='center'
																	w='full'>
																	<Text
																		style={{ textWrap: 'nowrap' }}
																		fontWeight={600}
																		fontSize='15px'
																		color='#475467'
																		whiteSpace='nowrap'
																		textOverflow='ellipsis'
																		minW='150px'>
																		Regex:
																	</Text>

																	<Input
																		value={instance.responseSelection.responseMatch}
																		style={
																			{
																				color: 'black',
																				borderRadius: '8px',
																				border: '1px solid #D0D5DD',

																			}
																		}
																		px='12px'
																		py='8px'
																		fontSize='15px'
																		onChange={
																			(e) => {
																				dispatch(
																					updateResponseSelection({
																						index: i,
																						responseSelection: {
																							xPath: instance.responseSelection.xPath,
																							jsonPath: instance.responseSelection.jsonPath,
																							responseMatch: e.target.value,
																							invariant: instance.invariant,
																						},
																					})
																				)
																			}
																		}
																	/>
																</Flex>

																<Flex
																	alignItems='center'
																	w='full'>
																	<Text
																		style={{ textWrap: 'nowrap' }}
																		fontWeight={600}
																		fontSize='15px'
																		color='#475467'
																		whiteSpace='nowrap'
																		textOverflow='ellipsis'
																		minW='150px'>
																		{
																			instance.responseSelection?.xPath
																				? 'Xpath:'
																				: 'JsonPath:'
																		}
																	</Text>

																	<Input
																		value={
																			instance.responseSelection?.xPath
																				? instance.responseSelection.xPath
																				: instance.responseSelection.jsonPath
																		}
																		style={
																			{
																				color: 'black',
																				borderRadius: '8px',
																				border: '1px solid #D0D5DD',
																			}
																		}
																		px='12px'
																		py='8px'
																		fontSize='15px'
																		onChange={
																			(e) => {
																				if (instance.responseSelection.xPath) {
																					dispatch(
																						updateResponseSelection({
																							index: i,
																							responseSelection: {
																								xPath: e.target.value,
																								jsonPath: instance.responseSelection.jsonPath,
																								responseMatch:
																									instance.responseSelection.responseMatch,
																								invariant: instance.invariant,
																							},
																						})
																					)
																				} else {
																					dispatch(
																						updateResponseSelection({
																							index: i,
																							responseSelection: {
																								xPath: instance.responseSelection.xPath,
																								jsonPath: e.target.value,
																								responseMatch:
																									instance.responseSelection.responseMatch,
																								invariant: instance.invariant,
																							},
																						})
																					)
																				}
																			}
																		}
																	/>
																</Flex>


															</Flex>
														</CardBody>
													</Card>

												))
											}
										</Flex>


									</Flex>

								</Flex>

							</Box>
						</Flex>
					</Box>
					<Box w='full'>
						<Flex gap='50px'>
							<Box flex={1}>
								<Flex gap={5}>
									<Flex
										direction='column'
										gap={2}
										py={5}
										px={1}
										borderBottom='1px solid #EFF1F5'
										flex={1}
									>
										<Heading
											fontSize='md'
										>
											JS Injection
										</Heading>
										<FormControl id='js-injection'>
											<Text>
												<Tooltip
													label='Providers with Custom Injection are subjected to admin approval'
													aria-label='A tooltip'
												>
													<Textarea
														placeholder='JS Injection to run on the website'
														required={false}
														value={jsInjection}
														style={
															{
																color: 'black',
																borderRadius: '8px',
																border: '1px solid #D0D5DD',

															}
														}
														px='12px'
														py='8px'
														fontSize='15px'
														onChange={
															(e: {
																target: { value: SetStateAction<string> }
															}) => setJsInjection(e.target.value)
														}
													/>
												</Tooltip>
											</Text>
										</FormControl>
									</Flex>
								</Flex>

							</Box>
						</Flex>
					</Box>
				</Collapse>
			</Box>


		</>
	)
}
