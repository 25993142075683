// export const GET_ALL_CUSTOM_PROVIDERS_ENDPOINT =
//   'https://api.reclaimprotocol.org/get/custom-providers'

import { getApiUrl } from './utils'

export const GET_ALL_CUSTOM_PROVIDERS_ENDPOINT =
	`${getApiUrl()}/get/custom-providers`


// export const GET_ALL_COMMUNITY_PROVIDERS_ENDPOINT =
// 	'https://api.reclaimprotocol.org/get/httpsproviders'

export const GET_ALL_COMMUNITY_PROVIDERS_ENDPOINT =
	`${getApiUrl()}/get/httpsproviders`


export const DOCS_LINKS = [
	{
		name: 'NodeJS',
		icon: 'https://docs.reclaimprotocol.org/assets/icons/node.svg',
		docsLink: 'https://docs.reclaimprotocol.org/node',
	},
	{
		name: 'React',
		icon: 'https://docs.reclaimprotocol.org/assets/icons/reactjs.svg',
		docsLink: 'https://docs.reclaimprotocol.org/react',
	},
	{
		name: 'React Native',
		icon: 'https://docs.reclaimprotocol.org/assets/icons/react_native.svg',
		docsLink: 'https://docs.reclaimprotocol.org/react-native',
	},
	{
		name: 'Flutter',
		icon: 'https://docs.reclaimprotocol.org/assets/icons/flutter.svg',
		docsLink: 'https://docs.reclaimprotocol.org/flutter/quickstart',
	},
	{
		name: 'Solidity',
		icon: 'https://docs.soliditylang.org/en/latest/_static/img/logo-dark.svg',
		docsLink: 'https://docs.reclaimprotocol.org/solidity',
	},
	{
		name: 'Solana',
		icon: 'https://solana.com/_next/static/media/solanaLogoMark.17260911.svg',
		docsLink: 'https://docs.reclaimprotocol.org/solana',
	},
]


// API ENDPOINTS

// USERS
export const LOGIN = `${getApiUrl()}/api/users/login`
export const GET_ALL_USERS = `${getApiUrl()}/api/users/all-users`

// APPLICATIONS
export const GET_USER_APPLICATIONS = `${getApiUrl()}/api/applications`
export const APPLICATION_BY_ID = (id: string) => `${getApiUrl()}/api/applications/${id}`
export const REGISTER_APPLICATION = `${getApiUrl()}/api/applications`
export const GET_SIGNED_URL_FOR_APPLICATION_LOGO = `${getApiUrl()}/api/applications/get-upload-url`
export const GET_PROVIDERS_BY_APPLICATION_ID = (id: string) => `${getApiUrl()}/api/applications/providers/${id}`
export const EDIT_APPLICATION = (id: string) => `${getApiUrl()}/api/applications/edit/${id}`
export const DELETE_APPLICATION = (id: string) => `${getApiUrl()}/api/applications/${id}`

// ZKFETCH APPLICATIONS
export const ZKFETCH_APPLICATION = `${getApiUrl()}/api/zkfetch`
export const ZKFETCH_APPLICATION_BY_ID = (id: string) => `${getApiUrl()}/api/zkfetch/${id}`


// PROVIDERS
export const REGISTER_PROVIDER = `${getApiUrl()}/api/providers/register`
export const EDIT_PROVIDER = (id: string) => `${getApiUrl()}/api/providers/${id}`
export const GET_ALL_ACTIVE_PROVIDERS = `${getApiUrl()}/api/providers/active`
export const GET_ALL_INACTIVE_PROVIDERS = `${getApiUrl()}/api/providers/inactive`
export const GET_ALL_PROVIDERS_WITH_MANUAL_VERIFICATION = `${getApiUrl()}/api/providers/manual-verification`
export const GET_APPROVED_PROVIDERS_BY_USER = (userId: string) => `${getApiUrl()}/api/providers/approved/${userId}`
export const GET_PENDING_PROVIDERS_BY_USER = (userId: string) => `${getApiUrl()}/api/providers/pending/${userId}`
export const GET_ALL_PENDING_PROVIDERS = `${getApiUrl()}/api/providers/pending`
export const GET_PROVIDER_BY_ID = (id: string) => `${getApiUrl()}/api/providers/${id}`
export const GET_ALL_PROVIDER_CREATED_BY_USER = `${getApiUrl()}/api/providers/user`
export const APPROVE_PROVIDER = (id: string) => `${getApiUrl()}/api/providers/approve/${id}`
export const VERIFY_PROVIDER = (id: string) => `${getApiUrl()}/api/providers/verify/${id}`
export const PRIVATE_PROVIDERS_BY_USER = `${getApiUrl()}/api/providers/user/private-providers`
export const GET_ALL_PRIVATE_PROVIDERS = `${getApiUrl()}/api/providers/all/private-providers`

// CHAT GPT
export const CHAT_GPT = `${getApiUrl()}/api/utils/chatGPT`

// SLACK ALERT
export const SLACK_ALERT = `${getApiUrl()}/api/utils/slack-alert`

// Log Search Queries
export const LOG_SEARCH_QUERY_API = `${getApiUrl()}/api/logger/search`

// Manual Verification Sessions
export const GET_ALL_MANUAL_VERIFICATION_SESSIONS = (httpsProviderId: string, queryType: string) => `${getApiUrl()}/api/manual-verification/${httpsProviderId}/sessions?type=${queryType}`
export const UPDATE_MANUAL_VERIFICATION_SESSION = (sessionId: string) => `${getApiUrl()}/api/manual-verification/session/${sessionId}`
export const GET_MANUAL_VERIFICATION_SESSION_DATA = (sessionId: string) => `${getApiUrl()}/api/manual-verification/session/${sessionId}`
export const RETRY_MANUAL_VERIFICATION_WEBHOOK = (sessionId: string) => `${getApiUrl()}/api/manual-verification/session/${sessionId}/retry-webhook`
export const GET_MANUAL_VERIFICATION_CAPTURED_NETWORK_REQUESTS = (sessionId: string) => `${getApiUrl()}/api/manual-verification/session/${sessionId}/network-requests`