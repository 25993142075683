import { useContext } from 'react'
import { Flex, HStack, Image, Text } from '@chakra-ui/react'
import { AmplitudeAnalyticsContext } from '../../../contexts/useAnalytics'

export interface DocsCardProps {
  name: string
  icon?: string
  docsLink: string
}

export const DocsCard = (props: DocsCardProps) => {
	const { name, icon, docsLink } = props

	const { logEvent } = useContext(AmplitudeAnalyticsContext)

	return (
		<>
			<Flex
				direction='column'
				borderRadius='lg'
				p='12px'>
				<HStack
					onClick={
						() => {
							window.open(docsLink, 'next')
							logEvent('DOCS_CARD_CLICK', {
								target: name
							})
						}

					}
					cursor='pointer'>
					<Image
						src={icon ? icon : 'fallbacklink'}
						alt={name}
						height='40px'
						width='40px'
					/>
					<Text
						as='h2'
						fontWeight={600}>
						{name}
					</Text>
				</HStack>
			</Flex>
		</>
	)
}
