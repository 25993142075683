import { useEffect } from 'react'
import { Box, Grid } from '@chakra-ui/react'
import { EmptyState } from '../../../components'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { approvalPendingProviderForUser } from '../../../redux/httpsProviders/actions'
import { getApprovalPendingProviderForUser } from '../../../redux/httpsProviders/selectors'
import { HttpsProvider } from '../../../redux/httpsProviders/types'
import { ProviderCard } from './provider-card.component'

export const PendingForApproval = () => {
	const provider = useAppSelector(getApprovalPendingProviderForUser) || []
	const dispatch = useAppDispatch()
	useEffect(() => {
		//@ts-ignore
		dispatch(approvalPendingProviderForUser())
	}, [])

	return (
		<Box>
			{
				provider?.length > 0 ? (
					<Grid
						mt='20px'
						gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
						gap='22px'
					>
						{
							provider?.map((providerDetails: HttpsProvider) => (
								<ProviderCard
									providerData={providerDetails}
									key={providerDetails?.id}
								/>
							))
						}
					</Grid>
				) : (
					<EmptyState
						message='You have not created any Providers that has Custom JS Injectios. Providers with Custom JS Injections need to be approved by the admin before they can be used.'
						navigateTo='/devtoolv2'
						buttonText='Create Data Provider'
					/>
				)
			}
		</Box>
	)
}

