import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { resetManualVerificationStepperState, setSelectedSessionId, updateActiveStep } from '../../redux/manualVerification/stepperDetails'
import { getManualVerificationStepperActiveStep } from '../../redux/manualVerification/stepperDetails/selectors'
import { ManualVerificationFinalConfigScreen } from './components/ final-config-screen/final-config.screen'
import { ManualVerificationProvidersScreen } from './components/providers-screen/manual-verification-providers'
import { ManualVerificationSelectDataScreen } from './components/select-data-screen/select-data.screen'
import { ManualVerificationSelectRequests } from './components/select-request-screen/manual-verification-select-requests'
import { ManualVerificationSessionsScreen } from './components/sessions-screen/manual-verification-sessions'


export const ManualVerificationScreen = () => {

	const activeStep = useAppSelector(getManualVerificationStepperActiveStep)
	const dispatch = useAppDispatch()

	const { sessionId } = useParams()

	useEffect(() => {
		return () => {
			dispatch(resetManualVerificationStepperState())
		}
	}, [])

	useEffect(() => {
		if (sessionId) {
			dispatch(setSelectedSessionId(sessionId))
			dispatch(updateActiveStep(3))
		}
	}, [sessionId])

	return (
		<Box p='16px'>
			{activeStep === 1 && <ManualVerificationProvidersScreen />}
			{activeStep === 2 && <ManualVerificationSessionsScreen />}
			{activeStep === 3 && <ManualVerificationSelectRequests />}
			{
				activeStep === 4 && (
					<ManualVerificationSelectDataScreen
					/>
				)
			}
			{activeStep === 5 && <ManualVerificationFinalConfigScreen />}
		</Box>
	)
}

