import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Application } from './types'

interface Applications {
	isFetching?: boolean
  applications: Application[]
}

const initialState: Applications = {
	isFetching: false,
	applications: [],
}

const applicationSlice = createSlice({
	name: 'application',
	initialState,
	reducers: {
		setApplications(state, action: PayloadAction<Application[]>) {
			state.applications = action.payload
		},
		updateApplications(state, action: PayloadAction<Application>) {

			const application = action.payload
			// append the new application to the list
			// state.applications.push(application);
			state.applications = [...state.applications, application]
		},
		setFetching(state, action: PayloadAction<boolean>) {
			state.isFetching = action.payload
		},
	},
})

export const { setApplications, updateApplications, setFetching } = applicationSlice.actions

export default applicationSlice.reducer
