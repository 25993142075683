/* eslint-disable react/jsx-indent */
import JsonView from 'react18-json-view'
import { Table, TableContainer, Td, Text, Tr } from '@chakra-ui/react'
import format from 'html-format'
import 'react18-json-view/src/style.css'
import { useAppSelector } from '../../../../redux/hooks'
import { getSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest/selectors'

export const RequestDetails = () => {

	const selectedNetworkRequest = useAppSelector(getSelectedNetworkRequest)

    interface FormatBodyProps {
        body: any
        contentType: any
    }

    const FormatBody = (props: FormatBodyProps) => {
    	const { body, contentType } = props
    	try {
    		if(contentType.includes('application/json')) {
    			const bodyObj = JSON.parse(body)
    			return (
    				<JsonView src={bodyObj} />
    			)
    		} else if(contentType.includes('text/html')) {
    			// format html
    			const formattedHtml = format(body)
    			return (
    				<pre>
    					{formattedHtml}
    				</pre>
    			)
    		} else if(contentType.includes('text/plain')) {
    			return (
    				<Text>
    					{body}
    				</Text>
    			)
    		} else if(contentType.includes('application/x-www-form-urlencoded')) {
    			return (
    				<Text>
    					{body}
    				</Text>
    			)
    		} else {
    			return (
    				<Text>
    					{body}
    				</Text>
    			)
    		}
    	} catch(error) {
    		return (
    			<Text>
    				{body}
    			</Text>
    		)
    	}
    }

    return (
    	<>
    		<TableContainer>
    			<Table
    				mt='10px'
    				variant='simple'
    				mb='20px'>
    				<Tr>
    					<Td
    						fontWeight='bold'
    						color='blue.500'
    						id='requestDataTitle'
    						verticalAlign='top'>
                            URL
    					</Td>
    					<Td>
    						<Text fontSize='medium'>
    							{selectedNetworkRequest.url}
    						</Text>
    					</Td>
    				</Tr>
    				<Tr>
    					<Td
    						fontWeight='bold'
    						color='blue.500'
    						id='requestDataTitle'
    						verticalAlign='top'>
                            Content Type
    					</Td>
    					<Td>
    						<Text fontSize='medium'>
    							{selectedNetworkRequest.contentType}
    						</Text>
    					</Td>
    				</Tr>
    				<Tr>
    					<Td
    						fontWeight='bold'
    						color='blue.500'
    						mr='90px'
    						id='requestDataTitle'
    						verticalAlign='top'>
                            Body
    					</Td>
    					<Td>
    						<FormatBody
    							body={selectedNetworkRequest.response.body}
    							contentType={selectedNetworkRequest.contentType} />
    					</Td>
    				</Tr>
    			</Table>
    		</TableContainer>
    	</>
    )
}