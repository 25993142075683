import { SetStateAction, useEffect, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import QRCode from 'react-qr-code'
import { Box, Button, Center, CircularProgress, Flex, Heading, Icon, Stack } from '@chakra-ui/react'
import { getAuth } from 'firebase/auth'
import { app } from '../../../../configs/firebaseApp'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateAppConnected } from '../../../../redux/providerBuilder/mobileClient'
import { getAppConnected } from '../../../../redux/providerBuilder/mobileClient/selectors'

interface ScanQRProps {
	sessionId: string
	accessToken: string
	userUid: string
    setActiveStepInnerForm: (value: SetStateAction<number>) => void
}

export const ScanQR = (props: ScanQRProps) => {
	const dispatch = useAppDispatch()

	const appConnected = useAppSelector(getAppConnected)
	const { sessionId, accessToken, userUid, setActiveStepInnerForm } = props
	const [newAccessToken, setNewAccessToken] = useState(accessToken)

	const refreshAccessToken = async() => {
		const newAccessToken = await getAuth(app).currentUser?.getIdToken() || ''
		if(newAccessToken !== accessToken && newAccessToken !== '') {
			setNewAccessToken(newAccessToken)
		}
	}

	useEffect(() => {
		const interval = setInterval(async() => {
			await refreshAccessToken()
		}
		, 3000)
		return () => clearInterval(interval)
	}
	, [])

	const handleNext = () => {
		setActiveStepInnerForm(2)
	}

	useEffect(() => {
		dispatch(updateAppConnected(false))
	}, [dispatch])

	useEffect(() => {
		if(appConnected) {
			handleNext()
		}
	}, [appConnected])


	return (
		<>
			<Box
				marginBottom='2.5rem'
				height='100%'>
				<Box minH='80%'>
					<Flex
						fontSize='lg'
						justify='center'
						mb='50px'
						gap={4}>
						{
							appConnected ? (
								<Icon
									as={MdCheckCircle}
									w={8}
									h={8}
									color='blue.500'
								/>
							) : (
								<CircularProgress
									isIndeterminate
									size='36px' />
							)
						}
						<Heading
							fontSize='larger'
							mt='5px'
							mr='50px'>
							Scan the Qr Code on the Reclaim protocol app
						</Heading>
					</Flex>
					<Stack flex='1'>
						<Center>
							<QRCode
								value={`dev_tool_${sessionId}_${newAccessToken}_${userUid}`}
							/>
						</Center>
					</Stack>
				</Box>
				<Flex justifyContent='flex-end'>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={() => props.setActiveStepInnerForm(0)}
						width='10%'
						mr='30px'
					>
						Back
					</Button>
					{/* <Button
						isDisabled={!appConnected}
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={handleNext}
						colorScheme='blue'
						width='10%'
						mr='30px'
						isLoading={buttonLoading}
					>
						Next
					</Button> */}
				</Flex>
			</Box>
		</>
	)
}