import { createContext, useEffect, useState } from 'react'
import { init, reset, setUserId, track } from '@amplitude/analytics-browser'
import { AMPLITUDE_BETA_KEY } from '../configs/amplitude'
import { useAppSelector } from '../redux/hooks'

interface IAmplitudeAnalytics {
	dashboardVisitCount: number
	setDashboardVisitCount: React.Dispatch<React.SetStateAction<number>>
	setIsFirstVisit: (isFirstVisit: boolean) => void
	logEvent: (eventName: string, eventProperties?: any) => void
	amplitudeLogOut: () => void
}

export const AmplitudeAnalyticsContext = createContext<IAmplitudeAnalytics>({
	dashboardVisitCount: 0,
	setDashboardVisitCount: () => { },
	setIsFirstVisit: () => { },
	logEvent: () => { },
	amplitudeLogOut: () => { },
})

export const AmplitudeProvider = ({ children }: { children: React.ReactNode }) => {

	const [dashboardVisitCount, setDashboardVisitCount] = useState(0)
	const [isFirstVisit, setIsFirstVisit] = useState(false)
	// const [isFirstVisitToDashboard, setIsFirstVisitToDashboard] = useState(false)
	// const [isLOGINEventLogged, setIsLOGINEventLogged] = useState(false)

	const userEmailId = useAppSelector(state => state.user.emailId)

	const logEvent = (eventName: string, eventProperties?: any) => {
		track(eventName, { ...eventProperties, timestamp: Date.now(), email: userEmailId })
	}

	const amplitudeLogOut = () => {
		setDashboardVisitCount(0)
		// setIsLOGINEventLogged(false)
		track('SIGN_OUT_BUTTON_CLICK').promise.then((res) => {
			if(res.code === 200) {
				reset()
			}
		})
	}

	useEffect(() => {
		init(AMPLITUDE_BETA_KEY!, {
			defaultTracking: true,
		})
	}, [])

	useEffect(() => {
		if(isFirstVisit) {
			logEvent('FIRST_VISIT')
			setIsFirstVisit(false)
		}
	}, [isFirstVisit])

	useEffect(() => {
		if(dashboardVisitCount === 1 && userEmailId) {
			setUserId(userEmailId)
			logEvent('LOGIN', {
				email: userEmailId
			})

			logEvent('DASHBOARD_VISIT', {
				email: userEmailId
			})
		} else if(dashboardVisitCount > 1 && userEmailId) {
			logEvent('DASHBOARD_VISIT', {
				email: userEmailId
			})
		}
	}, [dashboardVisitCount, userEmailId])

	// useEffect(() => {
	// 	if(isFirstVisitToDashboard && userEmailId && !isLOGINEventLogged) {
	// 		setUserId(userEmailId)
	// 		logEvent('LOGIN', {
	// 			email: userEmailId
	// 		})
	// 		setIsLOGINEventLogged(true)
	// 		setIsFirstVisitToDashboard(false)
	// 	}
	// }, [isFirstVisitToDashboard, userEmailId])

	return (
		<AmplitudeAnalyticsContext.Provider
			value={
				{
					logEvent,
					dashboardVisitCount,
					setDashboardVisitCount,
					setIsFirstVisit,
					amplitudeLogOut,
				}
			}>
			{children}
		</AmplitudeAnalyticsContext.Provider>
	)
}