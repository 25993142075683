import { stringifyWithBooleanConversion } from './create-param-values'

export const filterRequestUrlByRegex = (url: string, templateUrl: string) => {
	let urlRegex = templateUrl.replace(/{{URL_PARAMS_GRD_\d+}}/g, '(.*)$')
	urlRegex = urlRegex.replace(/{{URL_PARAMS_\d+}}/g, '(.*?)')
	const regex = new RegExp(urlRegex)
	return regex.test(url)
}


export const filterRequestBodyByRegex = (body: string, templateBody: string) => {
	let modifiedTemplateBody: any
	try {
		modifiedTemplateBody = stringifyWithBooleanConversion(JSON.parse(templateBody))
	} catch (error) {
		modifiedTemplateBody = templateBody
	}

	let bodyRegex = modifiedTemplateBody.replace(/{{REQ_BODY_GRD_\d+}}/g, '(.*)$')
	bodyRegex = bodyRegex.replace(/{{REQ_BODY_\d+}}/g, '(.*?)')
	const regex = new RegExp(bodyRegex)
	return regex.test(body)
}


export const sortByContentType = (array: any) => {
	return array.sort((a: any, b: any) => {
		if (a.contentType === 'application/json' && b.contentType !== 'application/json') {
			return -1
		}

		if (a.contentType !== 'application/json' && b.contentType === 'application/json') {
			return 1
		}

		return 0
	})
}