import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SearchIcon } from '@chakra-ui/icons'
import {
	Box,
	Button,
	Flex,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useColorMode,
} from '@chakra-ui/react'
import { AmplitudeAnalyticsContext } from '../../contexts/useAnalytics'
import { pxToRem } from '../../utils'
import { AllProviders } from './components/all-providers.component'
//import { PrivateProviders } from './components/my-private-providers.component'
import { MyProviders } from './components/my-providers.component'
import { PendingForApproval } from './components/pending-approval.component'

export const ExploreScreen = () => {
	const [searchQuery, setSearchQuery] = useState('')
	const [currentTabIndex, setCurrentTabIndex] = useState(0)

	//const dispatch = useAppDispatch()
	const { colorMode } = useColorMode()
	const navigate = useNavigate()

	const { logEvent } = useContext(AmplitudeAnalyticsContext)

	const handleTabChange = (index: number) => {
		setCurrentTabIndex(index)
	}

	useEffect(() => {
		logEvent('EXPLORE_DATA_PROVIDERS')
	}, [])


	return (
		<>
			<Box p='16px'>
				<Box mt='30px'>
					<Heading
						as='h1'
						fontWeight={700}
						size='lg'
						fontSize='24px'>
						Explore Data Providers
					</Heading>
				</Box>
				<Box mt='20px'>
					<Flex
						gap={pxToRem(10)}
						alignItems='center'
						justify='space-between'>
						<InputGroup>
							<InputRightElement pointerEvents='none'>
								<SearchIcon color='gray.300' />
							</InputRightElement>
							<Input
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
								type='search'
								placeholder='Search Providers'
								bg={colorMode === 'light' ? 'white' : '#131417'}
							/>
						</InputGroup>
						<Button
							colorScheme='blue'
							pl={pxToRem(40)}
							pr={pxToRem(40)}
							onClick={
								() => {
									logEvent('CREATE_NEW_PROVIDER_BUTTON_CLICK', {
										screen: 'explore',
									})
									navigate('/devtoolv2')
								}
							}
							color={colorMode === 'dark' ? 'white' : 'white'}
						>
							Create New Data Provider
						</Button>
					</Flex>
				</Box>
				<Box mt='19px'>
					<Tabs
						onChange={handleTabChange}
						defaultIndex={currentTabIndex}>
						<TabList>
							<Tab>
								All Providers
							</Tab>
							<Tab>
								My Providers
							</Tab>
							<Tab>
								My Pending Provider
							</Tab>
							{/* <Tab>
								My Private Providers
							</Tab> */}
						</TabList>

						<TabPanels>
							<TabPanel p={0}>
								<AllProviders searchQuery={searchQuery} />
							</TabPanel>
							<TabPanel p={0}>
								<MyProviders />
							</TabPanel>
							<TabPanel p={0}>
								<PendingForApproval />
							</TabPanel>
							{/* <TabPanel p={0}>
								<PrivateProviders />
							</TabPanel> */}
						</TabPanels>
					</Tabs>
				</Box>
			</Box>
		</>
	)


}
