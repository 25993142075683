/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useToast } from '@chakra-ui/react'
import { useAppDispatch } from '../../redux/hooks'
import { deleteProvider, getAllActiveHttpsProviders, getAllCommunityProviders } from '../../redux/httpsProviders/actions'


function DeleteProvider({
	isOpen,
	onClose,
	providerData,
	revalidate,
}: {
  isOpen: any
  onClose: any
  providerData: any
  revalidate?: any
}) {
	const [isLoaded, setIsLoaded] = React.useState(false)
	const cancelRef = React.useRef()
	const toast = useToast()
	const dispatch = useAppDispatch()

	const deleteproviderDataHandler = async(httpProviderId: string) => {
		setIsLoaded(true)

		//@ts-ignore
		const res = await dispatch(deleteProvider({ providerId: httpProviderId }))

		if(res.meta.requestStatus === 'fulfilled') {
			dispatch(getAllCommunityProviders())
			dispatch(getAllActiveHttpsProviders())
	  revalidate()
			setIsLoaded(false)
			toast({
				title: 'Provider Deleted.',
				description: '',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
			onClose()
		} else {
			toast({
				title: 'Provider Deletion Failed.',
				description: '',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}
	}

	return (
		<>
			<AlertDialog
				isOpen={isOpen}
				//@ts-ignore
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader
							fontSize='lg'
							fontWeight='bold'>
							Delete providerData
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure Do you want to delete
							{' '}
							<strong>
								{providerData?.name}
							</strong>
							?, You cant undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								//@ts-ignore
								ref={cancelRef}
								onClick={onClose}
							>
								Cancel
							</Button>
							<Button
								isLoading={isLoaded}
								colorScheme='red'
								onClick={
									() => deleteproviderDataHandler(
                    providerData?.httpProviderId as string
									)
								}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
}

export { DeleteProvider }