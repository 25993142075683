import { configureStore } from '@reduxjs/toolkit'
import manualVerificationProviderDetailsReducer from './manualVerification/providerDetails'
import manualVerificationSessionDetailsReducer from './manualVerification/sessionsDetails'
import manualVerificationStepperDetailsReducer from './manualVerification/stepperDetails'
import jsonViewerReducer from './providerBuilder/jsonViewer'
import mobileClientReducer from './providerBuilder/mobileClient'
import networkRequestStoreReducer from './providerBuilder/networkRequestStore'
import providerDetailsReducer from './providerBuilder/providerDetails'
import selectedInstancesReducer from './providerBuilder/selectedInstances'
import selectedNetworkRequestReducer from './providerBuilder/selectedNetworkRequest'
import stepperDetailsReducer from './providerBuilder/stepperDetails'
import applicationReducer from './applications'
import httpsProvidersReducer from './httpsProviders'
import sessionReducer from './session'
import userReducer from './user'

const store = configureStore({
	reducer: {
		user: userReducer,
		application: applicationReducer,
		httpsProviders: httpsProvidersReducer,
		session: sessionReducer,
		networkRequestStore: networkRequestStoreReducer,
		stepperDetails: stepperDetailsReducer,
		selectedNetworkRequest: selectedNetworkRequestReducer,
		selectedInstances: selectedInstancesReducer,
		providerDetails: providerDetailsReducer,
		mobileClient: mobileClientReducer,
		jsonViewer: jsonViewerReducer,
		manualVerificationStepperDetails: manualVerificationStepperDetailsReducer,
		manualVerificationProviderDetails: manualVerificationProviderDetailsReducer,
		manualVerificationSessionDetails: manualVerificationSessionDetailsReducer
	},
	devTools: true,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store
