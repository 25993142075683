import { ChatMessage } from '@reclaim-developer-experience/dev-tool-client-sdk/dist'
import { getAppClient, getWebClient } from '../../lib/client'
import { getFirebaseIdToken } from '../../utils/utils'
import { typedCreateAsyncThunk } from '../extraConfig'
import { setQrValue, setSessionId } from '../session'

export const generateSession = typedCreateAsyncThunk<void, { }>(
	'session/generateSession',
	async(payload, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		if(!accessToken) {
			return rejectWithValue('No access token')
		}

		try {
			const client = getWebClient(accessToken)

			const response = await client.generateSessionId()
			dispatch(setSessionId(response.sessionId))
			dispatch(setQrValue(`dev_tool_${response.sessionId}_${accessToken}`))
		} catch(err) {
			return rejectWithValue(err)
		}
	}
)

export const joinSession = typedCreateAsyncThunk<void, { sessionId: string }>(
	'session/joinSession',
	async({ sessionId }, { rejectWithValue }) => {
		try {
			const accessToken = await getFirebaseIdToken()
			const client = getAppClient(accessToken!)
			const response = await client?.joinSession(sessionId)
			console.log('handle send message', response)

		} catch(err) {
			console.log('handle join session', err)
			return rejectWithValue(err)
		}
	}
)

export const sendMessage = typedCreateAsyncThunk<void, { message: ChatMessage, sessionId: string }>(
	'session/sendMessage',
	async({ message, sessionId }, { rejectWithValue }) => {
		try {
			const accessToken = await getFirebaseIdToken()
			const client = getWebClient(accessToken!)
			const response = await client?.sendMessage({
				message: message.message,
				type: message.type,
			},
			sessionId
			)
			console.log('handle send message', response)

		} catch(err) {
			console.log('handle send message', err)
			return rejectWithValue(err)
		}
	}
)

