import { useLocation, useNavigate } from 'react-router-dom'
import {
	Box,
	Button,
	HStack,
	Switch,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorMode,
} from '@chakra-ui/react'
import { getAuth } from 'firebase/auth'
import { app } from '../../configs/firebaseApp'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { getAllActiveHttpsProviders } from '../../redux/httpsProviders/actions'
import { updateHttpsProvider } from '../../redux/httpsProviders/actions'
import { getHttpsProviders } from '../../redux/httpsProviders/selectors'
import { HttpsProvider } from '../../redux/httpsProviders/types'

export const ProvidersScreen = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const { colorMode } = useColorMode()
	const httpsProviders = useAppSelector(getHttpsProviders)
	const { currentUser } = getAuth(app)
	console.log(httpsProviders, currentUser)
	const dispatch = useAppDispatch()
	const userType = useAppSelector((state) => state.user.userType)

	const myProvidersList = httpsProviders.filter(
		(provider: any) => provider.creatorUid === currentUser?.uid
	)
	return (
		<Box>
			<Box p='32px'>
				<HStack justify='space-between'>
					<Text
						fontSize='md'
						fontWeight='bold'>
						{
							pathname === '/admin/provders' && userType === 'ADMIN'
								? 'All Providers'
								: 'My Providers'
						}
					</Text>
					<Button
						background='brand.primary'
						_hover={{ opacity: 0.8 }}
						color='white'
						variant='solid'
						onClick={
							() => {
								navigate('/devtool')
							}
						}
					>
						Create Basic Provider
					</Button>
				</HStack>
			</Box>

			<Box
				mt={4}
				p={8}
				marginLeft='32px'
				border={`1px solid ${colorMode === 'dark' ? '#22252a' : '#e6e9eb'}`}
				display='flex'
				flexDirection='column'
				gap={4}
			>
				{
					myProvidersList.length ? (
						<TableContainer>
							<Table
								variant='simple'
								size='md'>
								<Thead>
									<Tr>
										<Th>
											<Text fontSize='14px'>
												Name
											</Text>
										</Th>
										<Th>
											<Text fontSize='14px'>
												Login URL
											</Text>
										</Th>
										<Th>
											<Text fontSize='14px'>
												Data URL
											</Text>
										</Th>
										{
											userType === 'ADMIN' && (
												<Th>
													<Text fontSize='14px'>
														Is Active
													</Text>
												</Th>
											)
										}
									</Tr>
								</Thead>
								<Tbody>
									{
										myProvidersList.map((httpProvider: HttpsProvider) => {
											return (
												<Tr key={httpProvider.id}>
													<Td>
														{httpProvider.name}
													</Td>
													<Td
														style={
															{
																maxWidth: '200px',
																overflow: 'hidden',
																whiteSpace: 'nowrap',
																textOverflow: 'ellipsis',
															}
														}
													>
														{httpProvider.loginUrl}
													</Td>

													<Td
														style={
															{
																maxWidth: '340px',
																overflow: 'hidden',
																whiteSpace: 'nowrap',
																textOverflow: 'ellipsis',
															}
														}
													>
														{httpProvider.url}
													</Td>
													{
														userType === 'ADMIN' && (
															<Td>
																<Switch
																	isChecked={httpProvider.isActive}
																	onChange={
																		async() => {
																			dispatch(
																				updateHttpsProvider({
																					httpProviderId: httpProvider.id,
																					isActive: !httpProvider.isActive,
																				})
																			)
																			dispatch(getAllActiveHttpsProviders())
																		}
																	}
																	isDisabled={userType !== 'ADMIN'}
																/>
															</Td>
														)
													}
												</Tr>
											)
										})
									}
								</Tbody>
							</Table>
						</TableContainer>
					) : (
						<Text fontSize='md'>
							No provider created yet
						</Text>
					)
				}
			</Box>
		</Box>
	)
}
