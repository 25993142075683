
import { getAuth } from 'firebase/auth'
import { app } from '../../configs/firebaseApp'
import { EDIT_PROVIDER, GET_ALL_ACTIVE_PROVIDERS, GET_ALL_COMMUNITY_PROVIDERS_ENDPOINT, GET_ALL_INACTIVE_PROVIDERS, GET_ALL_PENDING_PROVIDERS, GET_PENDING_PROVIDERS_BY_USER, GET_PROVIDER_BY_ID, PRIVATE_PROVIDERS_BY_USER, REGISTER_PROVIDER } from '../../utils'
import { getApiUrl, getFirebaseIdToken } from '../../utils/utils'
import { typedCreateAsyncThunk } from '../extraConfig'
import { HttpsProvider } from './types'
import {
	setAllCommunityProviders,
	setAppRovalPendingProviderForUser,
	setHttpsProviders,
	setLoading,
	setNonActiveProviders,
	setNonApprovedProviders,
	updateHttpsProviders,
} from '.'

// const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
// TODO: decide if we need this
export const registerHttpsProvider = typedCreateAsyncThunk<
	void,
	{ payload: Omit<HttpsProvider, 'id' | 'applicationId' | 'creatorEmail'> }
>(
	'httpsProvider/register',
	async (payload, { dispatch, getState, rejectWithValue }) => {
		const user = getState().user.emailId
		const accessToken = await getFirebaseIdToken()

		// send access token in header
		const response = await fetch(REGISTER_PROVIDER, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ ...payload.payload, creatorEmail: user }),
		})
		const data = await response.json()
		if (data.isSuccess) {
			dispatch(updateHttpsProviders(data.provider))
		} else {
			return rejectWithValue(data.message)
		}
	}
)


//TODO: Decide if we need this
export const getHttpProvidersCreatedByUser = typedCreateAsyncThunk<void>(
	'httpsProvider/getHttpProvidersCreatedByUser',
	async (_, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		const response = await fetch(GET_ALL_COMMUNITY_PROVIDERS_ENDPOINT, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		const data = await response.json()

		if (data.isSuccess) {
			dispatch(setHttpsProviders(data.providers))
		} else {
			return rejectWithValue(data.message)
		}
	}
)

//This will update the status of the provider (active or inactive)
export const updateHttpsProvider = typedCreateAsyncThunk<
	void,
	{ httpProviderId: string, isActive: boolean, formData?: any }
>('httpsProvider/update', async (payload, { rejectWithValue }) => {
	const accessToken = await getFirebaseIdToken()
	const response = await fetch(`${EDIT_PROVIDER(payload.httpProviderId)}`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload.formData),
	})


	const data = await response.json()

	if (data.isSuccess) {
		return data
	} else {
		return rejectWithValue(data.message)
	}
})

export const getAllNonApprovedProviders = typedCreateAsyncThunk<void>(
	'httpsProvider/getAllNonApprovedProviders',
	async (_, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		const response = await fetch(GET_ALL_PENDING_PROVIDERS, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		const data = await response.json()
		if (data) {
			dispatch(setNonApprovedProviders(data.providers))
		} else {
			return rejectWithValue(data.message)
		}
	}
)


// gets all the providers - both active and non active
export const getAllCommunityProviders = typedCreateAsyncThunk<void>(
	'httpsProvider/getAllHttpsProviders',
	async (_, { dispatch, rejectWithValue }) => {
		dispatch(setLoading(true))
		const accessToken = await getFirebaseIdToken()
		const response = await fetch(GET_ALL_ACTIVE_PROVIDERS, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		})
		const data = await response.json()

		if (data) {
			dispatch(setLoading(false))
			dispatch(setAllCommunityProviders(data?.providers))
		} else {
			return rejectWithValue(data.message)
		}
	}
)

//getall non active httpproviders
export const getAllNonActiveHttpsProviders = typedCreateAsyncThunk<void>(
	'httpsProvider/getAllNonActiveHttpsProviders',
	async (_, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		const response = await fetch(GET_ALL_INACTIVE_PROVIDERS, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		})
		const data = await response.json()

		if (data) {
			dispatch(setNonActiveProviders(data?.providers))
		} else {
			return rejectWithValue(data.message)
		}
	}
)


export const dumpProviderResponse = typedCreateAsyncThunk<
	void,
	{ userId: string, websiteUrl: string, sessionId: string, responses: any[] }
>('httpsProvider/neworkDump', async (payload, { rejectWithValue }) => {
	const response = await fetch(`${getApiUrl()}/networkdump`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	})
	const data = await response.json()
	if (data.isSuccess) {
	} else {
		return rejectWithValue(data.message)
	}
})


// ------- refactor required. visit later after initial launch
// NEW ACTIONS

// This will fetch all the providers that are pending for approval (Created by the user)
export const approvalPendingProviderForUser = typedCreateAsyncThunk<any, any>(
	'httpsProvider/getApprovalPendingProviderForUser',
	async (_, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		const currentUser = getAuth(app).currentUser
		const response = await fetch(GET_PENDING_PROVIDERS_BY_USER(currentUser?.uid as string), {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		})
		const data = await response.json()
		if (data) {
			dispatch(setAppRovalPendingProviderForUser(data?.providers))
		} else {
			return rejectWithValue(data.message)
		}
	}
)

// This will fetch all HTTP providers that are active
export const getAllActiveHttpsProviders = typedCreateAsyncThunk<void>(
	'httpsProvider/getAllHttpsProviders',
	async (_, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		dispatch(setLoading(true))
		const response = await fetch(GET_ALL_ACTIVE_PROVIDERS, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		})
		const data = await response.json()

		// while creating the application, check if user has any private providers, if yes show them in active provider list
		const res = await fetch(PRIVATE_PROVIDERS_BY_USER, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			}
		})
		const { providers } = await res.json()
		if (data) {
			const combined = [...data.providers, ...(providers ?? [])]
			dispatch(setLoading(false))
			dispatch(setHttpsProviders(combined))
		} else {
			return rejectWithValue(data.message)
		}
	}
)


export const deleteProvider = typedCreateAsyncThunk<void, string>(
	'httpsProvider/deleteProvider',
	async (providerId, { dispatch, rejectWithValue }) => {
		//@ts-ignore
		const id = providerId?.providerId
		const accessToken = await getFirebaseIdToken()
		const response = await fetch(`${GET_PROVIDER_BY_ID(id)}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		})
		const data = await response.json()
		if (data.isSuccess) {
			dispatch(getAllCommunityProviders())
			return data
		} else {
			return rejectWithValue(data.message)
		}
	}
)
