/* eslint-disable react/jsx-indent */
import JsonView from 'react18-json-view'
import { Box, Code, Flex, Heading, Tag, TagLabel, Text } from '@chakra-ui/react'
import format from 'html-format'
import 'react18-json-view/src/style.css'


interface RequestDetailsProps {
	selectedNetworkRequest: any
}

export const RequestDetails: React.FC<RequestDetailsProps> = ({ selectedNetworkRequest }) => {


	interface FormatBodyProps {
		body: any
		contentType: any
	}

	const FormatBody = (props: FormatBodyProps) => {
		const { body, contentType } = props
		try {
			if (contentType.includes('application/json')) {
				const bodyObj = JSON.parse(body)
				return (
					<JsonView src={bodyObj} />
				)
			} else if (contentType.includes('text/html')) {
				// format html
				const formattedHtml = format(body)
				return (
					<pre
						color='blue.800'
						style={
							{
								color: '#1849A9',
								fontSize: 'medium',
								lineHeight: '20px',
								background: 'transparent',
								padding: '0px',
								borderRadius: '0px',
								border: 'none'
							}
						}
						className='font-roboto-mono-regular'
					>
						{formattedHtml}
					</pre>
				)
			} else if (contentType.includes('text/plain')) {
				return (
					<Text className='font-roboto-mono-regular'>
						{body}
					</Text>
				)
			} else if (contentType.includes('application/x-www-form-urlencoded')) {
				return (
					<Text className='font-roboto-mono-regular'>
						{body}
					</Text>
				)
			} else {
				return (
					<Text className='font-roboto-mono-regular'>
						{body}
					</Text>
				)
			}
		} catch (error) {
			return (
				<Text className='font-roboto-mono-regular'>
					{body}
				</Text>
			)
		}
	}

	return (
		<>
			<Box w='full'>
				<Flex
					direction='column'
					gap={2}>
					<Flex gap={5}>
						<Flex
							direction='column'
							gap={2}
							py={5}
							px={1}
							borderBottom='1px solid #EFF1F5'
							flex={1}
						>
							<Heading
								fontSize='14px'
							>
								URL
							</Heading>

							<Text
								color='#4CA30D'
								fontSize='13px'>
								{selectedNetworkRequest.url}
							</Text>
						</Flex>
						<Flex
							direction='column'
							gap={2}
							py={5}
							px={1}
							borderBottom='1px solid #EFF1F5'
							flex={1}
						>
							<Heading
								fontSize='14px'
							>
								Page URL
							</Heading>

							<Text
								color='#000'
								fontSize='13px'>
								{selectedNetworkRequest.currentPageUrl}
							</Text>
						</Flex>
					</Flex>
					<Flex gap={5}>
						<Flex
							direction='column'
							gap={2}
							py={5}
							px={1}
							flex={1}
							borderBottom='1px solid #EFF1F5'
						>
							<Heading
								fontSize='14px'
							>
								Content Type
							</Heading>
							<Code
								backgroundColor='transparent'
								fontSize='13px'
								color='#1849A9'>
								{selectedNetworkRequest.contentType}
							</Code>
						</Flex>
						<Flex
							direction='column'
							gap={2}
							py={5}
							px={1}
							borderBottom='1px solid #EFF1F5'
							flex={1}
						>
							<Heading
								fontSize='14px'
							>
								Request Method
							</Heading>
							<Code
								backgroundColor='transparent'
								fontSize='13px'
								color='#1849A9'>
								{selectedNetworkRequest.method}
							</Code>
						</Flex>
					</Flex>

					{
						!selectedNetworkRequest.contentType.includes('json') && (
							<Flex
								direction='row'
								alignItems='center'
								gap={2}
								py={5}
								px={1}
								borderBottom='1px solid #EFF1F5'

							>
								<Tag
									size='md'
									variant='subtle'
									borderRadius={5}
									backgroundColor='#FFFAEB'
									border='1px solid #FEDF89'
									w='fit-content'
								>
									<TagLabel
										color='#B54708'
										px={2}
										fontWeight='bold'
									>
										Beta
									</TagLabel>
								</Tag>
								<Text
									color='#B54708'
									fontSize='small'
									fontWeight='bold'>
									This request type is experimental and may be unreliable.
								</Text>
							</Flex>
						)
					}

					<Flex
						direction='column'
						gap={2}
						py={5}
						px={1}>
						<Heading fontSize='medium'>
							Body
						</Heading>
						<Box
							backgroundColor='gray.50'
							borderRadius='5px'
							p={2}
							minHeight='20vh'
							maxHeight='40vh'
							overflowY='auto'
						>
							<FormatBody
								body={selectedNetworkRequest.response.body}
								contentType={selectedNetworkRequest.contentType} />

						</Box>
					</Flex>
				</Flex>
			</Box>
		</>
	)
}