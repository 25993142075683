import { ChatBackendClient } from '@reclaim-developer-experience/dev-tool-client-sdk/dist'
// import { makeGrpcClient } from '../../api-client/make-grpc-server'

let webClient: ChatBackendClient | null = null
let appClient: ChatBackendClient | null = null

export function getWebClient(accessToken: string) {
	if(!webClient) {
		webClient = new ChatBackendClient(accessToken, 'web')
	}

	return webClient
}

export function getAppClient(accessToken: string) {
	if(!appClient) {
		appClient = new ChatBackendClient(accessToken, 'app')
	}

	return appClient
}

// export async function getGrpcClient(accessToken: string) {
// 	const client = await makeGrpcClient(accessToken, 'web')
// 	return client
// }
