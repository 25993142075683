/* eslint-disable indent */

import { NetworkRequest, RequestMethod } from '../types'
import { filterRequestBodyByRegex, filterRequestUrlByRegex } from './filter-requests'

interface Instance {
    variableName: string
    data: any
}

interface KeyValue {
    [key: string]: any
}

interface ParamValues {
    templateURL: string
    templateBody: string
    requestsData: NetworkRequest[]
    method: RequestMethod
    parameters: any[]
}

const processKey = (key: string): string => {
    return key.replace(/{{|}}/g, '')
}

function createParamValues(
    selectedInstances: Instance[],
    dynamicURLValues: KeyValue,
    dynamicBodyValues: KeyValue
): KeyValue {
    const paramValues: KeyValue = {}

    selectedInstances.forEach(instance => {
        // check if value is string, if not stringify it
        let dataValue = instance.data
        if (typeof instance.data !== 'string') {
            dataValue = JSON.stringify(instance.data)
        }

        paramValues[instance.variableName] = dataValue
    })

    for (const key in dynamicURLValues) {
        // check if value is string, if not stringify it
        let dataValue = dynamicURLValues[key]
        if (typeof dynamicURLValues[key] !== 'string') {
            dataValue = JSON.stringify(dynamicURLValues[key])
        }

        paramValues[processKey(key)] = dataValue
    }

    for (const key in dynamicBodyValues) {
        // check if value is string, if not stringify it
        let dataValue = dynamicBodyValues[key]
        if (typeof dynamicBodyValues[key] !== 'string') {
            dataValue = JSON.stringify(dynamicBodyValues[key])
        }

        paramValues[processKey(key)] = dataValue
    }

    return paramValues
}

function extractParamsValuesFromURL(url: string, templateURL: string): { [key: string]: string } {
    // Split the URLs into their path segments
    const urlSegments = url.split('/')
    const templateSegments = templateURL.split('/')

    // Object to store the extracted parameters
    const params: { [key: string]: string } = {}

    // Loop through the template segments to find and extract params
    for (let i = 0; i < templateSegments.length; i++) {
        const segment = templateSegments[i]
        if (segment.startsWith('{{URL_PARAMS')) {
            // Extract the parameter name, e.g., {{URL_PARAMS_1}} -> URL_PARAMS_1
            const paramName = segment.slice(2, -2)
            // Get the corresponding value from the url segments
            const paramValue = urlSegments[i]
            // Store the parameter and its value, converting to string if necessary
            params[paramName] = typeof paramValue === 'string' ? paramValue : JSON.stringify(paramValue)
        }
    }

    return params
}

function extractParamsFromBody(body: string, templateBody: string): { [key: string]: any } {
    try {
        const bodyObj = JSON.parse(body)
        const templateObj = JSON.parse(templateBody)

        const params: { [key: string]: any } = {}

        // Helper function to recursively find and extract params
        function findParams(template: any, data: any) {
            for (const key in template) {
                if (typeof template[key] === 'string' && template[key].startsWith('{{REQ_BODY')) {
                    const paramName = template[key].slice(2, -2)
                    const paramValue = data[key]
                    params[paramName] = typeof paramValue === 'string' ? paramValue : JSON.stringify(paramValue)
                } else if (typeof template[key] === 'object' && template[key] !== null) {
                    findParams(template[key], data[key])
                }
            }
        }

        // Start the recursive search
        findParams(templateObj, bodyObj)

        return params
    } catch (error) {
        return {}
    }
}

function arrayToObject(array: { key: string, value: any }[]): { [key: string]: any } {
    return array.reduce((acc: any, obj) => {
        acc[obj.key] = obj.value
        return acc
    }, {})
}

function stringifyWithBooleanConversion(obj: any): string {
    return JSON.stringify(obj, (key, value) => {
        if (typeof value === 'boolean') {
            return value.toString()
        }

        return value
    })
}

function createParamValuesFromExistingData({ templateURL, templateBody, requestsData, method, parameters }: ParamValues) {
    try {
        const allMatchingRequests = requestsData.filter((req) => {
            try {
                const reqBody = stringifyWithBooleanConversion(JSON.parse(req.requestBody))
                return filterRequestUrlByRegex(req.url, templateURL) && filterRequestBodyByRegex(reqBody, templateBody) && req.method === method
            } catch (error) {
                return filterRequestUrlByRegex(req.url, templateURL) && filterRequestBodyByRegex(req.requestBody, templateBody) && req.method === method
            }
        })
        if (!allMatchingRequests?.length) {
            return {
                isSuccess: false,
                errorMessage: 'Unable to find URL',
                paramValues: {}
            }
        }

        const filteredURL = allMatchingRequests[0]
        const extractedURLParams = extractParamsValuesFromURL(filteredURL?.url, templateURL)
        const extractedBodyParams = extractParamsFromBody(filteredURL?.requestBody, templateBody)
        const extractedParameterValues = arrayToObject(parameters ?? [])

        const paramValues = {
            ...extractedURLParams ?? {},
            ...extractedBodyParams ?? {},
            ...extractedParameterValues ?? {}
        }
        return {
            isSuccess: true,
            errorMessage: '',
            paramValues: paramValues
        }

    } catch (error) {
        console.error(error)
        return {
            isSuccess: false,
            errorMessage: 'something went wrong.',
            paramValues: {}
        }
    }
}

export {
    createParamValues,
    extractParamsValuesFromURL,
    extractParamsFromBody,
    arrayToObject,
    stringifyWithBooleanConversion,
    createParamValuesFromExistingData
}