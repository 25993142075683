/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ManualVerificationStepperDetails {
	activeStep: number
	backAction: boolean
	selectedProviderId: string
	selectedSessionId: string
}

const initialState: ManualVerificationStepperDetails = {
	activeStep: 1,
	backAction: false,
	selectedProviderId: '',
	selectedSessionId: ''
}

const manualVerificationStepperDetailsSlice = createSlice({
	name: 'manualVerificationStepperDetails',
	initialState,
	reducers: {
		updateActiveStep(state, action: PayloadAction<number>) {
			state.activeStep = action.payload
		},
		updateBackAction(state, action: PayloadAction<boolean>) {
			state.backAction = action.payload
		},
		resetActiveStep(state) {
			state.activeStep = 0
		},
		setSelectedProviderId(state, action: PayloadAction<string>) {
			state.selectedProviderId = action.payload
		},
		setSelectedSessionId(state, action: PayloadAction<string>) {
			state.selectedSessionId = action.payload
		},
		resetManualVerificationStepperState() {
			return { ...initialState }
		}
	},
})

export const {
	updateActiveStep,
	updateBackAction,
	resetActiveStep,
	setSelectedProviderId,
	setSelectedSessionId,
	resetManualVerificationStepperState
} = manualVerificationStepperDetailsSlice.actions

export default manualVerificationStepperDetailsSlice.reducer
