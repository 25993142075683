
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { logEvent } from '@amplitude/analytics-browser'
import { Flex, Image, Spinner, useToast } from '@chakra-ui/react'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
//@ts-ignore
import EmptyImage from '../../assets/empty.png'
import { app } from '../../configs/firebaseApp'
import { useAppDispatch } from '../../redux/hooks'
import { setUserType } from '../../redux/user'
import { loginClient } from '../../redux/user/actions'

export const LoaderScreen = () => {
	const dispatch = useAppDispatch()
	const toast = useToast()
	const navigate = useNavigate()

	async function handleGoogleLogin() {
		logEvent('SIGN_UP_FROM_RECLAIM_WEBSITE')
		const auth = getAuth(app)
		const provider = new GoogleAuthProvider()
		try {
			const user = await signInWithPopup(auth, provider)

			if(user) {
				dispatch(loginClient())
					.then((res: any) => {
						if(res.meta.requestStatus === 'fulfilled') {
							dispatch(setUserType(res.payload.type))
							toast({
								title: 'Welcome back',
								description: 'You have been logged in successfully',
								status: 'success',
								duration: 3000,
								isClosable: true,
								position: 'top-right',
							})
							navigate('/dashboard')
						} else {
							toast({
								title: 'Error',
								description: res.payload as string,
								status: 'error',
								duration: 3000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch((err: any) => {
						toast({
							title: 'Error',
							description: err.message,
							status: 'error',
							duration: 3000,
							isClosable: true,
							position: 'top-right',
						})
					})
			}
		} catch(e: any) {
			toast({
				title: e?.message.replace('Firebase: ', ''),
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}
	}

	useEffect(() => {
		handleGoogleLogin()
	}, [])

	return (
		<Flex
			alignItems='center'
			justifyContent='center'
			flexDirection='column'
		>
			<Image
				src={EmptyImage}
				alt='App Logo'
				mt={40}
				mb={4} />

			<Spinner
				size='xl'
				color='blue.500' />

		</Flex>
	)
}
