/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { Avatar, Box, Grid, Heading, HStack, Text, useColorMode, VStack } from '@chakra-ui/react'
import axios from 'axios'
import { getAuth } from 'firebase/auth'
import { EmptyState } from '../../components'
import { app } from '../../configs/firebaseApp'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setLoading } from '../../redux/httpsProviders'
import { getAllActiveHttpsProviders } from '../../redux/httpsProviders/actions'
import { getHttpsProviders } from '../../redux/httpsProviders/selectors'
import { HttpsProvider } from '../../redux/httpsProviders/types'
import { pxToRem } from '../../utils'
import { getApiUrl, getFirebaseIdToken } from '../../utils/utils'
import { ProviderCard } from './my-provider-card.component'

export const ProfileScreen = () => {
	//const httpsProviders = useAppSelector(getHttpsProviders)

	const [myProvidersList, setMyProvidersList] = useState<HttpsProvider[]>([])
	const [loading, isLoading] = useState(false)
	const user = getAuth(app).currentUser
	const fetchMyProviders = async() => {
		try {
			setLoading(true)
			const token = await getFirebaseIdToken()
			const { data } = await axios.get(`${getApiUrl()}/api/providers/user`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			if(data?.isSuccess) {
				setMyProvidersList(data.providers)
				setLoading(false)
			}
		} catch(err) {
			console.error(err)
		}
	}

	const { colorMode } = useColorMode()
	// const myProvidersList = httpsProviders.filter(
	// 	(provider: any) => provider.creatorUid === currentUser?.uid
	// )
	const dispatch = useAppDispatch()
	useEffect(() => {
		// dispatch(getAllActiveHttpsProviders())
		fetchMyProviders()
	}, [])

	return (
		<Box mt={pxToRem(40)}>
			<Box
				p={pxToRem(20)}
				bgColor={colorMode === 'dark' ? '' : 'white'}
				borderWidth='1px'
				borderStyle='solid'
				borderColor='borderColor'
				borderRadius='lg'
			>
				<HStack gap={pxToRem(20)}>
					<Avatar
						size='lg'
						name={user?.displayName as string}
						src={user?.photoURL as string}
					/>
					<VStack alignItems='flex-start'>
						<Text
							fontWeight={600}
							fontSize={pxToRem(18)}>
							{' '}
							{user?.displayName}
						</Text>
						<Text>
							{user?.email}
						</Text>
					</VStack>
				</HStack>
			</Box>
			<Box mt={pxToRem(19)}>
				<Box
					mt='32px'
					p='20px'
					borderRadius='lg'
					borderWidth='1px'
					borderStyle='solid'
					borderColor='borderColor'
					bgColor={colorMode === 'dark' ? '' : 'white'}
				>
					<Heading as='h2'>
						My Providers
					</Heading>
					<Grid
						mt='20px'
						gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
						gap='22px'
					>
						{
							myProvidersList?.length > 0 && (
								<>
									{
										myProvidersList
											?.sort((a, b) => {
												// Sort by isVerified (verified providers first)
												if(a.isVerified && !b.isVerified) {
													return -1
												} else if(!a.isVerified && b.isVerified) {
													return 1
												}

												// If both providers have the same isVerified status, sort alphabetically by name
												return a.name.localeCompare(b.name)
											})
											.map((providerDetails: HttpsProvider) => (
												<ProviderCard
													providerData={providerDetails}
													key={providerDetails?.id}
													revalidate={fetchMyProviders}
												/>
											))
									}
								</>
							)
						}
					</Grid>
					<Box mb={pxToRem(20)}>
						{
							myProvidersList?.length === 0 && !loading && (
								<EmptyState
									message='You have not created any providers yet'
									navigateTo='/devtoolv2'
									buttonText='Create New Provider'
								/>
							)
						}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}