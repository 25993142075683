import { SetStateAction, useEffect, useState } from 'react'
import { Box, Button, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, InputGroup, InputRightElement, List, ListItem, Spinner, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { getPagesVisted } from '../../../../redux/providerBuilder/networkRequestStore/selectors'
import { updateProviderDetails } from '../../../../redux/providerBuilder/providerDetails'
import { getProviderDetails } from '../../../../redux/providerBuilder/providerDetails/selectors'
import { getSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances/selectors'
import { getSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest/selectors'
import { updateActiveStep, updateBackAction } from '../../../../redux/providerBuilder/stepperDetails'
import { getActiveStep } from '../../../../redux/providerBuilder/stepperDetails/selectors'
import { getUniqueItems } from '../../../../utils/provider-builder/instance-selection-utils'
import { generateProviderDescriptionFromGPT, generateProviderNameFromGPT, getLoginURLs } from '../../../../utils/provider-builder/request-config-utils'

interface ProviderNameProps {
	setActiveStepInnerForm: (value: SetStateAction<number>) => void
}

export const ProviderName = (props: ProviderNameProps) => {
	const dispatch = useAppDispatch()

	const providerDetails = useAppSelector(getProviderDetails)
	const selectedNetworkRequest = useAppSelector(getSelectedNetworkRequest)
	const selectedInstanaces = useAppSelector(getSelectedInstances)
	const pagesVisted = useAppSelector(getPagesVisted)
	const activeStep = useAppSelector(getActiveStep)


	const uniqueUrls = getLoginURLs(getUniqueItems(pagesVisted))

	const [providerName, setProviderName] = useState(providerDetails.name ? providerDetails.name : '')
	const [providerDescription, setProviderDescription] = useState(providerDetails.description ? providerDetails.description : '')
	const [loginUrl, setLoginUrl] = useState(providerDetails.loginUrl ? providerDetails.loginUrl : '')
	const [nameError, setNameError] = useState('')
	const [descriptionError, setDescriptionError] = useState('')
	const [loginUrlError, setLoginUrlError] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)
	const [providerNameLoading, setProviderNameLoading] = useState(false)
	const [providerDetailsLoading, setProviderDetailsLoading] = useState(false)

	const handleNext = () => {
		if (!providerName) {
			setNameError('Provider name is required')
			return
		}

		if (!providerDescription) {
			setDescriptionError('Provider description is required')
			return
		}

		if (!loginUrl) {
			setLoginUrlError('Login url is required')
			return
		}


		setButtonLoading(true)
		dispatch(
			updateProviderDetails({
				name: providerName,
				description: providerDescription,
				method: selectedNetworkRequest.method,
				loginUrl: loginUrl
			})
		)
		props.setActiveStepInnerForm(1)
	}


	useEffect(() => {
		const setDefaultValues = async () => {
			setProviderNameLoading(true)
			setProviderDetailsLoading(true)
			const providerName = await generateProviderNameFromGPT(selectedNetworkRequest.url, selectedInstanaces)
			setProviderName(providerName)
			setProviderNameLoading(false)
			const providerDescription = await generateProviderDescriptionFromGPT(providerName, selectedNetworkRequest.url, selectedInstanaces)
			setProviderDescription(providerDescription)
			setProviderDetailsLoading(false)
		}

		if (!providerName && !providerDescription) {
			setDefaultValues()
		}
	}, [])


	function handleGoBack() {

		dispatch(updateActiveStep(activeStep - 1))
		dispatch(updateBackAction(true))
	}


	return (
		<>
			<Box
				marginBottom='1rem'
				gap='30px'>
				<FormControl
					marginTop='1.5rem'
					isInvalid={!!nameError}>
					<FormLabel>
						Name Your Provider *
					</FormLabel>
					<InputGroup>
						<Input
							onChange={
								(e) => {
									setNameError('')
									setProviderName(e.target.value)
								}
							}
							required={true}
							value={providerName}
						/>
						{
							providerNameLoading && (
								<InputRightElement width='4.5rem'>
									<Spinner />
								</InputRightElement>
							)
						}
					</InputGroup>
					<FormErrorMessage>
						{nameError}
					</FormErrorMessage>
					<FormHelperText>
						A friendly name that users will see on the reclaim app
					</FormHelperText>
				</FormControl>
				<FormControl
					marginTop='1.5rem'
					isInvalid={!!descriptionError}>
					<FormLabel>
						Description *
					</FormLabel>
					<InputGroup>
						<Input
							onChange={
								(e) => {
									setDescriptionError('')
									setProviderDescription(e.target.value)
								}
							}
							required={true}
							value={providerDescription}
						/>
						{
							providerDetailsLoading && (
								<InputRightElement width='4.5rem'>
									<Spinner />
								</InputRightElement>
							)
						}
					</InputGroup>
					<FormErrorMessage>
						{descriptionError}
					</FormErrorMessage>
					<FormHelperText>
						Short description of the provider
					</FormHelperText>
				</FormControl>
				<FormControl
					marginTop='1.5rem'
					isInvalid={!!loginUrlError}>
					<FormLabel>
						Login Url *
					</FormLabel>
					<Input
						onChange={
							(e) => {
								setLoginUrlError('')
								setLoginUrl(e.target.value)
							}
						}
						required={true}
						value={loginUrl}
					/>
					<FormHelperText>
						Initial login url for the provider. eg : https://www.reddit.com/login/
					</FormHelperText>
					<FormErrorMessage>
						{loginUrlError}
					</FormErrorMessage>
					<FormHelperText mt='15px'>
						Suggestions
					</FormHelperText>
					<List>
						{
							// get unique urls from pagesVisted
							uniqueUrls.map((item: string, index: number) => (
								<ListItem
									style={{ marginTop: '1rem' }}
									key={index}
									py='1'
								>
									<Button
										onClick={() => setLoginUrl(item)}
										size='sm'
										colorScheme='blue'
										borderRadius='5px'
										borderColor='blue.500'
										_hover={{ bgColor: 'blue.100' }}
										variant='outline'
										maxWidth='100%'
									>
										<Text isTruncated>
											{item}
										</Text>
									</Button>
								</ListItem>
							))
						}
					</List>
				</FormControl>

				<Flex justifyContent='flex-end'>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={handleGoBack}
						width='10%'
						mr='30px'
						isDisabled={buttonLoading || providerNameLoading || providerDetailsLoading}
						isLoading={buttonLoading}
					>
						Back
					</Button>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={handleNext}
						colorScheme='blue'
						width='10%'
						mr='30px'
						isDisabled={buttonLoading || providerNameLoading || providerDetailsLoading}
						isLoading={buttonLoading}
					>
						Next
					</Button>
				</Flex>
			</Box>
		</>
	)
}