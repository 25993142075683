import { SetStateAction, useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { QuestionIcon } from '@chakra-ui/icons'
import { Box, Button, Code, Flex, Icon, Input, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, StackDivider, Text, Tooltip, useToast, VStack } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateProviderDetails } from '../../../../redux/providerBuilder/providerDetails'
import { deleteSelectedInstance, updateResponseSelection } from '../../../../redux/providerBuilder/selectedInstances'
import { getSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances/selectors'
import { getSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest/selectors'
import { initializeRegexBody, seperateURLComponents, urlSpanToRegexUrl, URLSpanType } from '../../../../utils/provider-builder/request-config-utils'


interface RequestConfigProps {
	setActiveStepInnerForm: (value: SetStateAction<number>) => void
}

export const RequestConfig = (props: RequestConfigProps) => {
	const toast = useToast()
	const dispatch = useAppDispatch()

	const selectedNetworkRequest = useAppSelector(getSelectedNetworkRequest)
	const selectedInstances = useAppSelector(getSelectedInstances)

	const [urlSpans, setUrlSpans] = useState<any>([])
	const [regexBody, setRegexBody] = useState('')
	const [selectedText, setSelectedText] = useState<any>(null)
	const [buttonLoading, setButtonLoading] = useState(false)

	// Reset the body to its original state
	const resetRegexBody = () => {
		const newRegexBody = initializeRegexBody(selectedNetworkRequest.request?.body)
		setRegexBody(newRegexBody)
		setSelectedText(null)
	}

	// set selected text to the selectedText state
	const handleTextSelect = () => {
		const selection = window.getSelection()
		if (selection !== null) {
			setSelectedText(selection)
		}
	}

	// replace the selected text in the body and make it dynamic
	const handleReplaceRegexBody = () => {
		// Replace the selected text with the replacement text
		if (selectedText?.toString()?.length) {
			let startOffset = selectedText.anchorOffset
			let endOffset = selectedText.focusOffset

			if (startOffset > endOffset) {
				const endTemp = endOffset
				endOffset = startOffset
				startOffset = endTemp
			}

			// Update the state with the modified content
			setRegexBody((prevContent) => {
				const prefix = prevContent.slice(0, startOffset)
				const suffix = prevContent.slice(endOffset)

				// Replace the selected text with a special string
				const specialString = '<DYNAMIC>'
				const updatedContent = `${prefix}${specialString}${suffix}`

				return updatedContent
			})
			setSelectedText(null)
		} else {
			toast({
				title: 'No text selected',
				description: 'Select the text you want to make dynamic from the body',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}
	}

	// Makes all body content as dynamic in regex body
	const makeFullBodyDynamic = () => {
		setRegexBody('<DYNAMIC>')
		setSelectedText(null)
	}

	// Toggle the selected url span between dynamic and constant
	function toggleSelectedUrlSpan(index: number) {
		if (urlSpans[index].type !== 'STATIC') {
			urlSpans[index].type =
				urlSpans[index].type === URLSpanType.CONSTANT
					? URLSpanType.DYNAMIC
					: URLSpanType.CONSTANT
			setUrlSpans([...urlSpans])
		}
	}

	useEffect(() => {
		if (selectedNetworkRequest) {
			const newUrlSpans = seperateURLComponents(selectedNetworkRequest.url)
			setUrlSpans(newUrlSpans)
			const newRegexBody = initializeRegexBody(selectedNetworkRequest.request?.body)
			setRegexBody(newRegexBody)
		}
	}, [selectedNetworkRequest])

	const handleNext = () => {
		setButtonLoading(true)
		const templateUrl = urlSpanToRegexUrl(urlSpans)
		const allResponseSelections = selectedInstances.map((instance: any) => instance.responseSelection)

		const bodyEnabled = !!selectedNetworkRequest.request?.body
		let finalTemplateBody = regexBody
		let finalRegexBody = ''

		if(bodyEnabled) {
			let secretCounter = 1
			const dynamicVariables = regexBody.match(/<DYNAMIC>/g)
			if (dynamicVariables) {
				for (const dynamicVariable of dynamicVariables) {
					finalTemplateBody = finalTemplateBody.replace(dynamicVariable, `{{REQ_BODY_${secretCounter}}}`)
					secretCounter++
				}
			}

			finalRegexBody = regexBody.replace(/<DYNAMIC>/g, '(.*)')
		}


		dispatch(updateProviderDetails({
			url: templateUrl,
			urlType: 'TEMPLATE',
			responseSelections: allResponseSelections,
			bodySniff: {
				enabled: bodyEnabled,
				regex: bodyEnabled ? finalRegexBody + '$' : '',
				template: bodyEnabled ? finalTemplateBody : '',
			},
		}))
		props.setActiveStepInnerForm(3)
	}

	return (
		<>
			<Box
				marginBottom='2.5rem'
				maxW='93%'>
				<VStack
					divider={<StackDivider borderColor='gray.200' />}
					spacing={5}
					align='stretch'
					mt='10'
					ml='3'
				>
					<Box maxW='85%'>
						<Flex>
							<Text
								fontSize='md'
								minW='140px'>
								URL *
							</Text>
							<Text
								fontSize='md'
								overflow='hidden'
								whiteSpace='pre-wrap'>
								{
									urlSpans.map((span: any, idx: number) => (
										<span
											key={idx}
											className={
												span.type !== 'STATIC'
													? 'urlDynamicSpan'
													: 'urlStaticSpan'
											}
											onClick={() => toggleSelectedUrlSpan(idx)}
										>
											{
												span.type === URLSpanType.DYNAMIC
													? '<DYNAMIC>'
													: span.key
											}
										</span>
									))
								}
							</Text>
							<Tooltip
								whiteSpace='pre-line'
								label='Set parts of URL as dynamic.&#10;Eg. User Id, Post Id'>
								<Icon
									as={QuestionIcon}
									w={4}
									h={4}
									mt='4px'
									ml='20px'
									color='blue' />
							</Tooltip>
						</Flex>
					</Box>
					<Box maxW='90%'>

						{
							selectedInstances.map(
								(instance: any, i: any) => (
									<Box
										key={i}
										width='100%'>
										<Flex>
											<Text
												fontSize='md'
												minW='140px'
												mt='1.5'>
												Regex *
											</Text>

											<Input
												maxW='30%'
												mr='80px'
												value={instance.responseSelection.responseMatch}
												style={
													{
														color: 'black',
														borderRadius: '5px',
														border: '1px solid black',
													}
												}
												onChange={
													(e) => {
														dispatch(updateResponseSelection({ index: i, responseSelection: { xPath: instance.responseSelection.xPath, jsonPath: instance.responseSelection.jsonPath, responseMatch: e.target.value, invariant: instance.invariant } }))
													}
												}
											/>

											<Text
												fontSize='md'
												minW='120px'
												mt='1.5'>
												{instance.responseSelection?.xPath ? 'Xpath *' : 'JsonPath *'}
											</Text>

											<Input
												maxW='30%'
												mr='80px'
												value={instance.responseSelection?.xPath ? instance.responseSelection.xPath : instance.responseSelection.jsonPath}
												style={
													{
														color: 'black',
														borderRadius: '5px',
														border: '1px solid black',
													}
												}
												onChange={
													(e) => {
														if (instance.responseSelection.xPath) {
															dispatch(updateResponseSelection({ index: i, responseSelection: { xPath: e.target.value, jsonPath: instance.responseSelection.jsonPath, responseMatch: instance.responseSelection.responseMatch, invariant: instance.invariant } }))
														} else {
															dispatch(updateResponseSelection({ index: i, responseSelection: { xPath: instance.responseSelection.xPath, jsonPath: e.target.value, responseMatch: instance.responseSelection.responseMatch, invariant: instance.invariant } }))
														}
													}
												}
											/>
											{
												selectedInstances.length >
												1 && (
													<Button>
														<Icon
															as={MdDelete}
															w={5}
															h={5}
															color='white.500'
															onClick={
																() => {
																	dispatch(deleteSelectedInstance(i))
																}
															}
														/>
													</Button>
												)
											}
										</Flex>
									</Box>
								)
							)
						}
					</Box>
					<Box width='80%'>
						<Flex>
							<Text
								fontSize='md'
								minW='140px'>
								Body *
							</Text>
							{
								regexBody !== '' && (
									<Text
										onMouseUp={handleTextSelect}
										width='100%'>
										<div style={{ overflow: 'auto' }}>
											<Code
												style={
													{
														whiteSpace: 'pre-wrap',
														maxWidth: '100%',
													}
												}
											>
												{regexBody}
											</Code>
										</div>
										<Box>
											<Button
												onClick={handleReplaceRegexBody}
												isDisabled={!!!selectedText}
												size='sm'
												style={
													{
														marginRight: '1rem',
														marginTop: '1rem',
														boxSizing: 'border-box',
													}
												}
											>
												<Tooltip
													label='Select the text you want to make dynamic and click this button'
													aria-label='A tooltip'
												>
													Make Dynamic
												</Tooltip>
											</Button>
											<Button
												onClick={resetRegexBody}
												size='sm'
												style={
													{
														marginRight: '1rem',
														marginTop: '1rem',
														boxSizing: 'border-box',
													}
												}
											>
												<Tooltip
													label='Reset the body to its original state'
													style={
														{
															marginTop: '3rem',
															marginBottom: '3rem',
														}
													}
													aria-label='A tooltip'
												>
													Reset Body
												</Tooltip>
											</Button>
											<Button
												onClick={makeFullBodyDynamic}
												size='sm'
												style={
													{
														marginRight: '1rem',
														marginTop: '1rem',
														boxSizing: 'border-box',
													}
												}
											>
												<Tooltip
													label='Make all the body content dynamic'
													aria-label='A tooltip'
												>
													Make All Dynamic
												</Tooltip>
											</Button>
											<Popover closeOnBlur={false}>
												<PopoverTrigger>
													<Icon
														as={QuestionIcon}
														w={4}
														h={4}
														mt='13px'
														color='blue' />
												</PopoverTrigger>
												<PopoverContent
													color='black'>
													<PopoverArrow />
													<PopoverHeader>
														What to do here?
													</PopoverHeader>
													<PopoverCloseButton />
													<PopoverBody>
														<Box>
															<iframe
																src='https://www.loom.com/embed/93646bd34665411d9145991f171e6685?sid=62619623-30f4-43e8-ae63-e05854fa9f5f'
																allowFullScreen={true}
															/>
															<Text
																fontSize='md'
																mt='20px'>
																Highlight the parts of the body that are not important in identifying this request, and mark them as dynamic.
																Eg. Personal Data, IDs, etc
															</Text>
														</Box>
													</PopoverBody>
												</PopoverContent>
											</Popover>
										</Box>
									</Text>
								)
							}
							{
								regexBody === '' && (
									<Text>
										No Body
									</Text>
								)
							}
						</Flex>
					</Box>
				</VStack>
				<Flex
					maxW='88%'
					mt='40px'
					justifyContent='flex-end'>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={() => props.setActiveStepInnerForm(1)}
						width='10%'
						mr='30px'
						isLoading={buttonLoading}
					>
						Back
					</Button>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={handleNext}
						colorScheme='blue'
						width='10%'
						mr='30px'
						isLoading={buttonLoading}
					>
						Next
					</Button>
				</Flex>
			</Box>
		</>
	)
}