import React from 'react'
import { useRouteError } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Center, Heading, Image, Text, useColorMode, VStack } from '@chakra-ui/react'
//@ts-ignore
import EmptyImage from './assets/empty.png'
import { pxToRem } from './utils'

export const ErrorPage = () => {
	const navigate = useNavigate()
	const { errorCode } = useParams()
	const error: any = useRouteError()
	const handleGoBack = () => {
		navigate(-1)
	}

	const { colorMode } = useColorMode()

	return (
		<Box
			textAlign='center'
			mt='10'>
			<Center>
				<VStack mt='100px'>
					<Image
						height={pxToRem(350)}
						width={pxToRem(350)}
						src={EmptyImage}
						alt='Empty'
					/>
					<Heading
						as='h1'
						mb='4'
						fontSize='2xl'>
						Oops! Something went wrong.
					</Heading>
					<Text mb='6'>
						Error Code:
						{' '}
						<strong>
							{errorCode || error.status}
						</strong>
					</Text>
					<Text mb='6'>
						We apologize for the inconvenience. Please Try Again
						{' '}
					</Text>
					{
						process.env.NODE_ENV === 'development' && (
							<Text mb='6'>
								<strong>
									{error?.message}
									{error?.stack}
									{error?.data}
								</strong>
							</Text>
						)
					}
					<Button
						colorScheme='teal'
						color={colorMode === 'dark' ? 'white' : 'white'}
						onClick={handleGoBack}
					>
						Go Back
					</Button>
				</VStack>
			</Center>
		</Box>
	)
}
