import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, useSteps } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateBackAction } from '../../../../redux/providerBuilder/stepperDetails'
import { getBackAction } from '../../../../redux/providerBuilder/stepperDetails/selectors'
import { SelectData } from './select-data.component'
import { SelectRequest } from './select-request.component'


export const RequestSelection = () => {

	const dispatch = useAppDispatch()

	const location = useLocation()
	const { manualVerificationFlow } = location.state ?? {}


	const backActionUsed = useAppSelector(getBackAction)

	const steps = ['Select Request', 'Select Data Instances']
	const [searchQueryInput, setSearchQueryInput] = useState('')

	const { activeStep: activeStepInnerForm, setActiveStep: setActiveStepInnerForm } = useSteps({
		index: 0,
		count: steps.length,
	})

	useEffect(() => {
		if (manualVerificationFlow) {
			setActiveStepInnerForm(1)
		}
	}, [])

	useEffect(() => {
		if (backActionUsed) {
			setActiveStepInnerForm(1)
			dispatch(updateBackAction(false))
		}
	}, [backActionUsed, dispatch])


	return (
		<>
			<Box>
				{
					activeStepInnerForm === 0 && (
						<SelectRequest
							setActiveStepInnerForm={setActiveStepInnerForm}
							setSearchQueryInput={setSearchQueryInput}
							searchQueryInput={searchQueryInput} />
					)
				}
				{
					activeStepInnerForm === 1 && (
						<SelectData
							searchText={searchQueryInput}
							setActiveStepInnerForm={setActiveStepInnerForm} />
					)
				}
			</Box>
		</>
	)
}