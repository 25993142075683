/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { updateBackAction } from '../../../../redux/providerBuilder/stepperDetails'
import { getBackAction } from '../../../../redux/providerBuilder/stepperDetails/selectors'
import { getSessionId } from '../../../../redux/session/selectors'
import { sendDevtoolLogs } from '../../../../utils/logs/send-logs'
import { ScanQR } from './scan.component'

interface InitialiseSessionProps {
	accessToken: string
	userUid: string
}

export const InitialiseSession = (props: InitialiseSessionProps) => {
	const dispatch = useAppDispatch()
	const sessionId = useAppSelector(getSessionId)
	const { accessToken, userUid } = props
	const backActionUsed = useAppSelector(getBackAction)

	useEffect(() => {
		sendDevtoolLogs('PROVIDER_INITD')
	}, [])

	useEffect(() => {
		if (backActionUsed) {
			dispatch(updateBackAction(false))
		}
	}, [backActionUsed, dispatch])

	return (
		<>
			<Box
				marginTop='2.5rem'
				marginBottom='5rem'>
				<Flex>
					<Box
						padding='3%'
						width='100%'
						alignItems='center' >
						<ScanQR
							sessionId={sessionId}
							accessToken={accessToken}
							userUid={userUid} />
					</Box>
				</Flex>
			</Box>
		</>
	)
}