/* eslint-disable indent */
import { useEffect, useRef, useState } from 'react'
import {
	MdCheckCircle,
	MdEdit,
	MdKeyboardBackspace,
	MdThumbUp,
} from 'react-icons/md'
import { CheckIcon, CloseIcon, RepeatIcon } from '@chakra-ui/icons'
import {
	Alert,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Badge,
	Box,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Editable,
	EditableInput,
	EditablePreview,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Icon,
	IconButton,
	Image,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Switch,
	Tag,
	TagLabel,
	Text,
	Textarea,
	Tooltip,
	useDisclosure,
	useEditableControls,
	useToast,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setSelectedProvidersFromCreateApp } from '../../../redux/httpsProviders'
import { getAllActiveHttpsProviders } from '../../../redux/httpsProviders/actions'
import { getSelectedProvidersFromCreateApp } from '../../../redux/httpsProviders/selectors'
import { updateProviderDetails } from '../../../redux/providerBuilder/providerDetails'
import { getProviderDetails } from '../../../redux/providerBuilder/providerDetails/selectors'
import { getSelectedInstances } from '../../../redux/providerBuilder/selectedInstances/selectors'
import { updateActiveStep } from '../../../redux/providerBuilder/stepperDetails'
import {
	getActiveStep,
	getStepperTitles,
} from '../../../redux/providerBuilder/stepperDetails/selectors'
import { sendDevtoolLogs } from '../../../utils/logs/send-logs'
import {
	generateChatGPTProofCardText,
	getUnusedVariables,
	seperateProofCardText,
} from '../../../utils/provider-builder/proof-card-utils'
import { registedProvider } from '../../../utils/provider-builder/register-provider-utils'
import { getFirebaseIdToken } from '../../../utils/utils'

export const ProofCard = () => {
	const toast = useToast()
	const dispatch = useAppDispatch()

	const activeStep = useAppSelector(getActiveStep)
	const stepperTitles = useAppSelector(getStepperTitles)
	const selectedInstances = useAppSelector(getSelectedInstances)
	const providerDetails = useAppSelector(getProviderDetails)
	const selectedProvidersFromCreateApp = useAppSelector(
		getSelectedProvidersFromCreateApp
	)

	const [buttonLoading, setButtonLoading] = useState(false)
	const [repeatLoading, setRepeatLoading] = useState(false)
	const [proofCardError, setProofCardError] = useState('')

	const [proofCardTitle, setProofCardTitle] = useState(
		providerDetails.proofCardTitle ? providerDetails.proofCardTitle : ''
	)
	const [proofCardText, setProofCardText] = useState(
		providerDetails.proofCardText ? providerDetails.proofCardText : ''
	)
	const [providerFavicon] = useState<string>(
		providerDetails.logoUrl ? providerDetails.logoUrl : ''
	)
	const [proofCardTextList, setProofCardTextList] = useState<any[]>([])
	const [proofCardValueList, setProofCardValueList] = useState<any[]>([])
	const [availableVariables, setAvailableVariables] = useState<any[]>([])
	const [switchPosition, setSwitchPosition] = useState(false)
	const cancelRef = useRef<HTMLInputElement>(null)

	const {
		isOpen: isProofCardModelOpen,
		onOpen: onProofCardModelOpen,
		onClose: onProofCardModelClose,
	} = useDisclosure()

	const {
		isOpen: isAlertDialogOpen,
		onOpen: onAlertDialogOpen,
		onClose: onAlertDialogClose,
	} = useDisclosure()

	useEffect(() => {
		setProofCardTextList(seperateProofCardText(providerDetails.proofCardText))
		setAvailableVariables(
			getUnusedVariables(providerDetails.proofCardText, selectedInstances)
		)
	}, [providerDetails])

	useEffect(() => {
		// Focus the button when the component mounts
		cancelRef.current?.focus()
	  }, [])

	// Editable Controls for the proof card title
	function EditableControls() {
		const {
			isEditing,
			getSubmitButtonProps,
			getCancelButtonProps,
			getEditButtonProps,
		} = useEditableControls()

		return isEditing ? (
			<ButtonGroup
				justifyContent='center'
				size='sm'>
				<IconButton
					aria-label='save'
					icon={<CheckIcon />}
					{...getSubmitButtonProps()}
				/>
				<IconButton
					aria-label='cancel'
					icon={<CloseIcon />}
					{...getCancelButtonProps()}
				/>
			</ButtonGroup>
		) : (
			<Tooltip label='Set Proof Card Text Title'>
				<IconButton
					ml='2px'
					boxSize='8'
					variant='ghost'
					colorScheme='blue'
					fontSize='xl'
					aria-label='edit'
					size='sm'
					icon={<MdEdit />}
					{...getEditButtonProps()}
				/>
			</Tooltip>
		)
	}

	// Set available variables which are not used in proofCardText
	function setAllAvailableVariables(proofText: string) {
		const newAvailableVariables = getUnusedVariables(
			proofText,
			selectedInstances
		)

		setAvailableVariables(newAvailableVariables)
	}

	// Set the proof card text to the proofCardText state after disabling the used variables
	function onProofCardTextChange(e: any) {
		setProofCardError('')
		setProofCardText(e.target.value)
		setAllAvailableVariables(e.target.value)
	}

	// Append available variables to the proof card text and disable them
	const handleVariableClick = (variable: any) => {
		setProofCardError('')
		let newCardText = proofCardText
		if(proofCardText.slice(-1) !== ' ' && proofCardText.length) {
			newCardText += ' '
		}

		newCardText += '{{' + variable + '}}'
		setProofCardText(newCardText)
		setAvailableVariables(
			availableVariables.map((v) => {
				if(v.name === variable) {
					return { name: v.name, isUsed: true }
				}

				return v
			})
		)
	}

	// Set Proof Card Text after Modal Submission
	function updateProofCardText() {
		if(availableVariables.some((v) => !v.isUsed)) {
			setProofCardError('Please use all the variables')
			return
		}

		const proofTextList = seperateProofCardText(proofCardText)
		setProofCardTextList(proofTextList)
		dispatch(updateProviderDetails({ proofCardText: proofCardText }))
		onProofCardModelClose()
	}

	// get proof card text from chatGPT
	async function regenerateChapGptText() {
		setRepeatLoading(true)
		const newProofText = await generateChatGPTProofCardText(selectedInstances)
		setProofCardText(newProofText)
		setAllAvailableVariables(newProofText)
		dispatch(updateProviderDetails({ proofCardText: proofCardText }))
		setProofCardTextList(seperateProofCardText(newProofText))

		setRepeatLoading(false)
	}

	// Show the proof card preview before and after proof generation
	const handleSwitchChange = () => {
		const proofValList = proofCardTextList.map((val) => {
			if(val.type === 'variable') {
				const variableName = val.value.substring(2, val.value.length - 2)
				const variable = selectedInstances.find(
					(v) => v.variableName === variableName
				)
				if(variable) {
					return { type: 'variable', value: variable.data }
				}
			}

			return { type: 'string', value: val.value }
		})

		setProofCardValueList(proofValList)
		setSwitchPosition((switchPosition) => !switchPosition)
	}

	// Chech if all variables present in the proofCardTextList
	function checkAllVariablesUsed() {
		const proofCardTextListVarCount = proofCardTextList.filter((val) => val.type === 'variable').length
		return proofCardTextListVarCount === selectedInstances.length
	}

	async function submitHttpProvider() {
		setButtonLoading(true)

		try {
			const accessToken = (await getFirebaseIdToken()) ?? ''
			const message = await registedProvider(providerDetails, accessToken)

			if(!message.isSuccess) {
				toast({
					title: 'Provider Creation Failed',
					description: message.message,
					status: 'error',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				return
			} else {
				await sendDevtoolLogs('PROVIDER_PUBLISHED', message.provider.httpProviderId)
				dispatch(getAllActiveHttpsProviders())
				dispatch(
					setSelectedProvidersFromCreateApp([
						...selectedProvidersFromCreateApp,
						message.provider,
					])
				)
				dispatch(updateActiveStep(activeStep + 1))
			}
		} catch(e) {
			toast({
				title: 'Provider Creation Failed',
				description: 'Unknown Error while creating provider',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		}

		setButtonLoading(false)
	}

	return (
		<>
			<Box
				marginTop='2.5rem'
				marginBottom='5rem'>
				<Card>
					<CardHeader>
						<Heading fontSize='larger'>
							<IconButton
								variant='outline'
								colorScheme='teal'
								aria-label=''
								onClick={() => dispatch(updateActiveStep(activeStep - 1))}
								icon={<MdKeyboardBackspace />}
								marginRight='20px'
							/>
							{stepperTitles[activeStep]}
						</Heading>
					</CardHeader>
					<CardBody height='80%'>
						<Box>
							<Alert status='success'>
								You&apos;re almost done add few more details to
								complete&nbsp;&nbsp;
								<Icon as={MdThumbUp} />
							</Alert>
							<Text
								mt='15px'
								fontWeight='semibold'
								fontSize='lg'>
								{' '}
								Customize the Proof Card Title and Text
								{' '}
							</Text>
							<Text
								mt='3px'
								fontSize='large'>
								{' '}
								Note: This is how the end users will view their data while
								generating and submitting the Proofs
								{' '}
							</Text>
							<Flex
								mt='20px'
								justifyContent='center'
								maxW='100%'>
								<Card
									ml='20px'
									mt='10px'
									borderWidth={1}
									borderColor='blackAlpha.400'
									width='40%'
								>
									<CardHeader>
										<Flex justify='space-between'>
											<Box maxW='70%'>
												<Flex gap={4}>
													<Image
														mt='5px'
														src={providerFavicon}
														alt='Logo'
														boxSize='9'
													/>
													<Editable
														value={proofCardTitle}
														fontSize='2xl'
														color='black'
														maxW='400px'
														isPreviewFocusable={false}
														onChange={(value) => setProofCardTitle(value)}
														onSubmit={
															() => dispatch(
																updateProviderDetails({
																	proofCardTitle: proofCardTitle,
																})
															)
														}
													>
														<EditablePreview
															fontSize='large'
															mt='7px'
															maxW='210px'
															whiteSpace='pre-wrap'
														/>
														<Input
															mb='15px'
															as={EditableInput} />
														{!switchPosition && <EditableControls />}
													</Editable>
												</Flex>
											</Box>
											<Flex
												mt='12px'
												gap={2}>
												<Text fontSize='md'>
													Preview
												</Text>
												<Switch
													mt='1px'
													justifySelf='flex-end'
													size='md'
													onChange={handleSwitchChange}
												/>
											</Flex>
										</Flex>
									</CardHeader>
									<CardBody>
										<Flex flexWrap='wrap'>
											{
												!switchPosition &&
												proofCardTextList.map((obj, index) => (
													<Flex
														mt='5px'
														whiteSpace='pre'
														key={index}>
														{
															obj.type === 'variable' ? (
																<Text
																	fontWeight='bold'
																	fontSize='lg'
																	color='blue'
																	whiteSpace='pre-wrap'
																>
																	{obj.value.replace('{{', '').replace('}}', '')}
																	{' '}
																</Text>
															) : (
																<Text
																	fontSize='lg'
																	whiteSpace='pre-wrap'>
																	{obj.value}
																	{' '}
																</Text>
															)
														}
													</Flex>
												))
											}
											{
												switchPosition &&
												proofCardValueList.map((text, index) => (
													<Flex
														mt='5px'
														whiteSpace='pre'
														key={index}>
														{
															text.type === 'variable' ? (
																<>
																	<Text
																		fontWeight='bold'
																		fontSize='lg'
																		color='blue'
																		whiteSpace='pre-wrap'
																	>
																		{text.value}
																		{' '}
																	</Text>
																	<br />
																</>
															) : (
																<>
																	<Text
																		fontSize='lg'
																		whiteSpace='pre-wrap'>
																		{text.value}
																		{' '}
																	</Text>
																	<br />
																</>
															)
														}
													</Flex>
												))
											}
											{
												!switchPosition && (
													<>
														<Tooltip label='Set Proof Card Text Message'>
															<IconButton
																mt='4px'
																boxSize='8'
																variant='ghost'
																colorScheme='blue'
																fontSize='xl'
																aria-label='edit'
																size='sm'
																icon={<MdEdit />}
																onClick={
																	() => {
																		const newProofCardText = proofCardTextList
																			.map((obj) => obj.value)
																			.join(' ')
																		setProofCardText(newProofCardText)
																		setAllAvailableVariables(newProofCardText)
																		setProofCardError('')
																		onProofCardModelOpen()
																	}
																}
															/>
														</Tooltip>
														{
															!repeatLoading && (
																<Tooltip label='Regenerate Proof Card Text'>
																	<IconButton
																		mt='4px'
																		boxSize='8'
																		variant='ghost'
																		colorScheme='blue'
																		fontSize='xl'
																		aria-label='edit'
																		size='sm'
																		icon={<RepeatIcon />}
																		onClick={
																			async() => {
																				if(!repeatLoading) {
																					await regenerateChapGptText()
																				}
																			}
																		}
																	/>
																</Tooltip>
															)
														}
														{
															repeatLoading && (
																<Spinner
																	color='blue.600'
																	ml='7px'
																	mt='12px'
																	size='sm'
																/>
															)
														}
													</>
												)
											}
										</Flex>
										<Modal
											isOpen={isProofCardModelOpen}
											size='2xl'
											onClose={onProofCardModelClose}
										>
											<ModalOverlay />
											<ModalContent>
												<ModalHeader>
													Enter Proof Card Text
												</ModalHeader>
												<ModalCloseButton />
												<ModalBody minW='100%'>
													<FormControl
														marginTop='1.5rem'
														isInvalid={!!proofCardError}
													>
														<Flex direction='column'>
															<Flex
																mt='15px'
																mb='15px'
																minW='100%'>
																<FormLabel
																	fontSize='md'
																	mt='7px'>
																	Proof Card Text:
																</FormLabel>
																<Flex
																	minW='75%'
																	direction='column'>
																	<Textarea
																		borderRadius='5px'
																		borderWidth={1}
																		borderColor='black'
																		placeholder={proofCardText}
																		required={true}
																		value={proofCardText}
																		onChange={
																			(e) => {
																				onProofCardTextChange(e)
																			}
																		}
																	/>
																	<FormErrorMessage>
																		{proofCardError}
																	</FormErrorMessage>
																</Flex>
															</Flex>

															<Flex
																width='100%'
																mt='15px'
																mb='15px'
																minW='100%'
															>
																<FormLabel
																	fontSize='md'
																	mt='5px'
																	ml='45px'>
																	Variables:
																</FormLabel>
																<HStack
																	justify='space-between'
																	spacing={2}>
																	{
																		availableVariables.map((variable, index) => (
																			<Tag
																				key={index}
																				size='md'
																				variant='subtle'
																				colorScheme={variable.isUsed ? 'gray' : 'blue'}
																				cursor={variable.isUsed ? 'default' : 'pointer'}
																				onClick={
																					() => {
																						if(!variable.isUsed) {
																							handleVariableClick(variable.name)
																						}
																					}
																				}
																			>
																				<Tooltip
																					label='Variable already used'
																					isDisabled={!variable.isUsed}
																				>
																					<TagLabel>
																						{variable.name}
																					</TagLabel>
																				</Tooltip>
																			</Tag>
																		))
																	}
																</HStack>
															</Flex>
														</Flex>
													</FormControl>
												</ModalBody>

												<ModalFooter>
													<Button
														onClick={
															() => {
																setProofCardText(proofCardTextList.join(' '))
																onProofCardModelClose()
															}
														}
														mr={3}
													>
														Cancel
													</Button>
													<Button
														colorScheme='blue'
														onClick={() => updateProofCardText()}
													>
														Submit
													</Button>
												</ModalFooter>
											</ModalContent>
										</Modal>
									</CardBody>
									<CardFooter>
										{
											switchPosition && (
												<Badge
													padding='10px'
													colorScheme='gray'
													fontSize='lg'
													color='blue'
													rounded='full'
												>
													<HStack
														justifyContent='center'
														maxH='20px'>
														<Icon
															as={MdCheckCircle}
															w={5}
															h={5}
															color='white.500'
														/>
														<Text>
															Verified
														</Text>
													</HStack>
												</Badge>
											)
										}
										{
											!switchPosition && (
												<Flex
													width='100%'
													justify='center'>
													<Button
														bgColor='blue'
														color='white'
														fontSize='md'
														padding='10px'
														borderRadius='12px'
														width='95%'
														variant='unstyled'
														cursor='default'
													>
														Create proof
													</Button>
												</Flex>
											)
										}
									</CardFooter>
								</Card>
							</Flex>

							<Flex
								mt='20px'
								justifyContent='right'
								maxW='100%'>
								<Button
									marginTop='1.5rem'
									variant='solid'
									mr='20px'
									onClick={
										() => {
											if(checkAllVariablesUsed()) {
											submitHttpProvider()
											} else {
												onAlertDialogOpen()
											}
										}
									}
									colorScheme='blue'
									isLoading={buttonLoading}
								>
									Submit
								</Button>
								<AlertDialog
									isOpen={isAlertDialogOpen}
									onClose={onAlertDialogClose}
									leastDestructiveRef={cancelRef}
									>
									<AlertDialogOverlay>
										<AlertDialogContent>
											<AlertDialogHeader
												fontSize='lg'
												fontWeight='bold'>
												Create Provider
											</AlertDialogHeader>

											<AlertDialogBody>
												Proof Card Text has unused variables.
												{' '}
												<br />
												Are you sure you want to create provider?
											</AlertDialogBody>

											<AlertDialogFooter>
												<Button
													// @ts-ignore
													ref={cancelRef}
													onClick={
														() => {
															onAlertDialogClose()
														}
													}>
													Cancel
												</Button>
												<Button
													isLoading={buttonLoading}
													colorScheme='red'
													onClick={
														() => {
														submitHttpProvider()
													}
												}
													ml={3}
												>
													Create
												</Button>
											</AlertDialogFooter>
										</AlertDialogContent>
									</AlertDialogOverlay>
								</AlertDialog>
							</Flex>
						</Box>
					</CardBody>
				</Card>
			</Box>
		</>
	)
}
