//@ts-nocheck
import { useState } from 'react'
import { MdKeyboardBackspace } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { Box, Button, FormControl, FormLabel, Heading, HStack, IconButton, Input, Text, Textarea, useToast, VStack } from '@chakra-ui/react'
import axios from 'axios'
import { pxToRem, ZKFETCH_APPLICATION } from '../../utils'
import { generateEncryptionKeys } from '../../utils/generate-wallet-address'
import { getFirebaseIdToken } from '../../utils/utils'

export const PublicDataVerificationScreen = () => {


	const [isLoading, setIsLoading] = useState(false)
	const [formData, setFormData] = useState({
		applicationName: '',
		projectName: '',
		applicationDescription: '',
	})
	const navigate = useNavigate()
	const toast = useToast()

	const handleFormChange = (e: any) => {
		const { name, value } = e.target
		setFormData({
			...formData,
			[name]: value,
		})

	}


	const submitHandler = async () => {

		const { applicationName, projectName, applicationDescription } = formData


		if (!applicationName || !projectName || !applicationDescription) {
			return toast({
				title: 'Error',
				description: 'Please fill all the fields',
				status: 'error',
				duration: 3000,
				isClosable: true,
			})
		}

		try {
			setIsLoading(true)

			const { address, privateKey } = await generateEncryptionKeys()
			const accessToken = await getFirebaseIdToken()

			const { data } = await axios.post(ZKFETCH_APPLICATION, {
				publicKey: address,
				applicationName,
				projectName,
				description: applicationDescription
			}, {
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				}
			})

			setIsLoading(false)
			setFormData({
				applicationName: '',
				projectName: '',
				applicationDescription: '',
			})
			if (data.isSuccess) {
				toast({
					title: 'Success',
					description: 'Application registered successfully',
					status: 'success',
					duration: 3000,
					isClosable: true,
				})
				navigate(`/public-data/preview/${address}`, { state: { privateKey } })
			}


		} catch (error) {
			toast({
				title: 'Error',
				description: 'An error occurred while registering the application' + error.message,
				status: 'error',
				duration: 3000,
				isClosable: true,
			})

		}
	}


	return (
		<>
			<Box p='6px'>
				<Box mt='16px'>
					<Box mt='16'>
						<Heading
							as='h1'
							fontWeight={700}
							size='lg'
							fontSize='24px'>
							Register your Application
						</Heading>
						<Text mt='8px'>
							Select the list of providers and integrate them in 2 steps.
						</Text>
					</Box>


					<Box
						mt={pxToRem(30)}
						p={pxToRem(4)}
						bg='white'>
						<Box

							borderRadius='lg'
							p={pxToRem(30)}
						>
							<IconButton
								variant='outline'
								colorScheme='blue'
								aria-label=''
								onClick={() => navigate(- 1)}
								icon={<MdKeyboardBackspace />}
								width='fit-content'
							/>
							<VStack
								spacing={4}
								align='stretch'
								mt='20px'>

								{/* Application Name */}
								<FormControl>
									<FormLabel htmlFor='appName'>
										Application Name
									</FormLabel>
									<HStack>
										<Input
											type='text'
											id='appName'
											name='applicationName'
											value={formData.applicationName}
											onChange={handleFormChange}

											placeholder='Enter application name'
										/>

									</HStack>
								</FormControl>


								{/* Project Name/ Company Name */}
								<FormControl>
									<FormLabel htmlFor='appName'>
										Project/Company Name *
										{' '}
									</FormLabel>
									<HStack>
										<Input
											type='text'

											value={formData.projectName}
											name='projectName'
											onChange={handleFormChange}
											placeholder='Enter application name'
										/>
									</HStack>
								</FormControl>

								{/* Application Description */}
								<FormControl>
									<FormLabel htmlFor='appDescription'>
										Application Description *
									</FormLabel>
									<HStack>
										<Textarea
											value={formData.applicationDescription}
											name='applicationDescription'
											onChange={handleFormChange}
											defaultValue={formData.applicationDescription}
											id='appDescription'
											placeholder='Enter application description'
										/>

									</HStack>
								</FormControl>

								<Button
									colorScheme='blue'
									isLoading={isLoading}

									onClick={submitHandler}
								>
									Register
								</Button>
							</VStack>
						</Box>
					</Box>
				</Box>
			</Box>

		</>
	)
}