import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ManualVerificationSession } from './types'

interface ManualVerificationSessions {
	isFetching?: boolean
	manualVerificationSessions: ManualVerificationSession[]
	providerName: string
	selectedManualVerificationSessionData: any
	selectRequestScreenSearchQuery: string
	selectRequestScreenClickedParameterName: string
}

const initialState: ManualVerificationSessions = {
	isFetching: false,
	manualVerificationSessions: [],
	providerName: '',
	selectedManualVerificationSessionData: {},
	selectRequestScreenSearchQuery: '',
	selectRequestScreenClickedParameterName: ''
}

const manualVerificationSessionSlice = createSlice({
	name: 'manualVerificationSessions',
	initialState,
	reducers: {
		setManualVerificationSessions(state, action: PayloadAction<ManualVerificationSession[]>) {
			state.manualVerificationSessions = action.payload
		},
		updateManualVerificationSessions(state, action: PayloadAction<ManualVerificationSession>) {
			const sessions = action.payload
			state.manualVerificationSessions = [...state.manualVerificationSessions, sessions]
		},
		setManualVerificationSessionsFetching(state, action: PayloadAction<boolean>) {
			state.isFetching = action.payload
		},
		setManualVerificationSessionProviderName(state, action: PayloadAction<string>) {
			state.providerName = action.payload
		},
		setSelectedManualVerificationSessionData(state, action: PayloadAction<ManualVerificationSession>) {
			state.selectedManualVerificationSessionData = action.payload
		},
		resetSelectedManualVerificationSessionData(state) {
			state.selectedManualVerificationSessionData = {}
		},
		setSelectRequestScreenSearchQuery(state, action: PayloadAction<string>) {
			state.selectRequestScreenSearchQuery = action.payload
		},
		setSelectRequestScreenClickedParamerterName(state, action: PayloadAction<string>) {
			state.selectRequestScreenClickedParameterName = action.payload
		},
	},
})

export const { setManualVerificationSessions,
	updateManualVerificationSessions,
	setManualVerificationSessionsFetching,
	setManualVerificationSessionProviderName,
	setSelectedManualVerificationSessionData,
	resetSelectedManualVerificationSessionData,
	setSelectRequestScreenSearchQuery,
	setSelectRequestScreenClickedParamerterName
} = manualVerificationSessionSlice.actions

export default manualVerificationSessionSlice.reducer