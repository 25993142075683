import { DELETE_APPLICATION, EDIT_APPLICATION, GET_USER_APPLICATIONS, REGISTER_APPLICATION } from '../../utils'
import { getFirebaseIdToken } from '../../utils/utils'
import { setApplications, setFetching, updateApplications } from '../applications'
import { typedCreateAsyncThunk } from '../extraConfig'

export const registerApplication = typedCreateAsyncThunk<
	{ isSuccess: boolean, app: any },
	{
		name: string
		customSubProviders: string[]
		whitelistedDomains: string[]
		httpProviders: string[]
		callbackUrl: string
		publicKey: string
		applicationDescription?: string
		description?: string
		projectName: string
		image?: string

	}
>(
	'application/registerApplication',
	async (payload, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		const response = await fetch(REGISTER_APPLICATION, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		})
		const data = await response.json()

		if (data.isSuccess) {
			dispatch(updateApplications(data.app))
			return { isSuccess: true, app: data.app }
		} else {
			return rejectWithValue(data.message)
		}
	}
)

export const editApplication = typedCreateAsyncThunk<
	void,
	{
		name: string
		callbackUrl: string
		appId: string
		description?: string
		whitelistedDomains: string[]
		httpProviderId?: string[]
		image?: string
	}
>(
	'application/editApplication',
	async (payload, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		const response = await fetch(
			EDIT_APPLICATION(payload?.appId),
			{
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			}
		)
		const data = await response.json()
		if (data) {
			dispatch(fetchAllApplications())
		} else {
			return rejectWithValue(data.message)
		}
	}
)

export const deleteApplication = typedCreateAsyncThunk<
	void,
	{
		appId: string
	}
>(
	'application/deleteApplication',
	async (payload, { dispatch, rejectWithValue }) => {
		try {

			const accessToken = await getFirebaseIdToken()
			dispatch(setFetching(true))
			const response = await fetch(
				DELETE_APPLICATION(payload?.appId),
				{
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}
			)

			const data = await response.json()


			if (data.isSuccess) {
				dispatch(setFetching(false))
				dispatch(fetchAllApplications())
			} else {
				console.error(
					'Delete request was not successful. Error message:',
					data.message
				)
				return rejectWithValue(data.message)
			}
		} catch (error) {
			console.error('An error occurred while deleting the application:', error)
			return rejectWithValue(
				'An error occurred while deleting the application.'
			)
		}
	}
)


// this is the current one

export const fetchAllApplications = typedCreateAsyncThunk<any, void>(
	'application/fetchAllApplications',
	async (_, { dispatch, rejectWithValue }) => {
		const accessToken = await getFirebaseIdToken()
		dispatch(setFetching(true))
		const response = await fetch(GET_USER_APPLICATIONS, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		})
		const data = await response.json()

		if (data) {
			dispatch(setFetching(false))
			dispatch(setApplications(data.applications))
			return { isSuccess: true, app: data.app }
		} else {
			return rejectWithValue(data.message)
		}
	}
)