import { useContext, useEffect } from 'react'
import { FiCopy } from 'react-icons/fi'
//import { Text } from "../text/text.component"
import { IoMdCheckmark } from 'react-icons/io'
import {
	Box,
	Code,
	CodeProps,
	Icon,
	IconButton,
	Text,
	useClipboard,
	useColorModeValue,
	useToast,
} from '@chakra-ui/react'
import Highlight, { defaultProps } from 'prism-react-renderer'
import vsDark from 'prism-react-renderer/themes/nightOwl'
import vsLight from 'prism-react-renderer/themes/nightOwlLight'
import Prism from 'prismjs'
import { AmplitudeAnalyticsContext } from '../../../contexts/useAnalytics'

const darkThemeDefault = vsDark
const lightThemeDefault = vsLight

type PrismTheme = typeof darkThemeDefault

// add solidity/rust lang support for code
;(typeof global !== 'undefined' ? global : window).Prism = Prism
require('prismjs/components/prism-solidity')
require('prismjs/components/prism-rust')
// end add solidity/rust support

export interface CodeBlockProps extends Omit<CodeProps, 'size'> {
  code: string
  codeValue?: string
  language?: string
  canCopy?: boolean
  framework: string
  wrap?: boolean
  prefix?: string
  darkTheme?: PrismTheme
  lightTheme?: PrismTheme
}
export const CodeBlock: React.FC<CodeBlockProps> = ({
	code,
	codeValue,
	language = 'sh',
	px = 4,
	py = 2,
	w = 'full',
	borderRadius = 'md',
	borderColor = 'borderColor',
	borderWidth = '1px',
	fontFamily = 'mono',
	backgroundColor,
	prefix,
	canCopy = true,
	wrap = true,
	darkTheme,
	lightTheme,
	framework,
	...restCodeProps
}) => {
	const theme = useColorModeValue(
		lightTheme || lightThemeDefault,
		darkTheme || darkThemeDefault
	)
	const { hasCopied, setValue } = useClipboard(codeValue || code)

	const toast = useToast()

	const onCopy = () => {
		navigator.clipboard.writeText(codeValue || code).then(() => {
			logEvent('COPY_CODE_BUTTON_CLICK', {
				code: framework,
			})
		})

		toast({
			title: 'Code Copied',
			description: 'The code has been copied to the clipboard.',
			status: 'success',
			duration: 3000,
			isClosable: true,
		})
	}

	const { logEvent } = useContext(AmplitudeAnalyticsContext)

	useEffect(() => {
		if(code) {
			setValue(codeValue || code)
		}
	}, [code, codeValue, setValue])

	return (
		<Highlight
			{...defaultProps}
			code={prefix ? `${prefix} ${code}` : code}
			//@ts-ignore
			language={language ? language : 'bash'}
			theme={
				{
					...theme,
					plain: {
						backgroundColor:
            (backgroundColor as string) ||
            'var(--chakra-colors-backgroundHighlight)',
					},
				}
			}
			// theme={darkTheme}
		>
			{
				({ className, style, tokens, getLineProps, getTokenProps }: any) => (
					<Text
						borderRadius={borderRadius}
						py={py}
						px={px}
						w={w}
						borderWidth={borderWidth}
						borderColor={borderColor}
						position='relative'
						className={className}
						style={style}
						fontFamily={fontFamily}
						whiteSpace={wrap ? 'pre-wrap' : 'pre'}
						{...restCodeProps}
						as={Code}
					>
						{
							canCopy && code && (
								<IconButton
									mr={-2}
									onClick={onCopy}
									float='right'
									aria-label='Copy'
									borderRadius='md'
									variant='ghost'
									colorScheme='gray'
									size='sm'
									icon={
										<Icon
											as={hasCopied ? IoMdCheckmark : FiCopy}
											fill={hasCopied ? 'green.500' : undefined}
										/>
									}
								/>
							)
						}
						<Box
							as='span'
							display='block'
							my={1}
							color='heading'
							h='full'>
							{
								tokens.map((line: any[], i: any) => (
									// eslint-disable-next-line react/jsx-key
									<Box {...getLineProps({ line, key: i })}>
										{
											line.map((token: any, key: any) => (
												// eslint-disable-next-line react/jsx-key
												<span {...getTokenProps({ token, key })} />
											))
										}
									</Box>
								))
							}
						</Box>
					</Text>
				)
			}
		</Highlight>
	)
}
