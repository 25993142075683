/* eslint-disable indent */
export const statusTagColors: any = {
    'PENDING': 'yellow',
    'REJECTED': 'red',
    'VERIFIED': 'green',
    'QUEUED': 'teal',
    'SUCCESS': 'green',
    'FAILED': 'red',
    'RETRYING': 'orange',
    'NA': 'blackAlpha'
}

export const statusUpperCaseToLowerCase: any = {
    'PENDING': 'pending',
    'REJECTED': 'rejected',
    'VERIFIED': 'verified',
    'QUEUED': 'queued',
    'SUCCESS': 'success',
    'FAILED': 'failed',
    'RETRYING': 'retrying',
    'NA': 'NA'
}