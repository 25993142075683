import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface StepperDetails {
	activeStep: number
	backAction: boolean
	titles: string[]
	titlesV2: string[]
}

const initialState: StepperDetails = {
	activeStep: 0,
	backAction: false,
	titles: ['Start Dev Session', 'Select Request', 'Add Provider Details', 'Customize Proof Card', 'Review Provider'],
	titlesV2: ['Start Dev Session', 'Select Request', 'Add Provider Details', 'Test Provider']
}

const stepperDetailsSlice = createSlice({
	name: 'stepperDetails',
	initialState,
	reducers: {
		updateActiveStep(state, action: PayloadAction<number>) {
			state.activeStep = action.payload
		},
		updateBackAction(state, action: PayloadAction<boolean>) {
			state.backAction = action.payload
		},
		resetActiveStep(state) {
			state.activeStep = 0
		}
	},
})

export const { updateActiveStep, updateBackAction, resetActiveStep } = stepperDetailsSlice.actions

export default stepperDetailsSlice.reducer
