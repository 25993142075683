import { CheckIcon } from '@chakra-ui/icons'
import {
	Avatar,
	Badge,
	Box,
	Heading,
	HStack,
	Image,
	Text,
	Tooltip,
	useColorMode,
	VStack,
} from '@chakra-ui/react'
//@ts-ignore
import Verified from '../../assets/icons/verified.svg'
//import { CustomProvider } from '../../redux/customProviders/types'
import { HttpsProvider } from '../../redux/httpsProviders/types'
import { pxToRem } from '../../utils'

interface ProviderCardProps {
	providerDetail?: HttpsProvider | any
	isSelected?: boolean
	onSelect?: (id: string) => void
}
export const ProviderCard = ({
	providerDetail,
	isSelected,
	onSelect,
}: ProviderCardProps) => {
	const { colorMode } = useColorMode()
	const getBgColor = () => {
		if (colorMode === 'dark') {
			return isSelected ? '#15233B' : '#131417'
		} else {
			return isSelected ? '#DBE6F9' : 'white'
		}
	}

	return (
		<Box
			cursor='pointer'
			borderRadius='lg'
			position='relative'
			//border='1px solid transparent'
			//   bg={isSelected ? "#DBE6F9" : "white"}
			//@ts-ignore
			bg={getBgColor}
			p='16px'
			_hover={
				{
					borderColor: 'blue.500',
				}
			}
			border={`1px solid ${colorMode === 'dark' ? 'transparent' : '#e5e5ea'}`}
			transition='background 100ms ease, border-color 100ms ease'
			alignItems='center'
			//@ts-ignore
			onClick={() => onSelect(onSelect && providerDetail)}
		>
			<HStack justifyContent='space-between'>
				<VStack
					alignItems='flex-start'
					position='relative'
					justifyContent='flex-start'
				>
					<Avatar
						name={providerDetail?.name}
						src={providerDetail?.logoUrl}
						width={pxToRem(30)}
						size='sm'
						style={{ border: '1px solid #E5E5EA' }}
						height={pxToRem(30)}
					/>
					{
						providerDetail?.isVerified && (
							<Tooltip
								label='Verified - Official providers maintained by Reclaim Team'
								placement='top'
								hasArrow
							>
								<Image
									position='absolute'
									top={2.5}
									left={5}
									src={Verified}
									alt='verified'
									width={pxToRem(22)}
									height={pxToRem(22)}
								/>
							</Tooltip>
						)
					}
				</VStack>
				<HStack>

					{isSelected && <CheckIcon color='#3385FF' />}
				</HStack>
			</HStack>

			<Heading
				as='h2'
				mt='16px'
				fontSize={pxToRem(14)}>
				{providerDetail?.name}
			</Heading>

			<Box>
				<Text
					mt='6px'
					fontSize={pxToRem(14)}>
					{
						providerDetail?.description
							? providerDetail.description
							: `Proves ${providerDetail?.name}`
					}
				</Text>
			</Box>
			{
				providerDetail.providerType === 'PRIVATE' && (
					<Badge
						mt={pxToRem(10)}
						mr={pxToRem(10)}
						top={0}
						right={0}
						fontSize={pxToRem(12)}
						colorScheme='green'
						variant='outline'
						position='absolute'
					>
						Private
					</Badge>
				)
			}
		</Box>
	)
}
