/* eslint-disable */

import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Avatar,
  Box,
  Button,
  Code,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
  getAllActiveHttpsProviders,
  getAllNonActiveHttpsProviders,
  getAllNonApprovedProviders,
} from "../../redux/httpsProviders/actions"
import {
  getHttpsProviders,
  getNonActiveProviders,
  getNonApprovedProviders,
} from "../../redux/httpsProviders/selectors"
import { fetchAllUsers } from "../../redux/user/actions"
import { getAllUsers } from "../../redux/user/selectors"
import { APPROVE_PROVIDER, GET_ALL_PRIVATE_PROVIDERS, pxToRem } from "../../utils"
//import { approveProviderCall } from "../custom-providers/utils"
import { ProviderCard } from "../explore/components/provider-card.component"
import { getFirebaseIdToken } from "../../utils/utils"
import axios from "axios"


export const approveProviderCall = async (providerId: string) => {
  const accessToken = await getFirebaseIdToken()
  const response = await fetch(APPROVE_PROVIDER(providerId), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ httpProviderId: providerId }),
  })
  const data = await response.json()
  if (data.isSuccess) {
    return data
  } else {
    return data.message
  }
}

export const AdminDashboardScreen = () => {

  const httpsProviders = useAppSelector(getHttpsProviders)

  const nonActiveProviders = useAppSelector(getNonActiveProviders)
  const nonApprovedProviders = useAppSelector(getNonApprovedProviders)
  const [privateProviders, setPrivateProviders] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()
  const { colorMode } = useColorMode()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(getAllNonApprovedProviders())
    // dispatch(getAllActiveHttpsProviders())
    dispatch(getAllNonActiveHttpsProviders())
    fecthPrivateProviders()
  }, [httpsProviders])


  const fecthPrivateProviders = async () => {
    try {
      const accessToken = await getFirebaseIdToken()
      const response = await axios.get(GET_ALL_PRIVATE_PROVIDERS, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      //@ts-ignore
      if (response?.data?.isSuccess && response?.status === 200) {
        //@ts-ignore
        setPrivateProviders(response?.data?.privateProviders)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box p='16px'>
      <Box mt='16'>
        <Heading as='h1' fontWeight={700} size='lg' fontSize='24px'>
          Dashboard
        </Heading>
        {/* <Text mt='8px'>
          Select the list of providers and integrate in 2 steps!!
        </Text> */}
      </Box>

      <Box mt='19px'>
        <Box
          mt='32px'
          p='20px'
          borderRadius='lg'
          borderWidth='1px'
          borderStyle='solid'
          borderColor='borderColor'
          bgColor={colorMode === "dark" ? "" : "white"}
        >
          <Heading as='h2'>Engagements</Heading>
          <HStack mt='30px' minWidth='100%' justifyContent='space-between'>
            <VStack
              width='100%'
              borderRight='thin'
              borderRightWidth='1px'
              alignItems='flex-start'
              borderRightStyle='solid'
              borderColor='borderColor'
            >
              <Heading fontSize='24px'>
                {nonApprovedProviders.length > 0
                  ? nonApprovedProviders.length
                  : 0}
              </Heading>
              <Text fontSize='14px'>Pending for Approval</Text>
            </VStack>

            <VStack
              pl='24px'
              width='100%'
              borderRight='thin'
              borderRightWidth='1px'
              alignItems='flex-start'
              borderRightStyle='solid'
              borderColor='borderColor'
            >
              <Heading fontSize='24px'>
                {httpsProviders.length ? httpsProviders.length : 0}
              </Heading>
              <Text fontSize='14px'>All Providers</Text>
            </VStack>

            <VStack
              pl='24px'
              borderRight='thin'
              borderRightWidth='1px'
              width='100%'
              alignItems='flex-start'
              borderColor='borderColor'
            >
              <Heading fontSize='24px'>
                {nonActiveProviders?.length ? nonActiveProviders.length : 0}
              </Heading>
              <Text fontSize='14px'>Non Active Providers</Text>
            </VStack>
          </HStack>
        </Box>
      </Box>

      <Box mt='19px'>
        <Box
          mt='32px'
          p='20px'
          borderRadius='lg'
          borderWidth='1px'
          borderStyle='solid'
          borderColor='borderColor'
          bgColor={colorMode === "dark" ? "" : "white"}
        >
          <HStack justifyContent='space-between'>
            <Heading as='h2'>Pending for Approval</Heading>
            <Heading
              as='h2'
              cursor='pointer'
              color='pink'
              onClick={() => navigate("/explore")}
            >
              Browse All Providers
            </Heading>
          </HStack>
          <HStack mt='30px' minWidth='100%' justifyContent='space-between'>
            <Grid
              width={"100%"}
              mt='20px'
              gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
              gap='22px'
            //gridTemplateColumns='repeat(3,1fr)'
            >
              {nonApprovedProviders.length > 0 ? (
                <>
                  {nonApprovedProviders.map((p) => (
                    <PendingCard providerDetail={p} key={p.id} />
                  ))}
                </>
              ) : (
                <p>No Pending providers</p>
              )}
            </Grid>
          </HStack>
        </Box>
      </Box>

      <Box mt={pxToRem(30)}>

        <Tabs >
          <TabList mb='1em'>
            <Tab>Non Active Providers</Tab>
            <Tab>All Private Providers</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <Box mt='19px'>
                <Box
                  mt='32px'
                  p='20px'
                  borderRadius='lg'
                  borderWidth='1px'
                  borderStyle='solid'
                  borderColor='borderColor'
                  bgColor={colorMode === "dark" ? "" : "white"}
                >
                  <HStack justifyContent='space-between'>
                    <Heading as='h2'>Non Active Providers</Heading>
                    {/* <Heading
              as='h2'
              cursor='pointer'
              color='pink'
              onClick={() => navigate("/explore")}
            >
              Browse All Providers
            </Heading> */}
                  </HStack>
                  <HStack mt='30px' minWidth='100%' justifyContent='space-between'>
                    <Grid
                      width={"100%"}
                      mt='20px'
                      gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
                      gap='22px'
                    //gridTemplateColumns='repeat(3,1fr)'
                    >
                      {nonActiveProviders.length > 0 ? (
                        <>
                          {nonActiveProviders.map((p) => (
                            <ProviderCard providerData={p} key={p.id} />
                          ))}
                        </>
                      ) : (
                        <p>No Non Active Providers</p>
                      )}
                    </Grid>
                  </HStack>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel p={0}>
              <Box
                mt='32px'
                p='20px'
                borderRadius='lg'
                borderWidth='1px'
                borderStyle='solid'
                borderColor='borderColor'
                bgColor={colorMode === "dark" ? "" : "white"}
              >
                <HStack justifyContent='space-between'>
                  <Heading as='h2'>Non Active Providers</Heading>
                  {/* <Heading
              as='h2'
              cursor='pointer'
              color='pink'
              onClick={() => navigate("/explore")}
            >
              Browse All Providers
            </Heading> */}
                </HStack>
                <HStack mt='30px' minWidth='100%' justifyContent='space-between'>
                  <Grid
                    width={"100%"}
                    mt='20px'
                    gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
                    gap='22px'
                  //gridTemplateColumns='repeat(3,1fr)'
                  >
                    {privateProviders.length > 0 ? (
                      <>
                        {privateProviders.map((p, i) => (
                          <ProviderCard providerData={p} key={i} />
                        ))}
                      </>
                    ) : (
                      <p>No Non Active Providers</p>
                    )}
                  </Grid>
                </HStack>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>

      </Box>



    </Box>
  )
}

export const PendingCard = ({ providerDetail }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { colorMode } = useColorMode()
  return (
    <Box
      borderRadius='lg'
      border={`1px solid ${colorMode === "dark" ? "transparent" : "#e5e5ea"}`}
      //   bg={isSelected ? "#DBE6F9" : "white"}
      //@ts-ignore
      bg={colorMode === "dark" ? "#131417" : "white"}
      onClick={onOpen}
      p='16px'
      _hover={{
        borderColor: "blue.500",
      }}
      transition='background 100ms ease, border-color 100ms ease'
      alignItems='center'
    //@ts-ignore
    //onClick={() => onSelect(onSelect && providerDetail)}
    >
      <HStack justifyContent='space-between'>
        <VStack alignItems='flex-start' justifyContent='flex-start'>
          <Avatar
            name={providerDetail?.name}
            //src={providerData?.logoUrl}
            width={pxToRem(30)}
            height={pxToRem(30)}
            size='sm'
            textAlign='center'
          //objectFit='cover'
          />
        </VStack>
      </HStack>

      <Heading as='h2' mt='16px' fontSize={pxToRem(14)}>
        {providerDetail?.name}
      </Heading>

      <Box>
        <Text mt='6px' fontSize={pxToRem(14)}>
          {providerDetail?.description
            ? providerDetail?.description
            : " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima,laborum!"}
        </Text>
      </Box>
      <PendingProviderDetail
        onClose={onClose}
        isOpen={isOpen}
        providerDetail={providerDetail}
      />
      <HStack justifyContent='space-between' mt='20px'>
        <VStack>
          {/* <Text>Created By</Text> */}
          {/* <Text>Koushith97@gmail.com</Text> */}
        </VStack>

        {/* <VStack>
          <HStack>
            <Button
              variant={"ghost"}
              color={colorMode === "dark" ? "white" : "white"}
              colorScheme='red'
            >
              Reject
            </Button>
            <Button
              size={"sm"}
              color={colorMode === "dark" ? "white" : "white"}
              colorScheme='blue'
            >
              Approve
            </Button>
          </HStack>
        </VStack> */}
      </HStack>
    </Box>
  )
}


export const PendingProviderDetail = ({
  onClose,
  isOpen,
  providerDetail,
}: any) => {
  const { colorMode } = useColorMode()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const approveProviderHandler = async (id: string) => {
    await approveProviderCall(id)
    dispatch(getAllNonApprovedProviders())
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Provider Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            mt='8'
            p='4'
            borderWidth='1px'
            borderRadius='lg'
            borderColor='borderColor'
          >
            {/* Logo */}
            <Flex align='center' mb='4' justifyContent={"space-between"}>
              <Text fontWeight='bold' fontSize='lg' mr='2'>
                Logo:
              </Text>
              <Image
                src={
                  providerDetail?.logoUrl
                    ? providerDetail?.logoUrl
                    : "http://localhost:3000/assets/logo-light.png"
                }
                width='30px'
              />
            </Flex>

            {/* Key-Value Pairs */}
            {Object.entries({
              Name: providerDetail?.name,
              Description: providerDetail?.description
                ? providerDetail?.description
                : "Lorem ipsum...",
              Method: providerDetail?.method,

              "Login URL": providerDetail?.loginUrl,
              "Created By": `${providerDetail?.createdBy?.name} (${providerDetail?.createdBy?.email})`,
              "Body Sniff": providerDetail?.bodySniff?.enabled
                ? "Enabled"
                : "Not Enabled",
              "Response Selection": providerDetail?.bodySniff?.enabled
                ? "Enabled"
                : "Not Enabled",
              Regex: providerDetail?.bodySniff?.regex
                ? providerDetail?.bodySniff?.regex
                : "No RegeX found",
              "Custom JS Injections": providerDetail?.customInjection
                ? providerDetail?.customInjection
                : "No Custom JS Injections",
            }).map(([key, value], index) => (
              <Flex
                key={index}
                mt='4'
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Text
                  fontWeight='bold'
                  fontSize='lg'
                  mr='2'
                  flexShrink={0}
                  minW={"auto"}
                >
                  {key}:
                </Text>
                {key === "Custom JS Injections" ||
                  key === "Method" ||
                  key === "Regex" ? (
                  <Code colorScheme='red' wordBreak='break-word'>
                    {value}
                  </Code>
                ) : (
                  <Text wordBreak={"break-word"} ml='40px'>
                    {value}
                  </Text>
                )}
              </Flex>
            ))}

            {/* Buttons */}
            <Flex mt='40px' gap={"10px"}>
              <Button
                width={"100%"}
                size='sm'
                color={colorMode === "dark" ? "white" : "white"}
                colorScheme='blue'
                onClick={() =>
                  approveProviderHandler(providerDetail?.httpProviderId)
                }
                ml='2'
              >
                Approve
              </Button>
              {/* <Button
                size='sm'
                width={"100%"}
                variant='outline'
                // color={colorMode === "dark" ? "white" : "white"}
                colorScheme='gray'
                onClick={()=>{
                  toast({
                    title: "Provider Rejected- ",
                    description: "Provider Rejected",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  })
                  onClose()
                }}
              >
                Reject
              </Button> */}
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
