/* eslint-disable react/jsx-indent */
import { useEffect, useState } from 'react'
import { MdAdd, MdArrowBack, MdArrowForward } from 'react-icons/md'
import { DeleteIcon, SearchIcon } from '@chakra-ui/icons'
import {
	Box,
	Button,
	Checkbox,
	Code,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Icon,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Table,
	TableContainer,
	Td,
	Text,
	Th,
	Tooltip,
	Tr,
	useDisclosure,
	useToast,
	VStack,
} from '@chakra-ui/react'
import { getAuth } from 'firebase/auth'
import { VideoModal } from '../../../../components'
import { app } from '../../../../configs/firebaseApp'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import {
	deleteSelectedInstance,
	updateSelectedInstances,
} from '../../../../redux/providerBuilder/selectedInstances'
import { getSelectedInstances } from '../../../../redux/providerBuilder/selectedInstances/selectors'
import { getSelectedNetworkRequest } from '../../../../redux/providerBuilder/selectedNetworkRequest/selectors'
import { updateActiveStep } from '../../../../redux/providerBuilder/stepperDetails'
import { getActiveStep } from '../../../../redux/providerBuilder/stepperDetails/selectors'
import { sendDataSelectionErrorLogs } from '../../../../utils/logs/send-logs'
import {
	createXPathFromElement,
	escapeRegExp,
	getMatchIndexes,
	search,
} from '../../../../utils/provider-builder/instance-selection-utils'
import { sendSlackAlert } from '../../../../utils/slack-alert'

interface SelectDataProps {
  searchText: string
  setActiveStepInnerForm: (value: any) => void
}

export const SelectData = (props: SelectDataProps) => {
	const toast = useToast()
	const dispatch = useAppDispatch()
	const auth = getAuth(app)

	const activeStep = useAppSelector(getActiveStep)
	const selectedNetworkRequest = useAppSelector(getSelectedNetworkRequest)
	const selectedInstances = useAppSelector(getSelectedInstances)
	const { setActiveStepInnerForm, searchText } = props

	const {
		isOpen: isVariableNameModelOpen,
		onOpen: onVariableNameModelOpen,
		onClose: onVariableNameModelClose,
	} = useDisclosure()

	const [sections, setSections] = useState<any>([])
	const [queryInstanceCount, setQueryInstanceCount] = useState<number>(0)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [variableNameOfIntanceInput, setVariableNameOfIntanceInput] =
    useState('')
	const [error, setError] = useState('')
	const [selectedQueryInstance, setSelectedQueryInstance] = useState<number>(0)
	const [searchInstanceInput, setSearchInstanceInput] = useState(searchText)
	const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null)
	const [videoOpen, setVideoOpen] = useState(false)
	const [invariantChecked, setInvariantChecked] = useState(true)

	useEffect(() => {
		if (timerId) {
			clearTimeout(timerId)
		}

		const newTimerId = setTimeout(() => {
			handleSelectNetworkRequest()
		}, 500)
		setTimerId(newTimerId)
		return () => {
			clearTimeout(newTimerId)
		}
	}, [searchInstanceInput])

	function generateXpath(htmlString: string, index: number) {
		const lt = getMatchIndexes(htmlString.substring(0, index), '<')
		const gt = getMatchIndexes(htmlString.substring(index), '>')
		let element = null
		const parser = new DOMParser()
		const parsed = parser.parseFromString(htmlString, 'text/html')
		const allElements = parsed.getElementsByTagName('*')
		for (const e of allElements) {
			if (e?.outerHTML) {
				if (
					e.outerHTML?.includes(
						htmlString.substring(lt[lt.length - 1], index + gt[0] + 1)
					)
				) {
					if (element === null) {
						element = e
					} else if (
						element?.outerHTML &&
            element?.outerHTML?.length > e.outerHTML.length
					) {
						element = e
					}
				}
			}
		}

		const xpath = createXPathFromElement(element, parsed) ?? ''
		return xpath
	}

	function generateRegexHtml(
		htmlString: string,
		start: number,
		searchQueryInputsize: number,
		variableName?: string
	) {
		const characterOffset = 20
		let leftLimit = start > characterOffset ? start - characterOffset : 0
		let sqoutes = false
		let dqoutes = false
		for (let i = 0; i < characterOffset && start - i > 0; i++) {
			const c = htmlString.charAt(start - i)
			if (c === '<') {
				leftLimit = start - i + 1
				break
			}

			if (c === "'") {
				if (sqoutes) {
					leftLimit = start - i + 1
					break
				}

				sqoutes = true
			}

			if (c === '"') {
				if (dqoutes) {
					leftLimit = start - i + 1
					break
				}

				dqoutes = true
			}
		}

		let sqoutesr = false
		let dqoutesr = false
		const claimDataEndPos = start + searchQueryInputsize
		let rightLimit = claimDataEndPos + characterOffset
		for (
			let i = 0;
			i < characterOffset && claimDataEndPos + i < htmlString.length;
			i++
		) {
			const c = htmlString.charAt(claimDataEndPos + i)
			if (c === '>') {
				rightLimit = claimDataEndPos + i + 1
				break
			}

			if (c === "'") {
				if (sqoutesr) {
					rightLimit = claimDataEndPos + i + 1
					break
				}

				sqoutesr = true
			}

			if (c === '"') {
				if (dqoutesr) {
					rightLimit = claimDataEndPos + i + 1
					break
				}

				dqoutesr = true
			}
		}

		const regex = escapeRegExp(
			htmlString
				.substring(leftLimit, rightLimit)
				.replace(searchInstanceInput, variableName ?? 'CLAIM_DATA')
		).replace(variableName, '{{' + variableName + '}}')
		return regex
	}

	// set selected text to the selectedText state
	const handleInstanceSelect = () => {
		const selection = window.getSelection()?.toString()
		if (selection?.length) {
			setSearchInstanceInput(selection)
		}
	}

	function handleSelectNetworkRequest(): void {
		const characterOffset = 20
		const responseBody = selectedNetworkRequest.response.body
		const chunks: {
      t: any
      marked: boolean
      i?: number
      start?: number
      last?: number
      regx?: any
      jsonPath?: string
    }[] = []
		let last = 0
		let count = 0
		if (searchInstanceInput.length) {
			const searchQueryInputsize = searchInstanceInput.length
			const matchIndexs = getMatchIndexes(responseBody, searchInstanceInput)
			if (
				matchIndexs.length === 0 &&
        selectedNetworkRequest.contentType.includes('html')
			) {
				toast({
					title: 'No Match Found. Please try again',
					status: 'error',
					duration: 2000,
					isClosable: true,
					position: 'top-right',
				})
				return
			}

			if (selectedNetworkRequest.contentType.includes('html')) {
				matchIndexs.forEach((index) => {
					const start = index
					count += 1
					chunks.push({
						t: responseBody.substring(last, start),
						marked: false,
					})
					last = start + searchQueryInputsize
					chunks.push({
						i: count,
						t: responseBody.substring(start, last),
						marked: true,
						start,
						last,
					})
				})
			}

			if (selectedNetworkRequest.contentType.includes('json')) {
				const data = JSON.parse(responseBody)
				const jsonPaths = search('$', data, searchInstanceInput)
				// const posList: Array<number> = []
				jsonPaths.forEach(({ path, isString }, i) => {
					// const poss = getValuePositionInJson(responseBody, path, searchInstanceInput, posList)
					// console.log('poss final ', poss)
					// if(poss !== -1) {
					// posList.push(poss)
					const start = matchIndexs[i]
					let leftLimit = start
					let dqoutes = 0
					count += 1
					for (let i = 0; i < characterOffset && start - i - 1 > 0; i++) {
						const c = responseBody.charAt(start - i - 1)
						if (c === '"') {
							if (isString ? dqoutes === 2 : dqoutes === 1) {
								leftLimit = start - i - 1
								break
							}

							dqoutes += 1
						}
					}

					const claimDataEndPos = start + searchQueryInputsize
					let rightLimit = claimDataEndPos + characterOffset
					for (
						let i = 0;
						i < characterOffset && claimDataEndPos + i < responseBody.length;
						i++
					) {
						const c = responseBody.charAt(claimDataEndPos + i)
						if (c === ',' || c === '}') {
							rightLimit = claimDataEndPos + i
							break
						}
					}

					const regx = escapeRegExp(
						responseBody
							.substring(leftLimit, rightLimit)
							.replace(searchInstanceInput, 'CLAIM_DATA')
					).replace('CLAIM_DATA', '{{CLAIM_DATA}}')
					chunks.push({
						t: responseBody.substring(last, start),
						marked: false,
					})
					last = start + searchQueryInputsize
					chunks.push({
						i: count,
						t: responseBody.substring(start, last),
						marked: true,
						start,
						last,
						regx,
						jsonPath: path,
					})
				})
			}
		}

		setSelectedQueryInstance(count ? 1 : 0)

		chunks.push({ t: responseBody.substring(last), marked: false })
		setSections(chunks)
		setQueryInstanceCount(count)

		setTimeout(() => {
			document?.getElementById('queryMatch1')?.scrollIntoView()
			document?.getElementById('requestDataTitle')?.scrollIntoView()
		}, 100)
	}

	function checkIfVariableNameExists(variableName: string) {
		return !!selectedInstances.some(
			(instance) => instance.variableName === variableName
		)
	}

	function isValidVariableName(variableName: string) {
		return /^[a-zA-Z0-9_]+$/.test(variableName)
	}

	function addSelectedInstanceToList(e: any) {
		if (!variableNameOfIntanceInput.trim()) {
			setError('Input cannot be empty')
			return
		}

		if (checkIfVariableNameExists(variableNameOfIntanceInput)) {
			setError('Variable name already exists')
			return
		}

		if (!isValidVariableName(variableNameOfIntanceInput)) {
			setError('Special characters or spaces not allowed')
			return
		}

		const xPath = selectedNetworkRequest.contentType.includes('json')
			? ''
			: generateXpath(selectedNetworkRequest.response.body, e.start)
		if (e.regx) {
			const regexMat = /({{.*?}})/g
			const matches = e.regx.match(regexMat) || []
			if (matches.length > 0) {
				e.regx = e.regx.replace(
					matches[0],
					'{{' + variableNameOfIntanceInput + '}}'
				)
			}
		}

		const regex =
      e.regx ??
      generateRegexHtml(
      	selectedNetworkRequest.response.body,
      	e.start,
      	searchInstanceInput.length,
      	variableNameOfIntanceInput
      )
		const newSelectedInstanceList = {
			data: e.t,
			variableName: variableNameOfIntanceInput,
			responseSelection: {
				xPath: xPath,
				jsonPath: e.jsonPath ?? '',
				responseMatch: regex,
				invariant: invariantChecked,
			},
		}

		dispatch(updateSelectedInstances(newSelectedInstanceList))

		setError('')
		setVariableNameOfIntanceInput('')
		onVariableNameModelClose()
	}

	async function sendDataSelectionAlert() {
		const messageBody = {
			userId: auth.currentUser?.uid || '',
			url: selectedNetworkRequest.url,
			contentType: selectedNetworkRequest.contentType,
			requestType: selectedNetworkRequest.requestType,
			searchString: searchInstanceInput,
			requestBody: selectedNetworkRequest.response.body,
		}
		// Slack Channel Id
		const channelId = 'C070EGQ9Q94'

		const responseVal = await sendSlackAlert(
			JSON.stringify(messageBody),
			channelId
		)
		sendDataSelectionErrorLogs(messageBody)
		if (responseVal.status === 'success') {
			toast({
				title: 'Data Selection Issue Reported',
				description:
          'Thanks for reporting the issue. Our team will look into it',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		} else {
			toast({
				title: 'Error Reporting Issue',
				description: 'Some error occured while reporting the issue',
				status: 'error',
				duration: 2000,
				isClosable: true,
				position: 'top-right',
			})
		}
	}

	return (
		<>
			<Box width='80%'>
				<VStack
					alignItems='flex-start'
					ml='10px'
					mb='10px'>
					<Text
						fontSize='md'
						fontWeight='bold'
						mb='1'>
            Search the data you want to verify
					</Text>
					<InputGroup mb='10px'>
						<Input
							placeholder='1 Karma, email id, username'
							value={searchInstanceInput}
							style={
								{
									width: '95%',
									borderRadius: '5px',
									padding: '10px',
									borderWidth: '1.5px',
									borderColor: 'gray',
								}
							}
							onChange={(e) => setSearchInstanceInput(e.target.value)}
						/>
						<InputRightElement>
							<Icon
								as={SearchIcon}
								color='gray.500' />
						</InputRightElement>
					</InputGroup>
				</VStack>
				<TableContainer>
					<Table
						mt='10px'
						variant='simple'
						mb='20px'>
						<Tr>
							<Td
								fontWeight='bold'
								color='blue.500'
								id='requestDataTitle'>
                URL :
							</Td>
							<Td>
								<Text
									maxW='600px'
									whiteSpace='pre-wrap'
									overflow='hidden'>
									{selectedNetworkRequest.url}
								</Text>
							</Td>
						</Tr>
						<Tr>
							<Td
								fontWeight='bold'
								color='blue.500'
								style={{ whiteSpace: 'nowrap' }}
								id='queryMatchAnchor'
							>
                Instances Found :
							</Td>
							<Td>
								{queryInstanceCount}
							</Td>
						</Tr>
					</Table>
				</TableContainer>
				<Box
					ml='10px'
					mt='10px'>
					{
						selectedInstances.length > 0 ? (
							<>
								<Text
									fontSize='lg'
									fontFamily='sans-serif'
									fontWeight='bold'
									mb='10px'
								>
                Total Selected Instances
								</Text>
								<TableContainer width='100%'>
									<Table
										minW='90%'
										mb='10px'
										variant='simple'>
										<Tr>
											<Th />
											<Th
												fontWeight='bold'
												fontSize='sm'
												textAlign='center'>
												{' '}
                      Variable Name
											</Th>
											<Th
												fontWeight='bold'
												fontSize='sm'
												textAlign='center'>
												{' '}
                      Data
											</Th>
											<Th />
										</Tr>

										{
											selectedInstances.length > 0 &&
                    selectedInstances.map((instance, index) => {
                    	return (
                    		<>
                    			<Tr
                    				key={index}
                    				mt='10px'
                    				mb='10px'
                    				ml='10px'>
                    				<Td textAlign='center'>
                    					{' '}
                    					{index + 1}
                    				</Td>
                    				<Td
                    					textAlign='center'
                    					maxW='20%'
                    					overflow='hidden'
                    					whiteSpace='pre-wrap'
                    				>
                    					{' '}
                    					{instance.variableName}
                    				</Td>
                    				<Td
                    					textAlign='center'
                    					maxW='20%'
                    					overflow='hidden'
                    					whiteSpace='pre-wrap'
                    				>
                    					<Text fontSize='md'>
                    						{instance.data}
                    					</Text>
                    				</Td>
                    				<Td textAlign='center'>
                    					<IconButton
                    						aria-label='delete'
                    						icon={<DeleteIcon />}
                    						colorScheme='blue'
                    						borderRadius='14px'
                    						size='xs'
                    						onClick={
                    							() => {
                    							dispatch(deleteSelectedInstance(index))
                    						}
                    						}
                    					/>
                    				</Td>
                    			</Tr>
                    			<Divider />
                    		</>
                    	)
                    })
										}
									</Table>
								</TableContainer>
							</>
						) : (
							<Text
								fontSize='lg'
								mb='10px'
								fontFamily='sans-serif'
								color='black.500'
							>
              No Data Instances found. Click
								{' '}
								<Popover closeOnBlur={false}>
									<PopoverTrigger>
										<span
											style={
												{
													color: 'blue',
													textDecoration: 'underline',
													cursor: 'pointer',
												}
											}
										>
                    here
										</span>
									</PopoverTrigger>
									<PopoverContent
										backgroundColor='gray.50'
										color='black'>
										<PopoverArrow />
										<PopoverHeader>
How to Select Data
										</PopoverHeader>
										<PopoverCloseButton />
										<PopoverBody>
											<Box>
												<iframe
													src='https://www.loom.com/embed/bbc0dd2723db415ab5114be70113531c?sid=9c06cbe8-1f36-4eea-bbe5-d8ac63572e0e'
													allowFullScreen={true}
												/>
												<Text
													fontSize='md'
													mt='20px'>
                        Search in Text Box or Select the data from the response
                        body
												</Text>
											</Box>
										</PopoverBody>
									</PopoverContent>
								</Popover>
								{' '}
              to learn how to add new data instances.
							</Text>
						)
					}
					<Text
						fontSize='lg'
						mt='20px'
						mb='10px'
						fontFamily='sans-serif'
						color='red.500'
					>
            Cant Select the required data? Click
						{' '}
						<Button
							onClick={
								async () => {
									await sendDataSelectionAlert()
								}
							}
							variant='link'
							colorScheme='blue'
							p={0}
							height='auto'
							lineHeight='normal'
							verticalAlign='baseline'
						>
              here
						</Button>
						{' '}
            to report the issue
					</Text>
				</Box>
				<HStack justifyContent='space-between'>
					<Button onClick={() => setVideoOpen((open) => !open)}>
						{' '}
            Need Help? Watch Toturial
					</Button>
					<Flex
						gap={5}
						justify='flex-end'
						mt='4'
						mb='4'>
						<Button
							leftIcon={<MdArrowBack />}
							color='brand.primary'
							variant='solid'
							isDisabled={queryInstanceCount === 0 || selectedQueryInstance === 1}
							onClick={
								() => {
									setSelectedQueryInstance(selectedQueryInstance - 1)
									document
										.getElementById(`queryMatch${selectedQueryInstance - 1}`)
										?.scrollIntoView()
								}
							}
							maxH='40px'
						>
              Previous
						</Button>
						<Button
							maxH='40px'
							isDisabled={selectedQueryInstance === queryInstanceCount}
							rightIcon={<MdArrowForward />}
							color='brand.primary'
							variant='solid'
							onClick={
								() => {
									setSelectedQueryInstance(selectedQueryInstance + 1)
									document
										.getElementById(`queryMatch${selectedQueryInstance + 1}`)
										?.scrollIntoView()
								}
							}
						>
              Next
						</Button>
						<Button
							isDisabled={!selectedQueryInstance}
							maxH='40px'
							colorScheme='blue'
							onClick={
								() => {
									setError('')
									setVariableNameOfIntanceInput('')
									onVariableNameModelOpen()
								}
							}
						>
							<Icon
								as={MdAdd}
								w={5}
								h={5}
								color='white.500' />
							<Tooltip
								label='Search or select the data you want to add'
								aria-label='A tooltip'
								isDisabled={!!searchInstanceInput.length}
							>
                Add
							</Tooltip>
						</Button>
						<Modal
							isOpen={isVariableNameModelOpen}
							onClose={onVariableNameModelClose}
						>
							<ModalOverlay />
							<ModalContent>
								<ModalHeader>
Enter Variable Name
								</ModalHeader>
								<ModalCloseButton />
								<ModalBody>
									<FormControl
										marginTop='1.5rem'
										isInvalid={!!error}>
										<Flex
											mt='15px'
											mb='15px'>
											<FormLabel
												fontSize='md'
												mt='7px'>
                        Variable Name:
											</FormLabel>
											<Flex direction='column'>
												<Input
													borderRadius='5px'
													borderWidth={1}
													borderColor='black'
													placeholder='eg. email, username, id'
													required={true}
													onChange={
														(e) => {
															setVariableNameOfIntanceInput(e.target.value)
														}
													}
												/>
												<FormErrorMessage>
													{error}
												</FormErrorMessage>
											</Flex>
										</Flex>
										<Flex
											mt='15px'
											mb='15px'>
											<FormLabel fontSize='md'>
Variable Value:
											</FormLabel>
											<FormLabel
												fontSize='md'
												fontWeight='bold'
												ml='7px'>
												{searchInstanceInput}
											</FormLabel>
										</Flex>
										<Flex
											mt='15px'
											mb='15px'>
											<Flex direction='column'>
												<Tooltip label='To protect against duplication in future use'>
													<Checkbox
														iconSize='1rem'
														checked={invariantChecked}
														onChange={
															() => {
																setInvariantChecked(!invariantChecked)
															}
														}
													>
                            Unique to Each User
													</Checkbox>
												</Tooltip>
											</Flex>
										</Flex>
									</FormControl>
								</ModalBody>

								<ModalFooter>
									<Button
										onClick={onVariableNameModelClose}
										mr={3}>
                    Cancel
									</Button>
									<Button
										colorScheme='blue'
										onClick={
											() => {
												console.log(
													sections.find((e: any) => e.i === selectedQueryInstance)
												)
												addSelectedInstanceToList(
													sections.find((e: any) => e.i === selectedQueryInstance)
												)
											}
										}
									>
                    Add
									</Button>
								</ModalFooter>
							</ModalContent>
						</Modal>
					</Flex>
				</HStack>
				<Flex style={{ overflow: 'auto' }}>
					<Box style={{ width: '100%' }}>
						{
							sections ? (
								<Text
									onMouseUp={handleInstanceSelect}
									style={
										{
											maxHeight: '600px',
											overflow: 'auto',
											padding: '1.5rem',
											paddingBottom: '1.5rem',
											fontVariantNumeric: 'tabular-nums',
										}
									}
									backgroundColor='gray.200'
								>
									{
										sections.map((section: any, i: any) => section.marked ? (
											<span
												key={i}
												id={`queryMatch${section.i}`}
												style={
													{
														backgroundColor: 'yellow',
														cursor: 'pointer',
														borderColor:
                          section.i === selectedQueryInstance
                          	? 'red'
                          	: undefined,
														borderWidth: '2px',
														fontWeight:
                          section.i === selectedQueryInstance
                          	? 'bold'
                          	: undefined,
														padding:
                          section.i === selectedQueryInstance
                          	? '0.2rem'
                          	: '0.1rem',
														borderRadius: '30%',
													}
												}
												onClick={() => setSelectedQueryInstance(section.i)}
											>
												{section.t}
											</span>
										) : (
											section.t
										)
										)
									}
								</Text>
							) : (
								<Code
									style={
										{
											maxHeight: '600px',
											overflow: 'auto',
										}
									}
								>
									{selectedNetworkRequest.response.body}
								</Code>
							)
						}
					</Box>
				</Flex>
				<Flex justifyContent='flex-end'>
					<Button
						marginTop='30px'
						variant='solid'
						justifySelf='right'
						onClick={() => setActiveStepInnerForm(0)}
						width='10%'
						mr='30px'
					>
            Back
					</Button>
					<Tooltip
						label={
							selectedInstances.length
								? ''
								: 'Please add at least one data instance to proceed'
						}
					>
						<Button
							marginTop='30px'
							variant='solid'
							justifySelf='right'
							onClick={
								() => {
									setButtonLoading(true)
									dispatch(updateActiveStep(activeStep + 1))
								}
							}
							colorScheme='blue'
							width='10%'
							isLoading={buttonLoading}
							isDisabled={!selectedInstances.length}
						>
              Next
						</Button>
					</Tooltip>
				</Flex>
			</Box>
			<VideoModal
				isOpen={videoOpen}
				closeModal={() => setVideoOpen(false)}
				buttonText='Need Help? Watch Video Tutorial'
				modalTitle='Video Tutorial'
			/>
		</>
	)
}
