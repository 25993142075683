
import { useEffect, useState } from 'react'
import { Box, Grid, Skeleton, useToast } from '@chakra-ui/react'
import axios from 'axios'
import { EmptyState } from '../../../components'
import { HttpsProvider } from '../../../redux/httpsProviders/types'
import { GET_ALL_PROVIDER_CREATED_BY_USER, PRIVATE_PROVIDERS_BY_USER, pxToRem } from '../../../utils'
import { getFirebaseIdToken } from '../../../utils/utils'
import { ProviderCard } from './provider-card.component'

export const MyProviders = () => {
	const [loading, setIsLoading] = useState(false)
	const [myProviders, setMyProviders] = useState<HttpsProvider[]>([])
	const toast = useToast()

	const fetchProviderCreatedByUser = async () => {
		try {
			setIsLoading(true)
			const accessToken = await getFirebaseIdToken()
			const { data } = await axios.get(GET_ALL_PROVIDER_CREATED_BY_USER, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				}
			})

			const response = await axios.get(PRIVATE_PROVIDERS_BY_USER, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			})


			setIsLoading(false)
			if (data?.isSuccess && response?.data?.isSuccess) {
				const activeProviders = data?.providers?.filter(
					(provider: HttpsProvider) => provider?.isActive
				)
				const allProviders = [
					...activeProviders,
					...response?.data?.providers,
				]
				setMyProviders(allProviders)
			}


		} catch (error) {
			toast({
				title: 'Error',
				description: 'Something went wrong while fetching your providers. Please try again later.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			})

		}
	}

	useEffect(() => {
		fetchProviderCreatedByUser()
	}, [])


	return (
		<Box>
			{
				loading && (
					<Grid
						mt='20px'
						gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
						gap='22px'
					>
						{
							new Array(10).fill(0).map((_, i) => (
								<Skeleton
									key={i}
									height={pxToRem(117)}
									borderRadius='lg'
									width={pxToRem(371)}
								/>
							))
						}
					</Grid>
				)
			}
			{
				myProviders.length > 0 ? (
					<>
						<Grid
							mt='20px'
							gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
							gap='22px'
						>
							{
								myProviders.map((providerDetail) => (
									<Box key={providerDetail?.id}>
										<ProviderCard providerData={providerDetail} />
									</Box>
								))
							}
						</Grid>
					</>
				) : (
					<EmptyState
						message='You have not created any data providers yet. Create a data provider tailored to your usecase.'
						navigateTo='/devtoolv2'
						buttonText='Create Data Provider'
					/>
				)
			}
		</Box>
	)
}
