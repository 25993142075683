/* eslint-disable */
//@ts-nocheck
import { useState } from "react"
import { FaNode, FaReact, FaReacteurope } from "react-icons/fa"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Avatar,
  Box,
  Flex,
  HStack,
  Heading,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react"
import alephzero from "../../../assets/icons/alephzero.jpg"
import Cosmos from "../../../assets/icons/cosmos.svg"
import PolkaDot from "../../../assets/icons/polkadot.svg"
import Solana from "../../../assets/icons/solana.svg"
import EVM from "../../../assets/icons/solidity.svg"
import Flutter from "../../../assets/icons/flutter.svg"
import { CodeBlock } from "../../../components"
import { pxToRem } from "../../../utils"

const CodeRender = ({ language, isProduction, application }: any) => {
  //const applications = useAppSelector(getApplications)

  const recentApp = application ? application : []
  //const recentApp: never[] = []
  // console.log("recentApp", recentApp)
  switch (language) {
    case "react":
      return (
        <Box>
          <Text mb='10px' mt='20px'>
            Install the latest version of the SDK
          </Text>
          <CodeBlock
            code={INSTALL_COMMANDS.react}
            fontSize='14px'
            language='shell'
            canCopy={true}
          />
          <Box mt='20px'>
            <Text mb='10px'>Initialize the SDK and contract on your project:</Text>

            <CodeBlock
              fontSize='14px'
              code={
                isProduction
                  ? getCodeSnippetForProduction(recentApp).reactClient
                  : getCodeSnippetForPrototype(recentApp).reactClient
              }
              language='javascript'
              canCopy={true}
            />
          </Box>
        </Box>
      )
    case "flutter":
      return (
        <Box>
          <Text mb='10px' mt='20px'>
            Install the latest version of the SDK:
          </Text>
          <CodeBlock
            code={INSTALL_COMMANDS.flutter}
            fontSize='14px'
            language='bash'
            canCopy={true}
          />
          <Box mt='20px'>
            <Text mb='10px'>
              Initialize the SDK and Copy Paste the code on your project:
            </Text>

            <CodeBlock
              fontSize='14px'
              language='java'
              code={
                isProduction
                  ? getCodeSnippetForProduction(recentApp).flutter
                  : getCodeSnippetForPrototype(recentApp).flutter
              }
              canCopy={true}
            />
          </Box>
        </Box>
      )
    case "reactNative":
      return (
        <Box>
          <Text mb='10px' mt='20px'>
            Install the latest version of the SDK:
          </Text>
          <CodeBlock
            code={INSTALL_COMMANDS.reactNative}
            fontSize='14px'
            language='shell'
            canCopy={true}
          />
          <Box mt='20px'>
            <Text mb='10px'>Initialize the SDK and copy paste on your project:</Text>

            <CodeBlock
              fontSize='14px'
              code={
                isProduction
                  ? getCodeSnippetForProduction(recentApp).reactNative
                  : getCodeSnippetForPrototype(recentApp).reactNative
              }
              language='javascript'
              canCopy={true}
            />
          </Box>
        </Box>
      )

    case "nodeJS":
      return (
        <Box>
          <Text mb='10px' mt='20px'>
            Install the latest version of the SDK:
          </Text>
          <CodeBlock
            code={INSTALL_COMMANDS.nodeJS}
            fontSize='14px'
            language='shell'
            canCopy={true}
          />
          <Box mt='20px'>
            <Text mb='10px'>
              Initialize the SDK and Copy Paste the functions on your project:
            </Text>

            <CodeBlock
              fontSize='14px'
              code={
                isProduction
                  ? getCodeSnippetForProduction(recentApp).nodeJS
                  : getCodeSnippetForPrototype(recentApp).nodeJS
              }
              language='javascript'
              canCopy={true}
            />
          </Box>
        </Box>
      )

    case "polkaDot":
      return (
        <Box>
          <Text mb='10px' mt='20px'>
            Install the latest version of the SDK:
          </Text>
          <CodeBlock
            code={"COMING SOON"}
            fontSize='14px'
            language='shell'
            canCopy={true}
          />
          <Box mt='20px'>
            <Text mb='10px'>
              Initialize the SDK and Copy Paste the functions on your project:
            </Text>

            <CodeBlock
              fontSize='14px'
              code={"COMING SOON"}
              language='shell'
              canCopy={true}
            />
          </Box>
        </Box>
      )

    case "solana":
      return (
        <Box>
          <Text mb='10px' mt='20px'>
            Add the following dependency over your program's Cargo.toml
          </Text>
          <CodeBlock
            fontSize='14px'
            code={INSTALL_COMMANDS.solana}
            language='shell'
            canCopy={true}
          />
          <Box mt='20px'>
            <Text mb='10px'>
              Add the following boilerplate to your program's lib.rs file
            </Text>
            <CodeBlock
              fontSize='14px'
              code={getCodeSnippetForProduction(recentApp).solana}
              language='shell'
              // language="rust" (Rust isn't having syntax highlighting)
              canCopy={true}
            />
          </Box>
        </Box>
      )

    case "alephzero":
      return (
        <Box>
          <Text mb='10px' mt='20px'>
            Install the latest version of the SDK:
          </Text>
          <CodeBlock
            fontSize='14px'
            code={"COMING SOON"}
            language='bash'
            canCopy={true}
          />
          <Box mt='20px'>
            <Text mb='10px'>
              Initialize the SDK and Copy Paste the functions on your project:
            </Text>

            <CodeBlock
              fontSize='14px'
              code={"COMING SOON"}
              language='bash'
              canCopy={true}
            />
          </Box>
        </Box>
      )

    case "cosmos":
      return (
        <Box>
          <Text mb='10px' mt='20px'>
            Install the latest version of the SDK:
          </Text>
          <CodeBlock
            fontSize='14px'
            code={"COMING SOON"}
            language='bash'
            canCopy={true}
          />
          <Box mt='20px'>
            <Text mb='10px'>
              Initialize the SDK and Copy Paste the functions on your project:
            </Text>

            <CodeBlock
              fontSize='14px'
              code={"COMING SOON"}
              language='bash'
              canCopy={true}
            />
          </Box>
        </Box>
      )

    case "evm":
      return (
        <Box>
          <Text mb='10px' mt='20px'>
            Install the Reclaim Verifier-Solidity SDK
          </Text>
          <CodeBlock
            fontSize='14px'
            code={`npm i @reclaimprotocol/verifier-solidity-sdk`}
            language='shell'
            canCopy={true}
          />
          <Box mt='20px'>
            <CodeBlock
              fontSize='14px'
              code={getCodeSnippetForProduction(recentApp).SolidityAttestor}
              language='javascript'
              canCopy={true}
            />
          </Box>
          <Box mt='20px'>
            <Text mb='10px'>
              After Deploying your Contract and copy paste the functions on your Frontend
              Project.
            </Text>

            <CodeBlock
              fontSize='14px'
              code={getCodeSnippetForProduction(recentApp).EVM}
              language='javascript'
              canCopy={true}
            />
          </Box>
        </Box>
      )

    default:
      return (
        <CodeBlock
          code='Select a Framework/Library/Chains'
          language='bash'
          canCopy={true}
        />
      )
  }
}

export const CodeSegment = ({ application, isProduction, setIsProduction }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedLanguage, setSelectedLanguage] = useState("react")
  // const [isProduction, setIsProduction] = useState(false)
  const { colorMode } = useColorMode()
  const handleTabChange = (index: any) => {
    const languages = [
      "react",
      "reactNative",
      "nodeJS",
      "polkaDot",
      "solana",
      "alephzero",
      "cosmos",
      "evm",
      "flutter",
    ]
    setSelectedLanguage(languages[index])
  }

  return (
    <Tabs onChange={handleTabChange} isLazy>
      <TabList>
        <Tab>
          <HStack>
            <FaReact size='24px' />
            <Text fontSize={pxToRem(14)}>React</Text>
          </HStack>
        </Tab>

        <Tab>
          <HStack>
            <FaReacteurope size='24px' color='teal' />
            <Text fontSize={pxToRem(14)}>React Native</Text>
          </HStack>
        </Tab>
        <Tab>
          <HStack>
            <FaNode size='24px' /> <Text fontSize={pxToRem(14)}>NodeJS</Text>
          </HStack>
        </Tab>
        <>
          <Tab>
            <HStack>
              <Avatar name='PolkaDot' src={PolkaDot} height='24px' width='24px' />{" "}
              <Text fontSize={pxToRem(14)}>PolkaDot</Text>
            </HStack>
          </Tab>
          <Tab>
            <HStack>
              <Avatar name='Solana' src={Solana} height='24px' width='24px' />{" "}
              <Text fontSize={pxToRem(14)}>Solana</Text>
            </HStack>
          </Tab>
          <Tab>
            <HStack>
              <Avatar name='alephzero' src={alephzero} height='24px' width='24px' />{" "}
              <Text fontSize={pxToRem(14)}>alephzero</Text>
            </HStack>
          </Tab>
          <Tab>
            <HStack>
              <Avatar name='Cosmos' src={Cosmos} height='24px' width='24px' />{" "}
              <Text fontSize={pxToRem(14)}>Cosmos</Text>
            </HStack>
          </Tab>
          <Tab>
            <HStack>
              <Avatar name='EVM' src={EVM} height='24px' width='24px' />{" "}
              <Text fontSize={pxToRem(14)}>EVM</Text>
            </HStack>
          </Tab>
        </>
        <Tab>
          <HStack>
            <Avatar name='flutter' src={Flutter} height='24px' width='24px' />{" "}
            <Text fontSize={pxToRem(14)}>Flutter</Text>
          </HStack>
        </Tab>
      </TabList>
      {!["nodeJS", "polkaDot", "cosmos", "alephzero", "evm"].includes(
        selectedLanguage
      ) && (
          <>
            <HStack mt='30px'>
              <Alert borderRadius='lg'>
                <VStack>
                  <HStack>
                    {" "}
                    <AlertIcon />
                    <AlertDescription>
                      {isProduction
                        ? "You are viewing the code in Production mode. This is the finalized, stable version suitable for deployment."
                        : "You are viewing the code in Prototype mode. This mode is suitable for trying out and playing around. It may not be finalized for Production use."}
                    </AlertDescription>
                  </HStack>
                </VStack>
              </Alert>
            </HStack>
            <Box
              borderRadius='lg'
              mt={pxToRem(20)}
              bgColor={colorMode === "dark" ? "#131417" : "#fff"}
              pb={pxToRem(10)}
              pl={pxToRem(20)}
              pt={pxToRem(10)}
            >
              <Box bgColor={colorMode === "dark" ? "#131417" : "#fff"}>
                <HStack>
                  <Text>{isProduction ? "Production" : "Prototype"}</Text>
                  <Switch
                    ml='20px'
                    isChecked={isProduction}
                    onChange={() => setIsProduction(!isProduction)}
                  />
                </HStack>
              </Box>
            </Box>
          </>
        )}
      <TabPanels p={0}>
        <TabPanel p={0}>
          <CodeRender
            language='react'
            isProduction={isProduction}
            application={application}
          />
        </TabPanel>

        <TabPanel p={0}>
          <CodeRender
            language='reactNative'
            application={application}
            isProduction={isProduction}
          />
        </TabPanel>
        <TabPanel p={0}>
          <CodeRender
            language='nodeJS'
            application={application}
            isProduction={isProduction}
          />
        </TabPanel>

        <TabPanel p={0}>
          <CodeRender
            language='polkaDot'
            application={application}
            isProduction={isProduction}
          />
        </TabPanel>

        <TabPanel p={0}>
          <CodeRender
            language='solana'
            application={application}
            isProduction={isProduction}
          />
        </TabPanel>

        <TabPanel p={0}>
          <CodeRender
            language='alephzero'
            application={application}
            isProduction={isProduction}
          />
        </TabPanel>

        <TabPanel p={0}>
          <CodeRender
            language='cosmos'
            application={application}
            isProduction={isProduction}
          />
        </TabPanel>

        <TabPanel p={0}>
          <CodeRender
            language='evm'
            application={application}
            isProduction={isProduction}
          />
        </TabPanel>
        <TabPanel p={0}>
          <CodeRender
            language='flutter'
            application={application}
            isProduction={isProduction}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export const INSTALL_COMMANDS = {
  nodeJS: "npm i @reclaimprotocol/js-sdk",
  react: "npm i @reclaimprotocol/js-sdk",
  reactConnect: "npm install @reclaimprotocol/reclaim-connect-react ",
  reactNative: `npm i @reclaimprotocol/reactnative-sdk`,
  flutter: `flutter pub add reclaim_sdk`,
  evm: "npm i @reclaimprotocol/verifier-solidity-sdk",
  solana: 'reclaim-solana = { version = "0.1.0", features = ["cpi"] }',
}

export const getCodeSnippetForProduction = (app: any) => {
  const nodeJS = `
// Package import
import { Reclaim } from '@reclaimprotocol/js-sdk';

const getVerificationReq = async () => {
      const reclaimClient = new Reclaim.ProofRequest("${app?.publicKey}" );
      const providerIds = [
      ${app.httpProvider
      .map((provider) => `  '${provider?.httpProviderId}', // ${provider?.name}`)
      .join("\n")}
      ];
      const APP_SECRET ="${app?.privateKey}"  // your app secret key.
      
      await reclaimClient.buildProofRequest(providerIds[0])
      reclaimClient.setSignature(
          await Reclaim.generateSignature(
          APP_SECRET
        )
      )
      const {reclaimUrl, statusUrl} = await reclaimClient.createVerificationRequest()
      return {reclaimUrl, statusUrl}
  }
`
  const reactClient = `
// Production

import { Reclaim } from '@reclaimprotocol/js-sdk';

  const getVerificationReq = async () => {
    const APP_ID = "${app?.publicKey}";
    const reclaimClient = new Reclaim.ProofRequest(APP_ID);

    const providerIds = [
  ${app.httpProvider
      .map((provider) => `  '${provider?.httpProviderId}', // ${provider?.name}`)
      .join("\n")}
    ];

    await reclaimClient.buildProofRequest(providerIds[0])
  
    reclaimClient.setSignature(
      // TODO: fetch signature from your backend using APP_ID
    // On the backend, generate signature using:
    )

    const { requestUrl, statusUrl } =
      await reclaimClient.createVerificationRequest()


    await reclaimClient.startSession({
      onSuccessCallback: proof => {
        console.log('Verification success', proof)
        // Your business logic here
      },
      onFailureCallback: error => {
        console.error('Verification failed', error)
        // Your business logic here to handle the error
      }
    })
};


// call when user clicks on a button
// onClick={getVerificationReq}
`
  const reactNative = `
// Production
import { SafeAreaView, Text, View, Pressable } from 'react-native'
import { Reclaim } from '@reclaimprotocol/reactnative-sdk'
 
function App() {
  const reclaimClient = new Reclaim.ProofRequest("${app?.publicKey}") // your app ID.
 
  async function startVerificationFlow() {
    const providerIds = [
      ${app.httpProvider
      .map((provider) => `'${provider?.httpProviderId}', // ${provider?.name}`)
      .join("\n  ")}
      ];
    
 
    const appDeepLink = 'YOUR_APP_DEEP_LINK_HERE' //TODO: replace with your app deep link
    reclaimClient.setAppCallbackUrl(appDeepLink)
 
    await reclaimClient.addContext(
      ("users address"),
      ("add a message")
    )
 
    await reclaimClient.buildProofRequest(providerIds[0])
 
    reclaimClient.setSignature(
     // TODO: fetch signature from your backend using APP_ID
    // On the backend, generate signature using:
    )
 
    const { requestUrl, statusUrl } =
      await reclaimClient.createVerificationRequest()


    await reclaimClient.startSession({
      onSuccessCallback: proof => {
        console.log('Verification success', proof)
        // Your business logic here
      },
      onFailureCallback: error => {
        console.error('Verification failed', error)
        // Your business logic here to handle the error
      }
    })
  }
 
  return (
    <SafeAreaView>
      <View>
        <Pressable onPress={startVerificationFlow}>
          <Text>Start Verification Flow</Text>
        </Pressable>
      </View>
    </SafeAreaView>
  )
}
`
  const EVM = `
import { Reclaim } from "@reclaimprotocol/js-sdk";
import { useEffect, useState } from "react";
import { WagmiProvider } from "wagmi";
import { config } from "./config";
import VerifyProof from "./VerifyProof";
 
const queryClient = new QueryClient();
 
function App() {
  const [url, setUrl] = useState("");
  const [ready, setReady] = useState(true);
  const [proof, setProof] = useState({});
 
  const reclaimClient = new Reclaim.ProofRequest("${app?.publicKey}"); 
 
  async function generateVerificationRequest() {
     const providerIds = [
      ${app.httpProvider
      .map((provider) => `  '${provider?.httpProviderId}', // ${provider?.name}`)
      .join("\n")}
      ];
    
 
    await reclaimClient.addContext(
      ( "user's address"),
      ("add a message") // add a context message
    );
 
    await reclaimClient.buildProofRequest(providerIds[0]);
 
    reclaimClient.setSignature(
      // TODO: fetch signature from your backend using APP_ID
     // On the backend, generate signature using:
    );
 
    const {reclaimUrl, statusUrl} = await reclaimClient.createVerificationRequest()
    
    await reclaimClient.startSession({
      onSuccessCallback: proof => {
        console.log('Verification success', proof)
        setReady(true);
        setProof(Reclaim.transformForOnchain(proof));
        // Your business logic here
      },
      onFailureCallback: error => {
        console.error('Verification failed', error)
        // Your business logic here to handle the error
      }
    })
    
  }
 
  return (
    <div className="App">
      <button onClick={() => generateVerificationRequest()}>
        Generate Verification Request
      </button>
      <button onClick={() => window.open(url, "_blank")}>Open link</button>
      {ready && <VerifyProof proof={proof}></VerifyProof>}
    </div>
  );
}
 
export default App;
`
  const SolidityAttestor = `
// Attestor.sol

// SPDX-License-Identifier: MIT
pragma solidity 0.8.20;
 
 
import "@reclaimprotocol/verifier-solidity-sdk/contracts/Reclaim.sol";
import "@reclaimprotocol/verifier-solidity-sdk/contracts/Addresses.sol";
 
contract Attestor {
  address public reclaimAddress;
  // add providersHashes for your permitted providers
  string[] public providersHashes;

  constructor(string[] memory _providersHashes){
     providersHashes = _providersHashes;
       // TODO: Replace with network you are deploying on
     reclaimAddress = Addresses.PLOYGON_MUMBAI_TESTNET; 

  }  

  function verifyProof(Reclaim.Proof memory proof) public view{
      Reclaim(reclaimAddress).verifyProof(proof, providersHashes);
      // TODO: your business logic upon success
      // verify proof.context is what you expect
  }
}

// refer this example for more details - 
// https://github.com/reclaimprotocol/verifier-sdk-example/blob/main/contracts/Attestor.sol
`
  const flutter = `  
import 'package:reclaim_sdk/flutter_reclaim.dart';
import 'package:reclaim_sdk/types.dart';
import 'dart:convert';

class MyHomePage extends StatefulWidget {
  const MyHomePage({super.key, required this.title});
  final String title;

  @override
  State<MyHomePage> createState() => _MyHomePageState();
}

class _MyHomePageState extends State<MyHomePage> {
  String data = "";

  ProofRequest proofRequest = ProofRequest(applicationId: '${app?.publicKey}');

  void startVerificationFlow() async {
    final providerIds = [
      ${app.httpProvider
      .map((provider) => `  '${provider?.httpProviderId}', // ${provider?.name}`)
      .join("\n      ")}
      ];
    proofRequest.setAppCallbackUrl('YOUR_DEEP_LINK_HERE');

    await proofRequest
        .buildProofRequest(providerIds[0]);

    proofRequest.setSignature(
      // TODO: fetch signature from your backend using APP_ID
     // On the backend, generate signature using:
    );

    final verificationRequest = await proofRequest.createVerificationRequest();

    final startSessionParam = StartSessionParams(
      onSuccessCallback: (proof) => setState(() {
        data = jsonEncode(proof.extractedParameterValues);
      }),
      onFailureCallback: (error) => {
        setState(() {
          data = 'Error: $error';
        })
      },
    );

    await proofRequest.startSession(startSessionParam);
  }

  @override
  Widget build(BuildContext context) {
    return Scaffold(
      appBar: AppBar(
        backgroundColor: Theme.of(context).colorScheme.inversePrimary,
        title: Text(widget.title),
      ),
      body: Center(
        child: Column(
          mainAxisAlignment: MainAxisAlignment.center,
          children: <Widget>[
            const Text(
              'Prove that you have Steam ID By clicking on Verify button:',
            ),
            Text(
              data,
              style: Theme.of(context).textTheme.headlineMedium,
            ),
          ],
        ),
      ),
      floatingActionButton: FloatingActionButton(
        onPressed: startVerificationFlow,
        tooltip: 'Verify ',
        child: const Text('Verify'),
      ),
    );
  }
}`
  const solana = `use anchor_lang::prelude::*;
use anchor_lang::prelude::*;

use reclaim::cpi::accounts::VerifyProof;
use reclaim::cpi::verify_proof;
use reclaim::instructions::VerifyProofArgs;
use reclaim::program::Reclaim;
use reclaim::state::ClaimData as ReclaimClaimData;
use reclaim::state::ClaimInfo as ReclaimClaimInfo;
use reclaim::state::SignedClaim as ReclaimSignedClaim;
use reclaim::state::{Epoch, EpochConfig};

declare_id!("your program id here");

#[derive(AnchorSerialize, AnchorDeserialize)]
pub struct VerifyArgs {
  pub claim_info: ClaimInfo,
  pub signed_claim: SignedClaim,
}

#[derive(AnchorSerialize, AnchorDeserialize, Clone)]
pub struct SignedClaim {
  pub claim_data: ClaimData,
  pub signatures: Vec<[u8; 65]>,
}

#[derive(AnchorSerialize, AnchorDeserialize, Clone)]
pub struct ClaimInfo {
  pub provider: String,
  pub parameters: String,
  pub context_address: Pubkey,
  pub context_message: String,
}

#[derive(AnchorSerialize, AnchorDeserialize, Clone)]
pub struct ClaimData {
  pub identifier: [u8; 32],
  pub owner: String,
  pub timestamp: u32,
  pub epoch_index: u32,
}

#[program]
pub mod base {
  use super::*;

  pub fn verify<'info>(
    ctx: Context<'_, '_, '_, 'info, Verify<'info>>,
    args: VerifyArgs,
  ) -> Result<()> {
    let VerifyArgs {
        claim_info,
        signed_claim,
    } = args;

    let signer_account_info = ctx.accounts.signer.to_account_info();
    let reclaim_program_info = ctx.accounts.reclaim_program.to_account_info();

    let epoch_config_account_info = ctx.accounts.epoch_config.to_account_info();
    let epoch_account_info = ctx.accounts.epoch.to_account_info();

    verify_proof(
        CpiContext::new(
            reclaim_program_info,
            VerifyProof {
                epoch_config: epoch_config_account_info,
                epoch: epoch_account_info,
                signer: signer_account_info,
            },
        ),
        VerifyProofArgs {
            claim_info: ReclaimClaimInfo {
                parameters: claim_info.parameters,
                context_message: claim_info.context_message,
                provider: claim_info.provider,
                context_address: claim_info.context_address,
            },
            signed_claim: ReclaimSignedClaim {
                claim_data: ReclaimClaimData {
                    epoch_index: signed_claim.claim_data.epoch_index,
                    timestamp: signed_claim.claim_data.timestamp,
                    identifier: signed_claim.claim_data.identifier,
                    owner: signed_claim.claim_data.owner,
                },
                signatures: signed_claim.signatures,
            },
        },
    )?;

    Ok(())
  }
}

#[derive(Accounts)]
pub struct Verify<'info> {
  #[account(mut)]
  pub signer: Signer<'info>,
  pub epoch_config: Account<'info, EpochConfig>,
  pub epoch: Account<'info, Epoch>,
  pub reclaim_program: Program<'info, Reclaim>,
  pub system_program: Program<'info, System>,
}	
  `
  return {
    //INSTALL_COMMANDS,
    reactNative,
    reactClient,
    nodeJS,
    SolidityAttestor,
    EVM,
    flutter,
    solana,
  }
}

export const getCodeSnippetForPrototype = (app: any) => {
  const nodeJS = `
// Package import
import { Reclaim } from '@reclaimprotocol/js-sdk';
  
const getVerificationReq = async () => {
      const reclaimClient = new Reclaim.ProofRequest("${app?.publicKey}" );
      const providerIds = [
      ${app.httpProvider
      .map((provider) => `  '${provider?.httpProviderId}', // ${provider?.name}`)
      .join("\n")}
      ];
      const APP_SECRET ="${app?.privateKey}"  // your app secret key.
      
      await reclaimClient.buildProofRequest(providerIds[0])
      reclaimClient.setSignature(
          await reclaimClient.generateSignature(
            APP_SECRET
        )
      )
      const {reclaimUrl, statusUrl} = await reclaimClient.createVerificationRequest()
      return {reclaimUrl, statusUrl}
  }
`
  const reactClient = `
// Prototype
import { Reclaim } from '@reclaimprotocol/js-sdk';

  const getVerificationReq = async () => {
    const APP_ID = "${app?.publicKey}";
    const reclaimClient = new Reclaim.ProofRequest(APP_ID);

    const providerIds = [
  ${app.httpProvider
      .map((provider) => `  '${provider?.httpProviderId}', // ${provider?.name}`)
      .join("\n")}
    ];

    await reclaimClient.buildProofRequest(providerIds[0])

    const APP_SECRET ="${app?.privateKey}"  // your app secret key.
    reclaimClient.setSignature(
        await reclaimClient.generateSignature(APP_SECRET)
    )

    const { requestUrl, statusUrl } =
      await reclaimClient.createVerificationRequest()


    await reclaimClient.startSession({
      onSuccessCallback: proof => {
        console.log('Verification success', proof)
        // Your business logic here
      },
      onFailureCallback: error => {
        console.error('Verification failed', error)
        // Your business logic here to handle the error
      }
    })
};


// call when user clicks on a button
// onClick={getVerificationReq}
`
  const reactNative = `
// Prototype
import { SafeAreaView, Text, View, Pressable } from 'react-native'
import { Reclaim } from '@reclaimprotocol/reactnative-sdk'
 
function App() {
  const reclaimClient = new Reclaim.ProofRequest("${app?.publicKey}") // your app ID. 
  const APP_SECRET ="${app?.privateKey}" // your app secret key.
 
  async function startVerificationFlow() {
    const providerIds = [
      ${app.httpProvider
      .map((provider) => `  '${provider?.httpProviderId}', // ${provider?.name}`)
      .join("\n")}
      ];
    
 
    const appDeepLink = 'YOUR_APP_DEEP_LINK_HERE' //TODO: replace with your app deep link
    reclaimClient.setAppCallbackUrl(appDeepLink)
 
    await reclaimClient.addContext(
      ("users address"),
      ("add a message")
    )
 
    await reclaimClient.buildProofRequest(providerIds[0])
 
    reclaimClient.setSignature(
      await Reclaim.generateSignature(requestProofs, APP_SECRET)
    )
 
    
    const { requestUrl, statusUrl } =
      await reclaimClient.createVerificationRequest()


    await reclaimClient.startSession({
      onSuccessCallback: proof => {
        console.log('Verification success', proof)
        // Your business logic here
      },
      onFailureCallback: error => {
        console.error('Verification failed', error)
        // Your business logic here to handle the error
      }
    })
  }
 
  return (
    <SafeAreaView>
      <View>
        <Pressable onPress={startVerificationFlow}>
          <Text>Start Verification Flow</Text>
        </Pressable>
      </View>
    </SafeAreaView>
  )
}
`
  const flutter = `
import 'package:reclaim_sdk/flutter_reclaim.dart';
import 'package:reclaim_sdk/types.dart';
import 'dart:convert';

class MyHomePage extends StatefulWidget {
  const MyHomePage({super.key, required this.title});
  final String title;

  @override
  State<MyHomePage> createState() => _MyHomePageState();
}

class _MyHomePageState extends State<MyHomePage> {
  String data = "";

  ProofRequest proofRequest = ProofRequest(applicationId: '${app?.publicKey}');

  void startVerificationFlow() async {
    final providerIds = [
      ${app.httpProvider
      .map((provider) => `  '${provider?.httpProviderId}', // ${provider?.name}`)
      .join("\n      ")}
      ];
    proofRequest.setAppCallbackUrl('YOUR_DEEP_LINK_HERE');

    await proofRequest
        .buildProofRequest(providerIds[0]);

    proofRequest.setSignature(proofRequest.generateSignature(
        '${app?.privateKey}'));

    final verificationRequest = await proofRequest.createVerificationRequest();

    final startSessionParam = StartSessionParams(
      onSuccessCallback: (proof) => setState(() {
        data = jsonEncode(proof.extractedParameterValues);
      }),
      onFailureCallback: (error) => {
        setState(() {
          data = 'Error: $error';
        })
      },
    );

    await proofRequest.startSession(startSessionParam);
  }

  @override
  Widget build(BuildContext context) {
    return Scaffold(
      appBar: AppBar(
        backgroundColor: Theme.of(context).colorScheme.inversePrimary,
        title: Text(widget.title),
      ),
      body: Center(
        child: Column(
          mainAxisAlignment: MainAxisAlignment.center,
          children: <Widget>[
            const Text(
              'Prove that you have Steam ID By clicking on Verify button:',
            ),
            Text(
              data,
              style: Theme.of(context).textTheme.headlineMedium,
            ),
          ],
        ),
      ),
      floatingActionButton: FloatingActionButton(
        onPressed: startVerificationFlow,
        tooltip: 'Verify ',
        child: const Text('Verify'),
      ),
    );
  }
}`
  const solana = `use anchor_lang::prelude::*;
use anchor_lang::prelude::*;

use reclaim::cpi::accounts::VerifyProof;
use reclaim::cpi::verify_proof;
use reclaim::instructions::VerifyProofArgs;
use reclaim::program::Reclaim;
use reclaim::state::ClaimData as ReclaimClaimData;
use reclaim::state::ClaimInfo as ReclaimClaimInfo;
use reclaim::state::SignedClaim as ReclaimSignedClaim;
use reclaim::state::{Epoch, EpochConfig};

declare_id!("your program id here");

#[derive(AnchorSerialize, AnchorDeserialize)]
pub struct VerifyArgs {
  pub claim_info: ClaimInfo,
  pub signed_claim: SignedClaim,
}

#[derive(AnchorSerialize, AnchorDeserialize, Clone)]
pub struct SignedClaim {
  pub claim_data: ClaimData,
  pub signatures: Vec<[u8; 65]>,
}

#[derive(AnchorSerialize, AnchorDeserialize, Clone)]
pub struct ClaimInfo {
  pub provider: String,
  pub parameters: String,
  pub context_address: Pubkey,
  pub context_message: String,
}

#[derive(AnchorSerialize, AnchorDeserialize, Clone)]
pub struct ClaimData {
  pub identifier: [u8; 32],
  pub owner: String,
  pub timestamp: u32,
  pub epoch_index: u32,
}

#[program]
pub mod base {
  use super::*;

  pub fn verify<'info>(
    ctx: Context<'_, '_, '_, 'info, Verify<'info>>,
    args: VerifyArgs,
  ) -> Result<()> {
    let VerifyArgs {
        claim_info,
        signed_claim,
    } = args;

    let signer_account_info = ctx.accounts.signer.to_account_info();
    let reclaim_program_info = ctx.accounts.reclaim_program.to_account_info();

    let epoch_config_account_info = ctx.accounts.epoch_config.to_account_info();
    let epoch_account_info = ctx.accounts.epoch.to_account_info();

    verify_proof(
        CpiContext::new(
            reclaim_program_info,
            VerifyProof {
                epoch_config: epoch_config_account_info,
                epoch: epoch_account_info,
                signer: signer_account_info,
            },
        ),
        VerifyProofArgs {
            claim_info: ReclaimClaimInfo {
                parameters: claim_info.parameters,
                context_message: claim_info.context_message,
                provider: claim_info.provider,
                context_address: claim_info.context_address,
            },
            signed_claim: ReclaimSignedClaim {
                claim_data: ReclaimClaimData {
                    epoch_index: signed_claim.claim_data.epoch_index,
                    timestamp: signed_claim.claim_data.timestamp,
                    identifier: signed_claim.claim_data.identifier,
                    owner: signed_claim.claim_data.owner,
                },
                signatures: signed_claim.signatures,
            },
        },
    )?;

    Ok(())
  }
}

#[derive(Accounts)]
pub struct Verify<'info> {
  #[account(mut)]
  pub signer: Signer<'info>,
  pub epoch_config: Account<'info, EpochConfig>,
  pub epoch: Account<'info, Epoch>,
  pub reclaim_program: Program<'info, Reclaim>,
  pub system_program: Program<'info, System>,
}	
  `
  return {
    //  INSTALL_COMMANDS,
    reactNative,
    reactClient,
    nodeJS,
    flutter,
    solana,
    //reactConnect,
  }
}
